import React from 'react'
import PropTypes from 'prop-types'
const Loader = ({ height }) => {
  return (
    <div
      className={`flex flex-col ${
        height ? 'h-screen' : 'h-[calc(100vh_-_300px)]'
      } items-center justify-center m-auto`}
    >
      <div className='h-16 w-16 mx-auto animate-spin rounded-full border-4 border-solid border-primary border-t-transparent'></div>
    </div>
  )
}

Loader.propTypes = {
  height: PropTypes.bool, // Assuming height is a boolean, change if needed
}

export default Loader
