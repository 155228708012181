import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { useDispatch } from 'react-redux'

import { FormikProvider, useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useStepContext } from '../../../../../context/useStepContext'
import { RemoveVehicleDetails } from '../../../../../redux/vehicle/vehicleSlice'

import CustomToast from '../../../../../components/Custom/CustomToast'

import axiosInterceptorInstance from '../../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../../utils/axios/handleRequestError'

import { Icons } from '../../../../../components/Icons'

const {
  FiUpload,
  IoArrowUp,
  MdArrowRightAlt,
  MdDeleteOutline,
  MdOutlineCheck,
  TiArrowDown,
} = Icons

const schema = Yup.object().shape({
  exterior_main: Yup.string().required('Exterior image is required'),
  interior_driver: Yup.string().required('Interior image is required '),
})

const VehicleImages = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const vehicle = searchParams.get('vehicle')

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers, additionalProps) =>
      handleSubmit(values, formikHelpers, additionalProps),
    initialValues: {
      exterior_main: null,
      exterior_front: null,
      exterior_driver: null,
      exterior_back: null,
      exterior_passenger: null,
      exterior_tire: null,
      interior_driver: null,
      interior_dashboard: null,
      interior_console: null,
      another_image_1: null,
      another_image_2: null,
      another_image_3: null,
      video: null,
    },
  })

  const getvehicleDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-images/details/${vehicle}`,
      )
      if (response.data.success) {
        const fieldsToSet = {
          ...response.data.vehicleImages,
        }
        formik.setFieldValue('exterior_main', fieldsToSet.exterior_main || '')
        formik.setFieldValue('exterior_front', fieldsToSet.exterior_front || '')
        formik.setFieldValue(
          'exterior_driver',
          fieldsToSet.exterior_driver || '',
        )
        formik.setFieldValue('exterior_back', fieldsToSet.exterior_back || '')
        formik.setFieldValue(
          'exterior_passenger',
          fieldsToSet.exterior_passenger || '',
        )
        formik.setFieldValue('exterior_tire', fieldsToSet.exterior_tire || '')
        formik.setFieldValue(
          'interior_driver',
          fieldsToSet.interior_driver || '',
        )
        formik.setFieldValue(
          'interior_dashboard',
          fieldsToSet.interior_dashboard || '',
        )
        formik.setFieldValue(
          'interior_console',
          fieldsToSet.interior_console || '',
        )
        formik.setFieldValue(
          'another_image_1',
          fieldsToSet.another_image_1 || '',
        )
        formik.setFieldValue(
          'another_image_2',
          fieldsToSet.another_image_2 || '',
        )
        formik.setFieldValue(
          'another_image_3',
          fieldsToSet.another_image_3 || '',
        )
        formik.setFieldValue('video', fieldsToSet.video || '')
      }
    } catch (error) {
      handleRequestError(error)
    }
  }, [vehicle])

  useEffect(() => {
    getvehicleDetails()
  }, [getvehicleDetails])

  const { step, handleStepClick } = useStepContext()
  const goToPreviousStep = () => {
    if (step > 1) {
      handleStepClick(step - 1)
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [uploadinImage, setUploadinImage] = useState({
    exterior_main: false,
    exterior_front: false,
    exterior_driver: false,
    exterior_back: false,
    exterior_passenger: false,
    exterior_tire: false,
    interior_driver: false,
    interior_dashboard: false,
    interior_console: false,
    another_image_1: false,
    another_image_2: false,
    another_image_3: false,
    video: false,
  })

  const handleSubmit = async (values, { resetForm }, additionalProps) => {
    try {
      if (additionalProps) {
        setIsLoading(true)
      }
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-images/create/${vehicle}`,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (response.data.success) {
        if (additionalProps) {
          navigate(`/parking-lot/${vehicle}`)
        } else {
          navigate('/vehicle')
        }
        dispatch(RemoveVehicleDetails())
        setIsLoading(false)
        CustomToast({
          message: response.data.message || 'Vehicle Added Successfully!',
          type: 'success',
        })
      }
    } catch (error) {
      resetForm()
      handleRequestError(error)
      setIsLoading(false)
    }
  }

  const handleImageChange = async (ImageType, event) => {
    const formData = new FormData()

    formData.append('file', event.currentTarget.files[0])
    formData.append('ImageType', ImageType)

    try {
      setUploadinImage((e) => {
        return { ...e, [ImageType]: true }
      })
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-images/upload/${vehicle}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      formik.setFieldValue(response.data.imageType, response.data.imageUrl)

      CustomToast({
        message: response.data.message,
        type: 'success',
      })
    } catch (error) {
      handleRequestError(error)
    } finally {
      setUploadinImage((e) => {
        return { ...e, [ImageType]: false }
      })
    }
  }

  const imagesDeletehandler = async (ImageType) => {
    try {
      setUploadinImage((e) => {
        return { ...e, [ImageType]: true }
      })
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-images/delete/${vehicle}`,
        { ImageType },
      )
      if (response.data.success) {
        formik.setFieldValue(response.data.imageType, null)
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
      }
    } catch (error) {
      handleRequestError(error)
    } finally {
      setUploadinImage((e) => {
        return { ...e, [ImageType]: false }
      })
      document.getElementById(ImageType).value = ''
    }
  }

  const vehicleSaveAsDrafthandler = async () => {
    navigate('/vehicle')
  }

  const vehicleProceedToLaunchHandler = async () => {
    const errors = await formik.validateForm()
    formik.setTouched({
      exterior_main: true,
      exterior_front: true,
      exterior_driver: true,
      exterior_back: true,
      exterior_passenger: true,
      exterior_tire: true,
      interior_driver: true,
      interior_dashboard: true,
      interior_console: true,
      another_image_1: true,
      another_image_2: true,
      another_image_3: true,
      video: true,
    })

    if (Object.keys(errors).length === 0) {
      handleSubmit(formik.values, formik, 'drafthandler')
    }
  }

  return (
    <div className='bg-white rounded-tr-none rounded-bl-none rounded-br-none listing-submission-form-wrapper lg:rounded-3xl'>
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className='flex flex-col w-full gap-7'
        >
          <div className='flex flex-wrap items-center justify-between w-full gap-2 pb-2 border-b border-dashed border-gray border-opacity-10'>
            <div className='title title-primary'>
              <h5 className='heading-5'>Exterior</h5>
            </div>
            <div className='title title-gray text-40'>
              <h6 className='subHeading-6'>
                We recommend using JPEG or PNG format with dimensions of 1920 by
                1080 pixels.
              </h6>
            </div>
          </div>

          <div className='relative grid w-full grid-cols-2 p-0 m-0 max-sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-6 gap-7 md:gap-5'>
            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>
                  3/4 front main <span className='mr-1.5 text-red'>*</span>
                </h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_main', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_main'
                    defaultValue={formik.values.exterior_main || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_main && (
                    <img
                      src={formik.values.exterior_main}
                      alt='Preview'
                      onError={() => formik.setFieldValue('exterior_main', '')}
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_main ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_main ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_main',
                            formik.values.exterior_main,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_main'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_main &&
                  formik.errors.exterior_main && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_main}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Front</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_front', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_front'
                    defaultValue={formik.values.exterior_front || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_front && (
                    <img
                      src={formik.values.exterior_front}
                      alt='Preview'
                      onError={() => formik.setFieldValue('exterior_front', '')}
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_front ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_front ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_front',
                            formik.values.exterior_front,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_front'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_front &&
                  formik.errors.exterior_front && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_front}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Driver side</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_driver', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_driver'
                    defaultValue={formik.values.exterior_driver || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_driver && (
                    <img
                      src={formik.values.exterior_driver}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('exterior_driver', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_driver ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_driver ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_driver',
                            formik.values.exterior_driver,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_driver'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_driver &&
                  formik.errors.exterior_driver && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_driver}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Back</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_back', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_back'
                    defaultValue={formik.values.exterior_back || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_back && (
                    <img
                      src={formik.values.exterior_back}
                      alt='Preview'
                      onError={() => formik.setFieldValue('exterior_back', '')}
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_back ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_back ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_back',
                            formik.values.exterior_back,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_back'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_back &&
                  formik.errors.exterior_back && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_back}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Passenger side</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_passenger', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_passenger'
                    defaultValue={formik.values.exterior_passenger || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_passenger && (
                    <img
                      src={formik.values.exterior_passenger}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('exterior_passenger', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_passenger ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_passenger ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_passenger',
                            formik.values.exterior_passenger,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_passenger'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_passenger &&
                  formik.errors.exterior_passenger && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_passenger}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Tire</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('exterior_tire', event)
                    }
                    onBlur={formik.handleBlur}
                    id='exterior_tire'
                    defaultValue={formik.values.exterior_tire || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.exterior_tire && (
                    <img
                      src={formik.values.exterior_tire}
                      alt='Preview'
                      onError={() => formik.setFieldValue('exterior_tire', '')}
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.exterior_tire ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.exterior_tire ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'exterior_tire',
                            formik.values.exterior_tire,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='exterior_tire'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.exterior_tire &&
                  formik.errors.exterior_tire && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.exterior_tire}
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className='flex flex-wrap items-center justify-between w-full gap-2 pb-2 border-b border-dashed border-gray border-opacity-10'>
            <div className='title title-primary'>
              <h5 className='heading-5'>Interior</h5>
            </div>
          </div>

          <div className='relative grid w-full grid-cols-2 p-0 m-0 max-sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-6 gap-7 md:gap-5'>
            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>
                  Interior driver side
                  <span className='mr-1.5 text-red'>*</span>
                </h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('interior_driver', event)
                    }
                    onBlur={formik.handleBlur}
                    id='interior_driver'
                    defaultValue={formik.values.interior_driver || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.interior_driver && (
                    <img
                      src={formik.values.interior_driver}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('interior_driver', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.interior_driver ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.interior_driver ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'interior_driver',
                            formik.values.interior_driver,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='interior_driver'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.interior_driver &&
                  formik.errors.interior_driver && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.interior_driver}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Dashboard</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('interior_dashboard', event)
                    }
                    onBlur={formik.handleBlur}
                    id='interior_dashboard'
                    defaultValue={formik.values.interior_dashboard || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.interior_dashboard && (
                    <img
                      src={formik.values.interior_dashboard}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('interior_dashboard', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.interior_dashboard ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.interior_dashboard ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'interior_dashboard',
                            formik.values.interior_dashboard,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='interior_dashboard'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.interior_dashboard &&
                  formik.errors.interior_dashboard && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.interior_dashboard}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='mb-2 title title-gray text-opacity-90'>
                <h6 className='subHeading-6'>Console</h6>
              </div>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('interior_console', event)
                    }
                    onBlur={formik.handleBlur}
                    id='interior_console'
                    defaultValue={formik.values.interior_console || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.interior_console && (
                    <img
                      src={formik.values.interior_console}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('interior_console', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.interior_console ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.interior_console ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'interior_console',
                            formik.values.interior_console,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='interior_console'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.interior_console &&
                  formik.errors.interior_console && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.interior_console}
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className='flex flex-wrap items-center justify-between w-full gap-2 pb-2 border-b border-dashed border-gray border-opacity-10'>
            <div className='title title-primary'>
              <h5 className='heading-5'>Additional images</h5>
            </div>
          </div>

          <div className='relative grid items-end w-full grid-cols-2 p-0 m-0 max-sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 2xl:grid-cols-6 gap-7 md:gap-5'>
            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('another_image_1', event)
                    }
                    onBlur={formik.handleBlur}
                    id='another_image_1'
                    defaultValue={formik.values.another_image_1 || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.another_image_1 && (
                    <img
                      src={formik.values.another_image_1}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('another_image_1', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.another_image_1 ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.another_image_1 ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'another_image_1',
                            formik.values.another_image_1,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='another_image_1'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.another_image_1 &&
                  formik.errors.another_image_1 && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.another_image_1}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('another_image_2', event)
                    }
                    onBlur={formik.handleBlur}
                    id='another_image_2'
                    defaultValue={formik.values.another_image_2 || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.another_image_2 && (
                    <img
                      src={formik.values.another_image_2}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('another_image_2', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.another_image_2 ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.another_image_2 ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'another_image_2',
                            formik.values.another_image_2,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='another_image_2'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.another_image_2 &&
                  formik.errors.another_image_2 && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.another_image_2}
                    </div>
                  )}
              </div>
            </div>

            <div className='flex flex-col items-start justify-start w-full border-b last:border-0 border-gray border-opacity-10 md:border-0'>
              <div className='w-full '>
                <div className='w-full rounded-lg bg-primary bg-opacity-5  border-[1px] border-dashed border-primary border-opacity-20 relative overflow-hidden pt-[60%] sm:pt-[40%] md:pt-[60%]'>
                  <input
                    accept='image/*'
                    className='hidden z-1'
                    type='file'
                    onChange={(event) =>
                      handleImageChange('another_image_3', event)
                    }
                    onBlur={formik.handleBlur}
                    id='another_image_3'
                    defaultValue={formik.values.another_image_3 || null}
                    disabled={Object.values(uploadinImage).some(
                      (value) => value,
                    )}
                  />
                  {formik.values.another_image_3 && (
                    <img
                      src={formik.values.another_image_3}
                      alt='Preview'
                      onError={() =>
                        formik.setFieldValue('another_image_3', '')
                      }
                      className='absolute top-0 left-0 object-cover w-full h-full'
                    />
                  )}
                  <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                    {uploadinImage.another_image_3 ? (
                      <div className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-opacity-50 rounded-full bg-primary '>
                        <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                      </div>
                    ) : formik.values.another_image_3 ? (
                      <div
                        onClick={() =>
                          imagesDeletehandler(
                            'another_image_3',
                            formik.values.another_image_3,
                          )
                        }
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <MdDeleteOutline />
                      </div>
                    ) : (
                      <label
                        htmlFor='another_image_3'
                        className='flex flex-wrap items-center justify-center w-8 h-8 mx-auto mb-1 bg-white bg-opacity-50 rounded-full cursor-pointer text-primary hover:bg-primary hover:text-white'
                      >
                        <FiUpload />
                      </label>
                    )}
                  </div>
                </div>
                {formik.touched.another_image_3 &&
                  formik.errors.another_image_3 && (
                    <div className='text-sm font-normal text-danger'>
                      {formik.errors.another_image_3}
                    </div>
                  )}
              </div>
            </div>
          </div>

          {/* submit button */}
          <div className='flex justify-between  mt-8'>
            <button
              type='reset'
              className='prev next-prev-button '
              onClick={goToPreviousStep}
            >
              <MdArrowRightAlt /> Back
            </button>

            <div className='flex flex-col  sm:flex-row gap-2 justify-end'>
              <button
                className='next-prev-button  btn btn-primary-ico'
                type='button'
                onClick={vehicleSaveAsDrafthandler}
              >
                Save as Draft <TiArrowDown />
              </button>

              <button
                type='button'
                className='btn btn-primary-ico '
                disabled={isLoading}
                onClick={vehicleProceedToLaunchHandler}
              >
                {isLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    Proceed to Launch <IoArrowUp />
                  </Fragment>
                )}
              </button>

              <button
                type='submit'
                className='btn btn-primary-ico'
                disabled={formik.isSubmitting && !isLoading}
              >
                {formik.isSubmitting && !isLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    Complete
                    <MdOutlineCheck />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}

export default VehicleImages
