import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../utils/axios/handleRequestError'
import CopyToClipboard from '../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../components/Icons'

const { IoSearchSharp } = Icons

const Search = () => {
  const navigate = useNavigate()
  const { role } = useSelector((state) => state.user)

  const [resentActivity, setResentActivity] = useState([])

  const ResentActivityListHandler = async () => {
    try {
      const result = await axiosInterceptorInstance.get(
        `/auth/user/get/activity/list`,
      )
      if (result.data.success) {
        setResentActivity(result.data.data.recent_activity)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    ResentActivityListHandler()
  }, [])

  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [vehicleid, setVehicleid] = useState(null)
  const [selctSearchMode, setSelctSearchMode] = useState(false)
  const [searchResult, setSearchResult] = useState([])

  const selctSeaechModeref = useRef(null)

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!selctSeaechModeref?.current?.contains(target)) {
        setSelctSearchMode(false)
        setSelectedSuggestionIndex(-1)
      }
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  const handlesearchChange = (e) => {
    setSearchText(e.target.value.trim())

    if (e.target?.value?.trim()?.length > 0) {
      setSelctSearchMode(true)
    } else {
      setSelctSearchMode(false)
    }
  }

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)

  const handleKeyDown = (event) => {
    if (selctSearchMode && searchResult.length > 0) {
      switch (event.key) {
        case 'ArrowUp':
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : searchResult.length - 1,
          )
          break
        case 'ArrowDown':
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex < searchResult.length - 1 ? prevIndex + 1 : 0,
          )
          break
        case 'Enter':
          searchresultshowhandler('Enter', vehicleid)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (selectedSuggestionIndex !== -1) {
      setVehicleid(searchResult[selectedSuggestionIndex]?._id)
    }
  }, [selectedSuggestionIndex, searchResult])

  const searchhandler = useCallback(async () => {
    try {
      setIsSearching(true)
      const result = await axiosInterceptorInstance.post(
        '/vehicle/buyer/vehicle-details/search/list',
        { text: searchText },
      )
      if (result.data.success) {
        setIsSearching(false)
        setSearchResult(result.data.data)
      }
    } catch (error) {
      setIsSearching(false)
    }
  }, [searchText])

  useEffect(() => {
    if (searchText.length > 2) {
      searchhandler()
    } else {
      setSearchResult([])
      setSelectedSuggestionIndex(-1)
    }
  }, [searchText, searchhandler])

  const searchresultshowhandler = (e, vehicleid) => {
    if (vehicleid) {
      setSelectedSuggestionIndex(-1)
      navigate(`/vehicle/${vehicleid}`)
      setSearchText('')
      ResentActivityHandler(vehicleid)
    }
  }

  const ResentActivityHandler = async (vehicleid) => {
    try {
      const result = await axiosInterceptorInstance.post(
        `/auth/user/add/activity/${vehicleid}`,
      )

      if (result.data.success) {
        ResentActivityListHandler()
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  return (
    <div className='flex flex-col h-full py-5 overflow-y-auto duration-300 ease-linear no-scrollbar'>
      <div className='px-5 mb-5 title '>
        <h6 className='text-lg font-medium text-gray'>Search</h6>
      </div>
      <div className=' mb-5 px-5 relative h-[46px] w-full'>
        <div className='absolute w-full max-w-[calc(100%_-_40px)] z-50 block'>
          <header className='w-full Search-header'>
            <div className='w-full'>
              <div className='relative flex-auto'>
                <div
                  ref={selctSeaechModeref}
                  className={`w-full py-2  bg-white border border-light-gray focus:border-primary ${
                    searchResult.length > 0 || selctSearchMode
                      ? 'rounded-xl'
                      : 'rounded-full'
                  }`}
                >
                  <span className='absolute top-[25px] translate-y-[-50%] left-0 flex items-center px-3 text-lg text-opacity-40'>
                    <IoSearchSharp className='text-primary' />
                  </span>

                  <input
                    className='w-[calc(100%_-_25px)] focus:outline-none rounded-full px-2 py-1 ms-6 '
                    type='text'
                    placeholder='search vechicle'
                    onChange={handlesearchChange}
                    onKeyDown={handleKeyDown}
                    id='search-vehicle'
                    name='searchVehicle'
                  />

                  {selctSearchMode ? (
                    isSearching ? (
                      <div className='pt-4 m-2 text-center border-t text-light-gray border-light-gray'>
                        Loading...
                      </div>
                    ) : searchResult.length > 0 ? (
                      <ul className='mx-2 border-t border-light-gray'>
                        {searchResult?.map((searchdata, index) => {
                          return (
                            <li
                              onClick={(e) =>
                                searchresultshowhandler(e, searchdata._id)
                              }
                              key={index}
                              // eslint-disable-next-line react/no-unknown-property
                              vehicle_id={searchdata._id}
                              className={`block my-2 p-1 px-2 font-medium rounded-md  ${
                                selectedSuggestionIndex === index
                                  ? 'bg-light-gray text-primary border-s-4 border-gray'
                                  : 'hover:bg-light-gray hover:text-primary hover:border-s-4 hover:border-gray'
                              }bg-opacity-10`}
                            >
                              {searchdata.year} {searchdata.make}
                              {searchdata.model}
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <div className='pt-4 m-2 text-center border-t text-light-gray border-light-gray'>
                        No result
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>

      <div className='searchList'>
        <p>Recent Activity</p>
        <div className='list'>
          {resentActivity.length > 0 ? (
            resentActivity?.map((activity, index) => {
              return (
                <div key={index} className='innerlist'>
                  <div>
                    <p>
                      {activity.year} {activity.make} {activity.model}
                    </p>
                    <p>
                      <CopyToClipboard> {activity.vin_number}</CopyToClipboard>
                    </p>
                  </div>
                  <div className='action-buttons'>
                    {activity.vehicle_auction?.status === 'live' && (
                      <button className='btn btn-primary'>
                        {activity.vehicle_auction.bid_count}
                        &nbsp; Offer
                      </button>
                    )}

                    {role === 'seller' ? (
                      <>
                        {activity.vehicle_auction?.status === 'live' ? (
                          <Link
                            to={`/live/${activity._id}`}
                            className='btn btn-primary'
                          >
                            View
                          </Link>
                        ) : (
                          <Link
                            to={`/vehicle/${activity._id}`}
                            className='btn btn-primary'
                          >
                            View
                          </Link>
                        )}
                      </>
                    ) : (
                      <>
                        {activity.vehicle_auction?.status === 'live' && (
                          <Link
                            to={`/active/${activity._id}`}
                            className='btn btn-primary'
                          >
                            Place Offer
                          </Link>
                        )}
                        {activity.vehicle_auction?.status === 'scheduled' && (
                          <Link
                            to={`/upcoming/${activity._id}`}
                            className='btn btn-primary'
                          >
                            View
                          </Link>
                        )}
                        {activity.vehicle_auction?.status === 'closed' && (
                          <Link
                            to={`/awarded/${activity._id}`}
                            className='btn btn-primary'
                          >
                            View
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )
            })
          ) : (
            <p className='noResentActivity'>No Recent Activity</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Search
