import React, { useEffect, useRef } from 'react'
import { Fancybox as NativeFancybox } from '@fancyapps/ui'
import PropTypes from 'prop-types'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'

const CustomChatImageView = ({ Images, ...props }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current

    NativeFancybox.bind(container, {
      delegate: '[data-fancybox="gallery"]',
    })

    return () => {
      NativeFancybox.unbind(container)
      NativeFancybox.close()
    }
  }, [])

  return (
    <div ref={containerRef}>
      <a href={Images} data-fancybox='gallery'>
        <img
          src={Images}
          className='max-w-[200px] h-auto max-h-[200px] inline-block rounded-md'
          alt='img'
          onError={(e) => vehicleBrokenImage(e)}
          {...props}
        />
      </a>
    </div>
  )
}

CustomChatImageView.propTypes = {
  Images: PropTypes.string.isRequired,
}

export default CustomChatImageView
