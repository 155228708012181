import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Fancybox from '../../../../components/Custom/ImageViewer'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomVehicleDetails from '../../../../components/Custom/CustomBuyVehicleDetails'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

const View = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setVehicleDetails] = useState()

  const fetchVehicleDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/${id}`,
      )
      setVehicleDetails(response.data.vehicleDetails)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchVehicleDetails()
  }, [fetchVehicleDetails])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <section className='details-view-single'>
        <CustomTitle backBtn />
        <CustomPageTitle
          vehicleDetails={vehicleDetails}
          isViewVehicleDetailsBtn
        />
        <div className='m-0 p-0 w-full border-b border-gray border-opacity-10 pb-10 mb-[40px] last:mb-0'>
          <Fancybox vehicleImages={vehicleDetails?.vehicle_image} />
        </div>
        <CustomVehicleDetails vehicleDetails={vehicleDetails} />
      </section>
    </CustomLoadingSection>
  )
}

export default View
