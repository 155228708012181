import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import LiveOffers from '../../../views/Seller/Home/LiveOffers'
import ScheduledOffers from '../../../views/Seller/Home/ScheduledOffers'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

import Car_img from '../../../assets/car/car.png'

import 'swiper/css'
import 'swiper/css/pagination'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [liveCount, setLiveCount] = useState()
  const [scheduledCount, setScheduledCount] = useState()
  const [announcementList, setAnnouncementList] = useState([
    {
      title: 'wellcome to Revvit.',
      message:
        'Choose Revvit for an unparalleled vehicle inventory management solution. With our wide range of options, quality assurance, effortless inventory management, and competitive pricing, you can keep your customers happy while growing your business.',
    },
  ])

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-details/vehicle-count',
      )
      if (response.data.success) {
        setIsLoading(false)
        setLiveCount(response.data.result.live)
        setScheduledCount(response.data.result.scheduled)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const fetchAnnouncements = async () => {
    try {
      setIsLoading(true)
      const response = await axiosInterceptorInstance.get(
        `/auth/user/list/announcements`,
      )
      setAnnouncementList(response.data.announcementList)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchAnnouncements()
  }, [])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <div>
        <div className='relative w-full mb-8 border rounded-lg lg:block border-primary'>
          <Swiper
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className='mySwiper'
          >
            {announcementList?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className='grid p-6 pb-12 md:grid-cols-3 md:p-12'>
                    <div className='col-span-2'>
                      <h4 className='text-2xl font-normal capitalize'>
                        {item?.title}
                      </h4>
                      <p className='mt-2 text-sm font-normal text-silver'>
                        {item?.message?.length > 200
                          ? `${item?.message?.slice(0, 200)}...`
                          : item?.message}
                      </p>
                      <div className='mt-8'>
                        <NavLink
                          to={`/announcement/${item?._id}`}
                          className='px-12 py-4 bg-primary hover:bg-white text-sm font-normal text-white hover:text-primary border border-primary rounded-md mt-6'
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className='absolute bottom-0 right-0 hidden md:block'>
            <img src={Car_img} alt='car-img' className='h-[170px]' />
          </div>
        </div>
      </div>

      {scheduledCount === undefined && liveCount === undefined ? (
        <Fragment>
          <CustomTitle customTitle='Your Live Offers' />
          <VehicleListingComponent />
        </Fragment>
      ) : scheduledCount > 0 ? (
        ''
      ) : (
        <Fragment>
          <CustomTitle customTitle='Your Live Offers' />
          <LiveOffers />
        </Fragment>
      )}

      {scheduledCount === undefined && liveCount === undefined ? (
        <Fragment>
          <CustomTitle customTitle='Your Scheduled Offers' />
          <VehicleListingComponent />
        </Fragment>
      ) : liveCount > 0 ? (
        ''
      ) : (
        <Fragment>
          <CustomTitle customTitle='Your Scheduled Offers' />
          <ScheduledOffers />
        </Fragment>
      )}

      {scheduledCount !== undefined && liveCount !== undefined ? (
        <Fragment>
          <CustomTitle customTitle='Your Live Offers' />
          <LiveOffers />
          <CustomTitle customTitle='Your Scheduled Offers' />
          <ScheduledOffers />
        </Fragment>
      ) : (
        ''
      )}
    </CustomLoadingSection>
  )
}

export default Home

const VehicleListingComponent = () => {
  return (
    <div className='flex flex-col items-center justify-center mt-4 text-center no-result'>
      <div className='title title-gray'>
        <h5 className='subHeading-5'>
          There are no vehicle listings available.
        </h5>
      </div>
    </div>
  )
}
