import React, { useState, useEffect, Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'

const CustomStopWatch = ({ end_date, timezone }) => {
  const calculateTimeRemaining = useCallback(() => {
    let now = null
    if (timezone) {
      let options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }
      const formatter = new Intl.DateTimeFormat('en-US', options) //eslint-disable-line no-undef
      now = new Date(formatter.format(new Date()))
    } else {
      now = new Date()
    }
    // const end = new Date(end_date)
    const end = new Date(end_date?.replace(/-/g, '/'))
    const timeDiff = end - now

    if (timeDiff <= 0) {
      return { minutes: 0, seconds: 0 }
    }

    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

    return { minutes, seconds }
  }, [end_date, timezone])

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining)

  useEffect(() => {
    if (end_date) {
      var interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining())
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [end_date, calculateTimeRemaining])

  const { minutes, seconds } = timeRemaining

  const formatTime = (value) => (value < 10 ? `0${value}` : value)

  return <Fragment>{`${formatTime(minutes)}:${formatTime(seconds)}`}</Fragment>
}

CustomStopWatch.propTypes = {
  end_date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
}

export default CustomStopWatch
