import axios from 'axios'
import {
  getRegistrationToken,
  getRole,
  removeRegistrationToken,
  removeRole,
} from '../../utils/cookies/cookies'

import configManager from '../../config/configManager'
const configData = configManager()

const axiosRegistrationInstance = axios.create({
  baseURL: configData.API_URL,
})

axiosRegistrationInstance.interceptors.request.use(
  (config) => {
    const accessToken = getRegistrationToken()
    const role = getRole()

    config.headers['X-Revvit-role'] = role

    if (accessToken) {
      if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  },
)

axiosRegistrationInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { response } = error

    if (response) {
      const { status } = response
      if (status === 401 && window.location.pathname !== '/login') {
        logoutUser()
      }
    }

    return Promise.reject(error)
  },
)

const logoutUser = () => {
  removeRole()
  removeRegistrationToken()
  window.location.reload()
}

export default axiosRegistrationInstance
