import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Field, FormikProvider, useFormik } from 'formik'
import { useSearchParams, useNavigate } from 'react-router-dom'

import CustomInput from '../../../../../custom/CustomInput'
import CustomRadioInput from '../../../../../custom/CustomRadioInput'
import CustomSelect from '../../../../../custom/CustomSelect'
import CustomLoadingSection from '../../../../../components/Custom/CustomLoadingSection'

import CustomToast from '../../../../../components/Custom/CustomToast'
import { useStepContext } from '../../../../../context/useStepContext'

import axiosInterceptorInstance from '../../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../../utils/axios/handleRequestError'

import { Icons } from '../../../../../components/Icons'

const { TiArrowDown, MdArrowRightAlt } = Icons

const mileageList = [
  { value: 'mi', label: 'MI' },
  { value: 'km', label: 'KM' },
]

const has_accidentList = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

const colorList = [
  { value: 'Black', label: 'Black' },
  { value: 'White', label: 'White' },
  { value: 'Gray', label: 'Gray' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Red', label: 'Red' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Green', label: 'Green' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Purple', label: 'Purple' },
  { value: 'Brown', label: 'Brown' },
  { value: 'Beige', label: 'Beige' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Copper', label: 'Copper' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Turquoise', label: 'Turquoise' },
  { value: '#FF00FF', label: 'Magenta' },
  { value: 'Magenta', label: 'Lime' },
]

const schema = Yup.object().shape({
  odometer: Yup.string().required('Please enter odometer'),
  mileage_unit: Yup.string().required('Please select mileage unit '),
  interior_color: Yup.string().required('Please select interior color '),
  exterior_color: Yup.string().required('Please select exterior color '),
  has_accident: Yup.string().required('Please select accident information'),
})

const VehicleDetails = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const vehicle = searchParams.get('vehicle')

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers, additionalProps) =>
      handleSubmit(values, formikHelpers, additionalProps),
    initialValues: {
      odometer: '',
      mileage_unit: '',
      interior_color: '',
      exterior_color: '',
      has_accident: '',
      stock_number: '',
    },
  })

  const getvehicleDetails = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/${vehicle}`,
      )
      if (response.data.success) {
        formik.setValues({
          ...formik.values,
          ...response?.data?.vehicleDetails,
        })
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [vehicle])

  useEffect(() => {
    getvehicleDetails()
  }, [getvehicleDetails])

  const { step, handleStepClick, steps } = useStepContext()
  const goToPreviousStep = () => {
    if (step > 1) {
      handleStepClick(step - 1)
    }
  }
  const goToNextStep = () => {
    if (step < steps.length) {
      handleStepClick(step + 1)
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values, { resetForm }, additionalProps) => {
    try {
      setIsLoading(true)
      const response = await axiosInterceptorInstance.put(
        `/vehicle/seller/vehicle-details/update/${vehicle}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data.success) {
        if (additionalProps) {
          navigate(`/vehicle`)
          CustomToast({
            message: 'The vehicle has been successfully moved to draft.',
            type: 'success',
          })
          setIsLoading(false)
        } else {
          resetForm()
          setIsLoading(false)
          goToNextStep()
          CustomToast({
            message:
              response.data.message ||
              'Vehicle information has been updated successfully.',
            type: 'success',
          })
        }
      }
    } catch (error) {
      resetForm()
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const {
    isSubmitting,
    values: { interior_color, exterior_color },
  } = formik

  const vehicleSaveAsDrafthandler = () => {
    handleSubmit(formik.values, formik, 'drafthandler')
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-10'>
            <Field
              name='odometer'
              label='Odometer'
              type='text'
              placeholder='Enter Odometer'
              component={CustomInput}
              className='form-field'
              required
            />
            <Field
              name='mileage_unit'
              label='Mileage Unit'
              component={CustomRadioInput}
              options={mileageList}
              required
              className='custom-radio'
            />
            <Field
              name='interior_color'
              label='Interior Color'
              placeholder='Select interior color'
              component={CustomSelect}
              options={colorList}
              value={
                colorList.find((option) => option.value === interior_color) ??
                []
              }
              required
            />
            <Field
              name='exterior_color'
              label='Exterior Color'
              placeholder='Select exterior color'
              component={CustomSelect}
              options={colorList}
              value={
                colorList.find((option) => option.value === exterior_color) ??
                []
              }
              required
            />
            <Field
              name='stock_number'
              label='Stock Number'
              placeholder='Enter Stock Number'
              component={CustomInput}
              className='form-field'
            />

            <Field
              name='has_accident'
              label='Has Accident ?'
              component={CustomRadioInput}
              options={has_accidentList}
              required
              className='custom-radio'
            />
          </div>
          <div className='flex justify-between mt-8'>
            <button
              type='reset'
              className='prev next-prev-button '
              onClick={goToPreviousStep}
            >
              <MdArrowRightAlt /> Back
            </button>

            <div className='flex flex-col  sm:flex-row gap-2 justify-end'>
              <button
                className='next-prev-button  btn btn-primary-ico'
                type='submit'
                disabled={isSubmitting}
                onClick={vehicleSaveAsDrafthandler}
              >
                Save as Draft <TiArrowDown />
              </button>

              <button
                type='submit'
                className='next-prev-button  btn btn-primary-ico'
                disabled={isSubmitting}
              >
                {isLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    Save & Next
                    <MdArrowRightAlt />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

export default VehicleDetails
