import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Notification from './Notification'

import Logo from '../../assets/logo/revvit-logo.png'

import { Icons } from '../Icons'

const { RiMenu2Fill } = Icons

const Header = ({
  sidebarOpen,
  setSidebarOpen,
  setNotificationSidebarOpen,
  // notificationSidebarOpen,
  notificationCount,
}) => {
  return (
    <header className='lg:hidden sticky top-0 z-[999] w-full h-[55px] flex bg-white drop-shadow-1 border-b border-light-gray'>
      <div className='h-[55px] flex flex-grow items-center lg:justify-end justify-between shadow-2 py-4 px-4 md:px-6 2xl:px-11'>
        <div className='flex items-center gap-4 lg:hidden'>
          <button
            aria-controls='sidebar'
            onClick={(e) => {
              e.stopPropagation()
              setSidebarOpen(!sidebarOpen)
            }}
            className='z-[99999] block lg:hidden'
          >
            <RiMenu2Fill className='w-6 h-6' />
          </button>

          <Link className='block flex-shrink-0 lg:hidden' to='/'>
            <img src={Logo} alt='Logo' className='w-28 h-8' />
          </Link>
        </div>
        <Notification
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          setNotificationSidebarOpen={setNotificationSidebarOpen}
          notificationCount={notificationCount}
        />
      </div>
    </header>
  )
}

Header.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
  setNotificationSidebarOpen: PropTypes.func.isRequired,
  notificationSidebarOpen: PropTypes.bool.isRequired,
  notificationCount: PropTypes.number,
}

export default Header
