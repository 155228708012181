import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// layout
import DefaultLayout from './layout/DefaultLayout'

// routes
import PublicRoute from './components/Custom/CustomRoutes/PublicRoute'
import PrivateRoute from './components/Custom/CustomRoutes/PrivateRoute'
import RegistrationRoute from './components/Custom/CustomRoutes/RegistrationRoute'

// public
import PageNotFound from './pages/PageNotFound'
import Signup from './pages/Auth/Signup/Signup'
import SignupSuccess from './pages/Auth/Signup/SignupSuccess'
import Login from './pages/Auth/Login/Login'
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPasswordRequest'
import ForgotPasswordSuccess from './pages/Auth/ForgotPassword/ForgotPasswordRequestSuccess'
import ResetPassword from './pages/Auth/ForgotPassword/ResetPassword'
import ResetPasswordSuccess from './pages/Auth/ForgotPassword/ResetPasswordSuccess'
import ForgotPasswordLinkExpired from './pages/Auth/ForgotPassword/ForgotPasswordLinkExpired'

// register
import RegistrationProcess from './pages/Auth/RegistrationProcess'
import AccountPending from './views/RegistrationProcess/AccountPending'

// navigation
import SellerRoutes from './navigation/Seller'
import BuyerRoutes from './navigation/Buyer'

// utils
import { getRole } from './utils/cookies/cookies'
import { updateRole } from './redux/user/userSlice'

import SelectRole from './pages/Auth/SelectRole/SelectRole'
import SwitchRoleRegistration from './views/Common/SwitchRoleRegistration'
import SwitchRoleRequestPending from './views/Common/SwitchRoleRegistration/SwitchRoleRequestPending'

// components
import Loader from './components/Loader'
import CustomHistoryChangeEvent from './components/Custom/CustomHistoryChangeEvent'

import { NotificationProvider } from './utils/firebaseNotification/firebaseConfig'
import SwitchRoleRoute from './components/Custom/CustomRoutes/SwitchRoleRoute'
import AppDownload from './utils/AppDownload'

const App = () => {
  const dispatch = useDispatch()
  const [isPwaInstall, setIsPwaInstall] = useState(false)

  const { role } = useSelector((state) => state.user)

  useEffect(() => {
    if (role === null) {
      const role2 = getRole()
      dispatch(updateRole(role2))
    }
    setTimeout(() => setLoading(false), 1000)
  }, [role, dispatch])

  const [loading, setLoading] = useState(true)

  const CheckIsPwaInstalled = () => {
    return window.matchMedia('(display-mode: standalone)').matches
  }

  useEffect(() => {
    setIsPwaInstall(!CheckIsPwaInstalled())
  }, [])

  return loading ? (
    <Loader height />
  ) : (
    <BrowserRouter>
      <CustomHistoryChangeEvent>
        <NotificationProvider>
          {isPwaInstall && <AppDownload />}
          <Routes>
            <Route path='/*' element={<PageNotFound />} />

            <Route element={<PublicRoute />}>
              <Route path='/signup' element={<Signup />} />
              <Route path='/specify/role/:id' element={<SelectRole />} />
              <Route path='/signup/success' element={<SignupSuccess />} />
              <Route path='/login' element={<Login />} />
              <Route path='/password/forgot' element={<ForgotPassword />} />
              <Route
                path='/password/forgot/success'
                element={<ForgotPasswordSuccess />}
              />
              <Route path='/password/reset/:id' element={<ResetPassword />} />
              <Route
                path='/password/reset/success'
                element={<ResetPasswordSuccess />}
              />
              <Route
                path='/link/expired'
                element={<ForgotPasswordLinkExpired />}
              />
            </Route>

            <Route element={<RegistrationRoute />}>
              <Route
                path='/registration/process/:id?'
                element={<RegistrationProcess />}
              />

              <Route path='/account/pending' element={<AccountPending />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route element={<DefaultLayout />}>
                <Route element={<SwitchRoleRoute />}>
                  <Route
                    path='/switch-role/registration'
                    element={<SwitchRoleRegistration />}
                  />
                  <Route
                    path='/switch-role/pending'
                    element={<SwitchRoleRequestPending />}
                  />
                </Route>
                {role === 'seller'
                  ? SellerRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      )
                    })
                  : BuyerRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      )
                    })}

                {role === 'buyer'
                  ? BuyerRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      )
                    })
                  : SellerRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      )
                    })}
              </Route>
            </Route>
          </Routes>
        </NotificationProvider>
      </CustomHistoryChangeEvent>
    </BrowserRouter>
  )
}

export default App
