import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomButtonWithIcon from '../../../../components/Custom/CustomButtonWithIcon'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import placeholderCar from '../../../../assets/car/car-dummy.png'
import gif from '../../../../assets/gif/car-jump.gif'
import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../../../components/Icons'

const {
  RiErrorWarningLine,
  IoCarSportOutline,
  IoSettingsOutline,
  IoSpeedometerOutline,
  MdOutlineAddPhotoAlternate,
  MdOutlineLocalOffer,
  MdRemoveRedEye,
} = Icons

const PendingList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [pendingVehicleList, setPendingVehicleList] = useState([])

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-details/list/pending',
      )
      if (response.data.success) {
        setPendingVehicleList(response.data.vehicleDetailsList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Fragment>
      <CustomTitle />
      <CustomLoadingSection isLoading={isLoading}>
        {pendingVehicleList.length > 0 ? (
          <Fragment>
            <div className='grid w-full gap-5 py-2 mb-5 border-b lg:max-w-none lg:w-auto max-sm:grid-cols-2 max-lg:grid-cols-3 max-large:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 border-gray border-opacity-10 place-items-center lg:py-5 last:mb-0 last:pb-0 last:border-b-0'>
              <div className='flex flex-col items-center justify-center flex-auto gap-2'>
                <MdOutlineAddPhotoAlternate className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Media</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2'>
                <RiErrorWarningLine className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Description</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-large:hidden'>
                <IoCarSportOutline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>VIN</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <IoSpeedometerOutline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Odometer</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <MdOutlineLocalOffer className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Offer</h5>
                </div>
              </div>
              <div className='flex-col items-center justify-center flex-auto hidden gap-2 sm:flex'>
                <IoSettingsOutline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Action</h5>
                </div>
              </div>
            </div>
            {pendingVehicleList.map((item) => (
              <div
                className='relative flex flex-wrap items-center justify-center w-full border-b border-gray border-opacity-10 last:border-b-0'
                key={item._id}
              >
                <div className='grid w-full gap-5 py-3 lg:max-w-none max-sm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 place-items-center lg:py-5'>
                  <NavLink
                    to={
                      item.status === 'closed'
                        ? `/closed/${item?._id}`
                        : `/sold/${item?._id}`
                    }
                    className='img block w-full rounded-lg relative overflow-hidden pt-[calc(9/16*100%)]'
                  >
                    {item?.vehicle_image?.exterior_main ? (
                      <img
                        src={item?.vehicle_image?.exterior_main}
                        alt='vehicle-img'
                        sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                        width='1920'
                        height='1080'
                        className='absolute top-0 left-0 object-cover w-full h-full'
                        onError={(e) => vehicleBrokenImage(e)}
                      />
                    ) : (
                      <img
                        src={placeholderCar}
                        className='absolute top-0 left-0 object-cover w-full h-full'
                        alt='vehicle-img'
                        sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                        width='1920'
                        height='1080'
                        onError={(e) => vehicleBrokenImage(e)}
                      />
                    )}
                  </NavLink>
                  <div className='flex-auto'>
                    <div className='sm:text-center text-lg font-normal !text-gray'>
                      <div className='title title-gray'>
                        <h6 className='subHeading-6'>{item?.year}</h6>
                      </div>
                      <div className='title title-primary'>
                        <h6 className='heading-6 !font-medium'>
                          {item?.make}
                          <br className='max-small:hidden' />
                          &nbsp;{item?.model}
                        </h6>
                      </div>
                    </div>
                    <div className='flex-auto mt-2 large:hidden'>
                      <div className='title title-gray'>
                        <h6 className='subHeading-6'>
                          <CopyToClipboard>{item?.vin_number} </CopyToClipboard>
                        </h6>
                      </div>
                    </div>
                    <div className='mt-2 sm:hidden'>
                      <CustomToolTip label='View Vehicle'>
                        <CustomButtonWithIcon
                          icon={<MdRemoveRedEye />}
                          link={`/pending/${item?._id}`}
                          label='View'
                        />
                      </CustomToolTip>
                    </div>
                  </div>
                  <div className='max-large:hidden'>
                    <div className='title title-gray'>
                      <h6 className='subHeading-6'>
                        <CopyToClipboard> {item?.vin_number}</CopyToClipboard>
                      </h6>
                    </div>
                  </div>
                  <div className='max-lg:hidden'>
                    <div className='title title-gray'>
                      <h6 className='subHeading-6'>
                        {item?.odometer}&nbsp;
                        {item?.mileage_unit}
                      </h6>
                    </div>
                  </div>
                  <div className='max-lg:hidden'>
                    <div className='title title-primary'>
                      <h6 className='subHeading-5'>
                        {item?.vehicle_auction?.offer_count || 0}
                      </h6>
                    </div>
                  </div>
                  <div className='max-sm:hidden'>
                    <CustomToolTip label='View Vehicle'>
                      <CustomButtonWithIcon
                        icon={<MdRemoveRedEye />}
                        link={`/pending/${item?._id}`}
                        label='View'
                      />
                    </CustomToolTip>
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        ) : (
          <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
            <div className='img'>
              <img
                src={gif}
                alt='vehicle-img'
                onError={(e) => vehicleBrokenImage(e)}
              />
            </div>
            <div className='title title-gray subH-70'>
              <h5 className='subHeading-5'>
                There are no vehicle listings available.
              </h5>
            </div>
          </div>
        )}
      </CustomLoadingSection>
    </Fragment>
  )
}

export default PendingList
