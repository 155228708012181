import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

const SellerBankDetails = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userBankDetails, setUserBankDetails] = useState(null)

  const { id } = useParams()

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/awarded/${id}/bank`,
      )
      if (response.data.success === true) {
        setUserBankDetails(
          response.data.sellerBankDetails.user_financial_details,
        )
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div>
      <CustomLoadingSection isLoading={isLoading}>
        <div>
          <div className='flex flex-col w-full gap-10 '>
            <div className='flex flex-col items-start justify-start w-full gap-2 p-0 m-0'>
              <div className='w-full'>
                <CustomTitle backBtn />
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-start w-full p-0 m-0 gap-x-10 gap-y-5'>
              <div className='mb-3 text-left title title-primary'>
                <h2 className='heading-2'>Financial information</h2>
              </div>

              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='w-full sm:max-w-[calc(50%_-_16px)]'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-medium subHeading-5'>Bank Name</h5>
                  </div>
                  <div className='bg-gray/10 w-full text-gray text-sm py-3 px-4 outline-0 capitalize rounded-[4px] rounded-tl-[0px] rounded-bl-[0px] border-l-[5px] border-gray/40'>
                    {userBankDetails?.bank_name}
                  </div>
                </div>
                <div className='w-full sm:max-w-[calc(50%_-_16px)]'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-medium subHeading-5'>Account Number</h5>
                  </div>
                  <div className='bg-gray/10 w-full text-gray text-sm py-3 px-4 outline-0 capitalize rounded-[4px] rounded-tl-[0px] rounded-bl-[0px] border-l-[5px] border-gray/40'>
                    {userBankDetails?.account_number}
                  </div>
                </div>
                <div className='w-full sm:max-w-[calc(50%_-_16px)]'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-medium subHeading-5'>
                      Institution Number
                    </h5>
                  </div>
                  <div className='bg-gray/10 w-full text-gray text-sm py-3 px-4 outline-0 capitalize rounded-[4px] rounded-tl-[0px] rounded-bl-[0px] border-l-[5px] border-gray/40'>
                    {userBankDetails?.institution_number}
                  </div>
                </div>
                <div className='w-full sm:max-w-[calc(50%_-_16px)]'>
                  <div className='mb-2 capitalize title title-gray'>
                    <h5 className='font-medium subHeading-5'>Transit Number</h5>
                  </div>
                  <div className='bg-gray/10 w-full text-gray text-sm py-3 px-4 outline-0 capitalize rounded-[4px] rounded-tl-[0px] rounded-bl-[0px] border-l-[5px] border-gray/40'>
                    {userBankDetails?.transit_number}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomLoadingSection>
    </div>
  )
}

export default SellerBankDetails
