import { createSlice } from '@reduxjs/toolkit'
import { setRole } from '../../utils/cookies/cookies'

const initialState = {
  currentUser: null,
  currentUserId: null,
  role: null,
  trigger: false,
  switchRoleRequest: true,
  switchRoleRouter: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signOut: (state) => {
      state.currentUserId = null
      state.currentUser = null
      state.role = null
    },
    updateRole: (state, action) => {
      state.role = action.payload
      setRole(action.payload)
    },
    trigger: (state) => {
      state.trigger = !state.trigger
    },
    requesToSwitchRole: (state, action) => {
      state.switchRoleRequest = action.payload.switchRoleRequest
    },

    requesToSwitchRoleRouter: (state, action) => {
      state.switchRoleRouter = action.payload.status
    },
  },
})

export const {
  signOut,
  updateRole,
  trigger,
  requesToSwitchRole,
  requesToSwitchRoleRouter,
} = userSlice.actions

export default userSlice.reducer
