import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import LeftCard from '../../../views/Auth/LeftCard'

const SignupSuccess = () => {
  const location = useLocation()
  const state = location.state

  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <LeftCard />
        <div className='h-full'>
          <div className='login-card-root lg:h-screen'>
            <div className='text-center title'>
              <h1 className='!text-primary !mb-8'>Thank You!</h1>
              <p className='!mb-10'>
                We have sent an email to <b>{state?.email}</b> for email
                verification and login. Please check your inbox and verify your
                email.
              </p>
            </div>
            <NavLink
              to='/login'
              className='p-2 px-8 text-white border rounded-md bg-primary border-primary'
            >
              Log in
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupSuccess
