import React from 'react'

import LiveList from '../../Live/List'

const LiveOffers = () => {
  return (
    <div className='mb-10 last:mb-0'>
      <LiveList />
    </div>
  )
}

export default LiveOffers
