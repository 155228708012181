import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { signOut } from '../../../redux/user/userSlice'
import { RemoveEditVehicleDetails } from '../../../redux/vehicle/vehicleSlice'

import Logo from '../../../assets/logo/revvit-logo.png'
import Logow from '../../../assets/logo/sidebar-logo.png'
import cars from '../../../assets/car/car-right.png'
import check from '../../../assets/gif/regular-check.gif'

import configManager from '../../../config/configManager'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosRegistrationInstance from '../../../utils/axios/axiosRegistrationInstance'
import {
  removeRole,
  removeToken,
  removeRegistrationToken,
} from '../../../utils/cookies/cookies'

const configData = configManager()

const AccountPending = () => {
  const data = configManager()
  const dispatch = useDispatch()

  const step = 4
  const handleLogout = async () => {
    try {
      await axiosRegistrationInstance.get('/auth/logout')
      dispatch(signOut())
      dispatch(RemoveEditVehicleDetails())
      removeRole()
      removeRegistrationToken()
      removeToken()
      window.location = data?.WEBSITE_URL
    } catch (error) {
      handleRequestError(error)
    }
  }

  return (
    <div className='bg-white'>
      <div className='lg:h-screen h-[100dvh] p-4 max-lg:pr-0 pt-10 lg:pt-4 '>
        <div className='flex flex-wrap m-0 w-full lg:h-full gap-10 max-h-[calc(100dvh_-_30px)] max-lg:pr-4'>
          <div className='w-full lg:max-w-[420px] lg:h-full lg:bg-liner_white rounded-xl overflow-hidden relative'>
            <div className='relative flex flex-col items-start justify-start w-full gap-24 p-0 m-0 lg:py-16'>
              <div className='revvit-logo-wrapper'>
                <img
                  src={Logo}
                  alt='Logo'
                  className='block login-form-logo lg:hidden h-11'
                />
                <img
                  src={Logow}
                  alt='Logo'
                  className='hidden login-form-logo lg:block h-11'
                />
              </div>
              <div className='hidden lg:flex flex-col items-start lg:justify-start gap-8 w-full px-10 large:pl-[25%] large:pr-[20%]'>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 1 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    1
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Personal
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 2 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    2
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Dealer
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 3 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    3
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Financial
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='absolute bottom-0 right-0 hidden w-full lg:block'>
              <img
                src={cars}
                alt='Logo'
                className='block h-auto max-w-full mx-auto'
              />
            </div>
          </div>
          <div className='w-full lg:max-w-[calc(100%_-_460px)] lg:h-full flex flex-col justify-start items-center pt-0 py-8 lg:py-16'>
            <div className='w-full flex flex-col items-start justify-between border border-gray/10 rounded-xl shadow-lg lg:max-w-[95%] gap-8 px-5 py-8 md:p-10'>
              <div className='text-left'>
                <div className='relative flex flex-wrap items-center justify-start w-full gap-5 p-0 m-0 mb-8'>
                  <div className='flex justify-center items-center w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] bg-primary rounded-full'>
                    <img
                      src={check}
                      alt='Logo'
                      className='block login-form-logo h-11 invert'
                    />
                  </div>
                  <div className='w-[calc(100%_-_80px)]'>
                    <div className='mb-2 title title-gray'>
                      <h2 className='heading-2'>Account Under Review!</h2>
                    </div>
                    <div className='content'>
                      <p className='!text-base'>
                        Thank you for registering with
                        <Link to={configData.WEBSITE_URL}>Revvit</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='content '>
                  <p className='!text-base'>
                    Your account is currently under review to ensure a secure
                    and personalized experience for all our users. This process
                    typically takes up to <b>24-48 hours</b>. We appreciate your
                    patience and understanding.
                  </p>
                  <p className='!text-base'>
                    While you wait, feel free to explore our
                    website/blog/resources or follow us on social media links
                    for updates and more information. We&apos;re excited to have
                    you on board and look forward to connect dealers seamlessly
                    and facilitate hassle-free transactions through a secure and
                    intelligent platform.
                  </p>
                  <p className='!text-base'>
                    If you have any questions or need further assistance, please
                    don&apos;t hesitate to contact our support team at
                    <Link to='mailTo:info@revvit.ca'>info@revvit.ca</Link>
                  </p>
                  <p className='!text-base'>
                    Thank you for choosing&nbsp;
                    <Link to={configData.WEBSITE_URL}>Revvit</Link>. We&apos;re
                    thrilled to have you join our community!
                  </p>
                </div>
              </div>
              <div className='flex flex-wrap items-start justify-end w-full gap-4'>
                <div
                  onClick={handleLogout}
                  className='cursor-pointer btn btn-primary-ico'
                >
                  Visit Website
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPending
