import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { Icons } from '../../../../components/Icons'
import CustomModal from '../../../../components/Custom/CustomModal'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomButtonWithIcon from '../../../../components/Custom/CustomButtonWithIcon'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import AwardedBuyer from '../../../../views/Seller/Closed/AwardedBuyer'

import configManager from '../../../../config/configManager'

const {
  FaCheck,
  CgSpinner,
  BsCheckAll,
  IoDocumentTextSharp,
  MdFileDownload,
  MdDelete,
  MdFileUpload,
  MdOutlineClose,
  MdSend,
  MdDoneAll,
  MdOutlineDoneAll,
} = Icons

const companyData = configManager()

const View = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [sellerId, setSellerId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()
  const [awardedBuyerDetails, setAwardedBuyerDetails] = useState(null)

  const fileInputRef = useRef(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/closed/${id}`,
      )
      if (response.data.success) {
        setIsLoading(false)
        setvehicleDetails(response.data.vehicleDetails)
        setSellerId(response.data.vehicleDetails.user)
        setAwardedBuyerDetails(response.data.WonOfferDetails)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  const [titleList, setTitleList] = useState([])
  const [isTitleSent, setIsTitleSent] = useState(false)

  const fetchAllVehicleTitle = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-title/list/${id}`,
      )
      setTitleList(response.data.titles)

      const hasTrue = response?.data?.titles?.some(
        (item) => item?.is_send === true,
      )

      setIsTitleSent(hasTrue)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
    fetchAllVehicleTitle()
  }, [fetchData, fetchAllVehicleTitle])

  const [isUploading, setIsUploading] = useState(false)

  const submitTitleDocuments = async () => {
    setIsUploading(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-title/upload/single/${id}`,
        { document: fileInputRef.current.files[0] },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (response.data.success) {
        setIsUploading(false)
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        fetchAllVehicleTitle()
      }
      fileInputRef.current.value = null
    } catch (error) {
      setIsUploading(false)
      handleRequestError(error)
      fileInputRef.current.value = null
    }
  }

  const deleteTitle = async (item_id) => {
    try {
      const response = await axiosInterceptorInstance.delete(
        `/vehicle/seller/vehicle-title/delete/${id}/${item_id}`,
      )
      if (response.data.success) {
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        fetchAllVehicleTitle()
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const [selectedItems, setSelectedItems] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const handleCheckboxChange = (itemId) => {
    setErrorMessage('')
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId))
    } else {
      setSelectedItems([...selectedItems, itemId])
    }
  }
  const [sendTitleLoading, setSendTitleLoading] = useState(false)

  const sendTitle = async () => {
    const isDocumentUploaded = titleList && titleList.length > 0
    if (!isDocumentUploaded) {
      setErrorMessage('Please upload at least one document.')
      return
    }

    if (selectedItems.length === 0) {
      setErrorMessage('Please select at least one checkbox.')
      return
    }
    setSendTitleLoading(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-title/send/${id}`,
        { selectedDocumentId: selectedItems },
      )
      if (response.data.success) {
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        fetchAllVehicleTitle()
        setSelectedItems([])
        setSendTitleLoading(false)
      }
    } catch (error) {
      handleRequestError(error)
      setSendTitleLoading(false)
    }
  }

  const [confimModal, setConfirmModal] = useState(false)
  const [isCloseLoading, setIsCloseLoading] = useState(false)
  const openConfirmModal = () => {
    setConfirmModal(true)
  }
  const closeConfirmModal = () => {
    setConfirmModal(false)
  }

  const handleSoldDeal = async () => {
    setIsCloseLoading(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-auctions/sold/deal/${id}`,
        { sold_vehicle_date: moment() },
      )
      if (response.data.success) {
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        closeConfirmModal()
        setIsCloseLoading(false)
        navigate('/closed')
      }
    } catch (error) {
      handleRequestError(error)
      closeConfirmModal()
      setIsCloseLoading(false)
    }
  }

  const setLoading = (id, value) => {
    const newTitle = titleList.map((val) => {
      if (val._id === id) {
        return { ...val, isLoading: value }
      }
      return val
    })
    setTitleList(newTitle)
  }

  const truncateFileName = (fileName) => {
    const extension = fileName.split('.').at(-1)
    const name = fileName.slice(0, 24)
    return `${name}....${extension}`
  }

  const handleDownload = async (url, name, id) => {
    setLoading(id, true)
    try {
      const response = await axiosInterceptorInstance.get(
        `${companyData.API_BASE_URL}/api/v1/user/download?url=${url}`,
        { responseType: 'blob' },
      )
      if (response.statusText === 'OK') {
        url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        handleRequestError({
          response: { data: { message: 'Downloading failed' } },
        })
      }
      setLoading(id, false)
    } catch (error) {
      handleRequestError({
        response: { data: { message: 'Downloading failed' } },
      })
      setLoading(id, false)
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn />
      <CustomPageTitle vehicleDetails={vehicleDetails} />
      <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 large:w-auto large:items-stretch gap-y-10 large:gap-x-8'>
        <div className='w-full large:w-[calc(35%_-_16px)]'>
          <div className='flex flex-col items-start justify-center w-full gap-10 p-0 m-0'>
            {awardedBuyerDetails && (
              <AwardedBuyer
                id={id}
                title={'Awarded Details'}
                vehicleDetails={vehicleDetails}
                sellerId={sellerId}
                awardedBuyerDetails={awardedBuyerDetails}
                vehicleAuctionDetails={vehicleDetails?.vehicle_auction}
              />
            )}
          </div>
        </div>
        <div className='w-full large:w-[calc(65%_-_16px)] flex flex-col items-start justify-start gap-10'>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl '>
            <div className='relative flex flex-wrap items-center justify-between w-full gap-3 pb-2 border-b border-solid max-xs:flex-col md:flex-row max-xs:items-start border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  <FaCheck />
                </span>
                <div className='flex flex-wrap items-center gap-4 title title-gray'>
                  <h3 className='font-medium capitalize subHeading-3'>
                    Bill of sale
                  </h3>
                </div>
              </div>
              <div className='flex items-center gap-2'>
                <NavLink to={`/document/${vehicleDetails?._id}`}>
                  <CustomButtonWithIcon
                    label='Bill of sale'
                    icon={<IoDocumentTextSharp />}
                  />
                </NavLink>
              </div>
            </div>
            <div className='mt-4 content'>
              <p>
                This document is a bill of sale that is sent to the buyer along
                with vehicle details and payment information. This Agreement
                represents the complete understanding between the Parties and
                overrides any previous agreements or understandings, whether
                written or verbal, regarding the subject matter discussed here
                in.
              </p>
            </div>
          </div>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl'>
            <div className='relative flex flex-wrap items-center justify-between w-full gap-3 pb-2 border-b border-solid max-xs:flex-col md:flex-row max-xs:items-start border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  {isTitleSent ? <FaCheck /> : 2}
                </span>
                <div className='flex flex-wrap items-center gap-4 title title-gray'>
                  <h3 className='font-medium capitalize subHeading-3'>
                    Title/Registration
                  </h3>
                </div>
              </div>
              <div className='flex items-center gap-2'>
                <CustomButtonWithIcon
                  label='Upload Title/Registration'
                  icon={
                    isUploading ? (
                      <CgSpinner className='w-5 h-5 animate-spin' />
                    ) : (
                      <MdFileUpload className='w-5 h-5' />
                    )
                  }
                  onClick={() => fileInputRef.current.click()}
                  disabled={isUploading}
                />
                <input
                  ref={fileInputRef}
                  id='file-input'
                  type='file'
                  className='hidden'
                  onChange={submitTitleDocuments}
                />
              </div>
            </div>
            <div className='mt-4'>
              <div className='flex items-center justify-between pb-2 border-b border-gray border-opacity-30'>
                <div className='title title-gray'>
                  <h6 className='font-medium subHeading-6'>Document Name</h6>
                </div>
                <div className='title title-gray'>
                  <h6 className='font-medium subHeading-6'>Actions</h6>
                </div>
              </div>
              <div className='flex flex-col gap-2 mt-4 empty:mt-0'>
                {titleList?.map((item) => {
                  return (
                    <div
                      className={`flex flex-wrap items-center justify-between w-full`}
                      key={item._id}
                    >
                      <div className='flex flex-wrap items-center gap-3 w-[calc(100%_-_150px)]'>
                        {item?.is_send ? (
                          <CustomToolTip label='Document sended'>
                            <BsCheckAll className='text-lg text-primary' />
                          </CustomToolTip>
                        ) : (
                          <>
                            <label className='relative flex items-center cursor-pointer text-xl select-none'>
                              <input
                                type='checkbox'
                                checked={selectedItems.includes(item._id)}
                                onChange={() => handleCheckboxChange(item._id)}
                                className='absolute opacity-0 h-0 w-0'
                              />
                              <span className='h-5 w-5 flex items-center justify-center bg-transparent border border-solid border-gray/40 rounded-sm transition-colors duration-300'>
                                {selectedItems.includes(item._id) && (
                                  <svg
                                    className='w-5 h-5 text-primary'
                                    fill='none'
                                    stroke='currentColor'
                                    viewBox='0 0 24 24'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M5 13l4 4L19 7'
                                    ></path>
                                  </svg>
                                )}
                              </span>
                            </label>
                          </>
                        )}
                        <button className='flex items-center justify-center duration-300 ease-linear delay-150 cursor-default text-primary'>
                          <IoDocumentTextSharp />
                        </button>
                        <div className='content primary w-[calc(100%_-_72px)]'>
                          <p>
                            <span>{truncateFileName(item?.name)}</span>
                          </p>
                        </div>
                      </div>
                      <div className='flex items-center gap-2 flex-col xsmall:flex-row'>
                        <CustomToolTip label='Download Document'>
                          <button
                            className='btn btn-round'
                            onClick={() =>
                              handleDownload(
                                item.documents,
                                item.name,
                                item?._id,
                              )
                            }
                            disabled={item?.isLoading}
                          >
                            {item?.isLoading ? (
                              <CgSpinner className='animate-spin' />
                            ) : (
                              <MdFileDownload />
                            )}
                          </button>
                        </CustomToolTip>
                        <CustomToolTip label='Delete Document'>
                          <button
                            onClick={() => deleteTitle(item?._id)}
                            className='btn btn-round'
                          >
                            <MdDelete />
                          </button>
                        </CustomToolTip>
                      </div>
                    </div>
                  )
                })}
              </div>
              {errorMessage && (
                <div className='mt-2 text-sm text-danger'>{errorMessage}</div>
              )}
              <div className='flex items-start justify-between gap-5 mt-6 max-sm:flex-col sm:flex-wrap sm:items-center'>
                <div className='content lg:w-[calc(100%_-_150px)]'>
                  <p>
                    You can select a document and then proceed to send it to the
                    buyer.
                  </p>
                </div>
                <CustomButtonWithIcon
                  label='Send'
                  icon={<MdSend className='w-5 h-5' />}
                  onClick={sendTitle}
                  isLoading={sendTitleLoading}
                />
              </div>
            </div>
          </div>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl'>
            <div className='relative flex flex-wrap items-center justify-between w-full gap-3 pb-2 border-b border-solid max-xs:flex-col md:flex-row max-xs:items-start border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  3
                </span>
                <div className='flex flex-wrap items-center gap-4 title title-gray'>
                  <h3 className='font-medium capitalize subHeading-3'>
                    Mark as paid
                  </h3>
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <div className='mb-5 content'>
                <p>
                  This serves as confirmation that the payment has been marked
                  as paid. If you have received payment from the buyer, please
                  confirm it here to finalize the deal and mark the vehicle as
                  sold.
                </p>
              </div>
              <div className='inline-block sm:flex sm:flex-wrap md:justify-end'>
                <CustomButtonWithIcon
                  label='Mark as paid'
                  icon={<MdDoneAll />}
                  onClick={openConfirmModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {confimModal && (
        <CustomModal
          handleCloseModal={closeConfirmModal}
          closeModal={confimModal}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeConfirmModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <MdOutlineDoneAll />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Are you sure you want to mark this deal as paid?
                </h5>
              </div>
            </div>
            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeConfirmModal}
                className='btn btn-gray max-md:flex-auto'
              >
                No, cancel
              </button>
              <button
                onClick={handleSoldDeal}
                className={`btn btn-primary max-md:flex-auto ${
                  isCloseLoading && 'hover:bg-[#fff]'
                }`}
              >
                {isCloseLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </CustomLoadingSection>
  )
}

export default View
