import React, { Fragment } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'

import CustomStopWatch from '../CustomStopWatch'

import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'
import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'

import gif from '../../../assets/gif/car-jump.gif'
import placeholderCar from '../../../assets/car/car-dummy.png'
import CopyToClipboard from '../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../Icons'

const {
  IoArrowUp,
  IoCarSportOutline,
  IoSettingsOutline,
  IoSpeedometerOutline,
  MdDelete,
  MdEdit,
  MdRemoveRedEye,
  MdOutlineLocalOffer,
  MdOutlineAccessTime,
  MdOutlineAddPhotoAlternate,
  FiUserCheck,
  AiOutlineDollar,
  RiErrorWarningLine,
  HiOutlineStatusOnline,
} = Icons

const CustomTable = ({
  data,
  pathname,
  gridClass,
  showStatus,
  ParkingLot,
  Live,
  SalesHistory,
  Scheduled,
  Closed,
  Pending,
  ...props
}) => {
  return (
    <Fragment>
      {data?.length > 0 ? (
        <Fragment>
          <div className={`${gridClass}`}>
            {Live && (
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <MdOutlineAccessTime className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Time Left</h5>
                </div>
              </div>
            )}
            {Scheduled && (
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <MdOutlineAccessTime className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Time Left</h5>
                </div>
              </div>
            )}

            <div className='flex flex-col items-center justify-center flex-auto gap-2'>
              <MdOutlineAddPhotoAlternate className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Media</h5>
              </div>
            </div>

            <div className='flex flex-col items-center justify-center flex-auto gap-2'>
              <RiErrorWarningLine className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Description</h5>
              </div>
            </div>

            {Closed || SalesHistory ? (
              <>
                <div className='flex flex-col items-center justify-center flex-auto gap-2 max-large:hidden'>
                  <FiUserCheck className='text-xl large:text-3xl text-primary' />
                  <div className='title title-gray'>
                    <h5 className='subHeading-5'>Buyer</h5>
                  </div>
                </div>
                <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                  <AiOutlineDollar className='text-xl large:text-3xl text-primary' />
                  <div className='title title-gray'>
                    <h5 className='subHeading-5'>Amount</h5>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!Live && (
                  <div className='flex flex-col items-center justify-center flex-auto gap-2 max-xl:hidden'>
                    <IoSpeedometerOutline className='text-xl large:text-3xl text-primary' />
                    <div className='title title-gray'>
                      <h5 className='subHeading-5'>Odometer</h5>
                    </div>
                  </div>
                )}

                <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                  <IoCarSportOutline className='text-xl large:text-3xl text-primary' />
                  <div className='title title-gray'>
                    <h5 className='subHeading-5'>VIN</h5>
                  </div>
                </div>
              </>
            )}

            {(Live || Pending) && (
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-large:hidden'>
                <MdOutlineLocalOffer className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Offers</h5>
                </div>
              </div>
            )}

            {showStatus && (
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <HiOutlineStatusOnline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Status</h5>
                </div>
              </div>
            )}

            <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
              <IoSettingsOutline className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Action</h5>
              </div>
            </div>
          </div>
          <Fragment>
            {data.map((item) => {
              return (
                <div className={`${gridClass}`} key={item._id}>
                  {(Live || Scheduled) &&
                    (Scheduled ? (
                      <div className='flex-auto max-lg:hidden'>
                        <span className='text-lg font-normal !text-gray'>
                          <div className='title title-gray lg:text-center'>
                            <h6 className='subHeading-6'>
                              <span className='block mb-1 text-sm font-medium text-primary '>
                                Goes Live
                              </span>
                              {moment(
                                item?.vehicle_auction?.launch_auction_date,
                              ).format('YYYY MMM, D')}
                              <br />
                              {moment(
                                item?.vehicle_auction?.launch_auction_date,
                              ).format('h:mm A')}
                            </h6>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div className='flex-auto max-lg:hidden'>
                        <span className='text-lg font-normal !text-gray'>
                          <div className='title title-gray lg:text-center'>
                            <h6 className='subHeading-6'>
                              <span className='block mb-1 text-sm font-medium text-primary'>
                                <CustomStopWatch
                                  end_date={
                                    item?.vehicle_auction?.end_auction_time
                                  }
                                  timezone={
                                    item?.vehicle_auction
                                      ?.launch_auction_timezone
                                  }
                                />
                              </span>
                              min left
                            </h6>
                          </div>
                        </span>
                      </div>
                    ))}

                  <div className='w-full'>
                    {Live && (
                      <div className='flex-auto lg:hidden'>
                        <span className='inline-block px-4 py-2 rounded-tl-lg rounded-tr-lg bg-primary'>
                          <div className='text-sm font-normal text-white'>
                            <CustomStopWatch
                              end_date={item?.vehicle_auction?.end_auction_time}
                              timezone={
                                item?.vehicle_auction?.launch_auction_timezone
                              }
                            />
                            &nbsp;min left
                          </div>
                        </span>
                      </div>
                    )}
                    {Scheduled && (
                      <div className='flex-auto lg:hidden'>
                        <span className='inline-block px-4 py-2 rounded-tl-lg rounded-tr-lg bg-primary'>
                          <div className='title title-white lg:text-center'>
                            <h6 className='font-medium subHeading-6'>
                              Goes Live
                            </h6>
                          </div>
                          <div className='content white'>
                            <p className='!text-xs'>
                              {moment(
                                item?.vehicle_auction?.launch_auction_date,
                              ).format('YYYY MMM, D')}
                              &nbsp;
                              {moment(
                                item?.vehicle_auction?.launch_auction_date,
                              ).format('h:mm A')}
                            </p>
                          </div>
                        </span>
                      </div>
                    )}
                    <div className='img block w-full rounded-lg relative overflow-hidden pt-[calc(9/16*100%)] max-lg:rounded-tl-none'>
                      {item?.vehicle_image?.exterior_main ? (
                        <img
                          src={item?.vehicle_image?.exterior_main_thumbnail}
                          alt='vehicle-img'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      ) : (
                        <img
                          src={placeholderCar}
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          alt='vehicle-img'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      )}
                    </div>
                  </div>

                  <div className='flex-auto min-w-[150px]'>
                    <div className='flex-auto mb-4 lg:hidden'>
                      <div
                        className={`title ${
                          item?.status === 'parking'
                            ? 'title-violet'
                            : item?.status === 'live'
                              ? 'title-primary'
                              : item?.status === 'scheduled'
                                ? 'title-green'
                                : item?.status === 'pending'
                                  ? 'title-blue'
                                  : 'title-gray'
                        }`}
                      >
                        <h6 className='capitalize heading-6'>{item?.status}</h6>
                      </div>
                    </div>
                    <span className='lg:text-center text-lg font-normal !text-gray'>
                      <div className='title title-gray subH-70'>
                        <h6 className='subHeading-6'>{item?.year}</h6>
                      </div>

                      <div className='title title-primary'>
                        <h6 className='heading-6 !font-medium'>
                          {item?.make}&nbsp;{item?.model}
                        </h6>
                      </div>
                    </span>
                    <div className='flex-auto mt-4 lg:hidden'>
                      <div className='title ttitle-gray subH-70 max-lg:mb-4'>
                        <h6 className='subHeading-6'>
                          <CopyToClipboard> {item?.vin_number}</CopyToClipboard>
                        </h6>
                      </div>
                    </div>

                    <div className='flex-auto lg:hidden action'>
                      <div className='flex gap-2 text-right editable-ico'>
                        {Live && (
                          <Tooltip
                            title='View Offer'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/live/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <MdRemoveRedEye />
                              View
                            </NavLink>
                          </Tooltip>
                        )}

                        {Scheduled && (
                          <Tooltip
                            title='Edit Scheduled'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/scheduled/edit/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <MdEdit />
                              Edit
                            </NavLink>
                          </Tooltip>
                        )}
                        {showStatus && (
                          <>
                            <div className='flex items-center gap-2'>
                              {item?.status === 'scheduled' && (
                                <Tooltip
                                  title='Edit Scheduled'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <NavLink
                                    to={`/scheduled/edit/${item._id}`}
                                    className='flex items-center justify-center gap-2 transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdEdit />
                                  </NavLink>
                                </Tooltip>
                              )}

                              {item?.status === 'closed' && (
                                <Tooltip
                                  title='View Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <NavLink
                                    to={`/closed/${item._id}`}
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdRemoveRedEye />
                                  </NavLink>
                                </Tooltip>
                              )}
                              {item?.status !== 'closed' ? (
                                <Tooltip
                                  title='View Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <NavLink
                                    to={`/vehicle/${item._id}`}
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdRemoveRedEye />
                                  </NavLink>
                                </Tooltip>
                              ) : (
                                ''
                              )}
                              {item?.status === 'parking' && (
                                <>
                                  <Tooltip
                                    title='Launch Vehicle'
                                    position='top'
                                    trigger='mouseenter'
                                  >
                                    <NavLink
                                      to={`/parking-lot/${item._id}`}
                                      className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                    >
                                      <IoArrowUp />
                                    </NavLink>
                                  </Tooltip>
                                  <Tooltip
                                    title='Edit Vehicle'
                                    position='top'
                                    trigger='mouseenter'
                                  >
                                    <button
                                      onClick={() =>
                                        props.handleEditVehicle(item._id)
                                      }
                                      className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                    >
                                      <MdEdit />
                                    </button>
                                  </Tooltip>
                                </>
                              )}

                              {item.status === 'draft' && (
                                <div className='flex gap-2 text-right max-lg:hidden editable-ico'>
                                  <Tooltip
                                    title='Edit Vehicle'
                                    position='top'
                                    trigger='mouseenter'
                                  >
                                    <button
                                      onClick={() =>
                                        props.handleEditVehicle(item._id)
                                      }
                                      className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                    >
                                      <MdEdit />
                                    </button>
                                  </Tooltip>
                                </div>
                              )}

                              {![
                                'closed',
                                'sold',
                                'scheduled',
                                'live',
                              ].includes(item.status) && (
                                <Tooltip
                                  title='Delete Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <button
                                    onClick={() =>
                                      props.openDeleteModal(item._id)
                                    }
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdDelete />
                                  </button>
                                </Tooltip>
                              )}
                            </div>
                          </>
                        )}
                        {ParkingLot && (
                          <Tooltip
                            title='Launch Vehicle'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/parking-lot/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <IoArrowUp /> Launch
                            </NavLink>
                          </Tooltip>
                        )}

                        {Pending && (
                          <Tooltip
                            title='View Vehicle'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/pending/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <MdRemoveRedEye />
                              View
                            </NavLink>
                          </Tooltip>
                        )}

                        {Closed && (
                          <Tooltip
                            title='View Vehicle'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/closed/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <MdRemoveRedEye />
                              View
                            </NavLink>
                          </Tooltip>
                        )}
                        {SalesHistory && (
                          <Tooltip
                            title='View Vehicle'
                            position='top'
                            trigger='mouseenter'
                          >
                            <NavLink
                              to={`/sales-history/${item._id}`}
                              className='btn btn-primary-ico'
                            >
                              <MdRemoveRedEye />
                              View
                            </NavLink>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>

                  {Closed || SalesHistory ? (
                    <div className='title title-gray'>
                      <h6 className='subHeading-6'>
                        <NavLink
                          to={`/buyer/profile/${item?.vehicle_auction?.won_user_id?._id}`}
                          className='flex items-center gap-2 group'
                        >
                          <img
                            className='w-10 h-10 mb-1 rounded-full'
                            src={
                              item?.vehicle_auction?.won_user_id.profile_picture
                            }
                            alt='img'
                            onError={(e) => userBrokenImages(e)}
                          />

                          <span className='text-sm font-medium cursor-pointer text-gray group-hover:text-primary'>
                            {item?.vehicle_auction?.won_user_id?.first_name}
                            <br />
                            <span className='text-xs font-normal text-gray text-opacity-60 group-hover:text-gray'>
                              {item?.vehicle_auction?.won_user_id?.email}
                            </span>
                          </span>
                        </NavLink>
                      </h6>
                    </div>
                  ) : (
                    <Fragment>
                      {!Live && (
                        <div className='flex-auto max-xl:hidden'>
                          <div className='title title-gray'>
                            <h6 className='subHeading-6'>
                              {item?.odometer}&nbsp;
                              {item?.mileage_unit}
                            </h6>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )}

                  {Closed || SalesHistory ? (
                    <div className='title title-primary'>
                      <h6 className='heading-4'>
                        ${item?.vehicle_auction?.won_amount}
                      </h6>
                    </div>
                  ) : (
                    <div className='flex-auto max-lg:hidden'>
                      <div className='title ttitle-gray subH-70 max-lg:mb-4'>
                        <h6 className='subHeading-6'>
                          <CopyToClipboard>{item?.vin_number} </CopyToClipboard>
                        </h6>
                      </div>
                    </div>
                  )}

                  {showStatus && (
                    <div className='flex-auto max-lg:hidden'>
                      <div
                        className={`title ${
                          item?.status === 'parking'
                            ? 'title-violet'
                            : item?.status === 'live'
                              ? 'title-primary'
                              : item?.status === 'scheduled'
                                ? 'title-green'
                                : item?.status === 'pending'
                                  ? 'title-blue'
                                  : 'title-gray'
                        }`}
                      >
                        <h6 className='capitalize heading-6'>{item?.status}</h6>
                      </div>
                    </div>
                  )}

                  {(Live || Pending) && (
                    <div className='flex-auto max-large:hidden'>
                      <div className='title title-primary'>
                        <h6 className='subHeading-4'>
                          {item?.vehicle_auction?.offer_count || 0}
                        </h6>
                      </div>
                    </div>
                  )}

                  <div className='flex-auto max-lg:hidden action'>
                    <div className='flex gap-2 text-right editable-ico'>
                      {Live && (
                        <Tooltip
                          title='View Offer'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/live/${item._id}`}
                            className='btn btn-primary-ico'
                          >
                            <MdRemoveRedEye />
                            View
                          </NavLink>
                        </Tooltip>
                      )}

                      {Scheduled && (
                        <Tooltip
                          title='Edit Scheduled'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/scheduled/edit/${item._id}`}
                            className='btn btn-primary-ico'
                          >
                            <MdEdit />
                            Edit
                          </NavLink>
                        </Tooltip>
                      )}
                      {showStatus && (
                        <>
                          <div className='flex items-center gap-2'>
                            {item?.status === 'scheduled' && (
                              <Tooltip
                                title='Edit Scheduled'
                                position='top'
                                trigger='mouseenter'
                              >
                                <NavLink
                                  to={`/scheduled/edit/${item._id}`}
                                  className='flex items-center justify-center gap-2 transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                >
                                  <MdEdit />
                                </NavLink>
                              </Tooltip>
                            )}

                            {item?.status === 'closed' && (
                              <Tooltip
                                title='View Vehicle'
                                position='top'
                                trigger='mouseenter'
                              >
                                <NavLink
                                  to={`/closed/${item._id}`}
                                  className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                >
                                  <MdRemoveRedEye />
                                </NavLink>
                              </Tooltip>
                            )}
                            {item?.status !== 'closed' ? (
                              <Tooltip
                                title='View Vehicle'
                                position='top'
                                trigger='mouseenter'
                              >
                                <NavLink
                                  to={`/vehicle/${item._id}`}
                                  className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                >
                                  <MdRemoveRedEye />
                                </NavLink>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            {item?.status === 'parking' && (
                              <>
                                <Tooltip
                                  title='Launch Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <NavLink
                                    to={`/parking-lot/${item._id}`}
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <IoArrowUp />
                                  </NavLink>
                                </Tooltip>
                                <Tooltip
                                  title='Edit Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <button
                                    onClick={() =>
                                      props.handleEditVehicle(item._id)
                                    }
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdEdit />
                                  </button>
                                </Tooltip>
                              </>
                            )}

                            {item.status === 'draft' && (
                              <div className='flex gap-2 text-right max-lg:hidden editable-ico'>
                                <Tooltip
                                  title='Edit Vehicle'
                                  position='top'
                                  trigger='mouseenter'
                                >
                                  <button
                                    onClick={() =>
                                      props.handleEditVehicle(item._id)
                                    }
                                    className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                  >
                                    <MdEdit />
                                  </button>
                                </Tooltip>
                              </div>
                            )}

                            {!['closed', 'sold', 'scheduled', 'live'].includes(
                              item.status,
                            ) && (
                              <Tooltip
                                title='Delete Vehicle'
                                position='top'
                                trigger='mouseenter'
                              >
                                <button
                                  onClick={() =>
                                    props.openDeleteModal(item._id)
                                  }
                                  className='flex items-center justify-center transition rounded-full w-7 h-7 bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                                >
                                  <MdDelete />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </>
                      )}
                      {ParkingLot && (
                        <Tooltip
                          title='Launch Vehicle'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/parking-lot/${item._id}`}
                            className='flex items-center justify-center p-2 px-3 transition rounded bg-primary bg-opacity-10 hover:bg-opacity-100 hover:bg-primary text-primary hover:text-white'
                          >
                            <IoArrowUp /> &nbsp;Launch
                          </NavLink>
                        </Tooltip>
                      )}

                      {Pending && (
                        <Tooltip
                          title='View Vehicle'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/pending/${item._id}`}
                            className='btn btn-primary-ico'
                          >
                            <MdRemoveRedEye />
                            View
                          </NavLink>
                        </Tooltip>
                      )}

                      {Closed && (
                        <Tooltip
                          title='View Vehicle'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/closed/${item._id}`}
                            className='btn btn-primary-ico'
                          >
                            <MdRemoveRedEye />
                            View
                          </NavLink>
                        </Tooltip>
                      )}
                      {SalesHistory && (
                        <Tooltip
                          title='View Vehicle'
                          position='top'
                          trigger='mouseenter'
                        >
                          <NavLink
                            to={`/sales-history/${item._id}`}
                            className='btn btn-primary-ico'
                          >
                            <MdRemoveRedEye />
                            View
                          </NavLink>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </Fragment>
        </Fragment>
      ) : (
        <Fragment>
          {pathname !== '/' ? (
            <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
              <div className='img'>
                <img
                  src={gif}
                  alt='vehicle-img'
                  onError={(e) => vehicleBrokenImage(e)}
                />
              </div>
              <div className='title title-gray subH-70'>
                <h5 className='subHeading-5'>
                  There are no vehicle listings available.
                </h5>
              </div>
            </div>
          ) : (
            <div className='flex flex-col items-center justify-center mt-4 text-center no-result'>
              <div className='title title-gray'>
                <h5 className='subHeading-5'>
                  There are no vehicle listings available.
                </h5>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

CustomTable.propTypes = {
  data: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
  timeLeft: PropTypes.func,
  launchAccess: PropTypes.bool,
  gridClass: PropTypes.string,
  showStatus: PropTypes.bool,
  ParkingLot: PropTypes.bool,
  Live: PropTypes.bool,
  SalesHistory: PropTypes.bool,
  Scheduled: PropTypes.bool,
  Closed: PropTypes.bool,
  Pending: PropTypes.bool,
  handleEditVehicle: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
}

export default CustomTable
