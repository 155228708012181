import React from 'react'

import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'

import gif from '../../../assets/gif/car-jump.gif'

const CustomNoVehicleSection = () => {
  return (
    <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
      <div className='img'>
        <img
          src={gif}
          alt='vehicle-img'
          onError={(e) => vehicleBrokenImage(e)}
        />
      </div>
      <div className='title title-gray subH-70'>
        <h5 className='subHeading-5'>
          There are no vehicle listings available.
        </h5>
      </div>
    </div>
  )
}

export default CustomNoVehicleSection
