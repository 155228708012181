import React from 'react'

import Home from '../pages/Seller//Home'

import Vehicle from '../pages/Seller/Vehicle'

import VinDecoder from '../pages/Seller/Vehicle/actions/VinDecoder'

import AddVehicle from '../pages/Seller/Vehicle/actions/Add'
import EditVehicle from '../pages/Seller/Vehicle/actions/Edit'
import ViewVehicle from '../pages/Seller/Vehicle/actions/View'

import ParkingLot from '../pages/Seller/ParkingLot'
import LaunchParkingLot from '../pages/Seller/ParkingLot/actions/Launch'

import Live from '../pages/Seller/Live'
import ViewLive from '../pages/Seller/Live/actions/View'

import Scheduled from '../pages/Seller/Scheduled'
import EditScheduled from '../pages/Seller/Scheduled/actions/Edit'

import Pending from '../pages/Seller/Pending'
import ViewPending from '../pages/Seller/Pending/actions/View'

import Closed from '../pages/Seller/Closed'
import ViewClosed from '../pages/Seller/Closed/actions/View'
import ViewSold from '../pages/Seller/Sold/actions/View'
import Document from '../views/Common/BillOfSale/Document'

import SalesHistory from '../pages/Seller/SalesHistory'

import ViewProfile from '../views/Auth/ViewProfile'
import EditProfile from '../views/Auth/EditProfile'

import Announcement from '../views/Common/Announcement'
import Notification from '../views/Common/Notification'
import Buyerprofile from '../pages/Seller/BuyerProfile'
import Scan from '../pages/Seller/Vehicle/actions/Scan'

import SDKProvider from '../utils/scanVIN/sdk.js'
import { StoreProvider } from '../context/scanStore.js'

const SellerRoutes = [
  { path: '/', element: <Home /> },
  { path: '/vehicle', element: <Vehicle /> },
  { path: '/vehicle/:id', element: <ViewVehicle /> },
  {
    path: '/vin/decoder',
    element: (
      <SDKProvider>
        <StoreProvider>
          <VinDecoder />
        </StoreProvider>
      </SDKProvider>
    ),
  },
  {
    path: '/vin/decoder/scan',
    element: (
      <SDKProvider>
        <StoreProvider>
          <Scan />
        </StoreProvider>
      </SDKProvider>
    ),
  },
  { path: '/vehicle/add', element: <AddVehicle /> },
  { path: '/vehicle/edit/:id', element: <EditVehicle /> },
  { path: '/parking-lot', element: <ParkingLot /> },
  { path: '/parking-lot/:id', element: <LaunchParkingLot /> },
  { path: '/live', element: <Live /> },
  { path: '/live/:id', element: <ViewLive /> },
  { path: '/scheduled', element: <Scheduled /> },
  { path: '/scheduled/edit/:id', element: <EditScheduled /> },
  { path: '/pending', element: <Pending /> },
  { path: '/pending/:id', element: <ViewPending /> },
  { path: '/closed', element: <Closed /> },
  { path: '/closed/:id', element: <ViewClosed /> },
  { path: '/sold/:id', element: <ViewSold /> },
  { path: '/document/:id', element: <Document /> },
  { path: '/sales-history', element: <SalesHistory /> },
  { path: '/profile', element: <ViewProfile /> },
  { path: '/edit-profile', element: <EditProfile /> },
  { path: '/announcement/:id', element: <Announcement /> },
  { path: '/notifications', element: <Notification /> },
  { path: '/buyer/profile/:id', element: <Buyerprofile /> },
]

export default SellerRoutes
