import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import io from 'socket.io-client'

import CustomListView from '../../../../components/Custom/CustomListView'
import CustomTitle from '../../../../components/Custom/CustomTitle'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import configManager from '../../../../config/configManager'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const UpcomingList = () => {
  const location = useLocation()
  const { pathname } = location

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleList, setVehicleList] = useState(null)

  const fetchUpcomingVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/upcoming',
      )
      if (response.data.success) {
        setVehicleList(response.data.vehicleList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchUpcomingVehicleList()

    socket.on('vehicle_launch', () => {
      fetchUpcomingVehicleList()
    })
    socket.on('vehicle_expired', () => {
      fetchUpcomingVehicleList()
    })

    socket.on('upcoming_vehicle_live', () => {
      fetchUpcomingVehicleList()
    })

    return () => {
      socket.off('vehicle_launch', fetchUpcomingVehicleList)
      socket.off('vehicle_expired', fetchUpcomingVehicleList)
      socket.off('upcoming_vehicle_live', fetchUpcomingVehicleList)
    }
  }, [])

  return (
    <Fragment>
      {pathname === '/upcoming' ? (
        <CustomTitle />
      ) : (
        <CustomTitle customTitle='Upcoming Vehicles' />
      )}

      <CustomListView isLoading={isLoading} data={vehicleList} />
    </Fragment>
  )
}

export default UpcomingList
