import React from 'react'
import { Link } from 'react-router-dom'

import Show from './Show'

import ScannerComponent from './ScannerComponent'

import { useSDK } from '../../../../utils/scanVIN/sdk'

const Scan = () => {
  const { loaded } = useSDK()

  return (
    <main className='flex items-center justify-center flex-col'>
      <Show when={!loaded}>Loading....</Show>
      <Show when={loaded}>
        <nav className='fixed top-0 left-0 py-2 z-10 lg:p-4'>
          <Link to='/' className='p-4 flex items-center gap-2 text-white'>
            Back
          </Link>
        </nav>
        <ScannerComponent />
      </Show>
    </main>
  )
}

export default Scan
