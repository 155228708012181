import React, { Fragment, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import io from 'socket.io-client'
import PropTypes from 'prop-types'
import { NavLink, useParams, useNavigate } from 'react-router-dom'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomButtonWithIcon from '../../../../components/Custom/CustomButtonWithIcon'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomActiveBuyerSellerChat from '../../../../components/Custom/CustomActiveBuyerSellerChat'

import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import configManager from '../../../../config/configManager'

import Placeholder_Image from '../../../../assets/car/car-dummy.png'
import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../../../components/Icons'

const {
  CgSpinner,
  BsFillSendCheckFill,
  IoDocumentTextSharp,
  MdDoneAll,
  MdFileDownload,
} = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const View = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()
  const [sellerDetails, setSellerDetails] = useState()
  const [awardedBuyerDetails, setAwardedBuyerDetails] = useState(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/awarded/${id}`,
      )
      if (response.data.success) {
        setIsLoading(false)
        setvehicleDetails(response.data.vehicleDetails)
        setSellerDetails(response.data.sellerDetails)
        setAwardedBuyerDetails(response.data.WonOfferDetails)
        setTitleuploadRequested(
          response.data.vehicleDetails.vehicle_auction.title_upload_requested,
        )
      }
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }, [id])

  const [titleList, setTitleList] = useState([])
  const fetchTitles = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-title/list/${id}`,
      )

      if (response.data.success) {
        setTitleList(response.data?.titles[0]?.title_documents)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
    fetchTitles()

    socket.on('vehicle_title_uploaded', () => {
      fetchTitles()
    })
  }, [fetchData, fetchTitles])

  const setLoading = (id, value) => {
    const newTitle = titleList.map((val) => {
      if (val._id === id) {
        return { ...val, isLoading: value }
      }
      return val
    })
    setTitleList(newTitle)
  }

  const truncateFileName = (fileName) => {
    const extension = fileName.split('.').at(-1)
    const name = fileName.slice(0, 24)
    return `${name}....${extension}`
  }

  const handleDownload = async (url, name, id) => {
    setLoading(id, true)
    try {
      const response = await axiosInterceptorInstance.get(
        `${companyData.API_BASE_URL}/api/v1/user/download?url=${url}`,
        { responseType: 'blob' },
      )
      if (response.statusText === 'OK') {
        url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        setLoading(id, false)
      } else {
        setLoading(id, false)
      }
    } catch (error) {
      handleRequestError({
        response: { data: { message: 'Downloading failed' } },
      })
      setLoading(id, false)
    }
  }

  const [titleuploadRequested, setTitleuploadRequested] = useState(true)

  const sendRequestForTilteUpload = async (id) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/vehicle/buyer/vehicle-title/upload/request/${id}`,
      )
      if (response.data.success) {
        setTitleuploadRequested(true)
        CustomToast({
          message: response.data.message || 'Request send sucessfully.',
          type: 'success',
        })
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    socket.on('vehicle_sold', () => {
      navigate(`/awarded`)
    })

    return () => {
      socket.off('vehicle_sold')
    }
  }, [navigate])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn />
      <CustomPageTitle vehicleDetails={vehicleDetails} />
      <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 large:w-auto large:items-stretch gap-y-10 large:gap-x-8'>
        <div className='w-full large:w-[calc(35%_-_16px)] order-3 large:order-1'>
          <div className='flex flex-col items-start justify-center w-full gap-10 p-0 m-0'>
            <div className='w-full'>
              <div className='overflow-hidden border border-gray/10 rounded-xl'>
                <div className='relative flex flex-wrap items-center justify-between w-full gap-5 p-0 px-8 py-5 m-0 border bg-primary border-gray/5'>
                  <div className='title title-white'>
                    <h5 className='flex flex-wrap items-center gap-3 font-medium capitalize subHeading-4'>
                      Seller
                    </h5>
                  </div>
                  <CustomActiveBuyerSellerChat
                    id={id}
                    vehicleDetails={vehicleDetails}
                  />
                </div>
                <div className='flex flex-wrap items-start justify-between w-full px-5 py-5 md:flex-row md:items-center'>
                  <SellerCard sellerDetails={sellerDetails} />
                </div>
                <img
                  src={
                    vehicleDetails?.vehicle_image?.exterior_main ||
                    Placeholder_Image
                  }
                  alt='img'
                  className='object-cover w-full'
                  onError={(e) => vehicleBrokenImage(e)}
                />
                <div>
                  <ul className='flex flex-col gap-2'>
                    <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
                      <div className='flex-1 title title-gray'>
                        <h6 className='font-medium capitalize subHeading-5 text-80'>
                          VIN
                        </h6>
                      </div>
                      <div className='content flex-2'>
                        <p>
                          <CopyToClipboard>
                            {vehicleDetails?.vin_number}
                          </CopyToClipboard>
                        </p>
                      </div>
                    </li>
                    <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
                      <div className='flex-1 title title-gray'>
                        <h6 className='font-medium capitalize subHeading-5 text-80'>
                          Vehicle
                        </h6>
                      </div>
                      <div className='content flex-2'>
                        <p>
                          {vehicleDetails?.year}&nbsp;{vehicleDetails?.make}
                          &nbsp;
                          {vehicleDetails?.model}
                        </p>
                      </div>
                    </li>
                    <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
                      <div className='flex-1 title title-gray'>
                        <h6 className='font-medium capitalize subHeading-5 text-80'>
                          Awarded Offer
                        </h6>
                      </div>
                      <div className='content flex-2'>
                        <p className='inline-block !text-primary !text-lg !font-medium'>
                          $ {awardedBuyerDetails?.amount}
                        </p>
                      </div>
                    </li>
                    <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
                      <div className='flex-1 title title-gray'>
                        <h6 className='font-medium capitalize subHeading-5 text-80'>
                          Awarded Date
                        </h6>
                      </div>
                      <div className='content flex-2'>
                        <p className='!m-0'>
                          {moment(
                            vehicleDetails?.vehicle_auction?.awarded_at,
                          ).format('YYYY MMM, D h:mm A')}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full large:w-[calc(65%_-_16px)] order-2 large:order-2 flex flex-col items-start justify-start gap-10'>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl '>
            <div className='relative flex flex-wrap items-center justify-between w-full gap-3 pb-2 border-b border-solid max-xs:flex-col md:flex-row max-xs:items-start border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  1
                </span>
                <h3 className='font-medium subHeading-3'>Bill of sale</h3>
              </div>
              <div className='flex items-center gap-2'>
                <NavLink to={`/document/${vehicleDetails?._id}`}>
                  <CustomButtonWithIcon
                    label='Bill of sale'
                    icon={<IoDocumentTextSharp />}
                  />
                </NavLink>
              </div>
            </div>
            <div className='mt-4 content'>
              <p>
                This document serves as a bill of sale, containing the
                seller&apos;s bank information and other pertinent details.
                Therefore, you need to access the seller&apos;s account in order
                for them to finalize this transaction. This Agreement represents
                the complete understanding between the Parties and overrides any
                previous agreements or understandings, whether written or
                verbal, regarding the subject matter discussed here in.
              </p>
            </div>
          </div>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl'>
            <div className='relative flex flex-col items-start justify-between w-full pb-2 border-b border-solid md:flex-row md:items-center border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  2
                </span>
                <h3 className='font-medium subHeading-3'>Title/Registration</h3>
              </div>
            </div>
            <div className='mt-4'>
              {titleList?.length > 0 ? (
                <Fragment>
                  <div className='flex items-center justify-between pb-2 border-b border-gray border-opacity-30'>
                    <div className='title title-gray'>
                      <h6 className='font-medium subHeading-6'>
                        Document Name
                      </h6>
                    </div>
                    <div className='title title-gray'>
                      <h6 className='font-medium subHeading-6'>Actions</h6>
                    </div>
                  </div>
                  <div className='flex flex-col gap-2 mt-4'>
                    {titleList?.map((item) => {
                      return (
                        <div
                          className='flex flex-wrap items-center justify-between w-full'
                          key={item._id}
                        >
                          <div className='flex flex-wrap items-center gap-3 w-[calc(100%_-_72px)]'>
                            <button className='flex items-center justify-center duration-300 ease-linear delay-150 cursor-default text-primary'>
                              <IoDocumentTextSharp />
                            </button>
                            <div className='content primary w-[calc(100%_-_72px)]'>
                              <p>
                                <span>{truncateFileName(item?.name)}</span>
                              </p>
                            </div>
                          </div>
                          <div className='flex items-center gap-2'>
                            <CustomToolTip label='Download Document'>
                              <button
                                className='btn btn-round'
                                onClick={() =>
                                  handleDownload(
                                    item.documents,
                                    item.name,
                                    item?._id,
                                  )
                                }
                                disabled={item?.isLoading}
                              >
                                {item?.isLoading ? (
                                  <CgSpinner className='animate-spin' />
                                ) : (
                                  <MdFileDownload />
                                )}
                              </button>
                            </CustomToolTip>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {titleuploadRequested ? (
                    <Fragment>
                      <div className='mb-5 content'>
                        <p>
                          The request for title/registration upload has already
                          been sent to the seller.
                        </p>
                      </div>
                      <CustomButtonWithIcon
                        label='Request Sent'
                        icon={<MdDoneAll />}
                        disabled={titleuploadRequested}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className='mb-5 content'>
                        <p>
                          Here, you have the option to request the seller to
                          provide the title/registration so you can verify it.
                        </p>
                      </div>
                      <CustomButtonWithIcon
                        onClick={() => sendRequestForTilteUpload(id)}
                        disabled={titleuploadRequested}
                        label='Send Request'
                        icon={<BsFillSendCheckFill />}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div className='relative w-full px-5 py-4 border border-gray border-opacity-10 rounded-xl '>
            <div className='relative flex flex-wrap items-center justify-between w-full gap-3 pb-2 border-b border-solid max-xs:flex-col md:flex-row max-xs:items-start border-gray border-opacity-10'>
              <div className='flex flex-wrap items-center justify-start gap-5 capitalize title title-gray'>
                <span className='w-8 h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-lg font-medium absolute top-[-30px] left-[-30px] z-10'>
                  3
                </span>
                <h3 className='font-medium subHeading-3'>
                  Seller Bank Details
                </h3>
              </div>
            </div>
            <div className='mt-4 content'>
              <p>
                Please transfer the pending amount to the bank details of the
                seller. Once the seller confirms the receipt of payment, the
                deal will be closed officially and you can proceed to pickup the
                car from the pickup location.
              </p>
            </div>

            <div className='flex flex-wrap items-center justify-start w-full p-0 m-0 mt-10 gap-x-10 gap-y-5'>
              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Bank Name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {sellerDetails?.user_financial_details?.seller_bank_name}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Account Holder Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {
                        sellerDetails?.user_financial_details
                          ?.seller_account_holder_name
                      }
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Account Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {
                        sellerDetails?.user_financial_details
                          ?.seller_account_number
                      }
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Routing Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {
                        sellerDetails?.user_financial_details
                          ?.seller_routing_number
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLoadingSection>
  )
}

export default View

const SellerCard = ({ sellerDetails }) => {
  return (
    <div className='flex flex-col items-start justify-start w-full gap-5 p-0 m-0'>
      <NavLink
        className='flex items-center gap-3'
        to={`/seller/profile/${sellerDetails._id}`}
      >
        <div>
          {sellerDetails?.profile_picture ? (
            <img
              src={sellerDetails?.profile_picture}
              className='object-cover w-10 h-10 border rounded-full border-gray border-opacity-10'
              alt='img'
              onError={(e) => userBrokenImages(e)}
            />
          ) : (
            <div className='flex items-center justify-center w-10 h-10 rounded-full text-primary bg-light-gray'>
              {sellerDetails?.first_name?.substring(0, 2)?.toUpperCase()}
            </div>
          )}
        </div>
        <div>
          <div className='title title-gray'>
            <h5 className='font-medium capitalize subHeading-5'>
              {sellerDetails?.first_name}
            </h5>
          </div>
          <div className='content'>
            <p>{sellerDetails?.email}</p>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

SellerCard.propTypes = {
  sellerDetails: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    profile_picture: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
}
