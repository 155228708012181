import React from 'react'
import PropTypes from 'prop-types'
import { Icons } from '../Icons'

const { LuBell } = Icons

const Notification = ({
  sidebarOpen,
  setSidebarOpen,
  setNotificationSidebarOpen,
  notificationCount,
}) => {
  return (
    <div>
      <button
        onClick={(e) => {
          e.stopPropagation()
          if (!sidebarOpen) {
            setSidebarOpen((old) => !old)
          }
          setNotificationSidebarOpen((old) => !old)
        }}
        className={`group search_mnu group relative w-[35px] h-[35px] text-xl text-primary hover:text-white flex items-center justify-center bg-white hover:bg-primary border border-primary border-opacity-50 rounded-full cursor-pointer`}
      >
        {!!notificationCount && (
          <span className='group-hover:text-primary group-hover:bg-white absolute -top-[5px] -right-[3px] px-[1px] py-[1px] min-w-[18px] min-h-[18px] rounded-full flex justify-center items-center text-white text-[8px] leading-[1rem] bg-primary'>
            {notificationCount}
          </span>
        )}
        <LuBell />
      </button>
    </div>
  )
}

Notification.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
  setNotificationSidebarOpen: PropTypes.func.isRequired,
  notificationCount: PropTypes.number,
}

export default Notification
