import React, { Fragment } from 'react'

import CustomTitle from '../../../components/Custom/CustomTitle'

import PendingList from '../../../views/Buyer/Pending/List'

const Pending = () => {
  return (
    <Fragment>
      <CustomTitle customTitle='Pending' />
      <PendingList />
    </Fragment>
  )
}

export default Pending
