import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import CustomToast from '../CustomToast'
const env = process.env.REACT_APP_ENV || 'production' //eslint-disable-line no-undef

function CustomHistoryChangeEvent({ children }) {
  const navigate = useNavigate()

  useEffect(() => {
    let toastId = null
    window.addEventListener('historyChange', (event) => {
      navigate(event.detail.path)
      if (env === 'production') return

      if (!toastId) {
        toastId = CustomToast({
          message: 'Something went wrong!',
          type: 'error',
        })

        setTimeout(() => (toastId = null), 3000)
      }
    })
  }, [navigate])

  return <Fragment>{children}</Fragment>
}

CustomHistoryChangeEvent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomHistoryChangeEvent
