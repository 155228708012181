import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import CustomButtonWithIcon from '../CustomButtonWithIcon'

import { Icons } from '../../Icons'

const { FiPlusCircle, BsThreeDotsVertical, MdOutlineKeyboardBackspace } = Icons

const CustomTitle = ({
  addBtn,
  handleAddClick,
  backBtn,
  customTitle,
  backBtncount,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [heading, setHeading] = useState('')

  const params = useParams()

  useEffect(() => {
    if (location.pathname) {
      const Head = location.pathname.split('/')
      const charactor = Head.some((element) => /[a-zA-Z]/.test(element))
      if (!charactor) {
        setHeading('')
      } else {
        if (Head[1].includes('-')) {
          const slug = Head[1].replace('-', ' ')
          setHeading(slug)
        } else {
          setHeading(Head[1])
        }
      }
    } else {
      setHeading()
    }
  }, [params, location.pathname])

  const handleBackNavigation = () => {
    if (backBtncount) {
      navigate(-backBtncount)
    } else {
      navigate(-1)
    }
  }

  return (
    <div className='w-full border-0 border-b border-gray border-opacity-10 pb-4'>
      <div className='flex justify-between items-center gap-6 h-[46px]'>
        <div className='title title-gray'>
          <h2 className='flex flex-wrap items-center capitalize subHeading-3 sm:subHeading-2'>
            {customTitle ? customTitle : heading}&nbsp;
            <BsThreeDotsVertical className='text-primary' />
          </h2>
        </div>

        {addBtn && (
          <div className='order-2 sm:ml-auto btn-custom'>
            <CustomButtonWithIcon
              icon={<FiPlusCircle />}
              label='Add Vehicle'
              onClick={handleAddClick}
            />
          </div>
        )}

        {backBtn && (
          <div className='justify-end btn-custom'>
            <CustomButtonWithIcon
              icon={<MdOutlineKeyboardBackspace />}
              label='Go Back'
              onClick={handleBackNavigation}
            />
          </div>
        )}
      </div>
    </div>
  )
}

CustomTitle.propTypes = {
  addBtn: PropTypes.bool,
  handleAddClick: PropTypes.func,
  backBtn: PropTypes.bool,
  customTitle: PropTypes.string,
  backBtncount: PropTypes.number,
}

export default CustomTitle
