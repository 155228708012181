import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import DealerInformation from './DealerInformation'
import FinancialDetails from './FinancialDetails'

import CustomTitle from '../../../components/Custom/CustomTitle'

const SwitchRoleRegistration = () => {
  const { switchRoleRequest, role } = useSelector((state) => state.user)

  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    if (!switchRoleRequest) {
      navigate('/')
    }
  })

  return (
    <div>
      <CustomTitle
        customTitle={`become a ${role === 'seller' ? 'buyer' : 'seller'}`}
        backBtn
      />
      <div className='mt-4'>
        <div className='add-tabs md:flex items-center lg:justify-start'>
          <div
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 1 && 'active'
            }`}
          >
            Dealer
          </div>
          <div
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 2 && 'active'
            }`}
          >
            Financial
          </div>
        </div>
        <div className='border border-gray border-opacity-10 rounded-xl rounded-tl-none max-md:rounded-tr-none p-5 md:px-8 md:py-10'>
          {step === 1 && <DealerInformation setStep={setStep} />}
          {step === 2 && <FinancialDetails setStep={setStep} />}
        </div>
      </div>
    </div>
  )
}

export default SwitchRoleRegistration
