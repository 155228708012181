import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import io from 'socket.io-client'

import CustomListView from '../../../../components/Custom/CustomListView'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

import configManager from '../../../../config/configManager'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const PendingList = () => {
  const location = useLocation()
  const { pathname } = location

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleList, setVehicleList] = useState(null)

  const fetchPendingVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/pending',
      )
      if (response.data.success) {
        setVehicleList(response.data.vehicleList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchPendingVehicleList()

    socket.on('vehicle_back_to_parkingloat', () => {
      fetchPendingVehicleList()
    })

    socket.on('vehicle_offer_accept', () => {
      fetchPendingVehicleList()
    })

    return () => {
      socket.off('vehicle_back_to_parkingloat', fetchPendingVehicleList)
      socket.off('vehicle_offer_accept', fetchPendingVehicleList)
    }
  }, [])

  return (
    <CustomListView
      isLoading={isLoading}
      data={vehicleList}
      pathname={pathname}
      Pending
    />
  )
}

export default PendingList
