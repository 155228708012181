import React from 'react'
import PropTypes from 'prop-types'

const InvitationNotificationYear = ({ message }) => {
  const parts = message.split(/\b(\d{4})\b/)

  const formattedMessage = parts.map((part, index) => {
    if (/\b\d{4}\b/.test(part)) {
      return (
        <span className='font-medium text-primary' key={index}>
          {part}
        </span>
      )
    }
    return part
  })

  return <h4 className='text-sm font-normal text-gray'>{formattedMessage}</h4>
}

InvitationNotificationYear.propTypes = {
  message: PropTypes.string.isRequired,
}

export default InvitationNotificationYear
