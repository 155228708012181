import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import moment from 'moment'
import io from 'socket.io-client'

import CustomToolTip from '../CustomToolTip'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'

import configManager from '../../../config/configManager'

import CustomChatImageView from '../CustomChatImageView'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

import { Icons } from '../../Icons'

const {
  IoIosAttach,
  IoMdSend,
  IoCloseCircle,
  IoDocumentTextSharp,
  PiChats,
  MdFileDownload,
  CgSpinner,
} = Icons

const CustomSellerBuyerChat = ({ id, vehicleDetails, awardedBuyerDetails }) => {
  const location = useLocation()

  useEffect(() => {
    if (location.state) {
      if (location.state.chatNotification) {
        openChatBox()
      }
    }
  }, [location])

  const [permanentMessages, setPermanentMessages] = useState([])

  const fetchAllChat = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/chat/seller/${awardedBuyerDetails?.buyer_id?._id}/${id}`,
      )
      setPermanentMessages(response.data.chat.messages)
      setTimeout(scrollChat, 500)
    } catch (error) {
      handleRequestError(error)
    }
  }, [id, awardedBuyerDetails?.buyer_id?._id])

  useEffect(() => {
    fetchAllChat()
  }, [fetchAllChat])

  useEffect(() => {
    if (vehicleDetails) {
      fetchAllChat()
    }

    socket.on('new message', (/* newChatCreated */) => {
      fetchAllChat()
    })
    return () => {
      socket.off('new message', fetchAllChat)
    }
  }, [vehicleDetails, fetchAllChat])

  useEffect(() => {
    socket.on('message received', ({ chat }) => {
      if (chat?.vehicle_id === vehicleDetails?._id) {
        fetchAllChat()
        openChatBox()
      }
    })

    return () => {
      socket.off('message received', () => {
        fetchAllChat()
        openChatBox()
      })
    }
  }, [vehicleDetails, fetchAllChat])

  const [showChatBox, setShowChatBox] = useState(false)

  const openChatBox = () => {
    setShowChatBox(true)
  }

  const closeChatBox = () => {
    setShowChatBox(false)
  }

  const [inputMessage, setInputMessage] = useState()
  const [documentFile, setDocumentFile] = useState(null)
  const [isLoadingDocument, setIsLoadingDocument] = useState(false)
  const fileInput = useRef(null)
  const chatboxRef = useRef(null)
  const formSubmitRef = useRef(null)

  const handleDocumentFileChange = (e) => {
    const file = e.target.files[0]
    setDocumentFile(file)
    fileInput.current.value = null
  }

  const isMessageOrSelectedDocument = useMemo(() => {
    if ((inputMessage && inputMessage.trim() !== '') || !!documentFile)
      return true
    return false
  }, [inputMessage, documentFile])

  const truncateFileName = (url) => {
    const extension = url.split('.').at(-1)
    const name = url.slice(0, 24)
    return `${name}....${extension}`
  }

  const handleSendChat = async (e) => {
    e.preventDefault()
    if (!isMessageOrSelectedDocument) return
    const formData = new FormData()
    formData.append('receiver', awardedBuyerDetails?.buyer_id?._id)
    formData.append('role', awardedBuyerDetails?.buyer_id?.role)
    formData.append('vehicle_status', vehicleDetails?.status)
    if (documentFile) {
      formData.append('document', documentFile)
    } else {
      formData.append('message', inputMessage)
    }

    setIsLoadingDocument(true)

    try {
      const response = await axiosInterceptorInstance.post(
        `/chat/create/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (response.data.success) {
        if (!documentFile) {
          setInputMessage('')
        }
        setDocumentFile(null)
        setIsLoadingDocument(false)
        fetchAllChat()
        socket.emit('new message', response.data)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const getDocumentName = (url) => {
    return url.split('/').at(-1)
  }

  const setIsDownloading = (id, value) => {
    const newMessage = permanentMessages.map((val) => {
      if (val._id === id) {
        return { ...val, isDownloading: value }
      }
      return val
    })
    setPermanentMessages(newMessage)
  }

  const scrollChat = () => {
    chatboxRef.current?.scrollBy({
      top: chatboxRef.current.scrollHeight + 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleDownload = async (url, id) => {
    setIsDownloading(id, true)
    try {
      const response = await axiosInterceptorInstance.get(url, {
        responseType: 'blob',
      })
      if (response.statusText === 'OK') {
        const fileName = getDocumentName(url)
        url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        handleRequestError({
          response: { data: { message: 'Downloading failed' } },
        })
      }
      setIsDownloading(id, false)
    } catch (error) {
      handleRequestError({
        response: { data: { message: 'Downloading failed' } },
      })
      setIsDownloading(id, false)
    }
  }

  useEffect(() => {
    if (documentFile) {
      formSubmitRef.current.requestSubmit()
    }
  }, [documentFile])

  return (
    <div className='flex flex-col'>
      <CustomToolTip label='Chat with buyer'>
        <div
          className='text-2xl text-white cursor-pointer hover:text-gray'
          onClick={openChatBox}
        >
          <PiChats />
        </div>
      </CustomToolTip>
      {showChatBox && (
        <div className='fixed max-sm:bottom-[60px] bottom-[30px] right-[30px] w-full max-lg:max-w-[calc(100vw_-_30%)] max-w-[calc(700px_-_10%)] border border-gray/70 bg-white flex flex-col rounded-lg overflow-hidden z-20 drop-shadow-lg'>
          <div className='flex items-center justify-between px-5 py-3 border-b bg-primary border-gray/70'>
            <NavLink
              to={`/buyer/profile/${awardedBuyerDetails?.buyer_id?._id}`}
              className='flex items-center gap-2'
            >
              {awardedBuyerDetails?.buyer_id?.profile_picture ? (
                <img
                  src={awardedBuyerDetails?.buyer_id?.profile_picture}
                  className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                  alt='img'
                  onError={(e) => userBrokenImages(e)}
                />
              ) : (
                <div className='flex items-center justify-center w-8 h-8 rounded-full text-primary bg-light-gray'>
                  {awardedBuyerDetails?.buyer_id?.first_name
                    ?.substring(0, 2)
                    ?.toUpperCase()}
                </div>
              )}
              <span className='text-sm font-medium text-white capitalize lg:flex-2'>
                {awardedBuyerDetails?.buyer_id?.first_name}
              </span>
            </NavLink>
            <IoCloseCircle
              className='text-2xl text-white cursor-pointer'
              onClick={closeChatBox}
            />
          </div>
          <div
            ref={chatboxRef}
            className='chatbox p-3 max-sm:max-h-[calc(100dvh_-_50%)] h-[300px] overflow-x-hidden overflow-y-auto gap-3 flex flex-col'
          >
            {permanentMessages?.length > 0 &&
              permanentMessages?.map((message, index) => (
                <div key={index}>
                  <div className='w-full text-right '>
                    {message?.receiver ===
                      awardedBuyerDetails?.buyer_id?._id && (
                      <div className='w-fit max-w-[calc(100%_-_35%)] ml-auto'>
                        {message?.message && (
                          <div className='flex flex-col'>
                            <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                              {message?.message}
                            </div>
                            <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                              {moment(message?.created_at).format('h:mm a')}
                            </span>
                          </div>
                        )}
                        {message?.document && (
                          <div className='flex flex-col'>
                            {['jpg', 'png', 'jpeg'].includes(
                              message?.document.split('.').at(-1),
                            ) ? (
                              <div className='inline-block rounded-lg'>
                                <div className='relative flex items-center'>
                                  <button
                                    className='absolute w-5 h-5 bg-red-300 text-primary -left-7'
                                    onClick={() =>
                                      handleDownload(
                                        `${companyData.API_BASE_URL}/api/v1/user/download?url=${message?.document}`,
                                        message._id,
                                      )
                                    }
                                    disabled={message?.isDownloading}
                                  >
                                    {!message?.isDownloading ? (
                                      <MdFileDownload className='w-5 h-5' />
                                    ) : (
                                      <CgSpinner className='w-5 h-5 animate-spin' />
                                    )}
                                  </button>
                                  <CustomChatImageView
                                    Images={message?.document}
                                    onLoad={scrollChat}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className='relative inline-block px-4 py-1 text-sm font-light leading-6 break-words border rounded-lg shadow bg-primary border-gray/5 text-gray'>
                                <div className='flex items-center space-x-1'>
                                  <IoDocumentTextSharp className='-ml-2 text-white' />
                                  <span className='text-white break-all'>
                                    {truncateFileName(
                                      getDocumentName(message?.document),
                                    )}
                                  </span>
                                  <button
                                    className='absolute w-5 h-5 bg-red-300 text-danger top-2 -left-8'
                                    onClick={() =>
                                      handleDownload(
                                        `${companyData.API_BASE_URL}/api/v1/user/download?url=${message?.document}`,
                                        message._id,
                                      )
                                    }
                                    disabled={message?.isDownloading}
                                  >
                                    {!message?.isDownloading ? (
                                      <MdFileDownload className='w-5 h-5' />
                                    ) : (
                                      <CgSpinner className='w-5 h-5 animate-spin' />
                                    )}
                                  </button>
                                </div>
                              </div>
                            )}
                            <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                              {moment(message?.created_at).format('h:mm a')}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='w-full text-left'>
                    {message?.sender === awardedBuyerDetails?.buyer_id?._id && (
                      <div className='flex gap-2 items-start max-w-[calc(100%_-_35%)]'>
                        <div className='flex items-start gap-2'>
                          {awardedBuyerDetails?.buyer_id?.profile_picture ? (
                            <div>
                              <img
                                src={
                                  awardedBuyerDetails?.buyer_id?.profile_picture
                                }
                                alt='img-user'
                                className='block w-6 h-6 bg-white border rounded-full shadow-lg border-gray/60'
                                onError={(e) => userBrokenImages(e)}
                              />
                            </div>
                          ) : (
                            <div className='z-[999] mt-[6px] w-6 h-6 rounded-full flex items-center justify-center bg-gray/20 text-primary text-[10px]'>
                              {awardedBuyerDetails?.buyer_id?.first_name
                                ?.substring(0, 2)
                                ?.toUpperCase()}
                            </div>
                          )}
                          {message?.message && (
                            <div className='flex flex-col'>
                              <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                                {message?.message}
                              </div>
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(message?.created_at).format('h:mm a')}
                              </span>
                            </div>
                          )}
                          {message?.document && (
                            <div className='flex flex-col'>
                              {['jpg', 'png', 'jpeg'].includes(
                                message?.document.split('.').at(-1),
                              ) ? (
                                <div className='inline-block rounded-lg'>
                                  <div className='relative flex items-center'>
                                    <CustomChatImageView
                                      Images={message?.document}
                                      onLoad={scrollChat}
                                    />
                                    <button
                                      className='absolute w-5 h-5 bg-red-300 text-primary -right-7'
                                      onClick={() =>
                                        handleDownload(
                                          `${companyData.API_BASE_URL}/api/v1/user/download?url=${message?.document}`,
                                          message._id,
                                        )
                                      }
                                      disabled={message?.isDownloading}
                                    >
                                      {!message?.isDownloading ? (
                                        <MdFileDownload className='w-5 h-5' />
                                      ) : (
                                        <CgSpinner className='w-5 h-5 animate-spin' />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className='relative inline-block px-4 py-1 text-sm font-light leading-6 break-words border rounded-lg shadow bg-primary border-gray/5 text-gray'>
                                  <button
                                    className='absolute w-5 h-5 bg-red-300 text-primary top-2 -right-7'
                                    onClick={() =>
                                      handleDownload(
                                        `${companyData.API_BASE_URL}/api/v1/user/download?url=${message?.document}`,
                                        message._id,
                                      )
                                    }
                                    disabled={message?.isDownloading}
                                  >
                                    {!message?.isDownloading ? (
                                      <MdFileDownload className='w-5 h-5' />
                                    ) : (
                                      <CgSpinner className='w-5 h-5 animate-spin' />
                                    )}
                                  </button>
                                  <div className='flex items-center space-x-1'>
                                    <IoDocumentTextSharp className='-ml-2 text-white' />
                                    <span className='text-white break-all'>
                                      {truncateFileName(
                                        getDocumentName(message?.document),
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(message?.created_at).format('h:mm a')}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className='flex flex-col p-2 px-3 border-t border-gray/70 bg-[#ebebeb] relative z-10'>
            <form
              onSubmit={handleSendChat}
              ref={formSubmitRef}
              className='flex items-center w-full gap-5'
            >
              <input
                type='text'
                className='w-full px-6 py-3 text-sm font-light leading-5 border !rounded-md outline-none text-gray !border-gray/30 first-letter:!uppercase'
                placeholder='Message'
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              <div className='relative'>
                <label
                  htmlFor='file-input'
                  className='btn-gray-ico hover:text-primary !m-0'
                >
                  <IoIosAttach className='w-5 h-5 text-black' />
                </label>
                <input
                  ref={fileInput}
                  id='file-input'
                  type='file'
                  className='hidden'
                  accept='.jpg, .png, .jpeg, .pdf, .docx, .doc, .xlsx, .xls, .csv, .text'
                  onChange={handleDocumentFileChange}
                />
              </div>
              <button
                className='text-black btn-gray-ico disabled:opacity-50'
                disabled={!isMessageOrSelectedDocument || isLoadingDocument}
              >
                {isLoadingDocument ? (
                  <CgSpinner className='w-5 h-5 animate-spin' />
                ) : (
                  <IoMdSend className='w-5 h-5' />
                )}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

CustomSellerBuyerChat.propTypes = {
  id: PropTypes.string.isRequired,
  sellerId: PropTypes.string.isRequired,
  vehicleDetails: PropTypes.object.isRequired,
  awardedBuyerDetails: PropTypes.object.isRequired,
}

export default CustomSellerBuyerChat
