import React from 'react'
import PropTypes from 'prop-types'
import { PolarArea } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

export const options = {
  scales: {
    r: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
}

const Achart = ({ data }) => {
  return <PolarArea data={data} />
}

Achart.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Achart
