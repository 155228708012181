import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import PersonalProfile from './PersonalProfile'
import DealerInformation from './DealerInformation'
import FinancialDetails from './FinancialDetails'

import CustomTitle from '../../../components/Custom/CustomTitle'

const EditProfile = () => {
  const { role } = useSelector((state) => state.user)

  const [step, setStep] = useState(1)

  return (
    <div>
      <CustomTitle backBtn />
      <div className='mt-4'>
        <div className='add-tabs md:flex items-center lg:justify-start'>
          <div
            onClick={() => setStep(1)}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 1 && 'active'
            }`}
          >
            Personal
          </div>
          <div
            onClick={() => setStep(2)}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
              step === 2 && 'active'
            }`}
          >
            Dealer
          </div>
          {role === 'seller' && (
            <div
              onClick={() => setStep(3)}
              className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-pointer ${
                step === 3 && 'active'
              }`}
            >
              Financial
            </div>
          )}
        </div>
        <div className='border border-gray border-opacity-10 rounded-xl rounded-tl-none max-md:rounded-tr-none p-5 md:px-8 md:py-10'>
          {step === 1 && <PersonalProfile setStep={setStep} />}
          {step === 2 && <DealerInformation setStep={setStep} />}
          {step === 3 && role === 'seller' && (
            <FinancialDetails setStep={setStep} />
          )}
        </div>
      </div>
    </div>
  )
}

export default EditProfile
