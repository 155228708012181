import React from 'react'
import { NavLink } from 'react-router-dom'

import Logo from '../../assets/footer/revvit-white.png'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className='relative p-5 mt-auto bg-white border-t border-gray border-opacity-20'>
      <div className='text-center'>
        <NavLink to='/'>
          <img src={Logo} alt='Logo' className='w-auto h-[40px] mx-auto' />
        </NavLink>
        <div className='mt-4 content'>
          <p>
            Copyright © {currentYear}. Revvit® is a trademark. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
