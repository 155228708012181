import React, { Fragment, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import io from 'socket.io-client'

import CustomChatImageView from '../Custom/CustomChatImageView'

import { getId } from '../../utils/cookies/cookies'
import userBrokenImages from '../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'

import configManager from '../../config/configManager'

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)

import { Icons } from '../Icons'

const {
  CgSpinner,
  IoDocumentTextSharp,
  IoSendSharp,
  IoAttach,
  MdFileDownload,
  CiMinimize1,
  CiMaximize1,
  IoCloseOutline,
} = Icons

import PropTypes from 'prop-types'

const ChatSidebar = ({
  chatSidebarOpen,
  setChatSidebarOpen,
  chatRef,
  setSidebarOpen,
}) => {
  const chatTrigger = useRef(null)
  const chatSidebar = useRef(null)

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!chatSidebarOpen || keyCode !== 27) return

      setChatSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)

    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (window.innerWidth <= 768) return
      if (
        chatRef.current.contains(target) ||
        chatSidebar.current.contains(target)
      )
        return
      if (target.closest('li')) return
      if (target.closest('.ignore-click')) return

      setChatSidebarOpen(false)
    }

    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  }, [])
  const senderId = getId()

  const [loading, setLoading] = useState(true)
  const [chats, setChats] = useState([])

  const [selectedBuyerIds, setSelectedBuyerIds] = useState([])
  const [minimizedChatsBuyerIds, setMinimizedChatsBuyerIds] = useState([])
  const [isScreenSmallerThanSm, setIsScreenSmallerThanSm] = useState(false)
  const [isScreenMd, setIsScreenMd] = useState(false)
  const [isScreenLargerThanMd, setIsScreenLargerThanMd] = useState(false)

  const handleResize = () => {
    setIsScreenSmallerThanSm(window.innerWidth < 640)
    setIsScreenMd(window.innerWidth >= 640 && window.innerWidth < 1200)
    setIsScreenLargerThanMd(window.innerWidth >= 1200)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const chatSectionRef = useRef(null)
  const [showChatSection, setShowChatSection] = useState(false)
  const openShowChatSection = () => {
    setShowChatSection(true)
  }

  const handleClickOutside = (event) => {
    if (
      chatSectionRef.current &&
      !chatSectionRef.current.contains(event.target)
    ) {
      setShowChatSection(false)
    }
  }

  useEffect(() => {
    if (showChatSection) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showChatSection])

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get('/chat/users/chat')
      setChats(response.data.chats || [])
      setTimeout(scrollChat, 500)
      setLoading(false)
    } catch (error) {
      handleRequestError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [chatSenderId, setChatSenderId] = useState()
  const [chatVehicleId, setChatVehicleId] = useState()

  useEffect(() => {
    socket.on('message received', ({ chat, user_id }) => {
      if (senderId !== user_id) {
        setChatVehicleId(chat.vehicle_id)
        setChatSenderId(chat.messages[chat.messages.length - 1].sender)
        fetchData()
        setSidebarOpen(true)
        // setChatSidebarOpen(true)

        openShowChatSection()
      }
    })

    return () => {
      socket.off('message received')
    }
  }, [senderId])

  const [sellerToBuyerMessages, setSellerToBuyerMessages] = useState({})

  useEffect(() => {
    const messagesByBuyer = {}
    chats.forEach((chat) => {
      chat.messages.forEach((message) => {
        const otherParty =
          message.sender._id === senderId ? message.receiver : message.sender
        const buyerKey = `${otherParty._id}-${chat?.vehicle_id?._id}`
        if (otherParty._id !== senderId) {
          if (!messagesByBuyer[buyerKey]) {
            messagesByBuyer[buyerKey] = {
              messages: [],
              vehicleDetails: chat.vehicle_id,
              receiverDetails: otherParty,
            }
          }
          messagesByBuyer[buyerKey].messages.push(message)
        }
      })
    })
    setSellerToBuyerMessages(messagesByBuyer)
  }, [chats, senderId])

  const handleBuyerClick = (buyerId) => {
    if (isScreenSmallerThanSm) {
      setChatSidebarOpen(true)
    } else {
      setChatSidebarOpen(false)
    }

    if (!selectedBuyerIds.includes(buyerId)) {
      // const newSelectedBuyerIds = isScreenSmallerThanSm
      //  ? [buyerId]
      //  : [buyerId, ...selectedBuyerIds.slice(0, 2)]

      let newSelectedBuyerIds = []

      if (isScreenSmallerThanSm) {
        newSelectedBuyerIds = [buyerId]
      } else if (isScreenMd) {
        newSelectedBuyerIds = [buyerId, ...selectedBuyerIds.slice(0, 1)]
      } else if (isScreenLargerThanMd) {
        newSelectedBuyerIds = [buyerId, ...selectedBuyerIds.slice(0, 2)]
      }
      setSelectedBuyerIds(newSelectedBuyerIds)
      setChatSenderId('')
      setChatVehicleId('')
    }
  }

  const handleChatMinimizeMaximizeClick = (buyerId) => {
    if (!minimizedChatsBuyerIds.includes(buyerId)) {
      setMinimizedChatsBuyerIds([...minimizedChatsBuyerIds, buyerId])
    } else {
      setMinimizedChatsBuyerIds(
        minimizedChatsBuyerIds.filter((id) => id !== buyerId),
      )
    }
  }

  const removeBuyerId = (buyerId) => {
    if (isScreenSmallerThanSm) {
      setChatSidebarOpen(true)
    }
    setSelectedBuyerIds(selectedBuyerIds.filter((id) => id !== buyerId))
  }

  const [inputMessages, setInputMessages] = useState({})

  const handleInputChange = (buyerId, value) => {
    setInputMessages({
      ...inputMessages,
      [buyerId]: value,
    })
  }

  const [documentFile, setDocumentFile] = useState()

  const [loadingStatus, setLoadingStatus] = useState({})
  const fileInput = useRef(null)
  const formSubmitRef = useRef(null)
  const chatboxRef = useRef(null)

  const handleDocumentFileChange = (e) => {
    const file = e.target.files[0]
    setDocumentFile(file)
    fileInput.current.value = null
  }

  const truncateFileName = (url) => {
    const extension = url.split('.').at(-1)
    const name = url.slice(0, 24)
    return `${name}....${extension}`
  }

  const handleSendChat = async (e, details, buyerId) => {
    e.preventDefault()

    const messageToSend = inputMessages[buyerId]?.trim()

    if (!messageToSend && !documentFile) return

    const formData = new FormData()
    formData.append('receiver', details.receiverDetails._id)
    formData.append('role', details.vehicleDetails?.user?.role || 'seller')
    formData.append('vehicle_status', details?.vehicleDetails?.status)
    if (documentFile) {
      formData.append('document', documentFile)
    } else {
      formData.append('message', messageToSend)
    }

    setLoadingStatus({
      ...loadingStatus,
      [buyerId]: true,
    })
    try {
      const response = await axiosInterceptorInstance.post(
        `/chat/create/${details?.vehicleDetails?._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (response.data.success) {
        if (!documentFile) {
          setInputMessages({
            ...inputMessages,
            [buyerId]: '',
          })
        }
        setDocumentFile(null)

        setLoadingStatus({
          ...loadingStatus,
          [buyerId]: false,
        })
        fetchData()
        socket.emit('new message', response.data)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const getDocumentName = (url) => {
    return url.split('/').at(-1)
  }

  const scrollChat = () => {
    chatboxRef.current?.scrollBy({
      top: chatboxRef.current.scrollHeight + 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleDownload = async (url, msgId) => {
    setDownloadingDocuments((prev) => ({ ...prev, [msgId]: true }))
    try {
      const response = await axiosInterceptorInstance.get(
        `${companyData.API_BASE_URL}/api/v1/user/download?url=${url}`,
        { responseType: 'blob' },
      )
      if (response.statusText === 'OK') {
        const fileName = getDocumentName(url)
        url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        handleRequestError({
          response: { data: { message: 'Downloading failed' } },
        })
      }
      setDownloadingDocuments((prev) => ({ ...prev, [msgId]: false }))
    } catch (error) {
      handleRequestError({
        response: { data: { message: 'Downloading failed' } },
      })
      setDownloadingDocuments((prev) => ({ ...prev, [msgId]: false }))
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!documentFile) {
      formSubmitRef.current.requestSubmit()
    }
  }, [documentFile])

  const sortedMessages = Object.entries(sellerToBuyerMessages).sort(
    ([, a], [, b]) => {
      const lastMessageA = a.messages[a.messages.length - 1]
      const lastMessageB = b.messages[b.messages.length - 1]
      return (
        new Date(lastMessageB.created_at) - new Date(lastMessageA.created_at)
      )
    },
  )
  const [downloadingDocuments, setDownloadingDocuments] = useState({})

  const renderMessages = (buyerId) => {
    const details = sellerToBuyerMessages[buyerId]
    const isMinimized = minimizedChatsBuyerIds.includes(buyerId)

    return (
      <div
        className={`w-[300px] h-full rounded-lg overflow-hidden z-20 drop-shadow-lg`}
      >
        <div className='flex items-center justify-between bg-primary px-5 py-3'>
          <NavLink
            className='flex items-center gap-4'
            to={`/buyer/profile/${details?.receiverDetails?._id}`}
          >
            {details?.receiverDetails?.profile_picture ? (
              <img
                src={details?.receiverDetails?.profile_picture}
                className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                alt='img'
                onError={(e) => userBrokenImages(e)}
              />
            ) : (
              <div className='flex items-center justify-center w-8 h-8 rounded-full text-primary bg-light-gray'>
                {details?.receiverDetails?.first_name
                  .substring(0, 2)
                  ?.toUpperCase()}
              </div>
            )}
            <div className='flex flex-col text-sm md:text-base font-medium text-white capitalize'>
              <div className='text-center'>
                <span>{details?.receiverDetails?.first_name}</span>
              </div>
            </div>
          </NavLink>
          <div className='flex items-center gap-2'>
            {isMinimized ? (
              <CiMaximize1
                className='text-white cursor-pointer text-2xl'
                onClick={() => handleChatMinimizeMaximizeClick(buyerId)}
              />
            ) : (
              <CiMinimize1
                onClick={() => handleChatMinimizeMaximizeClick(buyerId)}
                className='text-white cursor-pointer text-2xl'
              />
            )}
            <IoCloseOutline
              onClick={() => removeBuyerId(buyerId)}
              className='text-white cursor-pointer text-2xl'
            />
          </div>
        </div>

        {!isMinimized && (
          <Fragment>
            <div
              ref={chatboxRef}
              className='max-sm:max-h-[calc(100dvh_-_50%)] h-[300px] bg-white overflow-x-hidden overflow-y-auto flex flex-col gap-2'
            >
              <div className='flex justify-center bg-[#FDE8EF] text-primary text-xs absolute w-full z-[99999999]'>
                <span className='p-2'>
                  {details?.vehicleDetails?.year}&nbsp;
                  {details?.vehicleDetails?.model}&nbsp;
                  {details?.vehicleDetails?.make}
                </span>
              </div>
              <div className='mt-10'>
                {sellerToBuyerMessages[buyerId].messages.map((msg) => (
                  <div key={msg._id} className='w-full relative px-2'>
                    {msg.sender._id !== senderId && (
                      <div className='w-full text-left'>
                        <div className='flex gap-2 items-start max-w-[calc(100%_-_35%)]'>
                          {msg?.message && (
                            <div className='flex flex-col'>
                              <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                                {msg?.message}
                              </div>
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(msg?.created_at).format('h:mm a')}
                              </span>
                            </div>
                          )}
                          {msg?.document && (
                            <Fragment>
                              {['jpg', 'png', 'jpeg'].includes(
                                msg?.document.split('.').at(-1),
                              ) ? (
                                <div className='flex gap-2'>
                                  <div>
                                    <CustomChatImageView
                                      Images={msg?.document}
                                      onLoad={scrollChat}
                                    />
                                    <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                      {moment(msg?.created_at).format('h:mm a')}
                                    </span>
                                  </div>
                                  <button
                                    className='bg-red-300 text-primary'
                                    onClick={() =>
                                      handleDownload(msg?.document, msg._id)
                                    }
                                    disabled={downloadingDocuments[msg._id]}
                                  >
                                    {!downloadingDocuments[msg._id] ? (
                                      <MdFileDownload className='w-5 h-5' />
                                    ) : (
                                      <CgSpinner className='w-5 h-5 animate-spin' />
                                    )}
                                  </button>
                                </div>
                              ) : (
                                <div className='flex flex-col'>
                                  <div className='flex items-center gap-2'>
                                    <div className='bg-primary rounded-lg break-words shadow px-4 py-1'>
                                      <div className='flex items-center space-x-1'>
                                        <IoDocumentTextSharp className='-ml-2 text-white' />
                                        <span className='text-sm font-light text-white break-all'>
                                          {truncateFileName(
                                            getDocumentName(msg?.document),
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <button
                                      className='bg-red-300 text-primary'
                                      onClick={() =>
                                        handleDownload(msg?.document, msg._id)
                                      }
                                      disabled={downloadingDocuments[msg._id]}
                                    >
                                      {!downloadingDocuments[msg._id] ? (
                                        <MdFileDownload className='w-5 h-5' />
                                      ) : (
                                        <CgSpinner className='w-5 h-5 animate-spin' />
                                      )}
                                    </button>
                                  </div>
                                  <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                    {moment(msg?.created_at).format('h:mm a')}
                                  </span>
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    )}
                    {msg.sender._id === senderId && (
                      <div className='w-full text-right'>
                        <div className='flex gap-2 justify-end max-w-[calc(100%_-_35%)] ml-auto'>
                          {msg?.message && (
                            <div className='flex flex-col'>
                              <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                                {msg?.message}
                              </div>
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(msg?.created_at).format('h:mm a')}
                              </span>
                            </div>
                          )}
                          {msg?.document && (
                            <Fragment>
                              {['jpg', 'png', 'jpeg'].includes(
                                msg?.document.split('.').at(-1),
                              ) ? (
                                <div className='flex gap-2'>
                                  <button
                                    className='bg-red-300 text-primary'
                                    onClick={() =>
                                      handleDownload(msg?.document, msg._id)
                                    }
                                    disabled={downloadingDocuments[msg._id]}
                                  >
                                    {!downloadingDocuments[msg._id] ? (
                                      <MdFileDownload className='w-5 h-5' />
                                    ) : (
                                      <CgSpinner className='w-5 h-5 animate-spin' />
                                    )}
                                  </button>
                                  <div>
                                    <CustomChatImageView
                                      Images={msg?.document}
                                      onLoad={scrollChat}
                                    />
                                    <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                      {moment(msg?.created_at).format('h:mm a')}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className='flex flex-col'>
                                  <div className='flex items-center gap-2'>
                                    <button
                                      className='bg-red-300 text-primary'
                                      onClick={() =>
                                        handleDownload(msg?.document, msg._id)
                                      }
                                      disabled={downloadingDocuments[msg._id]}
                                    >
                                      {!downloadingDocuments[msg._id] ? (
                                        <MdFileDownload className='w-5 h-5' />
                                      ) : (
                                        <CgSpinner className='w-5 h-5 animate-spin' />
                                      )}
                                    </button>
                                    <div className='bg-primary rounded-lg break-words shadow px-4 py-1'>
                                      <div className='flex items-center space-x-1'>
                                        <IoDocumentTextSharp className='-ml-2 text-white' />
                                        <span className='text-sm font-light text-white break-all'>
                                          {truncateFileName(
                                            getDocumentName(msg?.document),
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                    {moment(msg?.created_at).format('h:mm a')}
                                  </span>
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className='relative z-10 flex flex-col bg-[#ebebeb] py-2 px-2'>
              <form
                onSubmit={(e) => handleSendChat(e, details, buyerId)}
                ref={formSubmitRef}
                className='flex items-center w-full gap-5'
              >
                <input
                  type='text'
                  className='w-full px-3 py-3 text-sm font-light leading-5 border !rounded-md outline-none text-gray !border-gray/30 first-letter:!uppercase'
                  placeholder='Message'
                  value={inputMessages[buyerId] || ''}
                  onChange={(e) => handleInputChange(buyerId, e.target.value)}
                />
                <div className='relative'>
                  <label
                    htmlFor='file-input'
                    className='btn-gray-ico hover:text-primary !m-0'
                  >
                    <IoAttach className='w-5 h-5 text-black' />
                  </label>
                  <input
                    ref={fileInput}
                    id='file-input'
                    type='file'
                    className='!hidden'
                    accept='.jpg, .png, .jpeg, .pdf, .docx, .doc, .xlsx, .xls, .csv, .text'
                    onChange={handleDocumentFileChange}
                  />
                </div>
                <button
                  className='text-black btn-gray-ico disabled:opacity-50'
                  // disabled={!isMessageOrSelectedDocument || isLoadingDocument}
                  disabled={!inputMessages[buyerId]?.trim() && !documentFile}
                >
                  {loadingStatus[buyerId] ? (
                    <CgSpinner className='w-5 h-5 animate-spin' />
                  ) : (
                    <IoSendSharp className='w-5 h-5' />
                  )}
                </button>
              </form>
            </div>
          </Fragment>
        )}
      </div>
    )
  }

  const renderMssageOnSmScreen = (buyerId) => {
    const details = sellerToBuyerMessages[buyerId]
    return (
      <div className='w-full h-full overflow-hidden z-20'>
        <div className='flex items-center justify-between px-5 py-3 border-b bg-primary border-gray/70'>
          <NavLink
            className='flex items-center gap-4'
            to={`/buyer/profile/${details?.receiverDetails?._id}`}
          >
            {details?.receiverDetails?.profile_picture ? (
              <img
                src={details?.receiverDetails?.profile_picture}
                className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                alt='img'
                onError={(e) => userBrokenImages(e)}
              />
            ) : (
              <div className='flex items-center justify-center w-8 h-8 rounded-full text-primary bg-light-gray'>
                {details?.receiverDetails?.first_name
                  .substring(0, 2)
                  ?.toUpperCase()}
              </div>
            )}
            <div className='flex flex-col md:flex-row text-sm md:text-base font-medium text-white capitalize'>
              <span>{details?.receiverDetails?.first_name}</span>
              <span className='hidden md:block'>&nbsp;-&nbsp;</span>
              <span>
                {details?.vehicleDetails?.year}&nbsp;
                {details?.vehicleDetails?.model}
                &nbsp;
                {details?.vehicleDetails?.make}
              </span>
            </div>
          </NavLink>
          <IoCloseOutline
            onClick={() => removeBuyerId(buyerId)}
            className='text-white cursor-pointer text-2xl ignore-click'
          />
        </div>
        <div
          ref={chatboxRef}
          className='h-[calc(100dvh_-_45%)] bg-white overflow-x-hidden overflow-y-auto flex flex-col gap-2 p-2'
        >
          {sellerToBuyerMessages[buyerId].messages.map((msg) => (
            <div key={msg._id} className='w-full flex'>
              {msg.sender._id !== senderId && (
                <div className='w-full text-left'>
                  <div className='flex gap-2 items-start max-w-[calc(100%_-_35%)]'>
                    {msg?.message && (
                      <div className='flex flex-col'>
                        <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                          {msg?.message}
                        </div>
                        <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                          {moment(msg?.created_at).format('h:mm a')}
                        </span>
                      </div>
                    )}
                    {msg?.document && (
                      <Fragment>
                        {['jpg', 'png', 'jpeg'].includes(
                          msg?.document.split('.').at(-1),
                        ) ? (
                          <div className='flex gap-2'>
                            <div>
                              <CustomChatImageView
                                Images={msg?.document}
                                onLoad={scrollChat}
                              />
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(msg?.created_at).format('h:mm a')}
                              </span>
                            </div>
                            <button
                              className='bg-red-300 text-primary'
                              onClick={() =>
                                handleDownload(msg?.document, msg._id)
                              }
                              disabled={downloadingDocuments[msg._id]}
                            >
                              {!downloadingDocuments[msg._id] ? (
                                <MdFileDownload className='w-5 h-5' />
                              ) : (
                                <CgSpinner className='w-5 h-5 animate-spin' />
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                              <div className='bg-primary rounded-lg break-words shadow px-4 py-1'>
                                <div className='flex items-center space-x-1'>
                                  <IoDocumentTextSharp className='-ml-2 text-white' />
                                  <span className='text-sm font-light text-white break-all'>
                                    {truncateFileName(
                                      getDocumentName(msg?.document),
                                    )}
                                  </span>
                                </div>
                              </div>
                              <button
                                className='bg-red-300 text-primary'
                                onClick={() =>
                                  handleDownload(msg?.document, msg._id)
                                }
                                disabled={downloadingDocuments[msg._id]}
                              >
                                {!downloadingDocuments[msg._id] ? (
                                  <MdFileDownload className='w-5 h-5' />
                                ) : (
                                  <CgSpinner className='w-5 h-5 animate-spin' />
                                )}
                              </button>
                            </div>
                            <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                              {moment(msg?.created_at).format('h:mm a')}
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
              {msg.sender._id === senderId && (
                <div className='w-full text-right'>
                  <div className='flex gap-2 justify-end max-w-[calc(100%_-_35%)] ml-auto'>
                    {msg?.message && (
                      <div className='flex flex-col'>
                        <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                          {msg?.message}
                        </div>
                        <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                          {moment(msg?.created_at).format('h:mm a')}
                        </span>
                      </div>
                    )}
                    {msg?.document && (
                      <Fragment>
                        {['jpg', 'png', 'jpeg'].includes(
                          msg?.document.split('.').at(-1),
                        ) ? (
                          <div className='flex gap-2'>
                            <button
                              className='bg-red-300 text-primary'
                              onClick={() =>
                                handleDownload(msg?.document, msg._id)
                              }
                              disabled={downloadingDocuments[msg._id]}
                            >
                              {!downloadingDocuments[msg._id] ? (
                                <MdFileDownload className='w-5 h-5' />
                              ) : (
                                <CgSpinner className='w-5 h-5 animate-spin' />
                              )}
                            </button>
                            <div>
                              <CustomChatImageView
                                Images={msg?.document}
                                onLoad={scrollChat}
                              />
                              <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                {moment(msg?.created_at).format('h:mm a')}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='flex flex-col'>
                            <div className='flex items-center gap-2'>
                              <button
                                className='bg-red-300 text-primary'
                                onClick={() =>
                                  handleDownload(msg?.document, msg._id)
                                }
                                disabled={downloadingDocuments[msg._id]}
                              >
                                {!downloadingDocuments[msg._id] ? (
                                  <MdFileDownload className='w-5 h-5' />
                                ) : (
                                  <CgSpinner className='w-5 h-5 animate-spin' />
                                )}
                              </button>
                              <div className='bg-primary rounded-lg break-words shadow px-4 py-1'>
                                <div className='flex items-center space-x-1'>
                                  <IoDocumentTextSharp className='-ml-2 text-white' />
                                  <span className='text-sm font-light text-white break-all'>
                                    {truncateFileName(
                                      getDocumentName(msg?.document),
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                              {moment(msg?.created_at).format('h:mm a')}
                            </span>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='flex flex-col p-2 px-3 border-t border-gray/70 bg-[#ebebeb] relative z-10'>
          <form
            onSubmit={(e) => handleSendChat(e, details, buyerId)}
            ref={formSubmitRef}
            className='flex items-center w-full gap-5'
          >
            <input
              type='text'
              className='w-full px-6 py-3 text-sm font-light leading-5 border !rounded-md outline-none text-gray !border-gray/30 first-letter:!uppercase'
              placeholder='Message'
              value={inputMessages[buyerId] || ''}
              onChange={(e) => handleInputChange(buyerId, e.target.value)}
            />
            <div className='relative'>
              <label
                htmlFor='file-input'
                className='btn-gray-ico hover:text-primary !m-0'
              >
                <IoAttach className='w-5 h-5 text-black' />
              </label>
              <input
                ref={fileInput}
                id='file-input'
                type='file'
                className='!hidden'
                accept='.jpg, .png, .jpeg, .pdf, .docx, .doc, .xlsx, .xls, .csv, .text'
                onChange={handleDocumentFileChange}
              />
            </div>
            <button
              className='text-black btn-gray-ico disabled:opacity-50'
              disabled={!inputMessages[buyerId]?.trim() && !documentFile}
            >
              {loadingStatus[buyerId] ? (
                <CgSpinner className='w-5 h-5 animate-spin' />
              ) : (
                <IoSendSharp className='w-5 h-5' />
              )}
            </button>
          </form>
        </div>
      </div>
    )
  }

  const ChatList = () => {
    return (
      <aside
        ref={chatSidebar}
        className={`fixed md:absolute border-r border-primary bg-white left-0 top-0 z-[99999] md:z-[9999] flex w-full md:w-[450px] h-[calc(100dvh_-_48px)] lg:h-screen flex-col overflow-y-hidden bg-[rgba(250,_249,_245,_1)] duration-300 ease-linear ${
          chatSidebarOpen
            ? 'translate-x-[0px] md:translate-x-[270px]'
            : '-translate-x-full'
        }`}
      >
        <div className='flex justify-between items-center border-b border-primary/20 px-4 py-4'>
          <h5 className='text-lg font-medium text-gray'>Chats</h5>
          {selectedBuyerIds.length === 0 && (
            <button ref={chatTrigger} onClick={() => setChatSidebarOpen(false)}>
              <IoCloseOutline className='text-xl text-primary' />
            </button>
          )}
        </div>

        {isScreenSmallerThanSm && selectedBuyerIds.length > 0 ? (
          selectedBuyerIds
            .slice(0, 1)
            .map((buyerId) => renderMssageOnSmScreen(buyerId))
        ) : (
          <div className='flex flex-col items-start justify-start w-full h-full bg-white rounded-lg overflow-hidden z-20 drop-shadow-lg p-0 m-0'>
            {loading ? (
              <div className='w-full h-full flex items-center justify-center'>
                <div className='h-16 w-16 mx-auto animate-spin rounded-full border-4 border-solid border-primary border-t-transparent'></div>
              </div>
            ) : (
              <ul className='flex flex-col dropDown-list h-[calc(100dvh_-_200px)] overflow-y-auto w-full overflow-x-hidden'>
                {sortedMessages.length > 0 ? (
                  sortedMessages.map(([buyerId, details]) => {
                    const isHighlighted =
                      chatSenderId === details?.receiverDetails?._id &&
                      chatVehicleId === details?.vehicleDetails?._id
                    return (
                      <li
                        key={buyerId}
                        className='hover:bg-primary/5 border-b border-primary/20 transition duration-300 ease-in-out transform cursor-pointer px-4'
                        onClick={() => handleBuyerClick(buyerId)}
                      >
                        <div className='flex gap-4 py-2'>
                          {details?.receiverDetails?.profile_picture ? (
                            <img
                              src={details?.receiverDetails?.profile_picture}
                              className='w-10 h-10 object-cover border border-primary border-opacity-10 rounded-full'
                              alt='img'
                              onError={(e) => userBrokenImages(e)}
                            />
                          ) : (
                            <div
                              className={`w-10 h-10 flex items-center justify-center rounded-full ${
                                isHighlighted
                                  ? 'bg-white text-primary'
                                  : 'bg-primary/10 text-primary'
                              }`}
                            >
                              {details?.receiverDetails?.first_name
                                ?.substring(0, 2)
                                .toUpperCase()}
                            </div>
                          )}
                          <div className='flex flex-col items-start justify-start gap-1'>
                            <span className='text-base text-primary font-medium capitalize'>
                              {details?.receiverDetails?.first_name}
                            </span>
                            <span className='text-sm text-gray/90 font-normal capitalize'>
                              {details?.vehicleDetails?.year}&nbsp;
                              {details?.vehicleDetails?.model}&nbsp;
                              {details?.vehicleDetails?.make}
                            </span>
                          </div>
                        </div>
                      </li>
                    )
                  })
                ) : (
                  <p className='text-center text-gray'>No chats found</p>
                )}
              </ul>
            )}
          </div>
        )}
      </aside>
    )
  }

  return (
    <Fragment>
      {ChatList()}

      <div className='absolute bottom-16 max-sm:right-4 right-2 z-[100] flex flex-row gap-[10px]'>
        {isScreenMd &&
          selectedBuyerIds.slice(0, 2).map((buyerId) => (
            <div
              key={buyerId}
              className={`relative${minimizedChatsBuyerIds.includes(buyerId) ? ' flex justify-end items-end' : ''}`}
            >
              <div className='flex flex-col items-end'>
                {renderMessages(buyerId)}
              </div>
            </div>
          ))}
        {isScreenLargerThanMd &&
          selectedBuyerIds.slice(0, 3).map((buyerId) => (
            <div
              key={buyerId}
              className={`relative${minimizedChatsBuyerIds.includes(buyerId) ? ' flex justify-end items-end' : ''}`}
            >
              <div className='flex flex-col items-end'>
                {renderMessages(buyerId)}
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  )
}

ChatSidebar.propTypes = {
  chatSidebarOpen: PropTypes.bool.isRequired,
  setChatSidebarOpen: PropTypes.func.isRequired,
  chatRef: PropTypes.object.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
}

export default ChatSidebar
