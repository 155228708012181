import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

const CustomToolTip = ({ children, label }) => {
  return (
    <Tooltip
      title={label}
      position='top'
      trigger='mouseenter'
      className='max-md:flex-auto'
    >
      {children}
    </Tooltip>
  )
}

CustomToolTip.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
}

export default CustomToolTip
