import React, { useEffect, useRef, useState } from 'react'

import { getToken } from '../cookies/cookies'

import IosAppInstallStap1Img from '../../assets/Document/IosAppInstallStap1.png'
import IosAppInstallStap2Img from '../../assets/Document/IosAppInstallStap2.png'
import IosAppInstallStap3Img from '../../assets/Document/IosAppInstallStap3.png'

import AndroidAppInstallStap1Img from '../../assets/Document/AndroidAppInstallStap1.jpg'
import AndroidAppInstallStap2Img from '../../assets/Document/AndroidAppInstallStap2.jpg'
import AndroidAppInstallStap3Img from '../../assets/Document/AndroidAppInstallStap3.jpg'
import AndroidAppInstallStap4Img from '../../assets/Document/AndroidAppInstallStap4.jpg'

import Appinstalled from '../../assets/Document/Appinstalled.jpg'
import logo from '../../assets/logo/revvit-logo.png'
import revvitAppIcon from '../../assets/icons/icon.png'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Icons } from '../../components/Icons'

const { FaChevronRight, FiDownload, MdOutlineClose, MdOutlinePhoneAndroid } =
  Icons

let DetectForAddToDocks = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  const isFirefox = userAgent.indexOf('Firefox') !== -1

  const isSafari =
    userAgent.indexOf('Safari') !== -1 && !/chrome|android/i.test(userAgent)

  const isFirefoxOnIOS =
    isFirefox && /iPhone|iPad|iPod|IEMobile/i.test(userAgent)

  return isSafari || isFirefoxOnIOS
}

const AppDownload = () => {
  const modalRef = useRef(null)

  const isAddtodocks = DetectForAddToDocks()
  const [isVisible, setIsVisible] = useState(false)
  const [isSafari, setisSafari] = useState(false)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeConfirmModal()
      }
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isSafari =
      userAgent.indexOf('Safari') !== -1 && !/chrome|android/i.test(userAgent)
    setisSafari(isSafari)

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const closeConfirmModal = () => {
    setIsVisible(false)
  }

  const openInstallationguide = () => {
    setIsVisible(true)
  }

  return (
    <div className='block p-0 m-0 md:hidden'>
      <div
        className={`fixed right-6 z-[19] ${
          getToken() ? 'bottom-[70px]' : 'bottom-6 '
        }`}
      >
        <div
          onClick={() => openInstallationguide()}
          className='w-12 h-12 bg-primary flex justify-center items-center rounded-full cursor-pointer'
        >
          <MdOutlinePhoneAndroid className='text-4xl text-white' />
        </div>
      </div>

      {isAddtodocks && isVisible && (
        <div className='fixed top-0 left-0 z-[9999999] flex h-full min-h-screen w-full items-center justify-center bg-gray/70'>
          <div
            ref={modalRef}
            className={`w-full xs:w-[450px] mx-2 rounded-2xl bg-white z-[99999999] relative ${
              isSafari ? 'h-[75%]' : 'h-[90%]'
            }`}
          >
            <div
              className='close-ico flex justify-end items-end p-4 cursor-pointer'
              onClick={closeConfirmModal}
            >
              <MdOutlineClose className='text-3xl' />
            </div>
            <div className='px-4 h-[calc(100%-_82px)] overflow-y-auto'>
              <div>
                <div className='flex items-center justify-center text-center mb-4'>
                  <div className='title title-gray'>
                    <h5 className='flex gap-4 items-center justify-center'>
                      <img src={logo} alt='logo' className='h-8' />
                      <FaChevronRight className='text-2xl' />
                      <FiDownload className='text-4xl text-primary' />
                    </h5>

                    <h5 className='mt-2 font-semibold'>
                      Add Revvit app to your device
                    </h5>

                    <div className='my-4 flex flex-col gap-4 text-start items-center'>
                      <div>
                        <img
                          src={IosAppInstallStap1Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />

                        <div className='mt-1'>
                          1. Tap the Share button in your browser.
                        </div>
                      </div>
                      <div>
                        <img
                          src={IosAppInstallStap2Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />
                        <div className='mt-1'>
                          2. In the pop-up menu actions, scroll down and tap on
                          the Add to Home Screen.
                        </div>
                      </div>
                      <div>
                        <img
                          src={IosAppInstallStap3Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />
                        <div className='mt-1'>
                          3. Tap on the Add button on the Top-Right corner, to
                          install app.
                        </div>
                      </div>
                      <div>
                        <img
                          src={Appinstalled}
                          alt='img'
                          className='w-full mx-auto shadow-xl h-full'
                        />
                        <div className='mt-1'>
                          4. The app will be accessible from your iPhone home
                          screen
                        </div>
                      </div>
                    </div>

                    <div className='border-t-2 border-primary pt-4'>
                      <p className='text-gray'>
                        If you have already install the Revvit app, open it by
                        tapping the icon
                        <img
                          src={revvitAppIcon}
                          className='w-10 h-full object-contain mx-auto my-1'
                          alt='Appicon'
                        />
                        on the homescreen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isAddtodocks && isVisible && (
        <div className='fixed top-0 left-0 z-[9999999] flex h-full min-h-screen w-full items-center justify-center bg-gray/70'>
          <div
            ref={modalRef}
            className='w-full xs:w-[450px] mx-2 h-[90%] rounded-2xl bg-white z-[99999999] relative'
          >
            <div
              className='close-ico flex justify-end items-end p-4 cursor-pointer'
              onClick={closeConfirmModal}
            >
              <MdOutlineClose className='text-3xl' />
            </div>
            <div className='px-4 h-[calc(100%-_82px)] overflow-y-auto'>
              <div>
                <div className='flex items-center justify-center text-center mb-4'>
                  <div className='title title-gray '>
                    <h5 className='flex gap-4 items-center justify-center'>
                      <img src={logo} alt='logo' className='h-8' />
                      <FaChevronRight className='text-2xl' />
                      <FiDownload className='text-4xl text-primary' />
                    </h5>

                    <h5 className='mt-2 font-semibold'>
                      Add Revvit app to your device
                    </h5>

                    <div className='my-4 flex flex-col gap-4 md:gap-6 text-start items-center'>
                      <div>
                        <img
                          src={AndroidAppInstallStap1Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />

                        <div className='mt-1'>
                          1. Tap the top right three dots button in your
                          browser.
                        </div>
                      </div>
                      <div>
                        <img
                          src={AndroidAppInstallStap2Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />
                        <div className='mt-1'>
                          2. In the pop-up menu actions, scroll down and tap on
                          the Add to Home Screen.
                        </div>
                      </div>
                      <div>
                        <img
                          src={AndroidAppInstallStap3Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />
                        <div className='mt-1'>
                          3. Tap on the install button to install app.
                        </div>
                      </div>
                      <div>
                        <img
                          src={AndroidAppInstallStap4Img}
                          alt='img'
                          className='w-full mx-auto h-full'
                        />
                        <div className='mt-1'>
                          4. Tap on the Add button on the Top-Right corner, to
                          install app.
                        </div>
                      </div>
                      <div>
                        <img
                          src={Appinstalled}
                          alt='img'
                          className='w-full mx-auto shadow-xl h-full'
                        />
                        <div className='mt-1'>
                          5. The app will be accessible from your device home
                          screen
                        </div>
                      </div>
                    </div>

                    <div className='border-t-2 border-primary pt-4'>
                      <p className='text-gray'>
                        If you have already install the Revvit app, open it by
                        tapping the icon
                        <img
                          src={revvitAppIcon}
                          className='w-10 h-full object-contain mx-auto my-1'
                          alt='Appicon'
                        />
                        on the homescreen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AppDownload
