import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'

import { Field, FormikProvider, useFormik } from 'formik'
import PropTypes from 'prop-types'

import CustomInput from '../../../../custom/CustomInput'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { trigger } from '../../../../redux/user/userSlice'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'

import { Icons } from '../../../../components/Icons'

const { FiUpload, MdOutlineCheck } = Icons

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .transform((value) => value.trim())
    .required('Please enter your password')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/,
      'Password must be contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long',
    ),
  phone: Yup.string().required('Phone Number is required'),
})

const PersonalProfile = ({ setStep }) => {
  const dispatch = useDispatch()

  const [userDetails, setUserDetails] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      profile_picture: '',
      profile_picture_preview: '',
      first_name: '',
      last_name: '',
      password: '',
      email: '',
      phone: '',
    },
  })

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/auth/user/details`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        formik.setValues({
          ...formik.values,
          ...response.data.userdetails,
        })
        const fieldsToSet = {
          ...response.data.userdetails,
        }

        formik.setFieldValue(
          'profile_picture_preview',
          fieldsToSet.profile_picture || '',
        )
        setIsLoading(false)
      }
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUserDetails()
  }, [fetchUserDetails])

  const handleSubmit = async (values) => {
    if (userDetails._id) {
      try {
        const formData = new FormData()
        Object.keys(values).forEach((key) => {
          if (
            typeof values[key] === 'object' &&
            !(values[key] instanceof File)
          ) {
            formData.append(key, JSON.stringify(values[key]))
          } else {
            formData.append(key, values[key])
          }
        })

        const response = await axiosInterceptorInstance.put(
          `/auth/user/update/${userDetails._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          setStep(2)
          dispatch(trigger())
        }
      } catch (error) {
        handleRequestError(error)
      }
    }
  }

  const { isSubmitting } = formik

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0]
    formik.setFieldValue('profile_picture', file)

    if (file) {
      const previewUrl = URL.createObjectURL(file)
      formik.setFieldValue('profile_picture_preview', previewUrl)
    }
  }
  return (
    <Fragment>
      <CustomLoadingSection isLoading={isLoading}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className='w-full'>
            <div className='flex flex-col w-full gap-10'>
              <div>
                <div className='mb-3 text-left title title-primary'>
                  <h2 className='heading-2'>Personal information</h2>
                </div>
                <div className='text-left content'>
                  <p>
                    You can update your name, email address, and phone number.
                  </p>
                </div>
              </div>
              <ul className='max-sm:w-full flex-[0_0_auto] flex flex-wrap justify-start items-center'>
                <li className='inline-block'>
                  <label className='flex flex-col items-start justify-start w-full pb-3 border-b last:border-0 border-gray border-opacity-10 last:pb-0 mb:pb-0 md:border-0'>
                    <div className='relative w-24 h-24 overflow-hidden border border-dashed rounded-full cursor-pointer bg-gray bg-opacity-5 border-gray border-opacity-20'>
                      <input
                        accept='image/*'
                        className='hidden z-1'
                        type='file'
                        name='Profile'
                        onChange={(event) => handleImageChange(event)}
                        onBlur={formik.handleBlur}
                      />
                      {formik.values.profile_picture_preview ? (
                        <img
                          src={formik.values.profile_picture_preview}
                          alt='Preview'
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          onError={(e) => userBrokenImages(e)}
                        />
                      ) : (
                        <div className='absolute top-0 flex items-center justify-center object-cover w-full h-full p-0 text-3xl rounded-full bg-light-gray text-primary'>
                          {formik.values.first_name
                            ?.substring(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                      <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                        <div className='flex flex-col items-center justify-center w-8 h-8 mx-auto mb-1 text-white rounded-full bg-gray/90 hover:bg-primary hover:text-white'>
                          <FiUpload />
                        </div>
                      </div>
                    </div>
                    {formik.touched.profile_picture &&
                      formik.errors.profile_picture && (
                        <div className='text-xs font-normal text-danger'>
                          {formik.errors.profile_picture}
                        </div>
                      )}
                  </label>
                </li>
              </ul>
              <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 gap-x-5 gap-y-8'>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='first_name'
                    label='First Name'
                    placeholder='First Name'
                    component={CustomInput}
                    className='form-field'
                    required
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    name='last_name'
                    label='Last Name'
                    placeholder='Last Name'
                    component={CustomInput}
                    className='form-field'
                    required
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    type='email'
                    name='email'
                    label='Email'
                    placeholder='Email'
                    required
                    component={CustomInput}
                    className='form-field !bg-light-gray/50'
                    isDisabled={true}
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    type={`${showPassword ? 'text' : 'password'}`}
                    name='password'
                    label='Password'
                    placeholder='Password*'
                    component={CustomInput}
                    passwords
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    className='form-field'
                    required
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    type='text'
                    name='phone'
                    label='Phone Number'
                    placeholder='Phone Number*'
                    component={CustomInput}
                    className='form-field'
                    required
                  />
                </div>
                <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                  <Field
                    type='text'
                    name='auction_access_number'
                    label='Auction Access Number'
                    placeholder='Auction Access Number*'
                    component={CustomInput}
                    className='form-field'
                  />
                </div>

                <div className='flex flex-wrap items-center justify-center w-full gap-5 mt-10 md:items-center xs:justify-end'>
                  <button
                    type='submit'
                    className='w-full btn btn-primary-ico xs:w-auto'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                    ) : (
                      <Fragment>
                        Save & Continue
                        <MdOutlineCheck />
                      </Fragment>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormikProvider>
      </CustomLoadingSection>
    </Fragment>
  )
}

PersonalProfile.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default PersonalProfile
