import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

import { useStripe } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import CustomInput from '../../../../custom/CustomInput'
import CustomToast from '../../../../components/Custom/CustomToast'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import { requesToSwitchRole } from '../../../../redux/user/userSlice'
import CustomModal from '../../../../components/Custom/CustomModal'

import { Icons } from '../../../../components/Icons'

const {
  MdArrowRightAlt,
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineVerifiedUser,
} = Icons

const validationSchema = Yup.object().shape({
  bank_name: Yup.string().required('Bank name is required'),
  bank_branch_name: Yup.string().required('Bank branch name is required'),
  account_number: Yup.string().required('Account number is required'),
  institution_number: Yup.string().required('Institution number is required'),
  transit_number: Yup.string().required('Transit number is required'),
  bank_address: Yup.string().required('Bank address is required'),
})

const BuyerBankDetails = ({ userDetails, setStep }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const stripe = useStripe()

  const [showAccountNumber, setShowAccountNumber] = useState(false)
  const [showtransitNumber, setShowTransitNumber] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userFinancialDetails, setUserFinancialDetails] = useState()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [confimModal, setConfirmModal] = useState(false)

  const doNotCopyHandler = (e) => {
    e.preventDefault()
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      bank_name: '',
      account_number: '',
      institution_number: '',
      transit_number: '',
      swift_code: '',
      bank_address: '',
      bank_branch_name: '',
    },
  })

  const fetchUserData = useCallback(async () => {
    try {
      setIsLoading(true)

      const financialDetailsResponse = await axiosInterceptorInstance.get(
        `/auth/financial/buyer/details`,
      )

      if (financialDetailsResponse.data.success) {
        setUserFinancialDetails(financialDetailsResponse.data.financialDetails)
        formik.setValues({
          ...formik.values,
          ...financialDetailsResponse.data.financialDetails,
        })
      }
    } catch (error) {
      handleRequestError(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmiting(true)

    if (userFinancialDetails?._id) {
      try {
        const createdIntent = await axiosInterceptorInstance.post(
          `/payment/create-setup-intent`,
          { ...values },
        )
        if (createdIntent) {
          const setupIntent = await stripe.confirmAcssDebitSetup(
            createdIntent.data.clientSecret,
            {
              payment_method: {
                billing_details: {
                  name: userDetails.first_name,
                  email: userDetails.email,
                },
              },
            },
          )
          const verify = await stripe.verifyMicrodepositsForSetup(
            createdIntent.data.clientSecret,
            {
              amounts: [32, 45],
            },
          )

          if (setupIntent && verify) {
            await axiosInterceptorInstance.post('/payment/confirm-acss-debit', {
              acss_confirmed: true,
              microdeposit_verified: true,
              pm_id: setupIntent?.setupIntent?.payment_method,
            })

            const response = await axiosInterceptorInstance.put(
              `/auth/financial/buyer/update/${userDetails._id}`,
              { ...values },
            )
            if (response.data.success) {
              CustomToast({
                message: response.data.message,
                type: 'success',
              })
            }
          }

          const response = await axiosInterceptorInstance.post(
            `/auth/user/switch-role/request`,
          )
          if (response.data.success) {
            if (response.data.switch_role_request) {
              resetForm()
              CustomToast({
                message: response.data.message,
                type: 'success',
              })
              dispatch(
                requesToSwitchRole({
                  switchRoleRequest: false,
                }),
              )
              setTimeout(() => {
                navigate('/switch-role/pending')
              }, 100)
            }
          }
        }
      } catch (error) {
        handleRequestError(error)
      } finally {
        setIsSubmiting(false)
        closeConfirmModal()
      }
    } else {
      setIsSubmiting(false)
    }
  }

  const openConfirmModal = async () => {
    const errors = await formik.validateForm()

    formik.setTouched({
      bank_name: true,
      account_number: true,
      institution_number: true,
      transit_number: true,
      swift_code: true,
      bank_address: true,
      bank_branch_name: true,
    })

    if (Object.keys(errors).length === 0) {
      setConfirmModal(true)
    }
  }

  const closeConfirmModal = () => {
    setConfirmModal(false)
  }

  const submitFinancialDetaisHandler = async () => {
    const errors = await formik.validateForm()

    formik.setTouched({
      bank_name: true,
      account_number: true,
      institution_number: true,
      transit_number: true,
      swift_code: true,
      bank_address: true,
      bank_branch_name: true,
    })

    if (Object.keys(errors).length === 0) {
      handleSubmit(formik.values, formik)
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full max-lg:px-5 '>
          <div className='flex flex-col w-full gap-5 lg:gap-10 '>
            <div>
              <div className='mb-3 text-left title title-primary'>
                <h2 className='heading-2'>Financial Information</h2>
              </div>
              <div className='text-left content'>
                <p>
                  Please provide your bank name, account name, transit number,
                  institution number.
                </p>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full gap-5 p-0 m-0 lg:gap-y-8'>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='bank_name'
                  label='Bank Name'
                  placeholder='Bank Name'
                  component={CustomInput}
                  className='form-field'
                />
                <p className='text-sm text-gray'> Test: New Bank</p>
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='bank_branch_name'
                  label='Bank Branch Name'
                  placeholder='Bank Branch Name'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>

              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type={`${showAccountNumber ? 'text' : 'password'}`}
                  name='account_number'
                  label='Account Number'
                  placeholder='Account Number'
                  component={CustomInput}
                  className='form-field'
                  required
                  secure
                  setToggle={setShowAccountNumber}
                  Toggle={showAccountNumber}
                  doNotCopyHandler={(e) => doNotCopyHandler(e)}
                />
                <p className='text-sm text-gray'> Test: 000123456789</p>
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type={`${showtransitNumber ? 'text' : 'password'}`}
                  name='transit_number'
                  label='Transit Number'
                  placeholder='Transit Nnumber'
                  component={CustomInput}
                  className='form-field'
                  required
                  secure
                  setToggle={setShowTransitNumber}
                  Toggle={showtransitNumber}
                  doNotCopyHandler={(e) => doNotCopyHandler(e)}
                />
                <p className='text-sm'> Test: 11000</p>
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='institution_number'
                  label='Institution Number'
                  placeholder='Institution Number'
                  component={CustomInput}
                  className='form-field'
                />
                <p className='text-sm text-gray'> Test: 000</p>
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='swift_code'
                  label='Swift Code'
                  placeholder='Swift Code'
                  component={CustomInput}
                  className='form-field'
                />
              </div>
              <div className='w-full'>
                <Field
                  name='bank_address'
                  label='Bank Address'
                  placeholder='Bank Address'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-center w-full gap-5 lg:mt-10 md:items-center md:justify-between'>
              <button
                type='button'
                className='order-2 prev next-prev-button xs:flex-auto xs:order-1'
                onClick={() => setStep(1)}
              >
                <MdArrowRightAlt /> Back
              </button>
              <button
                type='button'
                className='order-1 w-full ml-auto btn btn-primary-ico xs:w-auto xs:order-2'
                disabled={isSubmiting}
                onClick={openConfirmModal}
              >
                <span>Verify</span>
                <MdOutlineCheck />
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
      {confimModal && (
        <CustomModal
          handleCloseModal={closeConfirmModal}
          closeModal={confimModal}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeConfirmModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <MdOutlineVerifiedUser />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Please verify your bank details before submitting!
                </h5>
              </div>
            </div>
            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeConfirmModal}
                className='btn btn-gray max-md:flex-auto'
              >
                No, cancel
              </button>

              <button
                onClick={submitFinancialDetaisHandler}
                className={`btn btn-primary max-md:flex-auto ${
                  isSubmiting && 'hover:bg-[#fff]'
                }`}
              >
                {isSubmiting ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </CustomLoadingSection>
  )
}

BuyerBankDetails.propTypes = {
  userDetails: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  setStep: PropTypes.func.isRequired,
}

export default BuyerBankDetails
