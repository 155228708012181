import React from 'react'
import { Link } from 'react-router-dom'

// config
import configManager from '../../../config/configManager'

// images
import Logo from '../../../assets/logo/sidebar-logo.png'

import { Icons } from '../../../components/Icons'

const { FaExclamationTriangle } = Icons

const configData = configManager()

const ForgotPasswordLinkExpired = () => {
  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <div className='card-left'>
          <div className='revvit-logo-wrapper'>
            <Link to={configData.WEBSITE_URL}>
              <img
                src={Logo}
                alt='Logo'
                className='login-form-logo drop-shadow-xl'
              />
            </Link>
          </div>
          <h4 className='mt-10 text-xl font-medium text-white'>
            Welcome to Revvit
          </h4>
        </div>
        <div className='h-full'>
          <div className='h-screen login-card-root'>
            <div className='flex justify-center mb-4'>
              <FaExclamationTriangle className='text-6xl text-center text-primary' />
            </div>
            <div className='text-center title'>
              <h1 className=' !mb-8'>
                This Link is
                <span className='!text-primary'> expired.</span>
              </h1>
            </div>
            <div className='text-center title'>
              <p className=' !mb-8'>
                Your password reset link has expired. Please click &nbsp;
                <Link to='/password/forgot' className='btn-primary-link'>
                  Forgot Password?
                </Link>
                &nbsp; to initiate a new reset request. Thank you.
              </p>
            </div>
            <div className='my-2 text-right'></div>
            <div className='flex flex-wrap items-center justify-between gap-4'>
              <Link
                to='/login'
                className='p-2 px-8 text-white border rounded-md bg-primary border-primary'
              >
                Log in
              </Link>
              <Link
                to='/signup'
                className='p-2 px-8 text-white border rounded-md bg-primary border-primary'
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordLinkExpired
