import React from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import CustomStopWatch from '../CustomStopWatch'
import CustomToolTip from '../CustomToolTip'
import CustomButtonWithIcon from '../CustomButtonWithIcon'
import CopyToClipboard from '../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../Icons'

const { PiChats, LuParkingCircle, MdRemoveRedEye, IoDocumentTextSharp } = Icons

const timezoneAbbreviations = {
  'Canada/Pacific': 'PT',
  'Canada/Mountain': 'MT',
  'Canada/Central': 'CT',
  'Canada/Eastern': 'ET',
  'Canada/Atlantic': 'AT',
  'Canada/Newfoundland': 'CN',
}

const CustomPageTitle = ({
  backParkingLot,
  vehicleDetails,
  timer,
  scheduledDate,
  openStatusModal,
  closed,
  payment,
  isViewVehicleDetailsBtn,
}) => {
  return (
    <div className='flex flex-wrap items-center justify-between w-full my-4 md:my-10 gap-y-4 gap-x-8 large:flex-row'>
      <div className='flex flex-wrap items-center justify-between gap-y-5 gap-x-8 max-md:w-full'>
        {scheduledDate && (
          <span className='flex flex-wrap items-center order-2 px-5 py-2 text-xs font-medium text-center rounded-full bg-primary bg-opacity-10 text-primary'>
            {moment(
              vehicleDetails?.vehicle_auction?.launch_auction_date,
              'YYYY-MM-DD THH:mm:ssZ',
            ).format('YYYY MMM, D')}
            <span className='mx-2 w-[1px] h-[17px] bg-primary/40 block'></span>
            {moment(
              vehicleDetails?.vehicle_auction?.launch_auction_date,
              'YYYY-MM-DD THH:mm:ssZ',
            ).format('h:mm A')}
            &nbsp; (
            {
              timezoneAbbreviations[
                vehicleDetails?.vehicle_auction?.launch_auction_timezone
              ]
            }
            )
          </span>
        )}
        <div className='flex justify-start max-sm:flex-col sm:items-center sm:flex-wrap max-sm:w-full'>
          <h2 className='w-full text-lg font-medium border-0 max-sm:mb-2 max-sm:border-b max-sm:pb-2 sm:border-r sm:mr-4 sm:pr-4 sm:text-xl large:text-2xl text-gray border-gray/30 last:border-r-0 last:m-0 last:p-0 xsmall:w-auto'>
            {vehicleDetails?.year}&nbsp;
            {vehicleDetails?.make}&nbsp;
            {vehicleDetails?.model}
          </h2>
          <h5 className='subHeading-5 text-gray/90'>
            VIN :&nbsp;
            <CopyToClipboard>{vehicleDetails?.vin_number} </CopyToClipboard>
          </h5>
        </div>

        {timer && (
          <span className='flex-wrap items-center justify-center hidden font-medium text-center text-primary max-md:flex text-md'>
            <CustomStopWatch
              end_date={vehicleDetails?.vehicle_auction?.end_auction_time}
              timezone={
                vehicleDetails?.vehicle_auction?.launch_auction_timezone
              }
            />
          </span>
        )}
      </div>
      <div className='flex flex-wrap gap-4 md:flex-row md:items-center md:justify-end max-md:w-full empty:hidden'>
        {timer && (
          <span className='flex-wrap items-center justify-center hidden font-medium md:flex text-md text-primary'>
            <CustomStopWatch
              end_date={vehicleDetails?.vehicle_auction?.end_auction_time}
              timezone={
                vehicleDetails?.vehicle_auction?.launch_auction_timezone
              }
            />
          </span>
        )}
        {vehicleDetails && !isViewVehicleDetailsBtn && (
          <CustomToolTip label='View vehicle details'>
            <CustomButtonWithIcon
              link={`/vehicle/${vehicleDetails?._id}`}
              icon={<MdRemoveRedEye className='text-2xl' />}
              label='View Details'
            />
          </CustomToolTip>
        )}
        {backParkingLot && (
          <CustomButtonWithIcon
            onClick={openStatusModal}
            icon={<LuParkingCircle className='text-2xl' />}
            label='Move To Parking Lot'
          />
        )}
        {closed && (
          <CustomToolTip label='This document is a bill of sale that is sent to the buyer along with vehicle details and payment information.'>
            <CustomButtonWithIcon
              link={`/document/${vehicleDetails?._id}`}
              label='Bill of sale'
              icon={<IoDocumentTextSharp />}
              className='max-md:flex-auto'
            />
          </CustomToolTip>
        )}
        {payment && (
          <>
            <CustomToolTip label='Chat with buyer'>
              <CustomButtonWithIcon
                icon={<PiChats />}
                label='Chat with Seller'
                className='max-md:flex-auto'
              />
            </CustomToolTip>
            <NavLink to={`/document/${vehicleDetails?._id}`}>
              <CustomButtonWithIcon label='Bill of sale' />
            </NavLink>
          </>
        )}
      </div>
    </div>
  )
}

CustomPageTitle.propTypes = {
  backParkingLot: PropTypes.bool,
  vehicleDetails: PropTypes.object,
  timer: PropTypes.bool,
  scheduledDate: PropTypes.bool,
  openStatusModal: PropTypes.func,
  closed: PropTypes.bool,
  payment: PropTypes.bool,
  isViewVehicleDetailsBtn: PropTypes.bool,
}

export default CustomPageTitle
