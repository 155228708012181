import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { useParams } from 'react-router-dom'

import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

import Car_img from '../../../assets/car/car.png'

import { Icons } from '../../../components/Icons'

const { FiClock, BsThreeDotsVertical } = Icons

const Announcement = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [announcementDetails, setAnnouncementDetails] = useState({})

  const fetchAnnouncementList = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/auth/user/announcement/details/${id}`,
      )
      if (response.data.success) {
        setAnnouncementDetails(response.data.announcementDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchAnnouncementList()
  }, [fetchAnnouncementList])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <div className='relative flex flex-col items-start justify-start w-full p-0 m-0 mx-auto h-[calc(100dvh_-_190px)]'>
        <div className='absolute -right-10 -bottom-10 hidden md:block -z-1'>
          <img src={Car_img} alt='car-img' className='h-[170px]' />
        </div>

        <div className='flex flex-wrap items-end justify-between w-full p-0 pb-4 bg-white border-b border-gray/10'>
          <div className='title title-gray'>
            <h2 className='flex flex-wrap items-center capitalize subHeading-3'>
              announcement &nbsp;
              <BsThreeDotsVertical className='text-primary' />
            </h2>
          </div>
        </div>

        <div className='relative p-6 my-5 border border-l-8 rounded-lg cursor-pointer z-2 bg-gray/5 backdrop-blur border-gray/10 border-l-primary  overflow-y-auto max-h-[calc(100dvh_-_200px)]'>
          <div className='flex flex-wrap items-center justify-between w-full p-0 m-0 mb-4'>
            <div className='flex-auto title-primary title'>
              <h2 className='capitalize subHeading-3'>
                {announcementDetails.title}
              </h2>
            </div>
            <div className='flex items-center text-sm text-gray/70'>
              <FiClock className='mr-2' />
              <span>
                {moment(announcementDetails.created_at).format('MMMM Do, YYYY')}
              </span>
            </div>
          </div>
          <div className='content'>
            <p className='!text-base !text-opacity-100'>
              {announcementDetails.message}
            </p>
          </div>
        </div>
      </div>
    </CustomLoadingSection>
  )
}

export default Announcement
