import React from 'react'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import {
  getRegistrationToken,
  setRegistrationToken,
  setRole,
} from '../../../utils/cookies/cookies'

export default function RegistrationRoute() {
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')
  const role = searchParams.get('role')

  if (token) {
    setRegistrationToken(token)
    setRole(role)
  }

  return getRegistrationToken() || token ? <Outlet /> : <Navigate to={'/'} />
}
