import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { trigger } from '../../../../redux/user/userSlice'

import configManager from '../../../../config/configManager'

import check from '../../../../assets/gif/regular-check.gif'

const configData = configManager()

const SwitchRoleRequestPending = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleLogout = async () => {
    dispatch(trigger())
    navigate('/')
  }

  return (
    <div className='bg-white'>
      <div className='lg:h-screen h-[100dvh] p-4 max-lg:pr-0 pt-10 lg:pt-4 '>
        <div className='flex flex-wrap m-0 w-full lg:h-full gap-10 max-h-[calc(100dvh_-_30px)] max-lg:pr-4'>
          <div className='w-full  lg:h-full flex flex-col justify-start items-center pt-0 py-8 lg:py-16'>
            <div className='w-full flex flex-col items-start justify-between border border-gray/10 rounded-xl shadow-lg lg:max-w-[95%] gap-8 px-5 py-8 md:p-10'>
              <div className='text-left'>
                <div className='relative flex flex-wrap items-center justify-start w-full gap-5 p-0 m-0 mb-8'>
                  <div className='flex justify-center items-center w-[50px] h-[50px] sm:w-[60px] sm:h-[60px] bg-primary rounded-full'>
                    <img
                      src={check}
                      alt='Logo'
                      className='block login-form-logo h-11 invert'
                    />
                  </div>
                  <div className='w-[calc(100%_-_80px)]'>
                    <div className='mb-2 title title-gray'>
                      <h2 className='heading-2'>Account Under Review!</h2>
                    </div>
                    <div className='content'>
                      <p className='!text-base'>
                        Switch role request submitted successfully with
                        <Link to={configData.WEBSITE_URL}>Revvit</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='content '>
                  <p className='!text-base'>
                    Your account is currently under review, your switch role
                    request has been successfully submitted. You&apos;re now in
                    queue for the role change confirmation from our
                    administrative team. Once approved by our administrators,
                    your role will be updated accordingly. Please allow some
                    time for this process, as it may take a short while to
                    complete.
                  </p>
                  <p className='!text-base'>
                    While you wait, feel free to explore our
                    website/blog/resources or follow us on social media links
                    for updates and more information. We&apos;re excited to have
                    you on board and look forward to connect dealers seamlessly
                    and facilitate hassle-free transactions through a secure and
                    intelligent platform.
                  </p>
                  <p className='!text-base'>
                    If you have any questions or need further assistance, please
                    don&apos;t hesitate to contact our support team at
                    <Link to='mailTo:info@revvit.ca'>info@revvit.ca</Link>
                  </p>
                  <p className='!text-base'>
                    Thank you for choosing&nbsp;
                    <Link to={configData.WEBSITE_URL}>Revvit</Link>. We&apos;re
                    thrilled to have you join our community!
                  </p>
                </div>
              </div>
              <div className='flex flex-wrap items-start justify-end w-full gap-4'>
                <div
                  onClick={handleLogout}
                  className='cursor-pointer btn btn-primary-ico'
                >
                  Continue
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SwitchRoleRequestPending
