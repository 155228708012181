import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

const ViewProfile = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [userDetails, setUserDetails] = useState(null)

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/auth/user/details`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <div className='flex flex-col w-full gap-10 '>
        <div className='flex flex-col items-start justify-start w-full gap-2 p-0 m-0'>
          <div className='w-full'>
            <CustomTitle backBtn />
          </div>
        </div>
        <div className='flex flex-wrap items-start justify-start w-full gap-10 p-0 m-0'>
          <div className='w-full lg:flex-[0_1_calc(45%_-_20px)]'>
            <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 mb-10 border lg:shadow-2xl gap-y-8 border-gray/10 rounded-xl bg-primary/5 last:mb-0'>
              <div>
                {userDetails?.profile_picture ? (
                  <img
                    src={userDetails?.profile_picture}
                    className='object-cover w-20 h-20 p-0 border rounded-full border-gray/10'
                    alt='img'
                    onError={(e) => userBrokenImages(e)}
                  />
                ) : (
                  <div className='flex items-center justify-center object-cover w-20 h-20 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                    {userDetails?.first_name?.substring(0, 2)?.toUpperCase()}
                  </div>
                )}
              </div>
              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-medium uppercase subHeading-4'>
                      My Profile
                    </h4>
                  </div>
                  <div className='content'>
                    <p>
                      Joined
                      {moment(userDetails?.created_at).format(
                        'YYYY MMM DD hh:mm A',
                      )}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>first name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>{userDetails?.first_name}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>last name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>{userDetails?.last_name}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Email</h5>
                  </div>
                  <div className='content'>
                    <p>{userDetails?.email}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Phone Number</h5>
                  </div>
                  <div className='content'>
                    <p>{userDetails?.phone}</p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Auction Access number
                    </h5>
                  </div>
                  <div className='content'>
                    <p>{userDetails?.auction_access_number}</p>
                  </div>
                </div>
              </div>
            </div>
            {userDetails?.role === 'buyer' ? (
              <div className='flex-wrap items-center justify-center hidden w-full p-8 m-0 border large:flex gap-y-8 border-gray/10 rounded-xl '>
                <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                  <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                    <div className='title title-primary'>
                      <h4 className='font-medium uppercase subHeading-4'>
                        Financial information
                      </h4>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Bank Name</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.bank_name || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Bank Branch Name
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.bank_branch_name || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Account Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.account_number ||
                          '_'}
                      </p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Institution Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.institution_number || '_'}
                      </p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Transit Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.transit_number ||
                          '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Swift Code</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.swift_code || '-'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Bank Address</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details?.bank_name || '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex-wrap items-center justify-center hidden w-full p-8 m-0 border large:flex gap-y-8 border-gray/10 rounded-xl '>
                <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                  <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                    <div className='title title-primary'>
                      <h4 className='font-medium uppercase subHeading-4'>
                        Financial information
                      </h4>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Bank Name</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_bank_name || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Bank Branch Name
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_bank_branch_name || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Account Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_account_number || '_'}
                      </p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Account Holder Name
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_account_holder_name || '_'}
                      </p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Routing Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_routing_number || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Swift Code</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_swift_code || '_'}
                      </p>
                    </div>
                  </div>
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                    <div className='capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>Bank Address</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>
                        {userDetails?.user_financial_details
                          ?.seller_bank_address || '_'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='w-full lg:flex-[0_1_calc(55%_-_20px)]'>
            <div className='flex flex-wrap items-center justify-center w-full p-0 px-8 pt-5 pb-8 m-0 bg-white border gap-y-5 border-gray/10 rounded-xl lg:h-[952px] lg:max-h-[952px] lg:overflow-y-auto'>
              <div>
                {userDetails?.user_dealer_details?.dealer_logo ? (
                  <img
                    src={userDetails?.user_dealer_details?.dealer_logo}
                    className='object-cover w-20 h-20 p-0 border rounded-full border-gray/10'
                    alt='img'
                    onError={(e) => userBrokenImages(e)}
                  />
                ) : (
                  <div className='flex items-center justify-center object-cover w-20 h-20 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                    {userDetails?.user_dealer_details?.name
                      ?.substring(0, 2)
                      ?.toUpperCase()}
                  </div>
                )}
              </div>

              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-medium uppercase subHeading-4'>
                      Dealer information
                    </h4>
                  </div>
                  <div className='content'>
                    <p>
                      Joined
                      {moment(
                        userDetails?.user_dealer_details?.created_at,
                      ).format('YYYY MMM DD hh:mm A')}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Dealership Name/Business Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.name
                        ? userDetails?.user_dealer_details?.name
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Primary Contact Person
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.contact_person
                        ? userDetails?.user_dealer_details?.contact_person
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Contact Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_dealer_details?.phone
                        ? userDetails?.user_dealer_details?.phone
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Contact Email</h5>
                  </div>
                  <div className='content'>
                    <p>
                      {userDetails?.user_dealer_details?.email
                        ? userDetails?.user_dealer_details?.email
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                  <div className='mb-2 capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Website URL</h5>
                  </div>
                  <div className='content'>
                    <p>
                      {userDetails?.user_dealer_details?.website
                        ? userDetails?.user_dealer_details?.website
                        : '-'}
                    </p>
                  </div>
                </div>

                {userDetails?.role === 'buyer' && (
                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10'>
                    <div className='mb-2 capitalize title title-gray text-70'>
                      <h5 className='font-medium subHeading-5'>
                        Interested Province(s)
                      </h5>
                    </div>
                    <ul className='flex flex-wrap items-start justify-start w-full gap-2 p-0 m-0 list-none'>
                      {userDetails?.user_dealer_details?.intrested_province?.map(
                        (province) => {
                          return (
                            <li
                              key={province}
                              className='px-2 py-1 text-xs text-primary bg-primary/10'
                            >
                              {province}
                            </li>
                          )
                        },
                      )}
                    </ul>
                  </div>
                )}

                <div className='w-full'>
                  <div className='mb-5 capitalize title title-gray'>
                    <h5 className='font-medium subHeading-5'>Address</h5>
                  </div>
                  <ul className='flex flex-col items-start justify-start w-full gap-10 p-0 m-0'>
                    {userDetails?.user_dealer_details?.address.map(
                      (address, index) => {
                        return (
                          <li className='w-full' key={index}>
                            <div className='flex flex-wrap items-start justify-center w-full gap-8 p-0 m-0'>
                              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                                <div className='mb-2 capitalize title title-gray text-70'>
                                  <h5 className='font-medium subHeading-5'>
                                    street:
                                  </h5>
                                </div>
                                <div className='capitalize content'>
                                  <p>
                                    {address?.street ? address?.street : '-'}
                                  </p>
                                </div>
                              </div>
                              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                                <div className='mb-2 capitalize title title-gray text-70'>
                                  <h5 className='font-medium subHeading-5'>
                                    city:
                                  </h5>
                                </div>
                                <div className='capitalize content'>
                                  <p>{address?.city ? address?.city : '-'}</p>
                                </div>
                              </div>

                              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                                <div className='mb-2 capitalize title title-gray text-70'>
                                  <h5 className='font-medium subHeading-5'>
                                    province:
                                  </h5>
                                </div>
                                <div className='capitalize content'>
                                  <p>
                                    {address?.province
                                      ? address?.province
                                      : '-'}
                                  </p>
                                </div>
                              </div>

                              <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                                <div className='mb-2 capitalize title title-gray text-70'>
                                  <h5 className='font-medium subHeading-5'>
                                    postal code:
                                  </h5>
                                </div>
                                <div className='capitalize content'>
                                  <p>
                                    {address?.postcode
                                      ? address?.postcode
                                      : '-'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      },
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {userDetails?.role === 'buyer' ? (
            <div className='flex-wrap items-center justify-center hidden w-full p-8 m-0 border max-large:flex gap-y-8 border-gray/10 rounded-xl '>
              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-medium uppercase subHeading-4'>
                      Financial information
                    </h4>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Bank Name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.bank_name || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Bank Branch Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.bank_branch_name ||
                        '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Account Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.account_number ||
                        '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Institution Number
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.institution_number || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Transit Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.transit_number ||
                        '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Swift Code</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.swift_code || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Bank Address</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.bank_address || '_'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='flex-wrap items-center justify-center hidden w-full p-8 m-0 border max-large:flex gap-y-8 border-gray/10 rounded-xl '>
              <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                  <div className='title title-primary'>
                    <h4 className='font-medium uppercase subHeading-4'>
                      Financial information
                    </h4>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Bank Name</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.seller_bank_name ||
                        '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Bank Branch Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.seller_bank_branch_name || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Account Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.seller_account_number || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>
                      Account Holder Name
                    </h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.seller_account_holder_name || '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Routing Number</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.seller_routing_number || '_'}
                    </p>
                  </div>
                </div>

                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Swift Code</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details?.seller_swift_code ||
                        '_'}
                    </p>
                  </div>
                </div>
                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-b-0'>
                  <div className='capitalize title title-gray text-70'>
                    <h5 className='font-medium subHeading-5'>Bank Address</h5>
                  </div>
                  <div className='capitalize content'>
                    <p>
                      {userDetails?.user_financial_details
                        ?.seller_bank_address || '_'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='w-full mt-10'>
        <Link to='/edit-profile' className='ml-auto btn btn-primary-ico w-fit'>
          Edit Profile
        </Link>
      </div>
    </CustomLoadingSection>
  )
}

export default ViewProfile
