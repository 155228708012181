import React, { Fragment, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'

import userBrokenImages from '../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'

import Logo from '../../assets/logo/sidebar-logo.png'

import configManager from '../../config/configManager'
import { updateRole } from '../../redux/user/userSlice'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

import { Icons } from '../../components/Icons'

const {
  LuBell,
  IoSearch,
  IoCarOutline,
  IoTimerOutline,
  IoAddCircleOutline,
  FiLogIn,
  ImHammer2,
  CiParking1,
  RiHome6Line,
  TbLivePhoto,
  CgLivePhoto,
  CgSpinner,
  IoMdHourglass,
  FaAngleRight,
  GiDiamondTrophy,
  PiUserCircleDuotone,
  LuParkingCircle,
  LuListTodo,
  MdOutlineDirectionsCarFilled,
  PiChats,
} = Icons

const Sidebar = ({
  userDetails,
  sidebarOpen,
  setSidebarOpen,
  setSearchSidebarOpen,
  notificationSidebarOpen,
  setNotificationSidebarOpen,
  searchSidebarOpen,
  setProfileSidebarOpen,
  profileSidebarOpen,
  notificationref,
  searchref,
  profileref,
  setIsLoading,
  isLoading,
  chatRef,
  chatSidebarOpen, // eslint-disable-line no-unused-vars
  setChatSidebarOpen,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const dispatch = useDispatch()
  const { role, switchRoleRequest } = useSelector((state) => state.user)

  const trigger = useRef(null)
  const sidebar = useRef(null)
  const [switchRoleIsLoading, SetSwitchRoleIsLoading] = useState(false)

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !sidebar.current ||
        !trigger.current ||
        searchSidebarOpen ||
        profileSidebarOpen ||
        chatSidebarOpen ||
        notificationSidebarOpen
      )
        return
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target) ||
        searchSidebarOpen ||
        profileSidebarOpen ||
        chatSidebarOpen ||
        notificationSidebarOpen
      )
        return
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (
        !sidebarOpen ||
        keyCode !== 27 ||
        searchSidebarOpen ||
        profileSidebarOpen ||
        chatSidebarOpen ||
        notificationSidebarOpen
      )
        return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  const [notificationCount, setNotificationCount] = useState(0)

  const [showNotification, setShowNotification] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setShowNotification((prevShowNotification) => !prevShowNotification)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (notificationCount > 0) {
      document.title = showNotification
        ? `(${notificationCount}) Revvit App`
        : 'Revvit App'
    } else {
      document.title = 'Revvit App'
    }
  }, [notificationCount, showNotification])

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axiosInterceptorInstance.get(
          `/notification/buyer/count`,
        )

        if (response.data.success) {
          setNotificationCount(response.data.notificationCount)
        }
      } catch (error) {
        handleRequestError(error)
      }
    }

    fetchNotificationCount()
    socket.on('notification_received', () => {
      fetchNotificationCount()
    })

    socket.on('all_notifications_read', () => {
      fetchNotificationCount()
    })
    socket.on('single_notifications_read', () => {
      fetchNotificationCount()
    })

    return () => {
      socket.off('notification_received', fetchNotificationCount)
      socket.off('all_notifications_read', fetchNotificationCount)
      socket.off('single_notifications_read', fetchNotificationCount)
    }
  }, [])

  const userRoleUpdatehandler = async (role) => {
    setIsLoading(true)
    SetSwitchRoleIsLoading(true)

    try {
      const response = await axiosInterceptorInstance.put(
        `/auth/user/update-role`,
        { role },
      )

      if (response.data.success) {
        navigate('/')
        dispatch(updateRole(response.data.role))
        setIsLoading(false)
        SetSwitchRoleIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      SetSwitchRoleIsLoading(false)
      handleRequestError(error)
      setIsLoading(false)
    }
  }

  const userRequestRoleUpdatehandler = async () => {
    if (switchRoleRequest) {
      navigate('/switch-role/registration')
    } else {
      navigate('/switch-role/pending')
    }
  }

  return (
    <Fragment>
      <aside
        ref={sidebar}
        className={`absolute lg:static left-0 top-0 z-[99999] flex h-[calc(100dvh_-_48px)] lg:h-screen w-[270px] flex-col overflow-y-hidden bg-gray duration-300 ease-linear lg:translate-x-0 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className='flex items-start justify-between gap-2 px-4 py-5'>
          <NavLink to='/'>
            <img src={Logo} alt='Logo' className='w-auto h-[40px]' />
          </NavLink>
          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
            className='hidden'
          >
            null
          </button>

          <div
            ref={profileref}
            className='flex flex-col items-center justify-center gap-0 '
          >
            <div
              onClick={() => {
                setSearchSidebarOpen(false)
                setNotificationSidebarOpen(false)
                setChatSidebarOpen(false)
                setProfileSidebarOpen((old) => !old)
              }}
              className={`box-border flex flex-col text-lg items-center justify-center w-[40px] h-[40px] rounded-full overflow-hidden text-primary cursor-pointer bg-white  ${
                profileSidebarOpen && 'border border-primary'
              }`}
            >
              {userDetails?.profile_picture ? (
                <img
                  src={userDetails.profile_picture}
                  className='object-cover w-full h-full'
                  alt='img'
                  onError={(e) => userBrokenImages(e)}
                />
              ) : (
                userDetails?.first_name?.substring(0, 2).toUpperCase()
              )}
            </div>
            <FaAngleRight className='text-sm text-white rotate-90' />
          </div>
        </div>
        <div className='mx-4 border-b border-white border-opacity-20'></div>
        <div className='flex flex-col h-full overflow-y-auto duration-300 ease-linear no-scrollbar'>
          <nav className='px-4 py-4 pl-0 overflow-y-auto'>
            <div>
              <div className='flex flex-wrap items-center justify-between w-full p-0 pl-4 m-0 mb-6'>
                <div className='flex items-center justify-end gap-2 text-sm font-normal capitalize text-light-gray'>
                  account:
                  <span className='text-lg font-medium text-primary'>
                    {role}
                  </span>
                </div>

                <button
                  ref={chatRef}
                  onClick={() => {
                    setChatSidebarOpen((old) => !old)
                    setNotificationSidebarOpen(false)
                    setProfileSidebarOpen(false)
                    setSearchSidebarOpen(false)
                  }}
                  className={`group search_mnu group relative w-[40px] h-[40px] text-xl text-primary hover:text-white flex items-center justify-center bg-white hover:bg-primary rounded-full cursor-pointer ${
                    chatSidebarOpen ? 'bg-primary' : ' '
                  } `}
                  // className='group relative w-[40px] h-[40px] text-xl text-primary hover:text-white flex items-center justify-center bg-white hover:bg-primary rounded-full cursor-pointer'
                >
                  <PiChats />
                </button>

                <button
                  ref={notificationref}
                  onClick={() => {
                    setNotificationSidebarOpen((old) => !old)
                    setProfileSidebarOpen(false)
                    setSearchSidebarOpen(false)
                    setChatSidebarOpen(false)
                  }}
                  className={`group search_mnu group relative w-[40px] h-[40px] text-xl text-primary hover:text-white flex items-center justify-center bg-white hover:bg-primary rounded-full cursor-pointer ${
                    searchSidebarOpen ? 'bg-primary' : ' '
                  } `}
                  // className='group relative w-[40px] h-[40px] text-xl text-primary hover:text-white flex items-center justify-center bg-white hover:bg-primary rounded-full cursor-pointer'
                >
                  {!!notificationCount && (
                    <span className='group-hover:text-primary group-hover:bg-white absolute -top-[5px] -right-[3px] px-[1px] py-[1px] min-w-[18px] min-h-[18px] rounded-full flex justify-center items-center text-white text-[10px] leading-[1rem] bg-primary'>
                      {notificationCount}
                    </span>
                  )}
                  <LuBell />
                </button>
              </div>
              {role === 'seller' ? (
                <ul className='flex flex-col gap-4 sidebar_menu'>
                  <li>
                    <NavLink
                      to='/'
                      className={`group ${
                        pathname === '/' && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <RiHome6Line className='text-xl' />
                      Home
                    </NavLink>
                  </li>
                  <li ref={searchref}>
                    <div
                      onClick={() => {
                        setSearchSidebarOpen((old) => !old)
                        setProfileSidebarOpen(false)
                        setNotificationSidebarOpen(false)
                        setChatSidebarOpen(false)
                      }}
                      className={`group search_mnu ${
                        searchSidebarOpen ? 'bg-primary' : ' '
                      } `}
                    >
                      <IoSearch className='text-xl' />
                      Search
                    </div>
                  </li>
                  <li>
                    <NavLink
                      to='/vehicle'
                      className={`group  ${
                        pathname.includes('vehicle') ||
                        (pathname.includes('/decoder') && !searchSidebarOpen)
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoAddCircleOutline className='text-xl' />
                      Add Vehicle
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/parking-lot'
                      className={`group ${
                        pathname.includes('parking-lot') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <CiParking1 className='text-xl' />
                      Parking Lot
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/live'
                      className={`group ${
                        pathname.includes('live') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <TbLivePhoto className='text-xl' />
                      Live
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/scheduled'
                      className={`group ${
                        pathname.includes('scheduled') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoTimerOutline className='text-xl' />
                      Scheduled
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/pending'
                      className={`group ${
                        pathname.includes('/pending') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoMdHourglass className='text-xl' />
                      Pending
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/closed'
                      className={`group ${
                        (pathname.includes('closed') ||
                          pathname.includes('sold') ||
                          pathname.includes('document')) &&
                        !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <ImHammer2 className='text-xl' />
                      Closed
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/sales-history'
                      className={`group ${
                        pathname.includes('sales-history') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <LuListTodo className='text-xl' />
                      Sales History
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul className='flex flex-col gap-4 sidebar_menu'>
                  <li>
                    <NavLink
                      to='/'
                      className={`group ${
                        pathname === '/' && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <RiHome6Line className='text-xl' />
                      Home
                    </NavLink>
                  </li>
                  <li ref={searchref}>
                    <div
                      onClick={() => {
                        setSearchSidebarOpen((old) => !old)
                        setProfileSidebarOpen(false)
                        setChatSidebarOpen(false)
                      }}
                      className={`group search_mnu ${
                        searchSidebarOpen ? 'bg-primary' : ' '
                      } `}
                    >
                      <IoSearch className='text-xl' />
                      Search
                    </div>
                  </li>
                  <li>
                    <NavLink
                      to='/vehicle'
                      className={`group ${
                        pathname.includes('vehicle') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoCarOutline className='text-xl' />
                      Vehicle
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/active'
                      className={`group ${
                        pathname.includes('active') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <TbLivePhoto className='text-xl' />
                      Active
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/upcoming'
                      className={`group ${
                        pathname.includes('upcoming') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoTimerOutline className='text-xl' />
                      Upcoming
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/pending'
                      className={`group ${
                        pathname.includes('/pending') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <IoMdHourglass className='text-xl' />
                      Pending
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/awarded'
                      className={`group ${
                        (pathname.includes('awarded') ||
                          pathname.includes('document') ||
                          pathname.includes('purchased')) &&
                        !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <GiDiamondTrophy className='text-xl' />
                      Awarded
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to='/buy-history'
                      className={`group ${
                        pathname.includes('buy-history') && !searchSidebarOpen
                          ? 'bg-primary'
                          : ' '
                      }`}
                    >
                      <LuListTodo className='text-xl' />
                      Buy History
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </nav>
        </div>

        {userDetails?.is_buyer_details_completed &&
        userDetails?.is_seller_details_completed ? (
          <div
            className='mx-4 bg-primary rounded-xl p-5 mb-5 cursor-pointer'
            onClick={() =>
              (!isLoading || !switchRoleIsLoading) &&
              userRoleUpdatehandler(role === 'seller' ? 'buyer' : 'seller')
            }
          >
            <div className='flex flex-wrap items-stretch justify-between w-full m-0 p-0'>
              <div>
                <h6 className='text-white font-medium text-sm'>Become a</h6>
                <h2 className='text-2xl text-white font-bold'>
                  {role === 'seller' ? 'Buyer' : 'Seller'}
                </h2>
              </div>

              <div className='border-0 border-l border-solid border-white pl-4 text-white text-lg flex flex-col justify-center'>
                {isLoading || switchRoleIsLoading ? (
                  <CgSpinner className='w-4 h-4 animate-spin text-white' />
                ) : (
                  <FiLogIn />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className='mx-4 bg-primary rounded-xl p-5 mb-5 cursor-pointer'
            onClick={() =>
              (!isLoading || !switchRoleIsLoading) &&
              userRequestRoleUpdatehandler()
            }
          >
            <div className='flex flex-wrap items-stretch justify-between w-full m-0 p-0'>
              <div>
                <h6 className='text-white font-medium text-sm'>
                  Request to Become a
                </h6>
                <h2 className='text-2xl text-white font-bold'>
                  {role === 'seller' ? 'Buyer' : 'Seller'}
                </h2>
              </div>
              <div className='border-0 border-l border-solid border-white pl-4 text-white text-lg flex flex-col justify-center'>
                {isLoading || switchRoleIsLoading ? (
                  <CgSpinner className='w-4 h-4 animate-spin text-white' />
                ) : (
                  <FiLogIn />
                )}
              </div>
            </div>
          </div>
        )}
      </aside>

      <div>
        {role === 'seller' ? (
          <div className='btm-menu-bar'>
            <div className='lg:hidden grid grid-cols-4 m-0 p-0 py-2 overflow-hidden rounded-lg rounded-b-none fixed w-full max-w-[calc(100%_-_10px)] left-1/2 -translate-x-1/2 bottom-[0] bg-gray z-20 shadow-up border-0 border-solid border-gray border-opacity-5 items-stretch'>
              <div className='card'>
                <NavLink to='/vehicle' title='Vehicle' className='btm-menu'>
                  <MdOutlineDirectionsCarFilled className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Vehicle</span>
                </NavLink>
              </div>
              <div className='card'>
                <NavLink
                  to='/parking-lot'
                  title='Parking Lot'
                  className='btm-menu'
                >
                  <LuParkingCircle className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Parking Lot</span>
                </NavLink>
              </div>
              <div className='card '>
                <NavLink to='/live' title='Live' className='btm-menu'>
                  <CgLivePhoto className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Live</span>
                </NavLink>
              </div>
              <div className='card'>
                <NavLink to='/profile' title='Profile' className='btm-menu'>
                  <PiUserCircleDuotone className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Profile</span>
                </NavLink>
              </div>
            </div>
          </div>
        ) : (
          <div className='btm-menu-bar'>
            <div className='lg:hidden grid grid-cols-4 m-0 p-0 py-2 overflow-hidden rounded-lg rounded-b-none fixed w-full max-w-[calc(100%_-_20px)] left-1/2 -translate-x-1/2 bottom-0 bg-gray z-20 shadow-up border-0 border-solid border-gray border-opacity-5 items-stretch'>
              <div className='card'>
                <NavLink to='/upcoming' title='Upcoming' className='btm-menu'>
                  <IoTimerOutline className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Upcoming</span>
                </NavLink>
              </div>
              <div className='card'>
                <NavLink to='/active' title='Active' className='btm-menu'>
                  <CgLivePhoto className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Active</span>
                </NavLink>
              </div>
              <div className='card'>
                <NavLink
                  to='/awarded'
                  title='Awarded'
                  className={`btm-menu ${
                    (pathname.includes('document') ||
                      pathname.includes('purchased')) &&
                    'active'
                  }`}
                >
                  <GiDiamondTrophy className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Awarded</span>
                </NavLink>
              </div>

              <div className='card'>
                <NavLink to='/profile' title='Profile' className='btm-menu'>
                  <PiUserCircleDuotone className='text-2xl xs:text-base' />
                  <span className='max-xs:hidden'>Profile</span>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

Sidebar.propTypes = {
  userDetails: PropTypes.shape({
    profile_picture: PropTypes.string,
    first_name: PropTypes.string,
    is_buyer_details_completed: PropTypes.bool,
    is_seller_details_completed: PropTypes.bool,
  }),
  sidebarOpen: PropTypes.bool.isRequired,
  notificationSidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
  setSearchSidebarOpen: PropTypes.func.isRequired,
  setNotificationSidebarOpen: PropTypes.func.isRequired,
  searchSidebarOpen: PropTypes.bool.isRequired,
  setProfileSidebarOpen: PropTypes.func.isRequired,
  profileSidebarOpen: PropTypes.bool.isRequired,
  notificationref: PropTypes.object.isRequired,
  searchref: PropTypes.object.isRequired,
  profileref: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setChatSidebarOpen: PropTypes.func.isRequired,
  chatSidebarOpen: PropTypes.bool.isRequired,
  chatRef: PropTypes.object.isRequired,
}

export default Sidebar
