import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import io from 'socket.io-client'
import moment from 'moment'

import Loader from '../Loader'

import { handleRequestError } from '../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import InvitationNotificationYear from '../../utils/notificationYearFormatter/InvitationNotificationYear'

import configManager from '../../config/configManager'

import { updateRole } from '../../redux/user/userSlice'

import { Icons } from '../../components/Icons'

const {
  IoCloseOutline,
  IoDocumentTextSharp,
  FaCar,
  FaUser,
  MdChat,
  CgSpinner,
} = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const NotificationSidebar = ({
  notificationSidebarOpen,
  setNotificationSidebarOpen,
  notificationref,
  setIsLoadingForSwitchRole,
}) => {
  const { role } = useSelector((state) => state.user)

  const navigate = useNavigate()
  const [isloading, setIsLoading] = useState(false)
  const notificationTrigger = useRef(null)
  const notificationSidebar = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!notificationSidebarOpen || keyCode !== 27) return
      setNotificationSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)

    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (notificationref.current.contains(target)) return
      if (notificationSidebar.current.contains(target)) return
      setNotificationSidebarOpen(false)
    }

    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  const [isNotificatinLoading, setIsNotificatinLoading] = useState(true)
  const [notificationList, setNotificationList] = useState([])

  useEffect(() => {
    const fetchNotificationList = async () => {
      try {
        const response = await axiosInterceptorInstance.get(
          `/notification/buyer/latest/list`,
        )

        if (response.data.success) {
          setNotificationList(response.data.notifications)
          setIsNotificatinLoading(false)
        }
      } catch (error) {
        setIsNotificatinLoading(false)
        handleRequestError(error)
      }
    }

    const handleSocketEvent = () => {
      fetchNotificationList()
    }

    handleSocketEvent()

    // Listen to each socket event only once
    socket.on('notification_received', handleSocketEvent)
    socket.on('all_notifications_read', handleSocketEvent)
    socket.on('single_notifications_read', handleSocketEvent)

    return () => {
      // Remove the event listeners when the component unmounts
      socket.off('notification_received', handleSocketEvent)
      socket.off('all_notifications_read', handleSocketEvent)
      socket.off('single_notifications_read', handleSocketEvent)
    }
  }, [])

  const handleOpenNotification = async (item) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/buyer/read/${item._id}`,
      )

      if (response.data.success) {
        if (!item?.vehicle_id?.status) {
          navigate(`/`)
          return true
        }

        if (!item?.vehicle_id?.status) {
          navigate(`/`)
          return true
        }

        const vehicleId = item?.vehicle_id?._id

        if (item.role === role) {
          switch (item?.vehicle_id?.status) {
            case 'live':
              navigate(
                role === 'seller'
                  ? `/live/${vehicleId}`
                  : `/active/${vehicleId}`,
              )
              break
            case 'parking':
              if (role === 'seller') {
                navigate(`/parking-lot/${vehicleId}`)
              }
              break
            case 'scheduled':
              navigate(
                role === 'seller'
                  ? `/scheduled/${vehicleId}`
                  : `/upcoming/${vehicleId}`,
              )
              break
            case 'pending':
              navigate(`/pending/${vehicleId}`)
              break
            case 'closed':
              navigate(
                role === 'seller'
                  ? `/closed/${vehicleId}`
                  : `/awarded/${vehicleId}`,
              )
              break
            case 'sold':
              navigate(
                role === 'seller'
                  ? `/sold/${vehicleId}`
                  : `/purchased/${vehicleId}`,
              )
              break
            default:
              break
          }
        } else {
          const userRoleUpdatehandler = async () => {
            setIsLoadingForSwitchRole(true)

            try {
              const response = await axiosInterceptorInstance.put(
                `/auth/user/update-role`,
                { role: item.role },
              )

              if (response.data.success) {
                navigate('/')
                dispatch(updateRole(response.data.role))
                setIsLoadingForSwitchRole(false)
                let role = response.data.role
                switch (item?.vehicle_id?.status) {
                  case 'live':
                    navigate(
                      role === 'seller'
                        ? `/live/${vehicleId}`
                        : `/active/${vehicleId}`,
                    )
                    break
                  case 'parking':
                    if (role === 'seller') {
                      navigate(`/parking-lot/${vehicleId}`)
                    }
                    break
                  case 'scheduled':
                    navigate(
                      role === 'seller'
                        ? `/scheduled/${vehicleId}`
                        : `/upcoming/${vehicleId}`,
                    )
                    break
                  case 'pending':
                    navigate(`/pending/${vehicleId}`)
                    break
                  case 'closed':
                    navigate(
                      role === 'seller'
                        ? `/closed/${vehicleId}`
                        : `/awarded/${vehicleId}`,
                    )
                    break
                  case 'sold':
                    navigate(
                      role === 'seller'
                        ? `/sold/${vehicleId}`
                        : `/purchased/${vehicleId}`,
                    )
                    break
                  default:
                    break
                }
              }
            } catch (error) {
              setIsLoading(false)
              setIsLoadingForSwitchRole(false)
            }
          }
          userRoleUpdatehandler()
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const makeAllNotificationAsRead = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/buyer/all/read`,
      )
      if (response.data.success) {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  return (
    <Fragment>
      <aside
        ref={notificationSidebar}
        className={`fixed md:absolute border-r border-primary left-0 top-0 z-[99999] md:z-[9999] flex  w-full md:w-[450px] h-[calc(100dvh_-_48px)] lg:h-screen flex-col overflow-y-hidden bg-[rgba(250,_249,_245,_1)] duration-300 ease-linear ${
          notificationSidebarOpen
            ? 'translate-x-[0px] md:translate-x-[270px]'
            : '-translate-x-full'
        }`}
      >
        <div className='flex justify-between'>
          <div className='flex flex-wrap items-end justify-between w-full px-5 pt-[25px] pb-5 border-b border-gray/10'>
            <h5 className='text-lg font-medium text-gray'>Notifications</h5>
            {notificationList.length > 0 && (
              <span
                className='text-sm font-normal cursor-pointer text-primary'
                onClick={() => {
                  !isloading && makeAllNotificationAsRead()
                }}
              >
                <div className='flex gap-1 items-center'>
                  {isloading && (
                    <CgSpinner className='w-4 h-4 animate-spin text-primary' />
                  )}
                  Mark all as read
                </div>
              </span>
            )}
          </div>
        </div>
        <div
          className={`absolute top-0 right-0 ${notificationList.length > 0 ? 'pt-[6px]' : 'pt-[25px]'} px-[5px]`}
        >
          <button
            ref={notificationTrigger}
            onClick={() => setNotificationSidebarOpen(false)}
          >
            <IoCloseOutline className='text-xl text-primary' />
          </button>
        </div>

        <div className='flex flex-col items-start justify-start w-full h-full p-0 m-0'>
          {isNotificatinLoading ? (
            <Loader />
          ) : (
            <ul className='flex gap-y-4 flex-col dropDown-list h-[calc(100dvh_-_200px)] overflow-y-auto w-full px-5 py-5 overflow-x-hidden'>
              {notificationList.length > 0 ? (
                notificationList.map((item, key) => (
                  <li
                    key={key}
                    className={`${
                      item.is_read === false ? 'bg-primary bg-opacity-10' : ''
                    } rounded-lg px-4 cursor-pointer transition duration-300 ease-in-out transform hover:scale-[1.02] `}
                    onClick={() => handleOpenNotification(item)}
                  >
                    <div className='flex gap-2 py-2 group/list'>
                      <div>
                        <div className='flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-primary '>
                          {item.type === 'vehicle' && <FaCar />}
                          {item.type === 'user' && <FaUser />}
                          {item.type === 'document' && <IoDocumentTextSharp />}
                          {item.type === 'chat' && <MdChat />}
                          {item.type === 'vehicle_invite' && <FaUser />}
                        </div>
                      </div>

                      <div className='flex flex-col items-start justify-start gap-1'>
                        <InvitationNotificationYear message={item.message} />

                        <p className='text-xs font-light text-gray/70 whitespace-nowrap'>
                          {moment(item?.created_at).format('lll')}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p className='text-center text-gray'>No notifications found</p>
              )}
            </ul>
          )}

          <div className='flex flex-wrap items-center justify-center w-full py-3 m-0 mt-auto border-t border-gray/10'>
            <NavLink
              to='/notifications'
              className='text-base font-medium duration-300 ease-linear delay-150 text-primary'
            >
              Show all notifications
            </NavLink>
          </div>
        </div>
      </aside>
    </Fragment>
  )
}

NotificationSidebar.propTypes = {
  notificationSidebarOpen: PropTypes.bool.isRequired,
  setNotificationSidebarOpen: PropTypes.func.isRequired,
  notificationref: PropTypes.object.isRequired,
  setIsLoadingForSwitchRole: PropTypes.func.isRequired,
}

export default NotificationSidebar
