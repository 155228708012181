import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

import PropTypes from 'prop-types'

import CustomInput from '../../../../custom/CustomInput'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import CustomModal from '../../../../components/Custom/CustomModal'

import { Icons } from '../../../../components/Icons'

const {
  MdArrowRightAlt,
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineVerifiedUser,
} = Icons

const sellerValidationSchema = Yup.object().shape({
  seller_bank_name: Yup.string().required('Bank name is required'),
  seller_bank_branch_name: Yup.string().required(
    'Bank branch name is required',
  ),
  seller_account_number: Yup.string().required('Account number is required'),
  seller_account_holder_name: Yup.string().required(
    'Account holder name is required',
  ),
  seller_routing_number: Yup.string().required('Routing number is required'),
  seller_bank_address: Yup.string().required('Bank address is required'),
})

const FinancialDetails = ({ setStep }) => {
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showAccountNumber, setShowAccountNumber] = useState(false)
  const [showRoutingNumber, setShowRoutingNumber] = useState(false)
  const [confimModal, setConfirmModal] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const closeConfirmModal = () => {
    setConfirmModal(false)
  }

  const doNotCopyHandler = (e) => {
    e.preventDefault()
  }

  const formik = useFormik({
    validationSchema: sellerValidationSchema,

    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      seller_bank_name: '',
      seller_bank_branch_name: '',
      seller_account_number: '',
      seller_account_holder_name: '',
      seller_routing_number: '',
      seller_swift_code: '',
    },
  })

  const fetchFinancialDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/auth/financial/seller/details`,
      )
      if (response.data.success) {
        setUserDetails(response.data.financialDetails)

        formik.setValues({
          ...formik.values,
          ...response.data.financialDetails,
        })

        setIsLoading(false)
      }
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchFinancialDetails()
  }, [fetchFinancialDetails])

  const handleSubmit = async (values) => {
    setIsSubmiting(true)
    if (userDetails?._id) {
      try {
        const response = await axiosInterceptorInstance.put(
          `/auth/financial/seller/update/${userDetails.user_id}`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          navigate('/profile')
        }
      } catch (error) {
        handleRequestError(error)
      } finally {
        setIsSubmiting(false)
        closeConfirmModal()
      }
    } else {
      try {
        const response = await axiosInterceptorInstance.post(
          `/auth/financial/seller/create`,
          { ...values },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          navigate('/profile')
        }
      } catch (error) {
        handleRequestError(error)
      } finally {
        setIsSubmiting(false)
        closeConfirmModal()
      }
    }
  }

  const openConfirmModal = async () => {
    const errors = await formik.validateForm()

    formik.setTouched({
      seller_bank_name: true,
      seller_bank_branch_name: true,
      seller_account_number: true,
      seller_account_holder_name: true,
      confirm_seller_account_number: true,
      seller_routing_number: true,
      seller_swift_code: true,
      seller_bank_address: true,
    })

    if (Object.keys(errors).length === 0) {
      setConfirmModal(true)
    }
  }

  const submitFinancialDetaisHandler = async () => {
    const errors = await formik.validateForm()

    formik.setTouched({
      seller_bank_name: true,
      seller_bank_branch_name: true,
      seller_account_number: true,
      seller_account_holder_name: true,
      confirm_seller_account_number: true,
      seller_routing_number: true,
      seller_swift_code: true,
      seller_bank_address: true,
    })

    if (Object.keys(errors).length === 0) {
      handleSubmit(formik.values, formik)
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10 '>
            <div>
              <div className='mb-3 text-left title title-primary'>
                <h2 className='heading-2'>Financial Information</h2>
              </div>
              <div className='text-left content'>
                <p>
                  You can update your bank name, branch name, account holder
                  name and account number.
                </p>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 gap-x-5 gap-y-8'>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='seller_bank_name'
                  label='Bank Name'
                  placeholder='Bank Name'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='seller_bank_branch_name'
                  label='Bank Branch Name'
                  placeholder='Bank Branch Name'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='seller_account_holder_name'
                  label='Account Holder'
                  placeholder='Account Holder Name'
                  component={CustomInput}
                  className='form-field'
                />
              </div>

              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type={`${showAccountNumber ? 'text' : 'password'}`}
                  name='seller_account_number'
                  label='Account Number'
                  placeholder='Account Number'
                  component={CustomInput}
                  className='form-field'
                  required
                  secure
                  setToggle={setShowAccountNumber}
                  Toggle={showAccountNumber}
                  doNotCopyHandler={(e) => doNotCopyHandler(e)}
                />
              </div>

              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type={`${showRoutingNumber ? 'text' : 'password'}`}
                  name='seller_routing_number'
                  label='Routing Number'
                  placeholder='Routing Number'
                  component={CustomInput}
                  className='form-field'
                  required
                  secure
                  setToggle={setShowRoutingNumber}
                  Toggle={showRoutingNumber}
                  doNotCopyHandler={(e) => doNotCopyHandler(e)}
                />
              </div>
              <div className='w-full md:max-w-[calc(50%_-_10px)]'>
                <Field
                  type='text'
                  name='seller_swift_code'
                  label='Swift Code'
                  placeholder='Swift Code'
                  component={CustomInput}
                  className='form-field'
                />
              </div>
              <div className='w-full'>
                <Field
                  name='seller_bank_address'
                  label='Bank Address'
                  placeholder='Bank Address'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
            </div>
            <div className='flex flex-wrap items-center justify-center w-full gap-5 mt-10 md:items-center md:justify-between'>
              <button
                type='button'
                className='order-2 prev next-prev-button xs:flex-auto xs:order-1'
                onClick={() => setStep(2)}
              >
                <MdArrowRightAlt /> Back
              </button>
              <button
                type='button'
                className='order-1 w-full ml-auto btn btn-primary-ico xs:w-auto xs:order-2'
                disabled={isSubmiting}
                onClick={openConfirmModal}
              >
                <span>Verify</span>
                <MdOutlineCheck />
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
      {confimModal && (
        <CustomModal
          handleCloseModal={closeConfirmModal}
          closeModal={confimModal}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeConfirmModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <MdOutlineVerifiedUser />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Please verify your bank details before submitting!
                </h5>
              </div>
            </div>
            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeConfirmModal}
                className='btn btn-gray max-md:flex-auto'
              >
                No, cancel
              </button>

              <button
                onClick={submitFinancialDetaisHandler}
                className={`btn btn-primary max-md:flex-auto ${
                  isSubmiting && 'hover:bg-[#fff]'
                }`}
              >
                {isSubmiting ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </CustomLoadingSection>
  )
}

FinancialDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default FinancialDetails
