import React from 'react'

import ScheduledList from '../../Scheduled/List'

const ScheduledOffers = () => {
  return (
    <div className='mb-10 last:mb-0'>
      <ScheduledList />
    </div>
  )
}

export default ScheduledOffers
