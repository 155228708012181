import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import io from 'socket.io-client'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomButtonWithIcon from '../../../../components/Custom/CustomButtonWithIcon'
import CustomStopWatch from '../../../../components/Custom/CustomStopWatch'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'
import placeholderCar from '../../../../assets/car/car-dummy.png'
import gif from '../../../../assets/gif/car-jump.gif'
import configManager from '../../../../config/configManager'
import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../../../components/Icons'

const {
  RiErrorWarningLine,
  IoCarSportOutline,
  IoSettingsOutline,
  MdOutlineAccessTime,
  MdOutlineAddPhotoAlternate,
  MdOutlineLocalOffer,
  MdRemoveRedEye,
} = Icons

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)

const LiveList = () => {
  const location = useLocation()
  const { pathname } = location

  const [isLoading, setIsLoading] = useState(true)
  const [liveVehicleList, setLiveVehicleList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInterceptorInstance.get(
          '/vehicle/seller/vehicle-details/list/live',
        )
        if (response.data.success) {
          setLiveVehicleList(response.data.vehicleDetailsList)
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        handleRequestError(error)
      }
    }

    fetchData()

    const handleOfferReceive = () => {
      fetchData()
    }

    const handleVehicleExpired = () => {
      fetchData()
    }

    const handleVehicleOfferAccept = () => {
      fetchData()
    }

    const handleUpcomingVehicleLive = () => {
      fetchData()
    }

    const handleMoveVehicleToParkingOnNoOffer = () => {
      fetchData()
    }

    socket.once('OfferReceive', handleOfferReceive)
    socket.once('vehicle_expired', handleVehicleExpired)
    socket.once('vehicle_offer_accept', handleVehicleOfferAccept)
    socket.once('upcoming_vehicle_live', handleUpcomingVehicleLive)
    socket.once('vehicle_move_to_parking', handleMoveVehicleToParkingOnNoOffer)

    return () => {
      socket.off('OfferReceive', handleOfferReceive)
      socket.off('vehicle_expired', handleVehicleExpired)
      socket.off('vehicle_offer_accept', handleVehicleOfferAccept)
      socket.off('upcoming_vehicle_live', handleUpcomingVehicleLive)
      socket.off('vehicle_move_to_parking', handleMoveVehicleToParkingOnNoOffer)
    }
  }, [])

  return (
    <Fragment>
      {pathname === '/live' && <CustomTitle />}
      <CustomLoadingSection isLoading={isLoading}>
        {liveVehicleList.length > 0 ? (
          <Fragment>
            <div className='grid w-full gap-5 py-2 mb-5 border-b lg:max-w-none lg:w-auto max-sm:grid-cols-2 max-lg:grid-cols-3 max-large:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 border-gray border-opacity-10 place-items-center lg:py-5 last:mb-0 last:pb-0 last:border-b-0'>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <MdOutlineAccessTime className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Time Left</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2'>
                <MdOutlineAddPhotoAlternate className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Media</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2'>
                <RiErrorWarningLine className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Description</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-large:hidden'>
                <IoCarSportOutline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>VIN</h5>
                </div>
              </div>
              <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
                <MdOutlineLocalOffer className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Offer</h5>
                </div>
              </div>
              <div className='flex-col items-center justify-center flex-auto hidden gap-2 sm:flex'>
                <IoSettingsOutline className='text-xl large:text-3xl text-primary' />
                <div className='title title-gray'>
                  <h5 className='subHeading-5'>Action</h5>
                </div>
              </div>
            </div>
            {liveVehicleList.map((item) => (
              <div
                className='relative flex flex-wrap items-center justify-center w-full py-3 border-b border-gray border-opacity-10 last:border-b-0'
                key={item._id}
              >
                <div className='grid w-full gap-5 lg:max-w-none max-sm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 place-items-center lg:py-5'>
                  <div className='flex-auto max-lg:hidden'>
                    <span className='text-lg font-normal !text-gray'>
                      <div className='title title-gray lg:text-center'>
                        <h6 className='subHeading-6'>
                          <span className='block mb-1 text-sm font-medium text-primary'>
                            <CustomStopWatch
                              end_date={item?.vehicle_auction?.end_auction_time}
                              timezone={
                                item?.vehicle_auction?.launch_auction_timezone
                              }
                            />
                          </span>
                          min left
                        </h6>
                      </div>
                    </span>
                  </div>

                  <div className='w-full'>
                    <div className='flex-auto lg:hidden'>
                      <span className='inline-block px-4 py-2 rounded-tl-lg rounded-tr-lg bg-primary'>
                        <div className='text-sm font-normal text-white'>
                          <CustomStopWatch
                            end_date={item?.vehicle_auction?.end_auction_time}
                            timezone={
                              item?.vehicle_auction?.launch_auction_timezone
                            }
                          />
                          &nbsp;min left
                        </div>
                      </span>
                    </div>
                    <div className='img block w-full rounded-lg relative overflow-hidden pt-[calc(9/16*100%)] max-lg:rounded-tl-none'>
                      {item?.vehicle_image?.exterior_main ? (
                        <img
                          src={item?.vehicle_image?.exterior_main_thumbnail}
                          alt='vehicle-img'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      ) : (
                        <img
                          src={placeholderCar}
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          alt='vehicle-img'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div className='flex-auto'>
                    <div className='sm:text-center text-lg font-normal !text-gray'>
                      <div className='title title-gray'>
                        <h6 className='subHeading-6'>{item?.year}</h6>
                      </div>
                      <div className='title title-primary'>
                        <h6 className='heading-6 !font-medium'>
                          {item?.make}
                          <br className='max-small:hidden' />
                          &nbsp;{item?.model}
                        </h6>
                      </div>
                    </div>
                    <div className='flex-auto mt-2 large:hidden'>
                      <div className='title title-gray'>
                        <h6 className='subHeading-6'>
                          <CopyToClipboard>{item?.vin_number}</CopyToClipboard>
                        </h6>
                      </div>
                    </div>
                    <div className='mt-2 sm:hidden'>
                      <CustomToolTip label='View Vehicle'>
                        <CustomButtonWithIcon
                          icon={<MdRemoveRedEye />}
                          link={`/live/${item?._id}`}
                          label='View'
                        />
                      </CustomToolTip>
                    </div>
                  </div>
                  <div className='max-large:hidden'>
                    <div className='title title-gray'>
                      <h6 className='subHeading-6'>
                        <CopyToClipboard>{item?.vin_number}</CopyToClipboard>
                      </h6>
                    </div>
                  </div>
                  <div className='max-lg:hidden'>
                    <div className='title title-primary'>
                      <h6 className='subHeading-5'>
                        {item?.vehicle_auction?.offer_count || 0}
                      </h6>
                    </div>
                  </div>
                  <div className='max-sm:hidden'>
                    <CustomToolTip label='View Vehicle'>
                      <CustomButtonWithIcon
                        icon={<MdRemoveRedEye />}
                        link={`/live/${item?._id}`}
                        label='View'
                      />
                    </CustomToolTip>
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {pathname === '/live' && (
              <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
                <div className='img'>
                  <img
                    src={gif}
                    alt='vehicle-img'
                    onError={(e) => vehicleBrokenImage(e)}
                  />
                </div>
                <div className='title title-gray subH-70'>
                  <h5 className='subHeading-5'>
                    There are no vehicle listings available.
                  </h5>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </CustomLoadingSection>
    </Fragment>
  )
}

export default LiveList
