import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  BarcodeCapture,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology,
} from 'scandit-web-datacapture-barcode'
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  LaserlineViewfinder,
} from 'scandit-web-datacapture-core'
import PropTypes from 'prop-types'

export function createSDKFacade() {
  let context
  let view
  let laserLineViewFinder
  let settings
  let barcodeCapture
  let overlay
  let host
  let cameraSwitchControl
  let camera

  function createHostElementIfNeeded() {
    if (!host) {
      host = document.createElement('div')
      host.style.display = 'none'
      host.style.width = '100%'
      host.style.height = '100%'
      document.body.append(host)
    }
    return host
  }

  return {
    async initialize() {
      await configure({
        // libraryLocation: new URL('library/engine', document.baseURI).toString(),
        libraryLocation: '/engine',
        licenseKey:
          'AgvE5iNqBob8AtyImQ1W5ZclZ7c8B2yBXzIfLIwjPGcuVqzTDmSIlwsKLZwvdTV2IH9EvNYhKAo3D3awbCa9Qj53MmJDTlLWQCLrHOwfLhrVBxURAw6TIrEEoQR/CbfYjxdv9+PQBIHWKZSXRtVNwrr0+K7aX2omYsnd8+oHQ12REnWiCaQfeUVLfqzf2T5EgZlAcwd4OJCXAQSl9aUxiUs9/6afIZxNDgagN52p7VfkyzqQ0zCePtS2Z3+49YwANPoYtHMyNUAwMlM0yOHqNXl7HGPWj4MwjQLWc19/ip70BqHTUgmVNbsuwSZMLfIx04TDxDqWaa1KACSVos05KKBQSw/MQIQ7meiXPDA3cGVDQonQtUQLzEFLQqbSVwsI5PaDqMsipxQ00wGiem9DkcJtp2Qwkb8wARgo+H+u+vzR8igx+tkHBUzMGJC9CVziq56Nu3jRhP7tyChSuGnWLKOChtJDoQumuXE/6c4xUXNvQgxdE/uCsJtxV3sYkodcexSUYaVGAYx9PjlvM1CDFCgp3CUwdwEWGR+3FZZE/Xv6cf3gVl+hCsdTPXab1ENdsk0Ukd0cPD+WD56DXvSJ5K8lV83J/rSLLZLiXiO/jGWg8Jwfcm6ns1tAWgK8LMQgEQWJca2Rfe2HxnLBURt/CljKdF/2wYLLVz3KMxxNYY1numCX7iSdEWT98DR+UAmK8HfdrDYHH1JAbrUxW4r3qpo8sSOpDV8u0oTSVlBq6su1Xf8FFkl29lFn2KXyPorJfGGfq8FniCmw/fStrGJZdE4b2gYCFVYSl/BjYmQ1',
        moduleLoaders: [barcodeCaptureLoader()],
      })
      context = await DataCaptureContext.create()
      settings = new BarcodeCaptureSettings()
      settings.enableSymbologies([
        Symbology.EAN13UPCA,
        Symbology.EAN8,
        Symbology.UPCE,
        Symbology.QR,
        Symbology.DataMatrix,
        Symbology.Code39,
        Symbology.Code128,
        Symbology.InterleavedTwoOfFive,
      ])

      view = await DataCaptureView.forContext(context)
      view.connectToElement(createHostElementIfNeeded())

      cameraSwitchControl = new CameraSwitchControl()
      view.addControl(cameraSwitchControl)

      barcodeCapture = await BarcodeCapture.forContext(context, settings)
      await barcodeCapture.setEnabled(false)

      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(
        barcodeCapture,
        view,
      )
      laserLineViewFinder = new LaserlineViewfinder()
      await overlay.setViewfinder(laserLineViewFinder)
      await view.addOverlay(overlay)

      camera = Camera.default
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings)
      await context.setFrameSource(camera)
    },
    async cleanup() {
      await context?.frameSource?.switchToDesiredState(FrameSourceState.Off)
      await context?.dispose()
      await context?.removeAllModes()
      if (overlay) {
        await overlay.setViewfinder(null)
        await view?.removeOverlay(overlay)
      }
      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl)
        cameraSwitchControl = undefined
      }
      view?.detachFromElement()
      laserLineViewFinder = undefined
      barcodeCapture = undefined
      context = undefined
      view = undefined
      settings = undefined
      camera = undefined
      host?.remove()
      host = undefined
    },
    connectToElement(element) {
      host = createHostElementIfNeeded()
      host.style.display = 'block'
      element.append(host)
    },
    detachFromElement() {
      if (host) {
        host.style.display = 'none'
        document.body.append(host)
      }
    },
    async enableCamera(enabled) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(
          enabled ? FrameSourceState.On : FrameSourceState.Off,
        )
      }
    },
    async enableScanning(enabled) {
      await barcodeCapture?.setEnabled(enabled)
    },
    async enableSymbology(symbology, enabled) {
      settings.enableSymbology(symbology, enabled)
      await barcodeCapture?.applySettings(settings)
    },
    addBarcodeCaptureListener(listener) {
      barcodeCapture?.addListener(listener)
    },
    removeBarcodeCaptureListener(listener) {
      barcodeCapture?.removeListener(listener)
    },
    getEnabledSymbologies() {
      return settings.enabledSymbologies
    },
  }
}

export const SDKContext = createContext({
  loaded: false,
  loading: false,
  sdk: null,
})

export default function SDKProvider({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const sdk = useMemo(() => createSDKFacade(), [])

  const providerValue = useMemo(
    () => ({ loading, loaded, sdk }),
    [loading, loaded, sdk],
  )

  useEffect(() => {
    async function start() {
      setLoading(true)
      await sdk.initialize()
      setLoading(false)
      setLoaded(true)
      // enable the camera on mount to speed up the access
      await sdk.enableCamera(true)
    }
    void start()
    return () => {
      void sdk.cleanup()
    }
  }, [sdk])

  return (
    <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>
  )
}

SDKProvider.propTypes = {
  children: PropTypes.any,
}

export function useSDK() {
  const value = useContext(SDKContext)

  if (value.sdk === null) {
    throw new Error(
      'Sdk facade is null. Did you forget to wrap the component with SDKProvider?',
    )
  }
  return value
}
