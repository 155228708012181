import Cookies from 'js-cookie'

// token
export const setToken = (token) => {
  Cookies.set('token', token)
}
export const getToken = () => {
  return Cookies.get('token')
}
export const removeToken = () => {
  Cookies.remove('token')
}

// roles
export const getRole = () => {
  return Cookies.get('role')
}
export const setRole = (role) => {
  Cookies.set('role', role)
}
export const removeRole = () => {
  Cookies.remove('role')
}

export const getId = () => {
  return Cookies.get('id')
}
export const setId = (id) => {
  Cookies.set('id', id)
}

// is verified or is profile complate
export const getRegistrationToken = () => {
  return Cookies.get('revvit_registration')
}
export const setRegistrationToken = (data) => {
  Cookies.set('revvit_registration', data)
}
export const removeRegistrationToken = () => {
  Cookies.remove('revvit_registration')
}
