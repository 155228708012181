import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik, FieldArray } from 'formik'
import * as Yup from 'yup'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import CustomInput from '../../../../custom/CustomInput'
import CustomSelect from '../../../../custom/CustomSelect'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomMultiSelect from '../../../../custom/CustomMultiSelect'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import { Icons } from '../../../../components/Icons'

const { FiUpload, MdDelete, MdAdd, MdArrowRightAlt, MdOutlineCheck } = Icons

const provinceList = [
  { value: 'Alberta', label: 'Alberta' },
  { value: 'British Columbia', label: 'British Columbia' },
  { value: 'Manitoba', label: 'Manitoba' },
  { value: 'New Brunswick', label: 'New Brunswick' },
  { value: 'Newfoundland and Labrador', label: 'Newfoundland and Labrador' },
  { value: 'Nova Scotia', label: 'Nova Scotia' },
  { value: 'Northwest Territories', label: 'Northwest Territories' },
  { value: 'Nunavut', label: 'Nunavut' },
  { value: 'Ontario', label: 'Ontario' },
  { value: 'Prince Edward Island', label: 'Prince Edward Island' },
  { value: 'Quebec', label: 'Quebec' },
  { value: 'Saskatchewan', label: 'Saskatchewan' },
  { value: 'Yukon', label: 'Yukon' },
]

const sellerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Dealership name is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
  contact_person: Yup.string().required('Contact person is required'),
  email: Yup.string().email('Invalid email address'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter a valid URL',
  ),
  address: Yup.array().of(
    Yup.object().shape({
      street: Yup.string().required('Street is required'),
      city: Yup.string().required('City is required'),
      province: Yup.string().required('Country is required'),
      postcode: Yup.string().required('Postcode is required'),
    }),
  ),
})

const buyerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Dealer name is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
  contact_person: Yup.string().required('Contact person is required'),
  intrested_province: Yup.array().min(
    1,
    'Please select at least one province from where you wish to purchase a vehicle.',
  ),
  email: Yup.string().email('Invalid email address'),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter a valid URL',
  ),
})

const DealerInformation = ({ setStep }) => {
  const navigate = useNavigate()
  const { role } = useSelector((state) => state.user)

  const [isLoading, setIsLoading] = useState(true)

  const [userDealerDetails, setUserDealerDetails] = useState()

  const formik = useFormik({
    validationSchema:
      role === 'buyer' ? buyerValidationSchema : sellerValidationSchema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      name: '',
      email: '',
      phone: '',
      contact_person: '',
      website: '',
      dealer_logo: '',
      dealer_logo_preview: '',
      address: [{ street: '', city: '', province: '', postcode: '' }],
      intrested_province: [],
    },
    validateOnBlur: false,
  })

  const fetchDealerDetails = useCallback(async () => {
    try {
      const response =
        await axiosInterceptorInstance.get(`/auth/dealer/details`)
      if (response.data.success) {
        setIsLoading(false)

        setUserDealerDetails(response.data.userDealerDetails)
        const { address, dealer_logo, intrested_province } =
          response.data.userDealerDetails

        formik.setValues({
          ...formik.values,
          ...response.data.userDealerDetails,
        })
        formik.setFieldValue('dealer_logo_preview', dealer_logo)
        formik.setFieldValue(
          'intrested_province',
          intrested_province[0] ? intrested_province : [],
        )
        if (Array.isArray(address) && address.length > 0) {
          address.forEach((addr, index) => {
            formik.setFieldValue(`address[${index}].street`, addr.street || '')
            formik.setFieldValue(`address[${index}].city`, addr.city || '')
            formik.setFieldValue(
              `address[${index}].province`,
              addr.province || '',
            )
            formik.setFieldValue(
              `address[${index}].postcode`,
              addr.postcode || '',
            )
          })
        }
      }
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDealerDetails()
  }, [fetchDealerDetails])

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData()

      Object.keys(values).forEach((key) => {
        if (key === 'address') {
          values[key].forEach((address, index) => {
            Object.entries(address).forEach(([subKey, subValue]) => {
              formData.append(`${key}[${index}][${subKey}]`, subValue)
            })
          })
        } else if (values[key] instanceof File) {
          formData.append(key, values[key])
        } else {
          formData.append(key, values[key])
        }
      })

      if (userDealerDetails || userDealerDetails?.length) {
        const response = await axiosInterceptorInstance.put(
          `/auth/dealer/update/${userDealerDetails?._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })

          role === 'buyer' ? navigate('/profile') : setStep(3)
        }
      } else {
        const response = await axiosInterceptorInstance.post(
          `/auth/dealer/create`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data.success) {
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
          role === 'buyer' ? navigate('/profile') : setStep(3)
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const {
    isSubmitting,
    values: { intrested_province },
  } = formik

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0]
    formik.setFieldValue('dealer_logo', file)

    if (file) {
      const previewUrl = URL.createObjectURL(file)
      formik.setFieldValue('dealer_logo_preview', previewUrl)
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} className='w-full'>
          <div className='flex flex-col w-full gap-10'>
            <div>
              <div className='mb-3 text-left title title-primary'>
                <h2 className='heading-2'>Dealer Information</h2>
              </div>
              <div className='text-left content'>
                <p>
                  You can update your dealer name, phone number, email address
                  and etc.
                </p>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 gap-x-5 gap-y-8'>
              <div className='max-sm:w-full flex-[0_0_auto] flex flex-wrap justify-center items-center'>
                <label className='flex flex-col items-start justify-start w-full pb-3 border-b last:border-0 border-gray border-opacity-10 last:pb-0 mb:pb-0 md:border-0'>
                  <div className='relative w-24 h-24 overflow-hidden border border-dashed rounded-full cursor-pointer bg-gray bg-opacity-5 border-gray border-opacity-20'>
                    <input
                      accept='image/*'
                      className='hidden z-1'
                      type='file'
                      name='Profile'
                      onChange={(event) => handleImageChange(event)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.values.dealer_logo_preview ? (
                      <img
                        src={formik.values.dealer_logo_preview}
                        alt='Preview'
                        className='absolute top-0 left-0 object-cover w-full h-full'
                        onError={(e) => userBrokenImages(e)}
                      />
                    ) : (
                      <div className='absolute top-0 flex items-center justify-center object-cover w-full h-full p-0 text-3xl rounded-full bg-light-gray text-primary'>
                        {formik.values.first_name
                          ?.substring(0, 2)
                          .toUpperCase()}
                      </div>
                    )}
                    <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center w-full h-full z-1'>
                      <div className='flex flex-col items-center justify-center w-8 h-8 mx-auto mb-1 text-white rounded-full bg-gray/90 hover:bg-primary hover:text-white'>
                        <FiUpload />
                      </div>
                    </div>
                  </div>
                  {formik.touched.dealer_logo && formik.errors.dealer_logo && (
                    <div className='text-xs font-normal text-danger'>
                      {formik.errors.dealer_logo}
                    </div>
                  )}
                </label>

                {formik.touched.dealer_logo && formik.errors.dealer_logo && (
                  <div className='text-xs font-normal text-danger'>
                    {formik.errors.dealer_logo}
                  </div>
                )}
              </div>

              <div className='w-full sm:max-w-[calc(100%_-_0px)]'>
                <Field
                  name='name'
                  label='Dealership Name/Business Name'
                  placeholder='Dealer Name/Business Name'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>

              <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='contact_person'
                  label='Primary Contact Person'
                  placeholder='Primary Contact Person'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
              <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='phone'
                  type='text'
                  label='Contact Number'
                  placeholder='Contact Phone Number'
                  component={CustomInput}
                  className='form-field'
                  required
                />
              </div>
              <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='email'
                  label='Contact Email'
                  placeholder='Contact Email'
                  component={CustomInput}
                  className='form-field'
                />
              </div>

              <div className='w-full sm:max-w-[calc(50%_-_10px)]'>
                <Field
                  name='website'
                  label='Website URL'
                  placeholder='http://example.com'
                  component={CustomInput}
                  className='form-field'
                />
              </div>
              {role === 'buyer' && (
                <div className='w-full'>
                  <Field
                    name='intrested_province'
                    placeholder='Province'
                    label=' Please specify which province you are interested in buying
                    the vehicle in?'
                    component={CustomMultiSelect}
                    options={provinceList}
                    value={intrested_province.map((value) => ({
                      value: value,
                      label: value,
                    }))}
                    required
                  />
                </div>
              )}
              <FieldArray name='address'>
                {({ push, remove }) => (
                  <div className='w-full'>
                    {formik.values.address.length === 0 &&
                      push({
                        street: '',
                        city: '',
                        province: '',
                        postcode: '',
                      })}
                    <div className='flex items-center justify-between'>
                      <div className='title title-primary'>
                        <h4 className='heading-4'>Pickup Location</h4>
                      </div>
                    </div>

                    {formik.values.address?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className='w-full pt-5 mt-5 border-t border-t-gray/10'
                        >
                          <div className='flex items-center justify-between gap-2 mb-5'>
                            <div className='title title-gray'>
                              <h4 className='font-medium subHeading-4'>
                                Location {index + 1}
                              </h4>
                            </div>
                            <div className='flex justify-end gap-2'>
                              {formik.values.address.length > 1 && (
                                <CustomToolTip label='Delete address'>
                                  <button
                                    className='rounded-full btn-primary-ico'
                                    type='button'
                                    onClick={() => remove(index)}
                                  >
                                    <MdDelete className='m-1' />
                                  </button>
                                </CustomToolTip>
                              )}
                            </div>
                          </div>

                          <div className='flex flex-wrap items-start justify-start w-full p-0 mx-0 gap-x-5 gap-y-8'>
                            <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                              <Field
                                name={`address[${index}].street`}
                                placeholder='Street*'
                                className='form-field'
                                label='Street'
                                required={role === 'seller' && true}
                                component={CustomInput}
                              />
                            </div>

                            <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                              <Field
                                component={CustomInput}
                                name={`address[${index}].city`}
                                className='form-field'
                                placeholder='City*'
                                label='City'
                                required={role === 'seller' && true}
                              />
                            </div>

                            <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                              <Field
                                name={`address[${index}].province`}
                                label='Province'
                                required={role === 'seller' && true}
                                placeholder='Select Province'
                                component={CustomSelect}
                                options={provinceList}
                                value={
                                  provinceList.find(
                                    (option) => option.value === item.province,
                                  ) ?? []
                                }
                              />
                            </div>
                            <div className='w-full md:max-w-[calc(50%_-_15px)]'>
                              <Field
                                name={`address[${index}].postcode`}
                                label='Postcode'
                                required={role === 'seller' && true}
                                placeholder='Enter postcode'
                                component={CustomInput}
                                className='form-field'
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    <div className='flex justify-end mt-5'>
                      <button
                        className='btn btn-dark-ico '
                        type='button'
                        onClick={() =>
                          push({
                            street: '',
                            city: '',
                            province: '',
                            postcode: '',
                          })
                        }
                      >
                        <MdAdd className='m-1' />
                        Add Address
                      </button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className='flex flex-wrap items-center justify-center w-full gap-5 mt-10 md:items-center md:justify-between'>
              {setStep && (
                <button
                  type='reset'
                  className='order-2 prev next-prev-button xs:flex-auto xs:order-1'
                  onClick={() => setStep(1)}
                >
                  <MdArrowRightAlt /> Back
                </button>
              )}

              <button
                type='submit'
                className='order-1 w-full ml-auto btn btn-primary-ico xs:w-auto xs:order-2'
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    <span>Save & Continue</span>
                    <MdOutlineCheck />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

DealerInformation.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default DealerInformation
