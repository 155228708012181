import React, { Fragment, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getToken, getMessaging, onMessage } from 'firebase/messaging'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updateRole } from '../../redux/user/userSlice'
import axiosInterceptorInstance from '../axios/axiosInterceptorInstance'

import CustomToast from '../../components/Custom/CustomToast'

import PropTypes from 'prop-types'
import { handleRequestError } from '../axios/handleRequestError'

const firebaseConfig = {
  apiKey: 'AIzaSyAunUOL1GUysVeFhSl_HkBaTGRB5Iol4v0',
  authDomain: 'revvit-458e3.firebaseapp.com',
  projectId: 'revvit-458e3',
  storageBucket: 'revvit-458e3.appspot.com',
  messagingSenderId: '1060935585982',
  appId: '1:1060935585982:web:f32647fd08798eddafceef',
  measurementId: 'G-WP27HVF4TE',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      '/firebase-push-notification-scope',
    )
    if (serviceWorker) return serviceWorker
    return window.navigator.serviceWorker.register(
      '/firebase-messaging-sw.js',
      {
        scope: '/firebase-push-notification-scope',
      },
    )
  }
  throw new Error('The browser doesn`t support service worker.')
}

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey:
        'BMHNmZ22kF9Tccg3Qczbpq1eAD_1jPdullfK-i2_4k_N4nj5cpMTkl6Ao-qyGBAx3Llm27XjyDSl3Z8gs0isXBs',
      serviceWorkerRegistration,
    }),
  )

export const NotificationProvider = ({ children }) => {
  const { role: Approle } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  useEffect(() => {
    onMessage(messaging, (payload) => {
      // const Notificationsong = new Audio(
      //   'https://revvit.s3.ca-central-1.amazonaws.com/revvit-media/I+Phone+%E2%80%93+Notification+Ringtone+Download+-+MobCup.Com.Co.mp3',
      // )
      // Notificationsong.play()

      const notificationTitle = payload.notification.title
      const notificationBody = {
        body: payload.notification.body,
        icon: 'https://stage-revvit.revvit.ca/assets/logo/revvit-logo-2.png',
      }

      if (Notification.permission === 'granted' && 'Notification' in window) {
        const notification = new Notification(
          notificationTitle,
          notificationBody,
        )

        const baseurl = window.location.origin
        const newURL = payload.fcmOptions.link

        notification.onclick = (event) => {
          event.preventDefault()
          event.stopPropagation()

          // Close the notification when clicked

          if (newURL.includes(baseurl)) {
            let originalUrl = new URL(newURL)

            if (payload.data) {
              const role = payload.data[`gcm.notification.click_role`]

              if ([Approle].includes(role)) {
                if (payload.data[`gcm.notification.collapseKey`]) {
                  const state = {
                    chatNotification: true,
                    senderId: payload.data[`gcm.notification.collapseKey`],
                  }
                  navigate(`${originalUrl.pathname}`, { state })
                } else {
                  navigate(`${originalUrl.pathname}`)
                }
                notification.close()
              } else {
                const userRoleUpdatehandler = async (role) => {
                  try {
                    const response = await axiosInterceptorInstance.put(
                      `/auth/user/update-role`,
                      { role },
                    )

                    if (response.data.success) {
                      dispatch(updateRole(response.data.role))
                      if (payload.data[`gcm.notification.collapseKey`]) {
                        const state = {
                          chatNotification: true,
                          senderId:
                            payload.data[`gcm.notification.collapseKey`],
                        }
                        navigate(`${originalUrl.pathname}`, { state })
                      } else {
                        navigate(`${originalUrl.pathname}`)
                      }
                    }
                  } catch (error) {
                    handleRequestError(error)
                  }
                }
                userRoleUpdatehandler(role)
              }
              notification.close()
            } else {
              navigate(`${originalUrl.pathname}`)
              notification.close()
            }
          }
        }
      } else {
        CustomToast({
          message: 'Notification permission has not been granted.',
          type: 'success',
        })
      }
    })
  }, [navigate, Approle, dispatch])

  return <Fragment>{children}</Fragment>
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired, // 'node' covers anything that can be rendered: numbers, strings, elements or an array (or fragment) containing these types.
}
