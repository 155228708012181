import React, { Fragment, useEffect, useState } from 'react'
import io from 'socket.io-client'

import AwardedList from '../../../views/Buyer/Awarded/List'
import PurchasedList from '../../../views/Buyer/Purchased/List'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomNoVehicleSection from '../../../components/Custom/CustomNoVehicleSection'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import configManager from '../../../config/configManager'

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)
const Awarded = () => {
  const [awardedIsLoading, setAwardedIsLoading] = useState(true)
  const [awardedVehicleCount, setAwardedVehicleCount] = useState()

  const fetchAwardedVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/awarded',
      )
      if (response.data.success) {
        setAwardedVehicleCount(response.data.vehicleList?.length)
        setAwardedIsLoading(false)
      }
    } catch (error) {
      setAwardedIsLoading(false)
      handleRequestError(error)
    }
  }

  const [purchaseIsLoading, setPurchaseIsLoading] = useState(true)
  const [purchasedVehicleCount, setPurchasedVehicleCount] = useState()

  const fetchPurchaseVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/purchased',
      )
      if (response.data.success) {
        setPurchasedVehicleCount(response.data.vehicleList?.length)
        setPurchaseIsLoading(false)
      }
    } catch (error) {
      setPurchaseIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchAwardedVehicleList()
    fetchPurchaseVehicleList()

    socket.on('vehicle_sold', () => {
      fetchAwardedVehicleList()
      fetchPurchaseVehicleList()
    })
  }, [])

  return (
    <Fragment>
      {!awardedIsLoading && !purchaseIsLoading && (
        <Fragment>
          {awardedVehicleCount === 0 && purchasedVehicleCount === 0 ? (
            <Fragment>
              <CustomTitle />
              <CustomNoVehicleSection />
            </Fragment>
          ) : (
            ''
          )}
          {awardedVehicleCount > 0 && (
            <Fragment>
              <CustomTitle customTitle='Awarded Vehicles' />
              <AwardedList />
            </Fragment>
          )}
          {purchasedVehicleCount > 0 && (
            <Fragment>
              <CustomTitle customTitle='Purchased Vehicles' />
              <PurchasedList />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Awarded
