import React, { useState } from 'react'
import * as Yup from 'yup'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

// components
import CustomInput from '../../../custom/CustomInput'

// utils
import axiosAuthInstance from '../../../utils/axios/axiosAuthInstance'

// config
import configManager from '../../../config/configManager'

// images
import Logo from '../../../assets/logo/sidebar-logo.png'

const companyData = configManager()

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your Email'),
})

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosAuthInstance.post(
        '/password/reset/send',
        values,
      )
      if (response.data.success) {
        navigate('/password/forgot/success')
      }
      resetForm()
      setIsLoading(false)
    } catch (error) {
      if (error.response) {
        setIsError(error.response.data.message)
      } else {
        setIsError(error.message)
      }
      setIsLoading(false)
    }
  }

  const errorRemovehandler = () => {
    isError && setIsError('')
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      email: '',
    },
  })

  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <div className='card-left'>
          <div className='revvit-logo-wrapper'>
            <Link to={companyData.WEBSITE_URL}>
              <img
                src={Logo}
                alt='Logo'
                className='login-form-logo drop-shadow-xl'
              />
            </Link>
          </div>
          <h4 className='mt-10 text-xl font-medium text-white'>
            Welcome to Revvit
          </h4>
        </div>
        <div className='h-full'>
          <div className='h-screen login-card-root'>
            <FormikProvider value={formik}>
              <div className='text-center title'>
                <h1>Forgot Password</h1>
                <p>
                  Please enter your email address to receive a link to reset
                  your password.
                </p>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className='w-full large:max-w-[70%] border-t border-t-gray/10 pt-8'
              >
                <div className='form-field-wrapper'>
                  <Field
                    type='email'
                    name='email'
                    placeholder='Email*'
                    component={CustomInput}
                    focusHandler={errorRemovehandler}
                    className='form-field'
                  />
                </div>
                {isError ? (
                  <div className='flex items-center justify-center mt-2 text-base text-red'>
                    {isError}
                  </div>
                ) : (
                  ''
                )}
                <div className='mt-8'>
                  <button
                    disabled={isLoading}
                    type='submit'
                    className='w-full p-2 text-base font-medium text-white border rounded-md bg-primary hover:bg-transparent border-primary hover:text-primary'
                  >
                    {isLoading ? (
                      <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                    ) : (
                      'Reset Password'
                    )}
                  </button>
                </div>
                <div className='flex justify-center mt-4'>
                  <h4 className='text-base font-normal'>
                    Already have an account?&nbsp;
                    <NavLink to='/login'>
                      <span className='btn-primary-link'>Log in</span>
                    </NavLink>
                  </h4>
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
