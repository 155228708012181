import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Field, FormikProvider, useFormik } from 'formik'
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import { updateRole } from '../../../redux/user/userSlice'

import CustomInput from '../../../custom/CustomInput'

import LeftCard from '../../../views/Auth/LeftCard'

import {
  setRole,
  setToken,
  setRegistrationToken,
  setId,
} from '../../../utils/cookies/cookies'
import axiosAuthInstance from '../../../utils/axios/axiosAuthInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address'),
  password: Yup.string().required('Please enter your password'),
})

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')
  const [attempt, seAttempt] = useState(0)
  const [isActiveSubmit, setIsActiveSubmit] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const [searchParams] = useSearchParams()
  const adminToken = searchParams.get('adminToken')
  const token = searchParams.get('token')
  const role = searchParams.get('role')

  const verifyAdminLoginAsUser = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axiosAuthInstance.post('/admin/login-as-user', {
        adminToken: adminToken,
        token: token,
        role: role,
      })
      if (response.data.success) {
        if (!response.data.is_admin_verified) {
          navigate('/account/pending')
        } else {
          navigate('/')
          setToken(response.data.token)
          dispatch(updateRole(response.data.role))
          setRole(response.data.role)
          setIsLoading(false)
        }
      }
    } catch (error) {
      navigate('/')
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [adminToken, token, role, navigate, dispatch])

  useEffect(() => {
    if (adminToken && token && role) {
      verifyAdminLoginAsUser()
    }
  }, [verifyAdminLoginAsUser, adminToken, token, role])

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)

    try {
      const response = await axiosAuthInstance.post('/login', values)
      if (response.data.success) {
        if (
          response.data.is_email_verified &&
          (!response.data.is_dealer_details_completed ||
            !response.data.is_financial_details_completed)
        ) {
          navigate('/registration/process')
          setRegistrationToken(response.data.token)
        } else if (!response.data.is_admin_verified) {
          navigate('/account/pending')
          setRegistrationToken(response.data.token)
        } else {
          if (
            response.data.is_seller_details_completed &&
            response.data.is_buyer_details_completed
          ) {
            setIsLoading(false)
            navigate(`/specify/role/${response.data.user}`, {
              state: { token: response.data.token },
            })
          } else {
            navigate('/')
            setToken(response.data.token)
            setRole(response.data.role)
            setId(response.data.user)
            dispatch(updateRole(response.data.role))
          }
        }
        setIsLoading(false)
      }
    } catch (error) {
      if (error.response.data.message) {
        setIsError(error.response.data.message)
      } else {
        setIsError('Server Error')
      }

      setIsLoading(false)
      resetForm()
      seAttempt((pre) => pre + 1)
      setIsActiveSubmit(true)
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    const isLocalStorageCleared = localStorage.getItem('isLocalStorageCleared')
    if (!isLocalStorageCleared) {
      localStorage.clear()
      localStorage.setItem('isLocalStorageCleared', 'true')
    }
  }, [])

  const errorRemovehandler = () => {
    isError && setIsError('')
  }

  const onChange = (data) => {
    if (data) {
      setIsActiveSubmit(false)
    } else {
      setIsActiveSubmit(true)
    }
  }
  const doNotCopyHandler = (e) => {
    e.preventDefault()
  }

  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <LeftCard />
        <div className='login-card-root'>
          <FormikProvider value={formik}>
            <div className='text-center title'>
              <h1>Log in</h1>
              <p>
                Please enter your login details below to access your account.
              </p>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className='w-full large:max-w-[70%] border-t border-t-gray/10 pt-8'
            >
              <div className='form-field-wrapper'>
                <Field
                  type='text'
                  name='email'
                  placeholder='Email*'
                  component={CustomInput}
                  focusHandler={errorRemovehandler}
                  className='form-field'
                />

                <Field
                  type={`${showPassword ? 'text' : 'password'}`}
                  name='password'
                  placeholder='Password*'
                  component={CustomInput}
                  focusHandler={errorRemovehandler}
                  passwords
                  setShowPassword={setShowPassword}
                  showPassword={showPassword}
                  className='form-field'
                  doNotCopyHandler={(e) => doNotCopyHandler(e)}
                />
              </div>

              <div className='mt-2 text-right'>
                <Link to={'/password/forgot'} className='btn-primary-link'>
                  Forgot Password?
                </Link>
              </div>

              {attempt >= 2 && (
                <ReCAPTCHA
                  key={attempt}
                  sitekey='6Lc0J4ApAAAAAFln21IUgoAdaUVgzpXuvHlGK0Sl'
                  onChange={onChange}
                />
              )}

              {isError ? (
                <div className='flex items-center justify-center my-2 text-base text-red'>
                  {isError}
                </div>
              ) : (
                ''
              )}

              <div className='my-5 btn-custom'>
                <button
                  disabled={isLoading || (attempt >= 2 && isActiveSubmit)}
                  type='submit'
                  className='btn btn-primary btn-full'
                >
                  {isLoading ? (
                    <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                  ) : (
                    'Log in'
                  )}
                </button>
              </div>

              <div className='flex justify-center mt-4'>
                <h4 className='text-base font-normal text-gray'>
                  New on Revvit?&nbsp;
                  <NavLink to='/signup'>
                    <span className='btn-primary-link'>Sign up</span>
                  </NavLink>
                </h4>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </div>
  )
}

export default Login
