import React from 'react'
import { ErrorMessage } from 'formik'
import { useState } from 'react'

import PropTypes from 'prop-types'

import 'react-tippy/dist/tippy.css'

import { Icons } from '../components/Icons'

const { FaRegEye, FaRegEyeSlash } = Icons

const CustomInput = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  required,
  placeholder,
  userValidationCheck,
  blurValidationHandler,
  focusHandler,
  doNotCopyHandler,
  isDisabled = false,
  uppercase,
  passwords,
  showPassword,
  setShowPassword,
  passwordshint,
  secure,
  setToggle,
  Toggle,
  ...props
}) => {
  const [submitted, setSubmitted] = useState(false)

  const isInvalid =
    (touched[field.name] && errors[field.name]) ||
    (submitted && errors[field.name])

  const handleChange = (e) => {
    const { name, value } = e.target
    let processedValue = value

    if (uppercase) {
      processedValue = value.toUpperCase()
    }

    if (name === 'phone') {
      processedValue = processedValue.replace(/\D/g, '')

      let formattedPhoneNumber = ''

      if (processedValue.length > 0) {
        formattedPhoneNumber += '(' + processedValue.substring(0, 3)
      }

      if (processedValue.length >= 4) {
        formattedPhoneNumber += ') ' + processedValue.substring(3, 6)
      }

      if (processedValue.length >= 7) {
        formattedPhoneNumber += '-' + processedValue.substring(6, 10)
      }

      processedValue = formattedPhoneNumber
    }

    if (name === 'odometer') {
      processedValue = processedValue.replace(/\D/g, '')
      processedValue = processedValue?.slice(0, 12)
      setFieldValue(name, processedValue)
    }

    if (
      name === 'institution_number' ||
      name === 'account_number' ||
      name === 'seller_account_number'
    ) {
      processedValue = processedValue.replace(/\D/g, '')
      setFieldValue(name, processedValue)
    }

    setFieldValue(name, processedValue)
  }

  const handleFocus = () => {
    setFieldTouched(field.name, true, false) // Mark field as touched when focused
  }

  return (
    <div className='w-full'>
      <h5 className='flex items-center gap-1 text-base font-medium text-gray mb-3'>
        {label}
        {required && <span className='text-danger'>*</span>}
      </h5>

      <div className='relative flex gap-4 items-center '>
        <input
          {...field}
          {...props}
          placeholder={placeholder}
          aria-invalid={isInvalid ? 'true' : 'false'}
          onCopy={(e) => doNotCopyHandler && doNotCopyHandler(e)}
          onBlur={() => {
            setSubmitted(true)
            if (userValidationCheck) {
              blurValidationHandler()
            }
          }}
          onFocus={() => {
            if (passwordshint) {
              handleFocus()
            } else {
              focusHandler && focusHandler()
            }
          }}
          disabled={isDisabled}
          onChange={(e) => handleChange(e)}
        />

        {(passwords || secure) && (
          <div
            className='absolute right-4'
            onClick={() => {
              setShowPassword && setShowPassword((prev) => !prev)
              setToggle && setToggle((prev) => !prev)
            }}
          >
            {showPassword || Toggle ? <FaRegEyeSlash /> : <FaRegEye />}
          </div>
        )}
      </div>
      {
        <ErrorMessage
          name={field.name}
          component='div'
          className='text-xs font-normal text-danger mt-1 ml-1'
        />
      }
    </div>
  )
}

CustomInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  }),
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  userValidationCheck: PropTypes.bool,
  blurValidationHandler: PropTypes.func,
  focusHandler: PropTypes.func,
  isDisabled: PropTypes.string,
  uppercase: PropTypes.bool,
  passwords: PropTypes.bool,
  showPassword: PropTypes.bool,
  setShowPassword: PropTypes.func,
  passwordshint: PropTypes.bool,
  secure: PropTypes.bool,
  setToggle: PropTypes.func,
  Toggle: PropTypes.bool,
  doNotCopyHandler: PropTypes.func,
}

export default CustomInput
