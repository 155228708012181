import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import io from 'socket.io-client'

import InvitedBuyers from '../../../../views/Seller/Live/Details/InvitedBuyers'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomModal from '../../../../components/Custom/CustomModal'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomChatImageView from '../../../../components/Custom/CustomChatImageView'
import CustomButtonWithIcon from '../../../../components/Custom/CustomButtonWithIcon'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import configManager from '../../../../config/configManager'

import { Icons } from '../../../../components/Icons'

const {
  PiChats,
  ImHammer2,
  CgSpinner,
  RiAlertFill,
  MdFileDownload,
  IoIosAttach,
  IoMdSend,
  IoCloseCircle,
  IoDocumentTextSharp,
  MdOutlineClose,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} = Icons

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)

const View = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()
  const [invitedUserList, setInvitedUserList] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/live/${id}`,
      )
      setvehicleDetails(response.data.vehicleDetails)
      setInvitedUserList(response.data.vehicleDetails.invited_buyers)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  const deleteExpiredOffers = useCallback(async () => {
    try {
      await axiosInterceptorInstance.delete(
        `/vehicle/seller/vehicle-auctions/delete/expired-offers/${id}`,
      )
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }, [id])

  const [offerCount, setOfferCount] = useState(0)
  const [liveOfferList, setLiveOfferList] = useState([])

  const fetchOfferList = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-auctions/list/offer/live/${id}`,
      )
      if (response.data.success) {
        setLiveOfferList([
          ...response.data.liveofferlist.sort(
            (a, b) => b.latestBid.amount - a.latestBid.amount,
          ),
        ])

        setOfferCount(response.data?.offerCount?.offer_count || 0)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
    fetchOfferList()
    deleteExpiredOffers()
    socket.on('OfferReceive', () => {
      fetchOfferList()
    })

    return () => {
      socket.off('OfferReceive', () => {
        fetchOfferList()
      })
    }
  }, [fetchData, fetchOfferList, deleteExpiredOffers])

  const [showMoreOffer, setShowMoreOffer] = useState(null)
  const [showMoreOfferId, setShowMoreOfferId] = useState(null)
  const [isLoadingMoreOffer, setIsLoadingMoreOffer] = useState(false)
  const fetchMoreOfferlist = async (userId) => {
    setShowMoreOffer(null)
    setShowMoreOfferId((pre) => (pre === userId ? null : userId))
  }

  useEffect(() => {
    const fetchdata = async (userId) => {
      setIsLoadingMoreOffer(true)
      try {
        const response = await axiosInterceptorInstance.post(
          `/vehicle/seller/vehicle-auctions/list/offer/more/live/${id}`,
          { buyerId: userId },
        )
        if (response.data.success) {
          setShowMoreOffer(response.data.Buyerliveofferlist)
          setIsLoadingMoreOffer(false)
        }
      } catch (error) {
        setIsLoadingMoreOffer(false)
        handleRequestError(error)
      }
    }

    if (showMoreOfferId) {
      fetchdata(showMoreOfferId)
    }
  }, [showMoreOfferId, id])

  const [statusModal, setStatusModal] = useState(false)
  const [isStatusLoading, setIsStatusLoading] = useState(false)
  const openStatusModal = () => {
    setStatusModal(true)
  }
  const closeStatusModal = () => {
    setStatusModal(false)
  }
  const handleAuctionStatus = async () => {
    setIsStatusLoading(true)
    try {
      const response = await axiosInterceptorInstance.put(
        `/vehicle/seller/vehicle-auctions/update/status/${id}`,
        { status: 'parking' },
      )
      if (response.data.success) {
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        navigate('/parking-lot')
      }
    } catch (error) {
      handleRequestError(error)
    } finally {
      setIsStatusLoading(false)
      setStatusModal(false)
    }
  }

  const [closeModal, setCloseModal] = useState(false)
  const [closeUser, setCloseUser] = useState(null)
  const [isCloseLoading, setIsCloseLoading] = useState(false)

  const openCloseModal = () => {
    setCloseModal(true)
  }

  const closeCloseModal = () => {
    setCloseModal(false)
  }
  const handleCloseDeal = async () => {
    try {
      setIsCloseLoading(true)
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-auctions/close/deal/${id}`,
        closeUser,
      )
      if (response.data.success) {
        CustomToast({
          message: response.data.message,
          type: 'success',
        })
        navigate('/closed')
        setIsCloseLoading(false)
        setCloseUser(null)
        closeCloseModal()
      }
    } catch (error) {
      handleRequestError(error)
      setIsCloseLoading(false)
    }
  }

  const [showChatBox, setShowChatBox] = useState(null)
  const [permanentMessages, setPermanentMessages] = useState([])

  const fetchAllChat = useCallback(
    async (buyerId) => {
      try {
        const response = await axiosInterceptorInstance.get(
          `/chat/seller/${buyerId}/${id}`,
        )
        if (response.data.chat !== null) {
          const chatMessages = response.data.chat.messages

          setPermanentMessages((prevPermanentMessages) => ({
            ...prevPermanentMessages,
            [buyerId]: chatMessages,
          }))

          setTimeout(scrollChat, 500)
        }
      } catch (error) {
        handleRequestError(error)
      }
    },
    [id],
  )

  const [autoChatBoxOpen, setAutoChatBoxOpen] = useState(null)

  useEffect(() => {
    socket.on('message received', (newChatCreated) => {
      fetchAllChat(newChatCreated.lastSenderID)
      setAutoChatBoxOpen(newChatCreated.lastSenderID)
    })

    return () => {
      socket.off('message received', () => {
        fetchAllChat()
        setAutoChatBoxOpen()
      })
    }
  }, [fetchAllChat])

  const location = useLocation()

  const openShowChatBox = useCallback(
    (buyerId) => {
      if (showChatBox !== null) {
        closeShowChatBox(showChatBox)
      }

      setShowChatBox(buyerId)
      fetchAllChat(buyerId)
    },
    [showChatBox, fetchAllChat],
  )

  useEffect(() => {
    if (location.state?.chatNotification) {
      openShowChatBox(location.state.senderId)
    }
  }, [location])

  const closeShowChatBox = () => {
    setShowChatBox(null)
    setAutoChatBoxOpen(null)
  }

  const isChatBoxOpen = (buyerId) => showChatBox === buyerId

  const [inputMessage, setInputMessage] = useState()
  const [documentFile, setDocumentFile] = useState(null)
  const [isLoadingDocument, setIsLoadingDocument] = useState(false)

  const fileInput = useRef(null)
  const chatboxRef = useRef(null)
  const formSubmitRef = useRef(null)

  const handleDocumentFileChange = (e) => {
    const file = e.target.files[0]
    setDocumentFile(file)
    fileInput.current.value = null
  }

  const isMessageOrSelectedDocument = useMemo(() => {
    if ((inputMessage && inputMessage.trim() !== '') || !!documentFile)
      return true
    return false
  }, [inputMessage, documentFile])

  const truncateFileName = (url) => {
    const extension = url.split('.').at(-1)
    const name = url.slice(0, 24)
    return `${name}....${extension}`
  }

  const handleSendChat = async (e, buyerId, buyerRole) => {
    e.preventDefault()
    if (!isMessageOrSelectedDocument) return
    const formData = new FormData()
    formData.append('receiver', buyerId)
    formData.append('role', buyerRole)
    formData.append('vehicle_status', vehicleDetails?.status)
    if (documentFile) {
      formData.append('document', documentFile)
    } else {
      formData.append('message', inputMessage)
    }
    setIsLoadingDocument(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/chat/create/${vehicleDetails?._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      if (response.data.success) {
        if (!documentFile) {
          setInputMessage('')
        }
        setDocumentFile(null)
        setIsLoadingDocument(false)
        fetchAllChat(buyerId)
        socket.emit('new message', response.data)
        setAutoChatBoxOpen(buyerId)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const getDocumentName = (url) => {
    return url.split('/').at(-1)
  }

  const setIsDownloading = (buyer_id, id, value) => {
    const newMessage = permanentMessages[buyer_id].map((val) => {
      if (val._id === id) {
        return { ...val, isDownloading: value }
      }
      return val
    })
    setPermanentMessages((messages) => ({
      ...messages,
      [buyer_id]: newMessage,
    }))
  }

  const scrollChat = () => {
    chatboxRef.current?.scrollBy({
      top: chatboxRef.current.scrollHeight + 50,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleDownload = async (url, id, buyer_id) => {
    setIsDownloading(buyer_id, id, true)
    try {
      const response = await axiosInterceptorInstance.get(
        `${companyData.API_BASE_URL}/api/v1/user/download?url=${url}`,
        { responseType: 'blob' },
      )
      if (response.statusText === 'OK') {
        const fileName = getDocumentName(url)
        url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        handleRequestError({
          response: { data: { message: 'Downloading failed' } },
        })
      }
      setIsDownloading(buyer_id, id, false)
    } catch (error) {
      handleRequestError({
        response: { data: { message: 'Downloading failed' } },
      })
      setIsDownloading(buyer_id, id, false)
    }
  }

  useEffect(() => {
    if (documentFile) {
      formSubmitRef.current.requestSubmit()
    }
  }, [documentFile])

  useEffect(() => {
    const handleVehicleExpired = () => {
      navigate(`/pending/${id}`)
      CustomToast({
        message:
          "The offer time for the vehicle has ended. You're free to accept offers from any interested buyers.",
        type: 'success',
      })
    }

    const moveVehicleToParkingOnNoOffers = () => {
      navigate(`/parking-lot/${id}`)
      CustomToast({
        message:
          'The Vehicle has not received any offers. vehicle moved to parking.',
        type: 'success',
      })
    }

    // Register event listener
    socket.on('vehicle_expired', handleVehicleExpired)
    socket.on('vehicle_move_to_parking', moveVehicleToParkingOnNoOffers)
    // Cleanup function to remove event listener
    return () => {
      socket.off('vehicle_expired', handleVehicleExpired)
      socket.off('vehicle_move_to_parking', moveVehicleToParkingOnNoOffers)
    }
  }, [id, navigate])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle
        backBtn
        backBtncount={location?.state?.chatNotification ? 2 : 1}
      />
      <CustomPageTitle
        vehicleDetails={vehicleDetails}
        openStatusModal={openStatusModal}
        backParkingLot
        timer
      />
      <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 large:w-auto large:items-stretch gap-y-10 large:gap-x-8'>
        <div className='w-full large:w-[calc(35%_-_16px)] order-2 large:order-1'>
          <div className='overflow-hidden border border-gray/10 rounded-xl'>
            <div className='flex flex-col items-start justify-center w-full p-0 m-0'>
              <InvitedBuyers
                invitedUserList={invitedUserList}
                openShowChatBox={openShowChatBox}
              />
            </div>
          </div>
        </div>
        <div className='w-full large:w-[calc(65%_-_16px)] order-1 large:order-2'>
          <div className='relative z-10 flex flex-col mb-10 right_content last:mb-0'>
            <div className='flex flex-wrap items-center justify-between w-full gap-3 mb-5'>
              <div className='flex flex-wrap items-center gap-4 title title-gray'>
                <h4 className='capitalize subHeading-4'>Offers</h4>
              </div>
              <div className='px-4 py-1 rounded-full title title-white bg-gray'>
                <h5 className='py-1 subHeading-5'>
                  {offerCount}&nbsp;{offerCount > 1 ? 'Offers' : 'Offer'}
                </h5>
              </div>
            </div>

            <ul className='flex flex-wrap gap-2 relative p-2 mb-10 border border-gray border-opacity-10 rounded-xl last:mb-0 max-h-[calc(100dvh_-_296px)] overflow-y-auto'>
              {liveOfferList.length > 0 ? (
                liveOfferList?.map((latestoffer, index) => (
                  <li
                    className='block w-full overflow-hidden rounded-lg bg-primary bg-opacity-5 last:mb-0'
                    key={index}
                  >
                    <div className='block w-full'>
                      <div className='flex flex-wrap items-center justify-end w-full p-0 gap-y-5 gap-x-2'>
                        <div className='flex flex-wrap items-center justify-between w-full gap-5 p-0 py-5 pb-3 m-0'>
                          <div className='flex flex-wrap items-center gap-3 pl-5 w-full max-w-[calc(50%_-_20px)] md:max-w-[calc(30%_-_20px)]'>
                            <div className='lg:flex-1 max-w-[32px]'>
                              {latestoffer?.buyer[0]?.profile_picture ? (
                                <img
                                  src={latestoffer?.buyer[0]?.profile_picture}
                                  className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                                  alt='img'
                                  onError={(e) => userBrokenImages(e)}
                                />
                              ) : (
                                <div className='flex items-center justify-center w-8 h-8 text-sm rounded-full bg-gray/10 text-primary'>
                                  {latestoffer?.buyer[0]?.first_name
                                    ?.substring(0, 2)
                                    ?.toUpperCase()}
                                </div>
                              )}
                            </div>
                            <NavLink
                              to={`/buyer/profile/${latestoffer?.buyer[0]?._id}`}
                              className='max-w-[calc(100%_-_44px)]'
                            >
                              <span className='text-sm font-medium capitalize cursor-pointer lg:flex-2 text-gray group/user hover:text-primary'>
                                {latestoffer?.buyer[0]?.first_name}
                                <br />
                                <span className='text-xs font-normal normal-case text-gray text-opacity-60 group-hover/user:text-gray'>
                                  {latestoffer?.buyer[0]?.email}
                                </span>
                              </span>
                            </NavLink>
                          </div>
                          <div className='flex flex-wrap items-center justify-start order-3 px-5 gap-2 w-full max-w-[calc(50%_-_20px)] md:max-w-[calc(20%_-_20px)]'>
                            <div>
                              <p className='text-xs text-gray/70'>Amount:</p>
                              <span className='text-sm font-medium text-gray'>
                                ${latestoffer?.latestBid?.amount}
                              </span>
                            </div>
                            {latestoffer.bidCount - 1 !== 0 && (
                              <button
                                onClick={() =>
                                  fetchMoreOfferlist(latestoffer?.buyer[0]?._id)
                                }
                              >
                                {showMoreOffer &&
                                showMoreOfferId ===
                                  latestoffer?.buyer[0]?._id ? (
                                  <MdKeyboardArrowUp className='text-lg' />
                                ) : (
                                  <MdKeyboardArrowDown className='text-lg' />
                                )}
                              </button>
                            )}
                          </div>

                          <div className='flex flex-wrap items-stretch justify-end order-4 gap-3 pr-5 w-full max-w-full md:max-w-[calc(40%_-_20px)]'>
                            <div className='flex flex-col max-md:items-start items-end justify-start gap-1 w-full'>
                              <div>
                                <p className='text-xs text-gray/70'>
                                  Offer Expires On:
                                </p>
                                <span className='text-sm font-medium text-gray'>
                                  {moment(
                                    latestoffer?.latestBid?.expire_offer_at,
                                  ).format('YYYY MMM, D')}
                                  &nbsp;&nbsp;
                                  {moment(
                                    latestoffer?.latestBid?.expire_offer_at,
                                  ).format('h:mm A')}
                                </span>
                              </div>
                            </div>
                            <div className='max-md:flex-auto max-md:flex max-md:flex-col'>
                              <CustomButtonWithIcon
                                icon={<ImHammer2 className='rotate-[75deg]' />}
                                label='Accept'
                                disabled={moment().isAfter(
                                  moment(
                                    latestoffer?.latestBid?.expire_offer_at,
                                  ),
                                )}
                                onClick={() => {
                                  openCloseModal()
                                  setCloseUser({
                                    awardedAuctionId: latestoffer.latestBid._id,
                                    awardedBuyerId: latestoffer?.buyer[0]?._id,
                                    wonAmount: latestoffer.latestBid.amount,
                                    awarded_at: moment(),
                                  })
                                }}
                              />
                            </div>

                            <div className='max-md:flex-auto max-md:flex max-md:flex-col'>
                              <CustomButtonWithIcon
                                icon={<PiChats />}
                                label='Chat'
                                onClick={() => {
                                  const state = {
                                    chatNotification: true,
                                    senderId: latestoffer?.buyer[0]?._id,
                                  }
                                  navigate(location.state, { state })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showMoreOfferId === latestoffer?.buyer[0]?._id &&
                      (showMoreOffer?.length - 1 > 0
                        ? showMoreOffer.slice(1).map((moreOffer, index) => {
                            return (
                              <div
                                className='w-full border-b border-dashed border-gray border-opacity-10 last:border-b-0'
                                key={index}
                              >
                                <div className='flex flex-wrap items-center justify-between w-full gap-5 py-2 '>
                                  <div className='flex flex-col items-start justify-start px-5 w-full max-w-[calc(50%_-_20px)] md:max-w-[calc(33.333%_-_20px)]'>
                                    <span className='flex flex-col items-center justify-center w-8 h-8 text-sm font-medium rounded-full bg-primary/20 text-gray'>
                                      {index + 1}
                                    </span>
                                  </div>
                                  <div className='flex flex-col items-start justify-start px-5 w-full max-w-[calc(50%_-_20px)] md:max-w-[calc(25%_-_20px)]'>
                                    <p className='text-xs text-gray/70'>
                                      Amount:
                                    </p>
                                    <span className='text-sm font-medium text-gray'>
                                      ${moreOffer?.amount}
                                    </span>
                                  </div>
                                  <div className='flex flex-col-reverse px-5 md:items-end md:justify-end w-full max-w-full md:max-w-[calc(38.5%_-_20px)]'>
                                    <p className='text-xs text-gray'>
                                      {moment(moreOffer?.created_at).format(
                                        'h:mm A',
                                      )}
                                    </p>
                                    <p className='text-xs text-gray/70'>
                                      {moment(moreOffer?.created_at).format(
                                        'YYYY MMM, D',
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        : isLoadingMoreOffer && (
                            <div className='flex items-center justify-center w-full pt-3 pb-5'>
                              <div className='w-8 h-8 border-2 border-solid rounded-full animate-spin border-primary border-t-transparent'></div>
                            </div>
                          ))}
                  </li>
                ))
              ) : (
                <div className='w-full flex justify-center text-2xl font-medium text-center text-gray text-opacity-20 py-4'>
                  No Offer
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {statusModal && (
        <CustomModal
          handleCloseModal={closeStatusModal}
          closeModal={statusModal}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeStatusModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <RiAlertFill />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Are you certain you wish to place this vehicle in the parking
                  lot?
                </h5>
              </div>
            </div>
            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeStatusModal}
                className='btn btn-gray max-lg:flex-auto'
              >
                No, cancel
              </button>

              <button
                onClick={handleAuctionStatus}
                className={`btn btn-primary max-lg:flex-auto ${
                  isStatusLoading && 'hover:bg-[#fff]'
                }`}
              >
                {isStatusLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
      {closeModal && (
        <CustomModal handleCloseModal={closeCloseModal} closeModal={closeModal}>
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeCloseModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <ImHammer2 />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Are you sure you wish to close this deal?
                </h5>
              </div>
            </div>
            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeCloseModal}
                className='btn btn-gray max-xl:flex-auto'
              >
                No, cancel
              </button>

              <button
                onClick={handleCloseDeal}
                className={`btn btn-primary max-xl:flex-auto ${
                  isCloseLoading && 'hover:bg-[#fff]'
                }`}
              >
                {isCloseLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
      {invitedUserList.map(
        (item, index) =>
          (isChatBoxOpen(item._id) || autoChatBoxOpen === item._id) && (
            <div key={index}>
              <div className='fixed max-sm:bottom-[60px] bottom-[30px] right-[30px] w-full max-lg:max-w-[calc(100vw_-_30%)] max-w-[calc(700px_-_10%)] border border-gray/70 bg-white flex flex-col rounded-lg overflow-hidden z-20 drop-shadow-lg'>
                <div className='flex items-center justify-between px-5 py-3 border-b bg-primary border-gray/70'>
                  <NavLink
                    className='flex items-center gap-2'
                    to={`/buyer/profile/${item._id}`}
                  >
                    {item?.profile_picture ? (
                      <img
                        src={item?.profile_picture}
                        className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                        alt='img'
                        onError={(e) => userBrokenImages(e)}
                      />
                    ) : (
                      <div className='flex items-center justify-center w-8 h-8 rounded-full text-primary bg-light-gray'>
                        {item?.first_name?.substring(0, 2)?.toUpperCase()}
                      </div>
                    )}
                    <span className='text-sm font-medium text-white capitalize lg:flex-2'>
                      {item?.first_name}
                    </span>
                  </NavLink>
                  <IoCloseCircle
                    className='text-2xl text-white cursor-pointer'
                    onClick={() => closeShowChatBox(item._id)}
                  />
                </div>
                <div
                  ref={chatboxRef}
                  className='chatbox p-5 max-h-[calc(100dvh_-_50%)] h-[300px] overflow-x-hidden overflow-y-auto gap-3 flex flex-col'
                >
                  {permanentMessages[item._id]?.map((msg, index) => (
                    <div key={index}>
                      {msg.sender === item._id && (
                        <div className='w-full text-left'>
                          <div className='flex gap-2 items-start max-w-[calc(100%_-_35%)]'>
                            {item?.profile_picture ? (
                              <img
                                src={item?.profile_picture}
                                alt='img-user'
                                className='block w-6 h-6 bg-white border rounded-full shadow-lg border-gray/60'
                                onError={(e) => userBrokenImages(e)}
                              />
                            ) : (
                              <div className='z-[999] mt-[6px] w-6 h-6 rounded-full flex items-center justify-center bg-gray/40 text-primary text-xs'>
                                {item?.first_name
                                  ?.substring(0, 2)
                                  ?.toUpperCase()}
                              </div>
                            )}
                            {msg?.message && (
                              <div className='flex flex-col'>
                                <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                                  {msg?.message}
                                </div>
                                <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                  {moment(msg?.created_at).format('h:mm a')}
                                </span>
                              </div>
                            )}
                            {msg?.document && (
                              <div className='flex flex-col'>
                                {['jpg', 'png', 'jpeg'].includes(
                                  msg?.document.split('.').at(-1),
                                ) ? (
                                  <div className='inline-block rounded-lg'>
                                    <div className='relative flex items-center'>
                                      <CustomChatImageView
                                        Images={msg?.document}
                                        onLoad={scrollChat}
                                      />
                                      <button
                                        className='absolute w-5 h-5 bg-red-300 text-primary -right-7'
                                        onClick={() =>
                                          handleDownload(
                                            msg?.document,
                                            msg._id,
                                            item._id,
                                          )
                                        }
                                        disabled={msg?.isDownloading}
                                      >
                                        {!msg?.isDownloading ? (
                                          <MdFileDownload className='w-5 h-5' />
                                        ) : (
                                          <CgSpinner className='w-5 h-5 animate-spin' />
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div className='relative inline-block px-4 py-1 text-sm font-light leading-6 break-words border rounded-lg shadow bg-primary border-gray/5 text-gray'>
                                      <div className='flex items-center space-x-1'>
                                        <IoDocumentTextSharp className='-ml-2 text-white' />
                                        <span className='text-white break-all'>
                                          {truncateFileName(
                                            getDocumentName(msg?.document),
                                          )}
                                        </span>
                                      </div>
                                      <button
                                        className='absolute w-5 h-5 bg-red-300 text-danger top-2 -right-7'
                                        onClick={() =>
                                          handleDownload(
                                            msg?.document,
                                            msg._id,
                                            item._id,
                                          )
                                        }
                                        disabled={msg?.isDownloading}
                                      >
                                        {!msg?.isDownloading ? (
                                          <MdFileDownload className='w-5 h-5' />
                                        ) : (
                                          <CgSpinner className='w-5 h-5 animate-spin' />
                                        )}
                                      </button>
                                    </div>
                                  </>
                                )}
                                <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                                  {moment(msg?.created_at).format('h:mm a')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {msg.receiver === item._id && (
                        <div className='w-full text-right'>
                          <div className='w-full max-w-[calc(100%_-_35%)] ml-auto'>
                            {msg?.message && (
                              <div className='inline-block px-4 py-1 text-sm font-light leading-6 break-all border rounded-lg shadow bg-gray/5 border-gray/5 text-gray first-letter:capitalize'>
                                {msg?.message}
                              </div>
                            )}
                            {msg?.document && (
                              <>
                                {['jpg', 'png', 'jpeg'].includes(
                                  msg?.document.split('.').at(-1),
                                ) ? (
                                  <div className='inline-block rounded-lg'>
                                    <div className='relative flex items-center'>
                                      <button
                                        className='absolute w-5 h-5 bg-red-300 text-primary -left-7'
                                        onClick={() =>
                                          handleDownload(
                                            msg?.document,
                                            msg._id,
                                            item._id,
                                          )
                                        }
                                        disabled={msg?.isDownloading}
                                      >
                                        {!msg?.isDownloading ? (
                                          <MdFileDownload className='w-5 h-5' />
                                        ) : (
                                          <CgSpinner className='w-5 h-5 animate-spin' />
                                        )}
                                      </button>
                                      <CustomChatImageView
                                        Images={msg?.document}
                                        onLoad={scrollChat}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className='relative inline-block px-4 py-1 text-sm font-light leading-6 break-words border rounded-lg shadow bg-primary border-gray/5 text-gray'>
                                    <button
                                      className='absolute w-5 h-5 bg-red-300 text-primary top-2 -left-7'
                                      onClick={() =>
                                        handleDownload(
                                          msg?.document,
                                          msg._id,
                                          item._id,
                                        )
                                      }
                                      disabled={msg?.isDownloading}
                                    >
                                      {!msg?.isDownloading ? (
                                        <MdFileDownload className='w-5 h-5' />
                                      ) : (
                                        <CgSpinner className='w-5 h-5 animate-spin' />
                                      )}
                                    </button>
                                    <div className='flex items-center space-x-1'>
                                      <IoDocumentTextSharp className='-ml-2 text-white' />
                                      <span className='text-white break-all'>
                                        {truncateFileName(
                                          getDocumentName(msg?.document),
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            <span className='block text-[10px] font-normal text-gray/70 ml-0 mt-1'>
                              {moment(msg?.created_at).format('h:mm a')}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className='flex flex-col p-2 px-3 border-t border-gray/70 bg-[#ebebeb] relative z-10'>
                  <form
                    onSubmit={(e) => handleSendChat(e, item._id, item.role)}
                    ref={formSubmitRef}
                    className='flex items-center w-full gap-5'
                  >
                    <input
                      type='text'
                      className='w-full px-6 py-3 text-sm font-light leading-5 border !rounded-md outline-none text-gray !border-gray/30 first-letter:!uppercase'
                      placeholder='Message'
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                    />
                    <div className='relative'>
                      <label
                        htmlFor='file-input'
                        className='btn-gray-ico hover:text-primary !m-0'
                      >
                        <IoIosAttach className='w-5 h-5 text-black' />
                      </label>
                      <input
                        ref={fileInput}
                        id='file-input'
                        type='file'
                        className='!hidden'
                        accept='.jpg, .png, .jpeg, .pdf, .docx, .doc, .xlsx, .xls, .csv, .text'
                        onChange={handleDocumentFileChange}
                      />
                    </div>
                    <button
                      className='text-black btn-gray-ico disabled:opacity-50'
                      disabled={
                        !isMessageOrSelectedDocument || isLoadingDocument
                      }
                    >
                      {isLoadingDocument ? (
                        <CgSpinner className='w-5 h-5 animate-spin' />
                      ) : (
                        <IoMdSend className='w-5 h-5' />
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          ),
      )}
    </CustomLoadingSection>
  )
}

export default View
