import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const CustomButtonWithIcon = ({
  onClick = () => {},
  icon = null,
  label = '',
  link = '',
  isLoading = false,
  disabled = false,
}) => {
  return (
    <Fragment>
      {link ? (
        <NavLink to={link} className='btn btn-primary-ico max-md:flex-auto'>
          {icon}
          {label ? <Fragment>&nbsp;{label}</Fragment> : ''}
        </NavLink>
      ) : (
        <button
          className='cursor-pointer btn btn-primary-ico disabled:cursor-not-allowed disabled:bg-opacity-50 disabled:text-white max-md:flex-auto'
          onClick={onClick}
          disabled={disabled || isLoading}
        >
          {isLoading ? (
            <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent ' />
          ) : (
            <Fragment>
              {icon}
              {label ? <Fragment>&nbsp;{label}</Fragment> : ''}
            </Fragment>
          )}
        </button>
      )}
    </Fragment>
  )
}

CustomButtonWithIcon.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  link: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default CustomButtonWithIcon
