import React, { Fragment, useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'

import BuyerBankDetails from './BuyerBankDetails'
import SellerBankDetails from './SellerBankDetails'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

const stripePromise = loadStripe(
  'pk_test_51OuHHj2KMG5sGLa6kqIzjMq93M6yYfryXqXc304NG4vsvOF4PyH4cnSWjocvacFD0ANJSSCRNrnBhVA3eyTUrePV00UNvMhRMh',
)

const FinancialDetails = ({ setStep }) => {
  const [userDetails, setUserDetails] = useState()

  const fetchUserData = async () => {
    try {
      const userDetailsResponse =
        await axiosInterceptorInstance.get(`/auth/user/details`)

      if (userDetailsResponse.data.success) {
        setUserDetails(userDetailsResponse.data.userdetails)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <Fragment>
      {userDetails?.role === 'buyer' ? (
        <SellerBankDetails userDetails={userDetails} setStep={setStep} />
      ) : (
        <Elements stripe={stripePromise}>
          <BuyerBankDetails userDetails={userDetails} setStep={setStep} />
        </Elements>
      )}
    </Fragment>
  )
}

FinancialDetails.propTypes = {
  setStep: PropTypes.func.isRequired,
}

export default FinancialDetails
