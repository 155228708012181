import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import CustomTitle from '../../../../components/Custom/CustomTitle'

import { useStepContext } from '../../../../context/useStepContext'

import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

const Edit = () => {
  const { editStep, editSteps } = useStepContext()
  const { editvehicles } = useSelector((store) => store.vehicle)
  return (
    <Fragment>
      <CustomTitle backBtn />
      <div className='w-full flex flex-col large:flex-row justify-between items-center gap-8'>
        <div className='w-full flex flex-wrap items-center my-10'>
          <h2 className='text-xl large:text-2xl font-medium text-gray border-r border-gray/30 pr-4 mr-4'>
            {editvehicles?.year}&nbsp;
            {editvehicles?.make}&nbsp;
            {editvehicles?.model}
          </h2>
          <h5 className='subHeading-5 text-gray/90'>
            VIN :&nbsp;
            <CopyToClipboard>{editvehicles?.vin_number} </CopyToClipboard>
          </h5>
        </div>
      </div>
      <div className='add-tabs md:flex items-center lg:justify-start'>
        {editSteps.map((s) => (
          <div
            key={s.stepNumber}
            className={`tabs text-center text-sm lg:text-base font-normal py-3 px-5 cursor-default ${
              editStep === s.stepNumber && 'active'
            }`}
          >
            {s.label}
          </div>
        ))}
      </div>
      <div className='border border-gray border-opacity-10 rounded-xl rounded-tl-none max-md:rounded-tr-none p-5 md:px-8 md:py-10'>
        {editSteps.map((s) =>
          editStep === s.stepNumber ? (
            <s.component
              key={s.stepNumber}
              vehicleStatus={editvehicles?.status}
            />
          ) : null,
        )}
      </div>
    </Fragment>
  )
}

export default Edit
