import { useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import io from 'socket.io-client'

import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import Fancybox from '../../../../components/Custom/ImageViewer'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import configManager from '../../../../config/configManager'
import CustomVehicleDetails from '../../../../components/Custom/CustomBuyVehicleDetails'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const View = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/upcoming/${id}`,
      )
      if (response.data.success) {
        setvehicleDetails(response.data.vehicleDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()

    socket.on('vehicle_launch', () => {
      fetchData()
    })

    socket.on('vehicle_expired', () => {
      fetchData()
    })

    socket.on('upcoming_vehicle_live', () => {
      fetchData()
    })

    return () => {
      socket.off('vehicle_launch', fetchData)
      socket.off('vehicle_expired', fetchData)
      socket.off('upcoming_vehicle_live', fetchData)
    }
  }, [fetchData])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <section className='details-single lg:pb-0'>
        <div className='flex flex-wrap items-end justify-start w-full p-0 m-0 '>
          <CustomTitle backBtn />
          <CustomPageTitle vehicleDetails={vehicleDetails} scheduledDate />
        </div>
        <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 large:items-stretch gap-y-10'>
          <div className='w-full'>
            <Fancybox vehicleImages={vehicleDetails?.vehicle_image} />
          </div>
          <CustomVehicleDetails vehicleDetails={vehicleDetails} />
        </div>
      </section>
    </CustomLoadingSection>
  )
}

export default View
