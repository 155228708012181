import React, { useEffect, useState } from 'react'

import CustomListView from '../../../../components/Custom/CustomListView'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

const PurchasedList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [purchasedVehicleList, setPurchasedVehicleList] = useState()

  const fetchPurchaseVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/purchased',
      )
      if (response.data.success) {
        setPurchasedVehicleList(response.data.vehicleList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchPurchaseVehicleList()
  }, [])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomListView
        CustomNoData='You have not purchased sofar'
        isLoading={isLoading}
        data={purchasedVehicleList}
      />
    </CustomLoadingSection>
  )
}

export default PurchasedList
