import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'formik'
import Select from 'react-select'

const CustomMultiSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  onChange,
  placeholder,
  required,
  label,
  className,
  options,
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    const fieldValues = field.value || []
    const selected = options.filter((option) =>
      fieldValues.includes(option.value),
    )
    setSelectedOptions(selected)
  }, [field.value, options])

  const allOptionsSelected = selectedOptions.length === options.length

  const onChangeHandler = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === 'select-all')) {
      const newSelectedOptions = allOptionsSelected ? [] : options
      setSelectedOptions(newSelectedOptions)
      setFieldValue(
        field.name,
        newSelectedOptions.map((option) => option.value),
      )
      if (onChange) {
        onChange(newSelectedOptions)
      }
    } else {
      setSelectedOptions(selectedOptions)
      setFieldValue(
        field.name,
        selectedOptions.map((option) => option.value),
      )
      if (onChange) {
        onChange(selectedOptions)
      }
    }
  }

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      background: '#FFFFFF',
      color: '#E60A54',
      borderRadius: '6px',
      borderColor: 'rgba(59,59,59,0.4)',
      boxShadow: state.isFocused ? null : null,
      minHeight: '32px',
      fontSize: '14px',
      fontWeight: '400',
      '&:hover': {
        borderColor: state.isFocused ? '#E60A54' : '#E60A54',
      },
      '@media (max-width: 639px)': {
        fontSize: '14px',
      },
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: '14px',
      fontWeight: 400,
      color: '#000',
      opacity: '0.4',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      borderBottom: '0.1px solid #bfbfbf',
      borderRadius: 0,
      height: 'full',
      cursor: 'pointer',
      color: isFocused ? '#FFF' : '#000',
      backgroundColor: isDisabled
        ? '#E60A54'
        : isSelected
          ? '#E60A54'
          : isFocused
            ? '#E60A54'
            : '#FFFFFF',
      '@media (max-width: 639px)': {
        fontSize: '13px',
      },
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    singleValue: (styles) => ({ ...styles, color: '#000' }),
  }

  const customOptions = [
    {
      value: 'select-all',
      label: allOptionsSelected ? 'Deselect All' : 'Select All',
    },
    ...options,
  ]

  return (
    <div className={className}>
      {label && (
        <h5 className='flex items-center gap-1 text-base font-medium text-gray mb-3'>
          {label}
          {required && <span className='text-danger'>*</span>}
        </h5>
      )}
      <Select
        {...field}
        {...props}
        styles={colourStyles}
        placeholder={placeholder}
        classNamePrefix='select'
        isMulti={true}
        options={customOptions}
        value={selectedOptions}
        onChange={onChangeHandler}
        className={`react-select text-sm font-normal h-fit ${
          !!touched[field.name] && !!errors[field.name]
        } ${touched[field.name] && !errors[field.name]} `}
      />
      <ErrorMessage
        name={field.name}
        component='div'
        className='text-xs font-normal text-danger'
      />
    </div>
  )
}

CustomMultiSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default CustomMultiSelect
