import React from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { signOut } from '../../../redux/user/userSlice'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import {
  getRole,
  getToken,
  removeRole,
  removeToken,
} from '../../../utils/cookies/cookies'

import { RemoveEditVehicleDetails } from '../../../redux/vehicle/vehicleSlice'

export default function PrivateRoute() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  if (!['seller', 'buyer'].includes(getRole())) {
    const logouthandler = async () => {
      try {
        await axiosInterceptorInstance.get('/auth/logout')
        dispatch(RemoveEditVehicleDetails())
        dispatch(signOut())
        removeRole()
        removeToken()
        navigate('/login')
      } catch (error) {
        handleRequestError(error)
      }
    }
    logouthandler()
  }

  return getToken() ? <Outlet /> : <Navigate to='/login' />
}
