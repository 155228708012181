import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'

import CustomInput from '../../../../custom/CustomInput'
import CustomModal from '../../../../components/Custom/CustomModal'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'

import { setVehicleDetails } from '../../../../redux/vehicle/vehicleSlice'
import { useStepContext } from '../../../../context/useStepContext'

import { useSDK } from '../../../../utils/scanVIN/sdk'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'

import { useStore } from '../../../../context/scanStore'

import carImage from '../../../../assets/car/car.png'
import whereismyvin from '../../../../assets/car/Where_Is_My_VIN_Image.png'

import configManager from '../../../../config/configManager'

import Show from './Show'

import { Icons } from '../../../../components/Icons'

const {
  MdCameraAlt,
  TiMediaPlayOutline,
  IoInformationCircleOutline,
  MdArrowRightAlt,
  MdOutlineClose,
} = Icons

const VinDecoder = () => {
  const navigate = useNavigate()

  const data = useSelector((store) => store.vehicle)

  const [isLoading, setIsLoading] = useState(false)
  const [vehicleImg, setVehicleImg] = useState(null)
  const [FetchedData, setFetchedData] = useState(false)
  const [isVINDecoderError, setIsVINDecoderError] = useState(false)
  const [whereIsMyVinModel, setWhereIsMyVinModel] = useState(false)

  const schema = Yup.object().shape({
    vin_number: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed')
      .required('VIN is required.')
      .max(17, 'Must be exactly 17 digits')
      .min(17, 'Must be exactly 17 digits')
      .test('trim', 'Please provide valid vin', (value) => {
        return value.trim().length === value.length
      }),
  })

  const dispatch = useDispatch()

  const { handleStepClick } = useStepContext()

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/vin/details/${values.vin_number}`,
      )

      if (response.data.VehicleDetails.success) {
        setIsLoading(false)
        dispatch(setVehicleDetails(response.data.VehicleDetails))
        setFetchedData(true)
        resetForm()
      }
    } catch (error) {
      setIsLoading(false)
      setIsVINDecoderError(true)
      setFetchedData(false)
      resetForm()
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      vin_number: '',
    },
  })

  const handleCloseModal = () => {
    setWhereIsMyVinModel(false)
  }
  const OpenModalss = () => {
    setWhereIsMyVinModel(true)
  }

  const [VehicleListing, setVehicleListing] = useState(null)

  const listeNewVehicleHandler = async () => {
    setIsLoading(true)

    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/vin/check/${data.vin}`,
      )
      if (response.data.success) {
        if (!response.data.isListedVin) {
          handleStepClick(1)
          setIsLoading(false)
          navigate('/vehicle/add')
        }
      } else {
        setFetchedData(null)

        setIsLoading(false)
        setVehicleListing(true)
      }
    } catch (error) {
      setIsLoading(false)
      setVehicleListing(true)
      setFetchedData(null)
      setIsVINDecoderError(null)
    }
  }

  useEffect(() => {
    data?.photos?.length > 0
      ? setVehicleImg(data?.photos[0]?.url)
      : setVehicleImg(carImage)
  }, [data])

  const configData = configManager()

  const { loading, sdk } = useSDK()

  useEffect(() => {
    if (!loading) {
      void sdk.enableScanning(false)
    }
  }, [loading, sdk])

  const { barcode } = useStore()

  useEffect(() => {
    if (barcode !== undefined) {
      formik.setFieldValue('vin_number', barcode.data || '')
    } else {
      formik.setFieldValue('vin_number', '')
    }
  }, [barcode])

  return (
    <Fragment>
      <CustomTitle backBtn customTitle='VIN Decoder' />
      <div
        className='w-full flex flex-wrap items-start gap-y-10 bg-no-repeat bg-50% bg-right-bottom overflow-y-auto max-large:py-10'
        style={{ backgroundImage: `url(${carImage})` }}
      >
        <div className='xl:max-w-[calc(100%_-_10%)] mx-auto flex flex-wrap items-center justify-center w-full large:pt-10 gap-y-10'>
          <div className='w-full py-0 large:basis-1/3'>
            <h2 className='mb-5 text-4xl font-medium text-primary last:mb-0'>
              Sell My vehicle
            </h2>
            <p className='mb-3 text-sm leading-6 text-gray last:mb-0'>
              Get a secure sale when you sell your vehicle online with Revvit.
              Let us help with the steps and paperwork while you control the
              price and sale.
            </p>
            <ul className='flex flex-col items-start justify-start gap-2 mb-3 last:mb-0'>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Save time on state required forms
              </li>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Verified community of buyers
              </li>
              <li className='flex flex-wrap items-center justify-start gap-2 text-sm text-gray'>
                <TiMediaPlayOutline />
                Sell completely online
              </li>
            </ul>
          </div>
          <div className='w-full large:basis-1/2'>
            <div className='h-full large:max-h-[calc(100%_-_30%)] p-7 w-full large:max-w-[calc(100%_-_15%)] mx-auto flex flex-col items-center justify-center bg-gray/5 backdrop-blur rounded-lg border border-gray/10 shadow-lg'>
              {VehicleListing && (
                <div className='flex flex-wrap content-between justify-between w-full h-full gap-5'>
                  <h3 className='w-full pb-5 text-xl font-bold text-center border-b text-gray border-gray/10'>
                    VIN is registered
                  </h3>
                  <div className='flex flex-wrap items-start justify-start w-full gap-5'>
                    <p className='mt-4 text-base text-gray'>
                      <span className='text-primary'>Oops,</span> it looks like
                      that vehicle is listed by someone else.
                    </p>
                    <p className='mt-4 text-base text-gray'>
                      Please double check your VIN or contact customer service
                      to <span className='text-primary'> Revvit</span>.
                    </p>
                    <div className='mt-4 ml-auto'>
                      <div
                        onClick={() => {
                          setIsVINDecoderError(false)
                          setVehicleListing(null)
                          setFetchedData(null)
                        }}
                        className='cursor-pointer btn btn-primary w-fit'
                      >
                        Try again
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {FetchedData ? (
                <Fragment>
                  <div className='flex flex-wrap content-between justify-between w-full h-full gap-5'>
                    <h3 className='w-full pb-5 text-xl font-bold text-center border-b text-gray border-gray/10'>
                      {data.vin}
                    </h3>
                    <div className='flex flex-wrap items-start justify-center w-full gap-5'>
                      <div className='w-full'>
                        <div className='relative block w-full'>
                          <img
                            className='max-w-[250px] h-[180px] block rounded-md mx-auto object-cover'
                            width={250}
                            height={250}
                            onError={(e) => vehicleBrokenImage(e)}
                            src={vehicleImg}
                            alt='img'
                          />
                        </div>
                      </div>

                      <div className='w-full text-center'>
                        <h1 className='mb-2 text-lg font-bold text-gray'>
                          {data.MMYT.year} {data.MMYT.make} {data.MMYT.model}
                          {data.MMYT.trim}
                        </h1>
                        <p className='mb-8 text-sm text-gray/70'>
                          Not my vehicle?&nbsp;
                          <span
                            onClick={() => {
                              setFetchedData(false)
                            }}
                            className='inline cursor-pointer btn-primary-link'
                          >
                            Try again
                          </span>
                        </p>
                        <button
                          disabled={isLoading}
                          type='button'
                          onClick={listeNewVehicleHandler}
                          className='flex items-center justify-center gap-2 text-white btn btn-primary btn-full'
                        >
                          {isLoading ? (
                            <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                          ) : (
                            <Fragment>
                              Continue
                              <MdArrowRightAlt className='text-2xl' />
                            </Fragment>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : isVINDecoderError ? (
                <div className='flex flex-wrap content-between justify-between w-full h-full gap-5'>
                  <h3 className='w-full pb-5 text-xl font-bold text-center border-b text-gray border-gray/10'>
                    Couldn&apos;t Find That VIN
                  </h3>
                  <div className='flex flex-wrap items-start justify-center w-full gap-5'>
                    <p className='mt-4 text-sm text-gray'>
                      The VIN you entered isn&#39;t in our system. Please
                      double-check and try again, or contact
                      <Link
                        to={configData.WEBSITE_URL}
                        className='text-primary mx-1'
                      >
                        Revvit
                      </Link>
                      customer service for assistance.
                    </p>
                    <div className='mt-4 ml-auto'>
                      <div
                        onClick={() => setIsVINDecoderError(false)}
                        className='cursor-pointer btn btn-primary w-fit'
                      >
                        Try again
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                !VehicleListing && (
                  <FormikProvider value={formik}>
                    <div className='mb-5 text-center title last:mb-0'>
                      <h1 className='mb-1 heading-2 text-gray'>
                        <span className=''> VIN</span> Decoder
                      </h1>
                      <p className='text-sm text-gray'>
                        Please enter your VIN to search your vehicle.
                      </p>
                    </div>
                    <form
                      onSubmit={formik.handleSubmit}
                      className='flex flex-col w-full gap-4'
                    >
                      <div className='flex items-center'>
                        <Field
                          name='vin_number'
                          placeholder='Enter VIN'
                          component={CustomInput}
                          className='form-field-white relative'
                          maxLength='17'
                        />
                        <div className='absolute right-10 top-[50%] translate-y-[-70%] lg:translate-y-[-80%]'>
                          <CustomToolTip label='Scan VIN'>
                            <Show when={loading}>
                              <div className='h-8 w-8 mx-auto animate-spin rounded-full border border-solid border-primary border-t-transparent'></div>
                            </Show>
                            <Show when={!loading}>
                              <Link to='/vin/decoder/scan'>
                                <MdCameraAlt className='w-9 h-9 text-primary' />
                              </Link>
                            </Show>
                          </CustomToolTip>
                        </div>
                      </div>
                      <div className='mb-5 text-left'>
                        <div
                          onClick={OpenModalss}
                          className='inline cursor-pointer btn-gray-link '
                        >
                          <div className='flex items-center gap-1 text-sm text-gray/50'>
                            <IoInformationCircleOutline className='text-lg' />
                            Where is my VIN?
                          </div>
                        </div>
                      </div>
                      <button
                        disabled={isLoading}
                        type='submit'
                        className='btn btn-primary btn-full'
                      >
                        {isLoading ? (
                          <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                        ) : (
                          'Look Up My vehicle'
                        )}
                      </button>
                    </form>
                  </FormikProvider>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {whereIsMyVinModel && (
        <CustomModal
          handleCloseModal={handleCloseModal}
          closeModal={whereIsMyVinModel}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={handleCloseModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap items-center justify-center gap-10'>
              <div className='flex-auto'>
                <div className='title title-gray'>
                  <h5 className='pb-3 mb-5 font-bold border-b heading-4 text-gray border-gray/10'>
                    Where Can I find My VIN?
                  </h5>
                  <div>
                    <h5 className='mb-2 font-medium subHeading-4 text-gray'>
                      What is a VIN?
                    </h5>
                    <p className='text-sm text-gray'>
                      A Vehicle Identification Number (VIN) is a unique number
                      for your vehicle. It is 17 characters long and will look
                      like this:
                    </p>
                  </div>

                  <h5 className='my-8 heading-4 text-gray/50'>
                    JTEZT17RX58088469
                  </h5>

                  <div className=''>
                    <h5 className='mb-2 font-medium subHeading-4 text-gray'>
                      Where is it located?
                    </h5>
                    <p className='text-sm text-gray'>
                      You can find your VIN on the vehicle itself and/or your
                      vehicle&#39;s documentation
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex-auto'>
                <img
                  src={whereismyvin}
                  className='block h-auto max-w-full mx-auto'
                  alt='img'
                  onError={(e) => vehicleBrokenImage(e)}
                />
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </Fragment>
  )
}

export default VinDecoder
