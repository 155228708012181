import React from 'react'
import PropTypes from 'prop-types'
import { PolarArea } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend)

// export const data = {
//   labels: ['Parking Lot', 'Live', 'Scheduled', 'Pending', 'Closed'],
//   datasets: [
//     {
//       label: 'Available',
//       data: [12, 19, 3, 5, 2],
//       backgroundColor: ['#8C0F3D', '#BA063C', '#333333', '#F67905', '#CC2423'],
//     },
//   ],
// }

export const options = {
  scales: {
    r: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
}

const Achart = ({ data }) => {
  return <PolarArea data={data} />
}

Achart.propTypes = {
  data: PropTypes.object.isRequired, // Define the structure of 'data'
}

export default Achart
