import React from 'react'
import { Link } from 'react-router-dom'

// images
import Logo from '../../../assets/logo/sidebar-logo.png'

// config
import configManager from '../../../config/configManager'

const configData = configManager()

const ForgotPasswordSuccess = () => {
  return (
    <div className='login-root'>
      <div className='grid grid-cols-1 lg:grid-cols-2 m-0 p-0 w-full lg:h-full h-auto overflow-y-auto'>
        <div className='card-left'>
          <div className='revvit-logo-wrapper'>
            <Link to={configData.WEBSITE_URL}>
              <img
                src={Logo}
                alt='Logo'
                className='login-form-logo drop-shadow-xl'
              />
            </Link>
          </div>
          <h4 className='text-xl font-medium text-white mt-10'>
            Welcome to Revvit
          </h4>
        </div>
        <div className='h-full'>
          <div className='login-card-root h-screen'>
            <div className='title text-center'>
              <h1 className='!text-primary !mb-8'>Thank You!</h1>
              <p>
                We are pleased to inform you that your request to reset your
                password has been successfully processed. A link to reset your
                password has been sent to your email address. Please check your
                inbox.
              </p>
            </div>
            <Link
              to='/login'
              className='p-2 bg-primary text-white border border-primary rounded-md px-8'
            >
              Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess
