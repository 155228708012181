import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useNavigate, useParams } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

import { AddEditVehicleDetails } from '../../../../../redux/vehicle/vehicleSlice'
import { useStepContext } from '../../../../../context/useStepContext'
import CustomToast from '../../../../../components/Custom/CustomToast'
import CustomRadioInput from '../../../../../custom/CustomRadioInput'
import CustomInput from '../../../../../custom/CustomInput'
import CustomSelect from '../../../../../custom/CustomSelect'
import CustomLoadingSection from '../../../../../components/Custom/CustomLoadingSection'

import axiosInterceptorInstance from '../../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../../utils/axios/handleRequestError'

import { Icons } from '../../../../../components/Icons'

const { TiArrowDown, MdArrowRightAlt } = Icons

const fuelTypeList = [
  { value: 'Gasoline', label: 'Gasoline' },
  // { value: 'Petrol', label: 'Petrol' },
  { value: 'Electric', label: 'Electric' },
  { value: 'Diesel', label: 'Diesel' },
]
const transmissionTypeList = [
  { value: 'Automatic', label: 'Automatic' },
  { value: 'Manual', label: 'Manual' },
  { value: 'CVT', label: 'CVT' },
]
const engineCylindersList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '6', label: '6' },
  { value: '8', label: '8' },
  { value: '10', label: '10' },
  { value: '12', label: '12' },
  { value: '14', label: '14' },
  { value: '16', label: '16' },
]
const drivetrainList = [
  { value: 'Four-Wheel Drive', label: '4WD' },
  { value: 'All-Wheel Drive', label: 'AWD' },
  { value: 'Front-Wheel Drive', label: 'FWD' },
  { value: 'Rear-Wheel Drive', label: 'RWD' },
]
const passengerList = [
  { value: '2', label: '2' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]
const doorsList = [
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
]

const VinDetails = ({ vehicleStatus }) => {
  const navigate = useNavigate()

  const { id } = useParams()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleTrimeList, setVehicleTrimeList] = useState([
    { label: '', value: '' },
  ])

  const formik = useFormik({
    onSubmit: (values, formikHelpers, additionalProps) =>
      handleSubmit(values, formikHelpers, additionalProps),
    initialValues: {
      vin_number: '',
      make: '',
      model: '',
      year: '',
      trim: '',
      odometer: '',
      fuel_type: '',
      engine: '',
      engine_cylinders: '',
      transmission_type: '',
      drivetrain: '',
      standard_seating: '',
      doors: '',
    },
  })

  const getvehicleDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/${id}`,
      )
      if (response.data.success) {
        const { year, make, model, vin_number, status } =
          response.data.vehicleDetails
        dispatch(
          AddEditVehicleDetails({ year, make, model, vin_number, status }),
        )
        formik.setValues({
          ...formik.values,
          ...response?.data?.vehicleDetails,
        })
        setIsLoading(false)
        setVehicleTrimeList([
          {
            label: response?.data?.vehicleDetails?.trim,
            value: response?.data?.vehicleDetails?.trim,
          },
        ])
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id, dispatch])

  useEffect(() => {
    getvehicleDetails()
  }, [getvehicleDetails])

  const { editStep, handleEditStepClick } = useStepContext()

  const goToNextStep = () => {
    handleEditStepClick(editStep + 1)
  }

  //eslint-disable-next-line no-unused-vars
  const handleSubmit = async (values, { resetForm }, additionalProps) => {
    try {
      setIsLoading(true)
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-details/create`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data.success) {
        if (additionalProps) {
          navigate(`/vehicle`)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          goToNextStep()
          CustomToast({
            message:
              response.data.message ||
              'Your vehicle registration has been completed successfully.',
            type: 'success',
          })
        }
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const {
    values: {
      trim,
      fuel_type,
      engine,
      engine_cylinders,
      transmission_type,
      drivetrain,
    },
  } = formik

  const vehicleSaveAsDrafthandler = () => {
    handleSubmit(formik.values, formik, 'drafthandler')
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className='grid grid-cols-1 gap-5 md:grid-cols-2'>
            <Field
              name='trim'
              label='Trim'
              placeholder='Enter Trim'
              component={CustomSelect}
              value={
                vehicleTrimeList?.find((option) => option.value === trim) ?? []
              }
              required
              option={vehicleTrimeList}
            />
            <Field
              name='fuel_type'
              label='Fuel Type'
              placeholder='Select Fuel Type'
              component={CustomSelect}
              options={fuelTypeList}
              value={
                fuelTypeList.find((option) => option.value === fuel_type) ?? []
              }
              required
            />
            <Field
              name='engine'
              label='Engine'
              placeholder='Transmission'
              component={CustomInput}
              className='form-field'
              isDisabled={engine}
              required
            />
            <Field
              name='engine_cylinders'
              label='Engine Cylinders'
              placeholder='Select Engine Cylinders'
              component={CustomSelect}
              options={engineCylindersList}
              value={
                engineCylindersList.find(
                  (option) => option.value === engine_cylinders,
                ) ?? []
              }
              isDisabled={engine_cylinders}
              required
            />
            <Field
              name='transmission_type'
              label='Transmission Type'
              component={CustomSelect}
              options={transmissionTypeList}
              value={
                transmissionTypeList.find(
                  (option) => option.value === transmission_type,
                ) ?? []
              }
              required
              isDisabled={transmission_type}
            />

            <Field
              name='drivetrain'
              label='Drivetrain'
              component={CustomRadioInput}
              options={drivetrainList}
              className='custom-radio'
              disabled={drivetrain}
              required
            />
            <Field
              name='standard_seating'
              label='Seating'
              component={CustomRadioInput}
              options={passengerList}
              required
              className='custom-radio'
            />
            <Field
              name='doors'
              label='Doors'
              component={CustomRadioInput}
              options={doorsList}
              required
              className='custom-radio'
            />
          </div>
          <div className='flex flex-col small:flex-row gap-2 justify-end mt-10'>
            {vehicleStatus === 'draft' && (
              <button
                className='next-prev-button  btn btn-primary-ico'
                type='button'
                disabled={isLoading}
                onClick={vehicleSaveAsDrafthandler}
              >
                {isLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    Save as Draft <TiArrowDown />
                  </Fragment>
                )}
              </button>
            )}
            <button
              type='submit'
              className='next-prev-button  btn btn-primary-ico'
            >
              {isLoading ? (
                <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
              ) : (
                <Fragment>
                  Save & Next
                  <MdArrowRightAlt />
                </Fragment>
              )}
            </button>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

VinDetails.propTypes = {
  vehicleStatus: PropTypes.string.isRequired,
}

export default VinDetails
