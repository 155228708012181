import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'

import { Icons } from '../../../../components/Icons'

const { PiChats } = Icons

const InvitedBuyers = ({ invitedUserList, openShowChatBox }) => {
  return (
    <div className='w-full'>
      <ul className='h-auto max-h-[388px] overflow-y-auto'>
        {invitedUserList?.length > 0 &&
          invitedUserList?.map((item, index) => {
            return (
              <li
                className='flex flex-wrap items-center gap-5 px-3 py-5 border-b border-dashed lg:px-5 bg-primary/10 border-gray border-opacity-10 last:border-b-0'
                key={index}
              >
                <span className='flex flex-wrap items-center flex-auto gap-3 max-md:w-full'>
                  <div className='flex-1 max-w-[32px]'>
                    {item?.profile_picture ? (
                      <img
                        src={item?.profile_picture}
                        className='object-cover w-8 h-8 border rounded-full border-gray border-opacity-10'
                        alt='img'
                        onError={(e) => userBrokenImages(e)}
                      />
                    ) : (
                      <div className='flex items-center justify-center w-8 h-8 text-sm rounded-full text-primary bg-gray/10'>
                        {item?.first_name
                          ? item?.first_name?.substring(0, 2)?.toUpperCase()
                          : item?.email?.substring(0, 2)?.toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className='flex-2'>
                    <span className='text-sm font-normal capitalize text-primary'>
                      <div className='flex flex-wrap items-center justify-between w-full gap-4 p-0 m-0'>
                        {item?.first_name ? (
                          <NavLink
                            to={`/buyer/profile/${item?._id}`}
                            className='flex flex-col items-start justify-start flex-auto capitalize cursor-pointer '
                          >
                            {item?.first_name}
                            <span className='text-xs font-normal normal-case text-primary'>
                              {item?.email}
                            </span>
                          </NavLink>
                        ) : (
                          <div className='flex flex-col items-start justify-start flex-auto capitalize'>
                            <span className='text-xs font-normal normal-case text-primary'>
                              {item?.email}
                            </span>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>
                  <button
                    className='ml-auto text-2xl cursor-pointer text-primary hover:text-gray'
                    onClick={() => openShowChatBox(item?._id)}
                  >
                    <PiChats />
                  </button>
                </span>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

InvitedBuyers.propTypes = {
  invitedUserList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      profile_picture: PropTypes.string,
      first_name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  openShowChatBox: PropTypes.func.isRequired,
}

export default InvitedBuyers
