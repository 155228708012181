import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Loader from '../../Loader'

const CustomLoadingSection = ({ isLoading, children }) => {
  return (
    <Fragment>
      {isLoading ? <Loader /> : <Fragment> {children}</Fragment>}
    </Fragment>
  )
}

CustomLoadingSection.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
}

export default CustomLoadingSection
