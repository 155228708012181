import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import io from 'socket.io-client'

import Loader from '../../../components/Loader'
import CustomTitle from '../../../components/Custom/CustomTitle'

import ActiveList from '../../../views/Buyer/Active/List'
import AwardedList from '../../../views/Buyer/Awarded/List'
import UpcomingList from '../../../views/Buyer/Upcoming/List'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

import configManager from '../../../config/configManager'

import Car_img from '../../../assets/car/car.png'

import 'swiper/css'
import 'swiper/css/pagination'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [activeVehicleCount, setActiveVehicleCount] = useState()
  const [upcomingVehicleCount, setUpcomingVehicleCount] = useState()
  const [awardedVehicleCount, setAwardedVehicleCount] = useState()
  const [announcementList, setAnnouncementList] = useState([
    {
      title: 'wellcome to Revvit.',
      message:
        'Choose Revvit for an unparalleled vehicle inventory management solution. With our wide range of options, quality assurance, effortless inventory management, and competitive pricing, you can keep your customers happy while growing your business.',
    },
  ])

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/vehicle-count',
      )
      if (response.data.success) {
        const result = response.data?.result
        setActiveVehicleCount(result?.live ? result?.live : 0)
        setUpcomingVehicleCount(result?.scheduled ? result?.scheduled : 0)
        setAwardedVehicleCount(result?.closed ? result?.closed : 0)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const fetchAnnouncements = async () => {
    try {
      setIsLoading(true)
      const response = await axiosInterceptorInstance.get(
        `/auth/user/list/announcements`,
      )
      setAnnouncementList(response.data.announcementList)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchAnnouncements()
    const handleVehicleExpired = () => fetchData()
    const handleVehicleOfferAccept = () => fetchData()
    const handleVehicleBackToParkingLot = () => fetchData()
    const handleVehicleLaunch = () => fetchData()
    const handleUpcomingVehicleLive = () => fetchData()
    const handleVehicleSold = () => fetchData()

    // Register socket event listeners
    socket.on('vehicle_expired', handleVehicleExpired)
    socket.on('vehicle_offer_accept', handleVehicleOfferAccept)
    socket.on('vehicle_back_to_parkingloat', handleVehicleBackToParkingLot)
    socket.on('vehicle_launch', handleVehicleLaunch)
    socket.on('upcoming_vehicle_live', handleUpcomingVehicleLive)
    socket.on('vehicle_sold', handleVehicleSold)

    // Cleanup function to remove event listeners
    return () => {
      socket.off('vehicle_expired', handleVehicleExpired)
      socket.off('vehicle_offer_accept', handleVehicleOfferAccept)
      socket.off('vehicle_back_to_parkingloat', handleVehicleBackToParkingLot)
      socket.off('vehicle_launch', handleVehicleLaunch)
      socket.off('upcoming_vehicle_live', handleUpcomingVehicleLive)
      socket.off('vehicle_sold', handleVehicleSold)
    }
  }, [])

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className='relative lg:block w-full border border-primary rounded-lg mb-8'>
            <Swiper
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className='mySwiper'
            >
              {announcementList?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='grid md:grid-cols-3 pb-12 p-6 md:p-12'>
                      <div className='col-span-2'>
                        <h4 className='text-2xl font-normal capitalize'>
                          {item?.title}
                        </h4>
                        <p className='text-sm text-silver font-normal mt-2'>
                          {item?.message?.length > 200
                            ? `${item?.message?.slice(0, 200)}...`
                            : item?.message}
                        </p>
                        <div className='mt-8'>
                          <NavLink
                            to={`/announcement/${item?._id}`}
                            className='px-12 py-4 bg-primary hover:bg-white text-sm font-normal text-white hover:text-primary border border-primary rounded-md mt-6'
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <div className='absolute right-0 bottom-0 hidden md:block'>
              <img src={Car_img} alt='car-img' className='h-[170px]' />
            </div>
          </div>
          {(activeVehicleCount ||
            (activeVehicleCount === 0 &&
              upcomingVehicleCount === 0 &&
              awardedVehicleCount === 0)) && (
            <Fragment>
              <ActiveList />
            </Fragment>
          )}
          {(upcomingVehicleCount ||
            (activeVehicleCount === 0 &&
              upcomingVehicleCount === 0 &&
              awardedVehicleCount === 0)) && (
            <Fragment>
              <UpcomingList />
            </Fragment>
          )}
          {(awardedVehicleCount ||
            (activeVehicleCount === 0 &&
              upcomingVehicleCount === 0 &&
              awardedVehicleCount === 0)) && (
            <Fragment>
              <CustomTitle customTitle='Awarded Vehicles' />
              <AwardedList />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Home
