import { toast } from 'react-toastify'

const CustomToast = ({ message, type }) => {
  const tosterType = type || 'error'

  return toast[tosterType](message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  })
}

export default CustomToast
