import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom'

// components
import CustomInput from '../../../custom/CustomInput'

// utils
import axiosAuthInstance from '../../../utils/axios/axiosAuthInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

// config
import configManager from '../../../config/configManager'

// images
import Logo from '../../../assets/logo/sidebar-logo.png'

const companyData = configManager()

const schema = Yup.object().shape({
  password: Yup.string()
    .transform((value) => value.trim())
    .required('Please enter your Password')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long',
    ),
  confirmpassword: Yup.string()
    .transform((value) => value.trim())
    .required('Please enter your confirm password')
    .oneOf(
      [Yup.ref('password'), null],
      'Password and confirm password are not the same',
    ),
})

const ResetPassword = () => {
  const { id } = useParams()
  const nivagation = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true)
    try {
      const response = await axiosAuthInstance.post(
        `/password/reset/${id}`,
        values,
      )
      if (response.data.success) {
        nivagation('/password/reset/success')
      }

      setIsLoading(false)
      resetForm()
    } catch (error) {
      if (error.response) {
        setIsError(error.response.data.message)
      } else {
        setIsError(error.message)
      }
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      password: '',
      confirmpassword: '',
    },
  })

  const errorRemovehandler = () => {
    isError && setIsError('')
  }

  useEffect(() => {
    const isLinkExpired = async () => {
      try {
        const response = await axiosAuthInstance.post(
          `password/forgot/is-expired/${id}`,
        )

        if (response.data.success) {
          if (response.data.isExpired) {
            nivagation('/link/expired')
          }
        }
      } catch (error) {
        handleRequestError(error)
      }
    }

    isLinkExpired()
  }, [id, nivagation])

  useEffect(() => {
    const isLocalStorageCleared = localStorage.getItem('isLocalStorageCleared')
    if (!isLocalStorageCleared) {
      localStorage.clear()
      localStorage.setItem('isLocalStorageCleared', 'true')
    }
  }, [])

  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <div className='card-left'>
          <div className='revvit-logo-wrapper'>
            <Link to={companyData.WEBSITE_URL}>
              <img
                src={Logo}
                alt='Logo'
                className='login-form-logo drop-shadow-xl'
              />
            </Link>
          </div>
          <h4 className='mt-10 text-xl font-medium text-white'>
            Welcome to Revvit
          </h4>
        </div>
        <div className='h-full'>
          <div className='h-screen login-card-root'>
            <FormikProvider value={formik}>
              <div className='text-center title'>
                <h1>Reset Password</h1>
                <p>Enter your new password</p>
              </div>
              <form onSubmit={formik.handleSubmit} className='w-full'>
                <div className='form-field-wrapper'>
                  <Field
                    type={`${showPassword ? 'text' : 'password'}`}
                    name='password'
                    placeholder='Password*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                    passwordshint
                    passwords
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                  <Field
                    type={`${showPassword ? 'text' : 'password'}`}
                    name='confirmpassword'
                    placeholder='Confirm password*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                  />
                </div>
                {isError ? (
                  <div className='flex items-center justify-center mt-2 text-base text-red'>
                    {isError}
                  </div>
                ) : (
                  ''
                )}
                <div className='mt-8'>
                  <button
                    disabled={isLoading}
                    type='submit'
                    className='w-full p-2 text-base font-medium text-white border rounded-md bg-primary hover:bg-transparent border-primary hover:text-primary'
                  >
                    {isLoading ? (
                      <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                    ) : (
                      'Continue'
                    )}
                  </button>
                </div>
                <div className='flex justify-center mt-4'>
                  <h4 className='text-base font-normal'>
                    New on Revvit?&nbsp;
                    <NavLink to='/signup'>
                      <span className='font-medium text-primary hover:underline'>
                        Join now
                      </span>
                    </NavLink>
                  </h4>
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
