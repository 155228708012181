import React, { Fragment, useCallback, useEffect, useState } from 'react'

import moment from 'moment'
import html2pdf from 'html2pdf.js'

import { useParams } from 'react-router-dom'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

import { Icons } from '../../../components/Icons'

const { MdFileDownload } = Icons

const Document = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()
  const [awardedDetails, setAwardedDetails] = useState([])
  const [sellerDetails, setSellerDetails] = useState()
  const [taxDetails, setTaxDetails] = useState()
  const [pickupLocationDetails, setPickupLocationDetails] = useState()
  const [awardedAt, setAwardedAt] = useState(null)

  const fetchBillOfsaleDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/document/${id}`,
      )
      if (response.data.success) {
        setvehicleDetails(response.data.vehicleDetails)
        setAwardedDetails(response.data.WonOfferDetails)
        setSellerDetails(response.data.sellerDetails)
        setTaxDetails(response.data.taxDetails)
        setPickupLocationDetails(response.data.pickupLocation)
        setAwardedAt(response.data.awardedAt)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchBillOfsaleDetails()
  }, [fetchBillOfsaleDetails])

  const openPrintDialog = () => {
    const input = document.getElementById('pdf-content')
    html2pdf(input, {
      margin: 0.2,
      filename: `Bill of sale ${vehicleDetails?.vin_number}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 300, letterRendering: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    })
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <section className='details-single lg:pb-0'>
        <div className='flex flex-wrap items-end justify-start w-full p-0 m-0 mb-10 gap-x-8'>
          <CustomTitle backBtn customTitle='Bill of sale' />
        </div>
        <div className='justify-end w-full mb-5 btn-custom'>
          <button className='btn btn-primary-ico' onClick={openPrintDialog}>
            <MdFileDownload /> Download
          </button>
        </div>
        <div id='pdf-content' className='flex flex-col w-full gap-10'>
          <div className='flex flex-col w-full gap-3 pb-3 border-b sm:justify-center sm:items-center border-gray/10'>
            <h2 className='capitalize heading-2 text-primary'>
              Vehicle Bill Of Sale
            </h2>
            <span className='self-start'>
              Date: {moment(awardedAt).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className='flex flex-col items-start justify-start gap-8'>
            <h3 className='capitalize heading-3 text-gray'>
              Terms and Conditions:
            </h3>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Title Transfer
              </h5>
              <p className='text-sm text-gray'>
                A copy of the title will be uploaded into the Revvit App to
                initiate payment prior to mailing the physical copy of ownership
                to the Buying dealership. The Seller shall transfer title of the
                vehicle to the Buyer upon receipt of full payment. All titles
                must be sent to the buyer&apos;s mailing address via priority
                mail upon completion of the sale. Titles must be in original,
                hard copy form in the selling dealership&apos;s name. The Seller
                warrants that they have clear and marketable title to the
                vehicle, free of any liens or encumbrances.
              </p>
            </div>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Condition of Vehicle:
              </h5>
              <p className='text-sm text-gray'>
                The Buyer acknowledges that they have examined the vehicle and
                accepts it &apos;as-is.&apos; The Seller makes no warranties,
                express or implied, regarding the condition of the vehicle.
              </p>
            </div>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Funding:
              </h5>
              <p className='text-sm text-gray'>
                All vehicles purchased by the buyers on this app will submit
                payment to the selling dealership within 48 hours of purchasing
                the vehicle, unless an alternative payment method is agreed upon
                by the selling and buying dealers after the sale. Revvit Inc.
                $200 fee will be automatically charged to the banking info
                entered upon setup to the app when the vehicle is aware to be
                sold to the buyers offer. This fee is non-refundable upon a
                cancellation of sale between the buyer and seller.
              </p>
            </div>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Revvit Inc&apos;s Role:
              </h5>
              <p className='text-sm text-gray'>
                The Parties acknowledge that Revvit Inc. serves solely as a
                platform to connect Buyers and Sellers. All sales transactions,
                including but not limited to negotiations, payment, and title
                transfer, are conducted directly between the Buyer and the
                Seller. Revvit Inc. assumes no responsibility for any issues
                arising from the transaction.
              </p>
            </div>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Arbitration:
              </h5>
              <p className='text-sm text-gray'>
                Any disputes or disagreements arising from this transaction,
                including but not limited to issues with payment or titling,
                shall be resolved solely between the Buyer and the Seller.
                Revvit Inc. shall not be liable for any such disputes and shall
                not be involved in any arbitration proceedings.
              </p>
            </div>
            <div>
              <h5 className='mb-3 font-medium capitalize subHeading-5'>
                Entire Agreement:
              </h5>
              <p className='text-sm text-gray'>
                This Agreement constitutes the entire understanding between the
                Parties and supersedes any prior agreements or understandings,
                whether written or oral, relating to the subject matter herein.
              </p>
            </div>
          </div>
          <div className='w-full pt-5 border-t border-gray/10'>
            <p className='text-sm text-gray'>
              <b>IN WITNESS WHEREOF</b>, the Parties have executed this
              Agreement as of the date first above written.
            </p>
          </div>

          <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 gap-y-8 gap-x-10'>
            <div className='w-full'>
              <div className='items-start justify-start w-full max-md:flex max-md:flex-col md:columns-2 gap-y-6'>
                <ul className='w-full space-y-3'>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Seller Name:</p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.first_name}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Email:</p>
                    <span className='font-medium flex-1 !lowercase'>
                      {sellerDetails?.email}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Phone:</p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.phone}
                    </span>
                  </li>
                </ul>
                <ul className='w-full space-y-3'>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Buyer Name:</p>
                    <span className='flex-1 font-medium'>
                      {awardedDetails?.buyer_id?.first_name}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Email:</p>
                    <span className='font-medium flex-1 !lowercase'>
                      {awardedDetails?.buyer_id?.email}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Phone:</p>
                    <span className='flex-1 font-medium'>
                      {awardedDetails?.buyer_id?.phone}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='grid w-full small:grid-cols-2 gap-4'>
              <div>
                <h4 className='px-4 py-2 mb-4 font-medium capitalize subHeading-4 text-gray bg-gray/40'>
                  Vehicle Details
                </h4>
                <div>
                  <ul className='items-start justify-start w-full gap-2 max-md:flex max-md:flex-col'>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>VIN:</p>
                      <span className='flex-1 font-medium'>
                        {vehicleDetails?.vin_number}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>Make:</p>
                      <span className='flex-1 font-medium'>
                        {vehicleDetails?.make}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>Model:</p>
                      <span className='flex-1 font-medium'>
                        {vehicleDetails?.model}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>Year: </p>
                      <span className='flex-1 font-medium'>
                        {vehicleDetails?.year}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>Odometer:</p>
                      <span className='flex-1 font-medium normal-case'>
                        {vehicleDetails?.odometer}&nbsp;
                        {vehicleDetails?.mileage_unit}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h4 className='px-4 py-2 mb-4 font-medium capitalize subHeading-4 text-gray bg-gray/40'>
                  Pickup location
                </h4>
                <div>
                  <ul className='items-start justify-start w-full gap-2 max-md:flex max-md:flex-col'>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>Street:</p>
                      <span className='flex-1 font-medium'>
                        {pickupLocationDetails?.street}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>City:</p>
                      <span className='flex-1 font-medium'>
                        {pickupLocationDetails?.city}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>province:</p>
                      <span className='flex-1 font-medium'>
                        {pickupLocationDetails?.province}
                      </span>
                    </li>
                    <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                      <p className='flex-1'>postal code:</p>
                      <span className='flex-1 font-medium'>
                        {pickupLocationDetails?.postcode}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='w-full'>
              <h4 className='px-4 py-2 mb-4 font-medium capitalize subHeading-4 text-gray bg-gray/40'>
                Seller Bank Details
              </h4>
              <div>
                <ul className='items-start justify-start w-full gap-2 max-md:flex max-md:flex-col md:columns-2'>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Bank Name:</p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_bank_name || '-'}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Bank Branch Name:</p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_bank_branch_name || '-'}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Account Holder Name:</p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_account_holder_name || '-'}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Routing Number: </p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_routing_number || '-'}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Bank Account Number: </p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_account_number || '-'}
                    </span>
                  </li>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:mb-2'>
                    <p className='flex-1'>Bank Address: </p>
                    <span className='flex-1 font-medium'>
                      {sellerDetails?.user_financial_details
                        ?.seller_bank_address || '-'}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='w-full'>
              <h4 className='px-4 py-2 mb-4 font-medium capitalize subHeading-4 text-gray bg-gray/40'>
                Price Details
              </h4>
              <div>
                <ul className='flex flex-col items-start justify-start w-full gap-2'>
                  <li className='flex w-full gap-2 text-sm capitalize text-gray md:w-1/2'>
                    <p className='flex-1'>Purchase price:</p>
                    <span className='flex-1 font-medium'>
                      ${taxDetails?.price || 0}
                    </span>
                  </li>
                  {taxDetails?.taxes.length > 0 && (
                    <Fragment>
                      {taxDetails.taxes.map((tax, index) => (
                        <li
                          key={index}
                          className='flex w-full gap-2 text-sm capitalize text-gray md:w-1/2'
                        >
                          <p className='flex-1 uppercase'>{tax.tax_name}:</p>
                          <span className='flex-1 font-medium'>
                            ${tax.tax_amount}
                          </span>
                        </li>
                      ))}
                    </Fragment>
                  )}
                  <li className='flex w-full gap-2 pt-2 text-sm capitalize border-t text-gray md:w-1/2 border-gray/40'>
                    <p className='flex-1'>Total:</p>
                    <span className='flex-1 font-medium'>
                      ${taxDetails?.total}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CustomLoadingSection>
  )
}

export default Document
