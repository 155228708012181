import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import { useStepContext } from '../../../../context/useStepContext'

const Add = () => {
  const [searchParams] = useSearchParams()

  const { step, steps } = useStepContext()

  const vehicle = searchParams.get('vehicle')
  const data = useSelector((store) => store.vehicle)

  return (
    <Fragment>
      <CustomTitle backBtn backBtncount={vehicle ? 2 : 1} />
      <div className='flex flex-col items-center justify-between w-full gap-8 large:flex-row'>
        <div className='flex w-full my-10 sm:items-center max-sm:flex-col sm:flex-wrap gap-y-3'>
          <h2 className='text-xl font-medium sm:mr-4 sm:pr-4 sm:border-r large:text-2xl text-primary sm:text-gray border-gray/30'>
            {data?.attributes?.year}&nbsp;
            {data?.attributes?.make}&nbsp;
            {data?.attributes?.model}
          </h2>
          <h5 className='font-medium subHeading-5 text-gray/90'>
            VIN :&nbsp;{data?.vin}
          </h5>
        </div>
      </div>

      <div className='flex items-center add-tabs lg:justify-start'>
        {steps.map((s) => (
          <div
            key={s.stepNumber}
            className={`tabs text-center text-sm lg:text-base font-normal sm:py-3 sm:px-5 cursor-default ${
              step === s.stepNumber && 'active'
            }`}
          >
            {s.label}
          </div>
        ))}
      </div>

      <div className='p-5 border rounded-tl-none border-gray border-opacity-10 rounded-xl max-md:rounded-tr-none md:px-8 md:py-10'>
        {steps.map((s) =>
          step === s.stepNumber ? <s.component key={s.stepNumber} /> : null,
        )}
      </div>
    </Fragment>
  )
}

export default Add
