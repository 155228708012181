import React from 'react'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'

const CustomRadioInput = ({
  field,
  form: { touched, errors },
  label,
  options,
  required,
  disabled,
  className,
  radio,
  ...props
}) => {
  const isInvalid = !!touched[field.name] && !!errors[field.name]

  return (
    <div className='w-full md:w-auto'>
      <h5
        className={`flex items-center gap-1 text-base font-medium text-gray mb-3 md:pb-0 pb-3 border-b border-gray border-opacity-10 md:border-0 ${
          radio ? 'text-xl font-medium' : 'text-sm font-normal'
        }`}
      >
        {label}
        {required && <span className='text-danger'>*</span>}
      </h5>
      <div className='flex items-center sm:justify-start flex-wrap gap-3'>
        {options.map((option) => (
          <div key={option.value}>
            <input
              type='radio'
              {...field}
              {...props}
              value={option.value}
              checked={field.value === option.value}
              className='hidden'
              id={`radio-${field.name}-${option.value}`}
              disabled={disabled}
              aria-invalid={isInvalid ? 'true' : 'false'}
            />
            <label
              htmlFor={`radio-${field.name}-${option.value}`}
              className={`${className} ${
                field.value === option.value
                  ? 'bg-primary/20 text-primary font-medium'
                  : 'bg-transparent text-gray text-opacity-55'
              }`}
            >
              <span>{option.label}</span>
            </label>
          </div>
        ))}
      </div>
      <ErrorMessage
        name={field.name}
        component='div'
        className='text-xs font-normal text-danger'
      />
    </div>
  )
}

CustomRadioInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.string,
  className: PropTypes.string,
  radio: PropTypes.bool,
}

export default CustomRadioInput
