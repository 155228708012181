import { combineReducers, configureStore } from '@reduxjs/toolkit'

import userReducer from './user/userSlice.js'
import vehicleReducer from './vehicle/vehicleSlice.js'
import triggerSlice from './trigger/triggerSlice.js'

const rootReducer = combineReducers({
  user: userReducer,
  vehicle: vehicleReducer,
  trigger: triggerSlice,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
