import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import ChatSidebar from '../components/ChatSidebar'
import SearchSidebar from '../components/SearchSidebar'
import ProfileSidebar from '../components/ProfileSidebar'
import NotificationSidebar from '../components/NotificationSidebar'

import { handleRequestError } from '../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../utils/axios/axiosInterceptorInstance'
import { getFirebaseToken } from '../utils/firebaseNotification/firebaseConfig'

import {
  requesToSwitchRole,
  requesToSwitchRoleRouter,
} from '../redux/user/userSlice'

const DefaultLayout = () => {
  const notificationref = useRef(null)
  const profileref = useRef(null)
  const searchref = useRef(null)

  const chatRef = useRef(null)

  const dispatch = useDispatch()

  const StoreNotificationToken = async (token) => {
    try {
      await axiosInterceptorInstance.post(`/push/notification/store/token`, {
        token,
      })
    } catch (error) {
      handleRequestError(error)
    }
  }

  const handleGetFirebaseToken = useCallback(() => {
    getFirebaseToken()
      .then((firebaseToken) => {
        StoreNotificationToken(firebaseToken)
      })
      .catch((error) => {
        handleRequestError(error)
      })
  }, [])

  useEffect(() => {
    handleGetFirebaseToken()
  }, [handleGetFirebaseToken])

  const params = useParams()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchSidebarOpen, setSearchSidebarOpen] = useState(false)
  const [notificationSidebarOpen, setNotificationSidebarOpen] = useState(false)
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false)

  const [chatSidebarOpen, setChatSidebarOpen] = useState(false)

  useEffect(() => {
    setSearchSidebarOpen(false)
    setNotificationSidebarOpen(false)
    setProfileSidebarOpen(false)
    setSidebarOpen(false)
    setChatSidebarOpen(false)
  }, [params])

  const { trigger: update } = useSelector((store) => store.user)
  const [userDetails, setUserDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(`/auth/user/details`)
      if (response.data.success) {
        setUserDetails(response.data.userdetails)

        if (response.data.userdetails) {
          if (
            !response.data.userdetails.is_buyer_details_completed ||
            !response.data.userdetails.is_seller_details_completed
          ) {
            dispatch(requesToSwitchRoleRouter({ status: true }))
          }

          dispatch(
            requesToSwitchRole({
              switchRoleRequest:
                response.data.userdetails.is_buyer_details_completed ||
                response.data.userdetails.is_seller_details_completed
                  ? response.data.userdetails.switch_role_request
                    ? false
                    : true
                  : false,
            }),
          )
        }
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData, update])

  return (
    <div className='dark:bg-boxdark-2 dark:text-bodydark'>
      <div className='flex h-screen overflow-hidden'>
        <Sidebar
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          userDetails={userDetails}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          setSearchSidebarOpen={setSearchSidebarOpen}
          searchSidebarOpen={searchSidebarOpen}
          setNotificationSidebarOpen={setNotificationSidebarOpen}
          notificationSidebarOpen={notificationSidebarOpen}
          setProfileSidebarOpen={setProfileSidebarOpen}
          profileSidebarOpen={profileSidebarOpen}
          notificationref={notificationref}
          searchref={searchref}
          profileref={profileref}
          chatRef={chatRef}
          chatSidebarOpen={chatSidebarOpen}
          setChatSidebarOpen={setChatSidebarOpen}
        />

        <SearchSidebar
          setSearchSidebarOpen={setSearchSidebarOpen}
          searchSidebarOpen={searchSidebarOpen}
          sidebarOpen={sidebarOpen}
          searchref={searchref}
        />

        <ProfileSidebar
          userDetails={userDetails}
          setProfileSidebarOpen={setProfileSidebarOpen}
          profileSidebarOpen={profileSidebarOpen}
          profileref={profileref}
        />

        <NotificationSidebar
          setNotificationSidebarOpen={setNotificationSidebarOpen}
          notificationSidebarOpen={notificationSidebarOpen}
          notificationref={notificationref}
          setIsLoadingForSwitchRole={setIsLoading}
        />

        <ChatSidebar
          setChatSidebarOpen={setChatSidebarOpen}
          chatSidebarOpen={chatSidebarOpen}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          chatRef={chatRef}
        />

        <div className='relative flex flex-col flex-1 w-full h-screen'>
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setNotificationSidebarOpen={setNotificationSidebarOpen}
            notificationSidebarOpen={notificationSidebarOpen}
          />
          <main className='flex flex-col bg-white h-[calc(100dvh_-_112px)] xs:h-[calc(100dvh_-_104px)] lg:h-screen overflow-y-auto overflow-x-hidden w-full lg:rounded-tl-3xl'>
            <div className='w-full mx-auto p-4 md:p-8'>
              <Outlet />
            </div>
            <Footer />
          </main>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout
