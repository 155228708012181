import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

const SellerProfile = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [userDetails, setUserDetails] = useState(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/auth/user/details/seller/${id}`,
      )
      if (response.data.success) {
        setUserDetails(response.data.userdetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <div>
        <div className='flex flex-col w-full gap-10'>
          <div className='w-full flex flex-col items-start justify-start gap-2 p-0 m-0'>
            <div className='w-full'>
              <CustomTitle customTitle='Seller Profile' backBtn />
            </div>
          </div>
          <div className='relative w-full flex flex-wrap justify-start gap-10 p-0 m-0'>
            <div className='w-full large:max-w-[calc(50%_-_20px)]'>
              <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 border shadow-xl large:mb-10 border-gray/10 rounded-xl bg-primary/5 last:mb-0'>
                <div className='block mb-10'>
                  {userDetails?.profile_picture ? (
                    <img
                      src={userDetails?.profile_picture}
                      className='object-cover w-16 h-16 p-0 border rounded-full border-gray/10'
                      alt='img'
                      onError={(e) => userBrokenImages(e)}
                    />
                  ) : (
                    <div className='flex items-center justify-center object-cover w-16 h-16 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                      {userDetails?.first_name?.substring(0, 2)?.toUpperCase()}
                    </div>
                  )}
                </div>

                <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                  <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                    <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                      <div className='title title-primary'>
                        <h2 className='font-medium uppercase subHeading-4'>
                          Personal Information
                        </h2>
                      </div>
                      <div className='content'>
                        <p>
                          Joined&nbsp;
                          {moment(
                            userDetails?.user_dealer_details?.created_at,
                          ).format('YYYY-MM-DD hh:mm a')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>First Name</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.first_name}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>Last Name</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.last_name}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>Email</h5>
                    </div>
                    <div className='content'>
                      <p>{userDetails?.email}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>Phone Number</h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='w-full large:max-w-[calc(50%_-_20px)]'>
              <div className='flex flex-wrap items-center justify-center w-full p-8 m-0 border large:mb-10 border-gray/10 rounded-xl last:mb-0'>
                <div className='block mb-10'>
                  {userDetails?.user_dealer_details?.dealer_logo ? (
                    <img
                      src={userDetails?.user_dealer_details?.dealer_logo}
                      className='object-cover w-12 p-0 border rounded-full h-w-12 border-gray/10'
                      alt='img'
                      onError={(e) => userBrokenImages(e)}
                    />
                  ) : (
                    <div className='flex items-center justify-center object-cover w-16 h-16 p-0 text-2xl rounded-full bg-gray/40 text-primary'>
                      {userDetails?.user_dealer_details?.name
                        ?.substring(0, 2)
                        ?.toUpperCase()}
                    </div>
                  )}
                </div>

                <div className='flex flex-wrap items-start justify-start w-full gap-8 p-0 m-0'>
                  <div className='flex flex-wrap items-start justify-between w-full gap-5 p-0 m-0'>
                    <div className='title title-primary'>
                      <h2 className='font-medium uppercase subHeading-4'>
                        Dealer Information
                      </h2>
                    </div>
                    <div className='content'>
                      <p>
                        Joined&nbsp;
                        {moment(
                          userDetails?.user_dealer_details?.created_at,
                        ).format('YYYY-MM-DD hh:mm a')}
                      </p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>
                        Dealership Name/Business Name
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.user_dealer_details?.name}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>
                        Primary Contact Person
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.user_dealer_details?.contact_person}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>
                        Contact Number
                      </h5>
                    </div>
                    <div className='capitalize content'>
                      <p>{userDetails?.user_dealer_details?.phone}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>
                        Contact Email
                      </h5>
                    </div>
                    <div className='content'>
                      <p>{userDetails?.user_dealer_details?.email}</p>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                    <div className='capitalize title title-gray'>
                      <h5 className='font-medium subHeading-5'>Website URL</h5>
                    </div>
                    <div className='content'>
                      <p>{userDetails?.user_dealer_details?.website}</p>
                    </div>
                  </div>

                  <div className='w-full'>
                    <div className='title title-gray capitalize mb-5'>
                      <h5 className='subHeading-5 font-medium'>Address</h5>
                    </div>
                    <ul className='w-full flex flex-col items-start justify-start gap-10 p-0 m-0'>
                      {userDetails?.user_dealer_details?.address.map(
                        (address, index) => {
                          return (
                            <li className='w-full' key={index}>
                              <div className='flex flex-wrap items-start justify-center w-full gap-8 p-0 m-0'>
                                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                                  <div className='capitalize title title-gray'>
                                    <h5 className='font-medium subHeading-5'>
                                      street:
                                    </h5>
                                  </div>
                                  <div className='capitalize content'>
                                    <p>{address?.street}</p>
                                  </div>
                                </div>
                                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                                  <div className='capitalize title title-gray'>
                                    <h5 className='font-medium subHeading-5'>
                                      city:
                                    </h5>
                                  </div>
                                  <div className='capitalize content'>
                                    <p>{address?.city}</p>
                                  </div>
                                </div>

                                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                                  <div className='capitalize title title-gray'>
                                    <h5 className='font-medium subHeading-5'>
                                      province:
                                    </h5>
                                  </div>
                                  <div className='capitalize content'>
                                    <p>{address?.province}</p>
                                  </div>
                                </div>

                                <div className='w-full flex flex-wrap xs:flex-row justify-between items-center m-0 p-0 pb-2 border-[0px] border-b border-gray/10 last:border-0'>
                                  <div className='capitalize title title-gray'>
                                    <h5 className='font-medium subHeading-5'>
                                      Postal Code:
                                    </h5>
                                  </div>
                                  <div className='capitalize content'>
                                    <p>{address?.postcode}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        },
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLoadingSection>
  )
}

export default SellerProfile
