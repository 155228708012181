import CustomToast from '../../components/Custom/CustomToast'

export const handleRequestError = (error) => {
  const env = process.env.REACT_APP_ENV || 'production' //eslint-disable-line no-undef

  if (env === 'production') return

  if (error.isErrorHandled) return
  console.log('error:,', error)
  if (error.response) {
    CustomToast({
      message: error.response.data.message
        ? error.response.data.message
        : 'Server Error!',
      type: 'error',
    })
  } else {
    CustomToast({
      message: 'Server Error!',
      type: 'error',
    })
  }
}
