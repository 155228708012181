import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  reFetchNotification: false,
}

const triggerSlice = createSlice({
  name: 'trigger',
  initialState,
  reducers: {
    triggerNotification: (state) => {
      state.reFetchNotification = !state.reFetchNotification
    },
  },
})

export const { triggerNotification } = triggerSlice.actions

export default triggerSlice.reducer
