// md
import {
  MdCameraAlt,
  MdArrowRightAlt,
  MdOutlineClose,
  MdFileDownload,
  MdOutlineKeyboardBackspace,
  MdDelete,
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  MdRemoveRedEye,
  MdEdit,
  MdOutlineLocalOffer,
  MdOutlineAccessTime,
  MdOutlineAddPhotoAlternate,
  MdDeleteOutline,
  MdOutlineCheck,
  MdOutlineVerifiedUser,
  MdAdd,
  MdOutlinePhoneAndroid,
  MdChat,
  MdFileUpload,
  MdSend,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdLogout,
  MdPayment,
  MdOutlineNotificationsActive,
  MdDoneAll,
  MdOutlineDoneAll,
  MdOutlineDirectionsCarFilled,
} from 'react-icons/md'

// ti
import { TiMediaPlayOutline, TiArrowDown } from 'react-icons/ti'

// io5
import {
  IoInformationCircleOutline,
  IoDocumentTextSharp,
  IoSendSharp,
  IoAttach,
  IoCloseCircle,
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  IoArrowUp,
  IoSettingsOutline,
  IoArrowDownOutline,
  IoArrowUpOutline,
  IoCloseOutline,
  IoSearchSharp,
  IoRemoveCircleOutline,
  IoSearch,
  IoCarOutline,
  IoTimerOutline,
  IoAddCircleOutline,
} from 'react-icons/io5'

// io
import { IoIosAttach, IoMdSend, IoMdHourglass } from 'react-icons/io'

// pi
import {
  PiChats,
  PiCylinderThin,
  PiSteeringWheel,
  PiUserCircleDuotone,
} from 'react-icons/pi'

// cg
import { CgSpinner, CgLivePhoto } from 'react-icons/cg'

// lu
import {
  LuCalendarDays,
  LuFuel,
  LuUsers2,
  LuParkingCircle,
  LuBell,
  LuListTodo,
} from 'react-icons/lu'

// tb
import {
  TbEngine,
  TbLivePhoto,
  TbArrowsDiagonalMinimize2,
} from 'react-icons/tb'

// vsc
import { VscTypeHierarchy } from 'react-icons/vsc'

// bi
import { BiSolidColorFill } from 'react-icons/bi'

// gi
import { GiCarDoor, GiGearStickPattern, GiDiamondTrophy } from 'react-icons/gi'

// gr
import { GrDocumentText } from 'react-icons/gr'

//fi
import {
  FiArrowUpRight,
  FiUserCheck,
  FiPlusCircle,
  FiUpload,
  FiClock,
  FiDownload,
  FiLogIn,
  FiMinimize2,
} from 'react-icons/fi'

// ai
import { AiOutlineDollar } from 'react-icons/ai'

// ri
import {
  RiErrorWarningLine,
  RiMenu2Fill,
  RiAlertFill,
  RiEdit2Line,
  RiHome6Line,
} from 'react-icons/ri'

// hi
import { HiOutlineStatusOnline } from 'react-icons/hi'

// bs
import {
  BsThreeDotsVertical,
  BsCheckAll,
  BsFillSendCheckFill,
} from 'react-icons/bs'

// fa
import {
  FaAngleDown,
  FaAngleUp,
  FaExclamationTriangle,
  FaChevronRight,
  FaRegEye,
  FaRegEyeSlash,
  FaCar,
  FaUser,
  FaHeart,
  FaRegHeart,
  FaTrash,
} from 'react-icons/fa'

// fa6
import { FaCheck, FaRegCircleCheck, FaAngleRight } from 'react-icons/fa6'

// go
import { GoArrowLeft } from 'react-icons/go'

// im
import { ImHammer2 } from 'react-icons/im'

// ci
import { CiParking1, CiMinimize1, CiMaximize1 } from 'react-icons/ci'

export const Icons = {
  MdCameraAlt,
  MdArrowRightAlt,
  MdOutlineClose,
  MdFileDownload,
  MdOutlineKeyboardBackspace,
  IoDocumentTextSharp,
  IoSendSharp,
  IoAttach,
  TiMediaPlayOutline,
  IoInformationCircleOutline,
  PiChats,
  CgSpinner,
  IoCloseCircle,
  MdDelete,
  IoIosAttach,
  IoMdSend,
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  LuCalendarDays,
  LuFuel,
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  PiCylinderThin,
  PiSteeringWheel,
  TbEngine,
  VscTypeHierarchy,
  BiSolidColorFill,
  GiCarDoor,
  GiGearStickPattern,
  GrDocumentText,
  LuUsers2,
  FiArrowUpRight,
  LuParkingCircle,
  MdRemoveRedEye,
  IoArrowUp,
  IoSettingsOutline,
  MdEdit,
  MdOutlineLocalOffer,
  MdOutlineAccessTime,
  MdOutlineAddPhotoAlternate,
  FiUserCheck,
  AiOutlineDollar,
  RiErrorWarningLine,
  HiOutlineStatusOnline,
  FiPlusCircle,
  BsThreeDotsVertical,
  RiMenu2Fill,
  LuBell,
  TiArrowDown,
  FiUpload,
  MdDeleteOutline,
  MdOutlineCheck,
  FaAngleDown,
  FaAngleUp,
  IoArrowDownOutline,
  IoArrowUpOutline,
  MdOutlineVerifiedUser,
  MdAdd,
  FiClock,
  IoCloseOutline,
  IoSearchSharp,
  FaCheck,
  FaExclamationTriangle,
  GoArrowLeft,
  RiAlertFill,
  FaChevronRight,
  FiDownload,
  MdOutlinePhoneAndroid,
  FaRegEye,
  FaRegEyeSlash,
  FaCar,
  FaUser,
  MdChat,
  MdFileUpload,
  MdSend,
  FaRegCircleCheck,
  FaHeart,
  FaRegHeart,
  FaTrash,
  IoRemoveCircleOutline,
  ImHammer2,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  RiEdit2Line,
  MdLogout,
  MdPayment,
  MdOutlineNotificationsActive,
  MdDoneAll,
  MdOutlineDoneAll,
  BsCheckAll,
  BsFillSendCheckFill,
  IoSearch,
  IoCarOutline,
  IoTimerOutline,
  IoAddCircleOutline,
  FiLogIn,
  CiParking1,
  RiHome6Line,
  TbLivePhoto,
  CgLivePhoto,
  IoMdHourglass,
  FaAngleRight,
  GiDiamondTrophy,
  PiUserCircleDuotone,
  LuListTodo,
  MdOutlineDirectionsCarFilled,
  CiMinimize1,
  CiMaximize1,
  FiMinimize2,
  TbArrowsDiagonalMinimize2,
}
