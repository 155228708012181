import { createSlice } from '@reduxjs/toolkit'

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('vehicleDetails')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  const serializedState = JSON.stringify(state)
  localStorage.setItem('vehicleDetails', serializedState)
}

const initialState = loadState() || {
  hsa_data: false,
  MMYT: {},
  vin: [],
  selections: [],
  attributes: [],
  equipments: [],
  photos: [],
  editvehicles: [],
  status: null,
}

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setVehicleDetails: (state, action) => {
      const { year, make, model, trim } = action.payload.attributes
      state.hsa_data = true
      state.vin = action.payload?.input?.vin || {}
      state.MMYT = { year, make, model, trim }
      state.selections = action.payload?.selections || []
      state.attributes = action.payload?.attributes || []
      state.equipments = action.payload?.equipments || []
      state.photos = action.payload?.photos || []
      state.status = action.payload?.status
    },

    RemoveVehicleDetails: (state) => {
      state.hsa_data = false
      state.vin = []
      state.MMYT = {}
      state.selections = []
      state.attributes = []
      state.equipments = []
      state.photos = []
      state.status = null

      localStorage.removeItem('vehicleDetails')
    },

    AddEditVehicleDetails: (state, action) => {
      state.editvehicles = action.payload || []
    },

    RemoveEditVehicleDetails: (state) => {
      state.editvehicles = []
      localStorage.removeItem('vehicleDetails')
    },
  },
})

export const {
  setVehicleDetails,
  RemoveVehicleDetails,
  AddEditVehicleDetails,
  RemoveEditVehicleDetails,
} = vehicleSlice.actions

const persistedReducer = (state, action) => {
  const newState = vehicleSlice.reducer(state, action)
  saveState(newState)
  return newState
}

export default persistedReducer
