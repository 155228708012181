import React from 'react'
import PropTypes from 'prop-types'

import CustomToolTip from '../CustomToolTip'
import CopyToClipboard from '../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../Icons'

const {
  BiSolidColorFill,
  GiCarDoor,
  GiGearStickPattern,
  GrDocumentText,
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  LuCalendarDays,
  LuFuel,
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  PiCylinderThin,
  PiSteeringWheel,
  TbEngine,
  VscTypeHierarchy,
} = Icons

const CustomVehicleDetails = ({ vehicleDetails }) => {
  const detailsconvert = (fieldData) => {
    const data = fieldData && fieldData.trim()

    let value = data

    if (data !== 'Standard' && data !== 'Optional') {
      value = data
    }
    if (data === 'Standard') {
      value = 'Yes'
    }
    if (data === 'Optional') {
      value = 'No'
    }
    if (data === '' || !data) {
      value = ''
    }

    return value.toString()
  }

  return (
    <div className='flex flex-wrap items-start order-3 large:items-stretch justify-start m-0 p-0 w-full pb-10 mb-[40px] last:mb-0'>
      <div className='w-full'>
        <div className='pb-10 mb-10 border-0 border-b details_overview last:pb-0 last:mb-0 last:border-b-0 border-gray border-opacity-10'>
          <div className='relative flex flex-wrap items-start justify-start w-full gap-5 p-0 pb-8 m-0 mb-8 border-b last:pb-0 last:mb-0 border-gray/10 last:border-none'>
            <div className='w-full'>
              <div className='flex flex-row items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>
                  Get to know this&nbsp;
                  <b>
                    {detailsconvert(vehicleDetails?.make)}&nbsp;
                    {detailsconvert(vehicleDetails?.model)}
                  </b>
                </h3>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detailsconvert(vehicleDetails?.vin_number) && (
                    <li>
                      <div className='text'>
                        <GrDocumentText className='text-primary' />
                        VIN
                      </div>
                      <div className='value'>
                        <CopyToClipboard>
                          {detailsconvert(vehicleDetails?.vin_number)}
                        </CopyToClipboard>
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.make) && (
                    <li>
                      <div className='text'>
                        <MdCarRepair className='text-primary' />
                        Make
                      </div>
                      <div className='value'>
                        <span className='pe-1'>
                          {detailsconvert(vehicleDetails?.make)}
                        </span>
                      </div>
                    </li>
                  )}

                  {detailsconvert(vehicleDetails?.make) &&
                    detailsconvert(vehicleDetails?.model) && (
                      <li>
                        <div className='text'>
                          <IoCarSportOutline className='text-primary' />
                          Model
                        </div>
                        <div className='value'>
                          <span className='pe-1'>
                            {detailsconvert(vehicleDetails?.make)}
                            &nbsp;
                            {detailsconvert(vehicleDetails?.model)}
                          </span>
                        </div>
                      </li>
                    )}

                  {detailsconvert(vehicleDetails?.trim) && (
                    <li>
                      <div className='text'>
                        <VscTypeHierarchy className='text-primary' />
                        Trim
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.trim)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.year) && (
                    <li>
                      <div className='text'>
                        <LuCalendarDays className='text-primary' />
                        Year
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.year)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.stock_number) && (
                    <li>
                      <div className='text'>
                        <MdOutlineSimCard className='text-primary' />
                        Stock
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.stock_number)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.engine) && (
                    <li>
                      <div className='text'>
                        <TbEngine className='text-primary' />
                        Engine
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.engine)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.fuel_type) && (
                    <li>
                      <div className='text'>
                        <LuFuel className='text-primary' />
                        Fuel Type
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.fuel_type)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {(detailsconvert(vehicleDetails?.odometer) ||
                    detailsconvert(vehicleDetails?.mileage_unit)) && (
                    <li>
                      <div className='text'>
                        <IoSpeedometerOutline className='text-primary' />
                        Odometer
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.odometer)}
                        &nbsp;
                        {detailsconvert(vehicleDetails?.mileage_unit)}
                      </div>
                    </li>
                  )}

                  {detailsconvert(vehicleDetails?.transmission_type) && (
                    <li>
                      <div className='text'>
                        <GiGearStickPattern className='text-primary' />
                        Transmission
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.transmission_type)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.drivetrain) && (
                    <li>
                      <div className='text'>
                        <PiSteeringWheel className='text-primary' />
                        Drive Type
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.drivetrain)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.engine_cylinders) && (
                    <li>
                      <div className='text'>
                        <PiCylinderThin className='text-primary' />
                        Cylinder
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.engine_cylinders)}
                      </div>
                    </li>
                  )}

                  {detailsconvert(vehicleDetails?.doors) && (
                    <li>
                      <div className='text'>
                        <GiCarDoor className='text-primary' />
                        Doors
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.doors)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.standard_seating) && (
                    <li>
                      <div className='text'>
                        <MdAirlineSeatReclineExtra className='text-primary' />
                        Seating
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.standard_seating)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.exterior_color) && (
                    <li>
                      <div className='text'>
                        <BiSolidColorFill className='text-primary' />
                        Exterior Color
                      </div>
                      <div className='value'>
                        <span aria-label={vehicleDetails?.exterior_color}>
                          <CustomToolTip label={vehicleDetails?.exterior_color}>
                            <span></span>
                          </CustomToolTip>
                        </span>
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.interior_color) && (
                    <li>
                      <div className='text'>
                        <BiSolidColorFill className='text-primary' />
                        Interior Color
                      </div>
                      <div className='value'>
                        <span aria-label={vehicleDetails?.interior_color}>
                          <CustomToolTip label={vehicleDetails?.interior_color}>
                            <span></span>
                          </CustomToolTip>
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className='relative flex flex-wrap items-start justify-start w-full gap-5 p-0 pb-8 m-0 mb-8 border-b last:pb-0 last:mb-0 border-gray/10 last:border-none'>
            <div className='w-full'>
              <div className='flex flex-wrap items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>Vehicle conditions</h3>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detailsconvert(vehicleDetails?.ABS_brakes) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>ABS Brakes</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.ABS_brakes)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.front_side_airbag) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          Front Side Airbag
                        </p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.front_side_airbag)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.side_head_curtain_airbag) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>side Air Bag</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(
                          vehicleDetails?.side_head_curtain_airbag,
                        )}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.air_conditioning) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          Air Conditioning
                        </p>
                      </div>
                      <div className='value'>
                        <span className='pe-1'>
                          {detailsconvert(vehicleDetails?.air_conditioning)}
                        </span>
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.child_safety_door_locks) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          Child Safety Door Locks
                        </p>
                      </div>
                      <div className='value'>
                        {detailsconvert(
                          vehicleDetails?.child_safety_door_locks,
                        )}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.cruise_control) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>Cruise Control</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.cruise_control)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.fog_lights) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>Fog Lights</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.fog_lights)}
                      </div>
                    </li>
                  )}

                  {detailsconvert(vehicleDetails?.has_accident) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>Has Accident</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.has_accident)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detailsconvert(vehicleDetails?.front_brake_type) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          front brake type
                        </p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.front_brake_type)}
                      </div>
                    </li>
                  )}

                  {detailsconvert(vehicleDetails?.windshield) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>windshield</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.windshield)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.structural_parts) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          structural parts
                        </p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.structural_parts)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.suspension) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>suspension</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.suspension)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.tires) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>tires</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.tires)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.manual_sunroof) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>Sunroof</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.manual_sunroof)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.navigation_system) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          Navigation System
                        </p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.navigation_system)}
                      </div>
                    </li>
                  )}
                  {detailsconvert(vehicleDetails?.parking_sensors) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>Parking Sensors</p>
                      </div>
                      <div className='value'>
                        {detailsconvert(vehicleDetails?.parking_sensors)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {detailsconvert(vehicleDetails?.description) && (
            <div className='w-full my-6'>
              <div className='flex flex-wrap items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>Description</h3>
              </div>
              <div className='content'>
                <p className='!text-[#3b3b3b]'>
                  {detailsconvert(vehicleDetails?.description)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

CustomVehicleDetails.propTypes = {
  vehicleDetails: PropTypes.shape({
    vin_number: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    trim: PropTypes.string,
    year: PropTypes.string,
    stock_number: PropTypes.string,
    engine: PropTypes.string,
    fuel_type: PropTypes.string,
    odometer: PropTypes.string,
    mileage_unit: PropTypes.string,
    transmission_type: PropTypes.string,
    drivetrain: PropTypes.string,
    engine_cylinders: PropTypes.string,
    doors: PropTypes.string,
    standard_seating: PropTypes.string,
    exterior_color: PropTypes.string,
    interior_color: PropTypes.string,
    ABS_brakes: PropTypes.string,
    front_side_airbag: PropTypes.string,
    side_head_curtain_airbag: PropTypes.string,
    air_conditioning: PropTypes.string,
    child_safety_door_locks: PropTypes.string,
    cruise_control: PropTypes.string,
    fog_lights: PropTypes.string,
    has_accident: PropTypes.string,
    front_brake_type: PropTypes.string,
    windshield: PropTypes.string,
    structural_parts: PropTypes.string,
    suspension: PropTypes.string,
    tires: PropTypes.string,
    manual_sunroof: PropTypes.string,
    navigation_system: PropTypes.string,
    parking_sensors: PropTypes.string,
    description: PropTypes.string,
  }),
}

export default CustomVehicleDetails
