import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import io from 'socket.io-client'
import moment from 'moment'

import Loader from '../../../components/Loader'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import InvitationNotificationYear from '../../../utils/notificationYearFormatter/InvitationNotificationYear'

import Car_img from '../../../assets/car/car.png'
import configManager from '../../../config/configManager'
import { updateRole } from '../../../redux/user/userSlice'

import { Icons } from '../../../components/Icons'

const {
  BsThreeDotsVertical,
  IoDocumentTextSharp,
  FaCar,
  FaUser,
  CgSpinner,
  MdChat,
} = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const Notification = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const observer = useRef()

  const [loadingMoreNotification, setLoadingMoreNotification] = useState(false)
  const [hasMoreNotification, setHasMoreNotification] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  const lastBookElementRef = useCallback(
    (node) => {
      if (loadingMoreNotification) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreNotification) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasMoreNotification, loadingMoreNotification],
  )

  const [isloading, setIsLoading] = useState(false)
  const [isNotificatinLoading, setIsNotificatinLoading] = useState(true)
  const [notificationList, setNotificationList] = useState([])
  const { role } = useSelector((state) => state.user)

  useEffect(() => {
    const fetchNotificationList = async () => {
      setLoadingMoreNotification(true)
      try {
        const response = await axiosInterceptorInstance.get(
          `/notification/buyer/list?page=${pageNumber}`,
        )
        if (response.data.success) {
          setNotificationList((pre) => [...pre, ...response.data.notifications])
          setHasMoreNotification(response.data.hasMore)
          setLoadingMoreNotification(false)
          setIsNotificatinLoading(false)
        }
      } catch (error) {
        setLoadingMoreNotification(false)
        setIsNotificatinLoading(false)
        handleRequestError(error)
      }
    }

    fetchNotificationList()

    socket.once('notification_received', () => {
      fetchNotificationList()
    })

    socket.once('all_notifications_read', () => {
      fetchNotificationList()
    })

    socket.once('single_notifications_read', () => {
      fetchNotificationList()
    })

    return () => {
      socket.off('notification_received', fetchNotificationList)
      socket.off('all_notifications_read', fetchNotificationList)
      socket.off('single_notifications_read', fetchNotificationList)
    }
  }, [pageNumber])

  const handleOpenNotification = async (item) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/buyer/read/${item._id}`,
      )

      if (response.data.success) {
        if (!item?.vehicle_id?.status) {
          navigate(`/`)
          return true
        }

        if (!item?.vehicle_id?.status) {
          navigate(`/`)
          return true
        }

        const vehicleId = item?.vehicle_id?._id

        if (item.role === role) {
          switch (item?.vehicle_id?.status) {
            case 'live':
              navigate(
                role === 'seller'
                  ? `/live/${vehicleId}`
                  : `/active/${vehicleId}`,
              )
              break
            case 'parking':
              if (role === 'seller') {
                navigate(`/parking-lot/${vehicleId}`)
              }
              break
            case 'scheduled':
              navigate(
                role === 'seller'
                  ? `/scheduled/${vehicleId}`
                  : `/upcoming/${vehicleId}`,
              )
              break
            case 'pending':
              navigate(`/pending/${vehicleId}`)
              break
            case 'closed':
              navigate(
                role === 'seller'
                  ? `/closed/${vehicleId}`
                  : `/awarded/${vehicleId}`,
              )
              break
            case 'sold':
              navigate(
                role === 'seller'
                  ? `/sold/${vehicleId}`
                  : `/purchased/${vehicleId}`,
              )
              break
            default:
              break
          }
        } else {
          const userRoleUpdatehandler = async () => {
            try {
              const response = await axiosInterceptorInstance.put(
                `/auth/user/update-role`,
                { role: item.role },
              )

              if (response.data.success) {
                navigate('/')
                dispatch(updateRole(response.data.role))
                let role = response.data.role
                switch (item?.vehicle_id?.status) {
                  case 'live':
                    navigate(
                      role === 'seller'
                        ? `/live/${vehicleId}`
                        : `/active/${vehicleId}`,
                    )
                    break
                  case 'parking':
                    if (role === 'seller') {
                      navigate(`/parking-lot/${vehicleId}`)
                    }
                    break
                  case 'scheduled':
                    navigate(
                      role === 'seller'
                        ? `/scheduled/${vehicleId}`
                        : `/upcoming/${vehicleId}`,
                    )
                    break
                  case 'pending':
                    navigate(`/pending/${vehicleId}`)
                    break
                  case 'closed':
                    navigate(
                      role === 'seller'
                        ? `/closed/${vehicleId}`
                        : `/awarded/${vehicleId}`,
                    )
                    break
                  case 'sold':
                    navigate(
                      role === 'seller'
                        ? `/sold/${vehicleId}`
                        : `/purchased/${vehicleId}`,
                    )
                    break
                  default:
                    break
                }
              }
              setIsLoading(false)
            } catch (error) {
              setIsLoading(false)
            }
          }
          userRoleUpdatehandler()
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const makeAllNotificationAsRead = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.post(
        `/notification/buyer/all/read`,
      )
      if (response.data.success) {
        setIsLoading(false)
      }
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }

  return (
    <Fragment>
      <div className='relative w-full '>
        <div className='sticky top-0 flex flex-wrap items-end justify-between w-full p-0 pb-4 bg-white border-b border-gray/10'>
          <div className='title title-gray'>
            <h2 className='flex flex-wrap items-center capitalize subHeading-3'>
              Notifications &nbsp;
              <BsThreeDotsVertical className='text-primary' />
            </h2>
          </div>
          {notificationList.length > 0 && (
            <div
              className='text-sm font-normal cursor-pointer text-primary'
              onClick={() => !isloading && makeAllNotificationAsRead()}
            >
              <div className='flex gap-1 items-center'>
                {isloading && (
                  <CgSpinner className='w-4 h-4 animate-spin text-primary' />
                )}
                Mark all as read
              </div>
            </div>
          )}
        </div>

        {isNotificatinLoading ? (
          <Loader />
        ) : (
          <ul className='h-[calc(100dvh_-_235px)] overflow-auto flex flex-col p-2 my-2 w-full py-5 gap-y-4  '>
            {notificationList.length > 0 ? (
              notificationList.map((item, key) => {
                if (notificationList.length === key + 1) {
                  return (
                    <li
                      ref={lastBookElementRef}
                      key={key}
                      className={`${
                        item.is_read === false
                          ? 'bg-primary bg-opacity-10 border-primary/10'
                          : 'bg-gray/5 border-gray/10'
                      } rounded-lg cursor-pointer border border-gray/10`}
                      onClick={() => handleOpenNotification(item)}
                    >
                      <div className='flex flex-wrap items-center group/list'>
                        <div className='flex flex-wrap items-center justify-start w-full gap-4 p-0 px-8 pt-3 pb-3 m-0 border-0 border-t border-gray/5'>
                          <div>
                            <div className='flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-primary '>
                              {item.type === 'vehicle' && <FaCar />}
                              {item.type === 'user' && <FaUser />}
                              {item.type === 'document' && (
                                <IoDocumentTextSharp />
                              )}
                              {item.type === 'chat' && <MdChat />}
                              {item.type === 'vehicle_invite' && <FaUser />}
                            </div>
                          </div>
                          <div className='flex flex-col items-start justify-start flex-grow gap-1 max-w-[calc(100%_-_48px)]'>
                            <div>
                              <InvitationNotificationYear
                                message={item.message}
                              />
                            </div>
                          </div>
                        </div>
                        <p className='px-4 py-2 ml-auto text-xs font-light text-right text-gray whitespace-nowrap'>
                          {moment(item?.created_at).format('lll')}
                        </p>
                      </div>
                    </li>
                  )
                } else {
                  return (
                    <li
                      key={key}
                      className={`${
                        item.is_read === false
                          ? 'bg-primary bg-opacity-10 border-primary/10'
                          : 'bg-gray/5 border-gray/10'
                      } rounded-lg cursor-pointer border border-gray/10`}
                      onClick={() => handleOpenNotification(item)}
                    >
                      <div className='flex flex-wrap items-center group/list'>
                        <div className='flex flex-wrap items-center justify-start w-full gap-4 p-0 px-8 pt-3 pb-3 m-0 border-0 border-t border-gray/5'>
                          <div>
                            <div className='flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-primary '>
                              {item.type === 'vehicle' && <FaCar />}
                              {item.type === 'user' && <FaUser />}
                              {item.type === 'document' && (
                                <IoDocumentTextSharp />
                              )}
                              {item.type === 'chat' && <MdChat />}
                              {item.type === 'vehicle_invite' && <FaUser />}
                            </div>
                          </div>
                          <div className='flex flex-col items-start justify-start flex-grow gap-1 max-w-[calc(100%_-_48px)]'>
                            <div>
                              <InvitationNotificationYear
                                message={item.message}
                              />
                            </div>
                          </div>
                        </div>
                        <p className='px-4 py-2 ml-auto text-xs font-light text-right text-gray whitespace-nowrap'>
                          {moment(item?.created_at).format('lll')}
                        </p>
                      </div>
                    </li>
                  )
                }
              })
            ) : (
              <p className='text-center text-gray'>No notifications found</p>
            )}
          </ul>
        )}
        <div className='absolute -right-10 -bottom-10 hidden md:block'>
          <img src={Car_img} alt='car-img' className='h-[170px]' />
        </div>
      </div>
    </Fragment>
  )
}

export default Notification
