import React from 'react'
import { Link } from 'react-router-dom'

import configManager from '../../config/configManager'

import Logo from '../../assets/logo/sidebar-logo.png'

const configData = configManager()

const LeftCard = () => {
  return (
    <div className='card-left'>
      <div className='revvit-logo-wrapper'>
        <Link to={configData.WEBSITE_URL}>
          <img
            src={Logo}
            alt='Logo'
            className='login-form-logo drop-shadow-xl'
          />
        </Link>
      </div>
      <h4 className='mt-10 text-xl font-medium text-white'>
        Welcome to Revvit
      </h4>
    </div>
  )
}

export default LeftCard
