import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Logo from '../../../assets/logo/revvit-logo.png'
import Logow from '../../../assets/logo/sidebar-logo.png'
import cars from '../../../assets/car/car-right.png'

import PersonalDetails from '../../../views/RegistrationProcess/PersonalDetails'
import DealerInformation from '../../../views/RegistrationProcess/DealerInformation'
import FinancialDetails from '../../../views/RegistrationProcess/FinancialDetails'

import axiosRegistrationInstance from '../../../utils/axios/axiosRegistrationInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import {
  getRegistrationToken,
  getToken,
  removeRegistrationToken,
  setRegistrationToken,
  setRole,
} from '../../../utils/cookies/cookies'

const RegistrationProcess = () => {
  const [step, setStep] = useState(1)

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const register_token = searchParams.get('token')
  const role = searchParams.get('role')

  useEffect(() => {
    const configureToken = async () => {
      if (!register_token) {
        const registrationToken = getRegistrationToken()
        const token = getToken()

        if (registrationToken) {
          return setRegistrationToken(registrationToken)
        } else if (token) {
          return setRegistrationToken(token)
        } else {
          removeRegistrationToken()
          return navigate('/login')
        }
      } else {
        try {
          const { data } = await axiosRegistrationInstance.post(
            '/auth/verify-email',
            {
              token: register_token,
            },
          )
          if (data.status === 'continue') {
            setRegistrationToken(register_token)
            setRole(role)
            return navigate('/registration/process')
          }
        } catch (error) {
          handleRequestError(error)
          if (error.response?.data?.status === 'expired') {
            removeRegistrationToken()
            return navigate('/login')
          }
        }
      }
    }

    configureToken()
  }, [searchParams, navigate, register_token, role])

  return (
    <div className='bg-white'>
      <div className='lg:h-screen h-[100dvh] p-4 max-lg:pr-0 pt-10 lg:pt-4'>
        <div className='flex flex-wrap m-0 w-full lg:h-full gap-10 max-h-[calc(100dvh_-_30px)] max-lg:pr-4 max-lg:overflow-y-auto max-lg:pb-10'>
          <div className='w-full lg:max-w-[420px] lg:h-full lg:bg-liner_white rounded-xl overflow-hidden relative'>
            <div className='relative flex flex-col items-start justify-start w-full gap-24 p-0 m-0 lg:py-16'>
              <div className='revvit-logo-wrapper'>
                <img
                  src={Logo}
                  alt='Logo'
                  className='block login-form-logo lg:hidden h-11'
                />
                <img
                  src={Logow}
                  alt='Logo'
                  className='hidden login-form-logo lg:block h-11'
                />
              </div>
              <div className='hidden lg:flex flex-col items-start lg:justify-start gap-8 w-full px-10 large:pl-[25%] large:pr-[20%]'>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 1 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    1
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Personal
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 2 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    2
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Dealer
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
                <div
                  className={`tabs text-white text-sm lg:text-base font-normal cursor-default ${
                    step === 3 && 'active'
                  }`}
                >
                  <span className='flex flex-col items-center justify-center text-xl font-bold text-white bg-transparent border border-white rounded-full w-9 h-9'>
                    3
                  </span>
                  <div className='flex flex-col items-start justify-start gap-1 p-0 m-0 text-xl font-medium'>
                    Financial
                    <span className='text-xs font-normal text-white !bg-transparent'>
                      Information
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='absolute bottom-0 right-0 hidden w-full lg:block'>
              <img
                src={cars}
                alt='Logo'
                className='block h-auto max-w-full mx-auto'
              />
            </div>
          </div>
          <div className='w-full lg:max-w-[calc(100%_-_460px)] lg:h-full flex flex-col justify-start items-center py-8 lg:py-16 overflow-y-auto border border-gray/10 rounded-lg lg:rounded-none lg:border-none'>
            {step === 1 && <PersonalDetails setStep={setStep} />}
            {step === 2 && <DealerInformation setStep={setStep} />}
            {step === 3 && <FinancialDetails setStep={setStep} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationProcess
