import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import Fancybox from '../../../../components/Custom/ImageViewer'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomStopWatch from '../../../../components/Custom/CustomStopWatch'
import CustomTitle from '../../../../components/Custom/CustomTitle'

import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import CustomVehicleDetails from '../../../../components/Custom/CustomBuyVehicleDetails'
import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

const View = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/${id}`,
      )
      if (response.data.success) {
        setvehicleDetails(response.data.vehicleDetails)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <section className='details-single lg:pb-0'>
        <div className='flex flex-wrap items-end justify-start w-full gap-8 p-0 m-0 mb-5'>
          <CustomTitle backBtn />
        </div>
        <div className='flex flex-wrap items-end justify-start w-full gap-8 p-0 m-0 mb-10'>
          <div className='w-full md:flex-2'>
            <div className='flex gap-2 listing-detail-title'>
              <div className='flex flex-wrap items-center justify-start'>
                <h2 className='w-full border-0 max-xsmall:!mb-2 max-xsmall:border-b max-xsmall:!pb-2 xsmall:border-r xsmall:!mr-4 xsmall:!pr-4 border-gray/30 last:border-r-0 last:!m-0 last:!p-0 xsmall:w-auto'>
                  {vehicleDetails?.year}&nbsp;
                  {vehicleDetails?.make}&nbsp;
                  {vehicleDetails?.model}
                </h2>
                <h5 className='subHeading-5 text-gray/90'>
                  VIN :&nbsp;
                  <CopyToClipboard>
                    {vehicleDetails?.vin_number}
                  </CopyToClipboard>
                </h5>
              </div>
              {vehicleDetails?.vehicle_auction?.satus === 'live' && (
                <span className='bg-primary py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                  <CustomStopWatch
                    end_date={vehicleDetails?.vehicle_auction?.end_auction_time}
                    timezone={
                      vehicleDetails?.vehicle_auction?.launch_auction_timezone
                    }
                  />
                  min left
                </span>
              )}
              {vehicleDetails?.vehicle_auction?.satus === 'scheduled' && (
                <span className='px-5 py-2 text-xs font-medium text-center rounded-full bg-primary bg-opacity-10 text-primary '>
                  {moment(
                    vehicleDetails?.vehicle_auction?.launch_auction_date,
                  ).format('YYYY MMM, D')}
                  &nbsp;
                  {moment(
                    vehicleDetails?.vehicle_auction?.launch_auction_date,
                  ).format('h:mm A')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='flex flex-wrap items-start justify-start w-full p-0 m-0 large:items-stretch gap-y-10'>
          <div className='w-full'>
            <Fancybox vehicleImages={vehicleDetails?.vehicle_image} />
          </div>
          <CustomVehicleDetails vehicleDetails={vehicleDetails} />
        </div>
      </section>
    </CustomLoadingSection>
  )
}

export default View
