import React, { Fragment, useEffect, useState } from 'react'
import io from 'socket.io-client'

import { useLocation, useNavigate } from 'react-router-dom'

import CustomToast from '../../../components/Custom/CustomToast'
import CustomModal from '../../../components/Custom/CustomModal'
import CustomTable from '../../../components/Custom/CustomTable'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'
import CustomTitle from '../../../components/Custom/CustomTitle'

import { useStepContext } from '../../../context/useStepContext'

import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'

import configManager from '../../../config/configManager'

import { Icons } from '../../../components/Icons'

const { RiAlertFill, MdOutlineClose } = Icons

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const Vehicle = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location

  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const openDeleteModal = (id) => {
    setDeleteModal(!deleteModal)
    setSelectedId(id)
  }
  const closeDeleteModal = () => {
    setDeleteModal(false)
  }

  const deleteVehicleHandler = async () => {
    if (selectedId) {
      setIsDeleteLoading(true)
      try {
        const response = await axiosInterceptorInstance.delete(
          `/vehicle/seller/vehicle-details/delete/${selectedId}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (response.data.success) {
          CustomToast({
            message: response.data.message
              ? response.data.message
              : 'Deleted Successfully!',
            type: 'success',
          })
          setSelectedId('')
          closeDeleteModal(false)
          setIsDeleteLoading(false)
        }
      } catch (error) {
        setSelectedId('')
        closeDeleteModal(false)
        setIsDeleteLoading(false)
        handleRequestError(error)
      }
    }
  }

  const [isLoading, setIsLoading] = useState(true)
  const [carList, setCarList] = useState([])

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-details/list',
      )
      if (response.data.success) {
        setCarList(response.data.vehicleDetailsList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const { handleStepClick, handleEditStepClick } = useStepContext()
  const handleAddVehicle = () => {
    navigate('/vin/decoder')
    handleStepClick(1)
  }
  const handleEditVehicle = (id) => {
    navigate(`/vehicle/edit/${id}`)
    handleEditStepClick(1)
  }

  useEffect(() => {
    fetchData()

    socket.on('vehicle_expired', () => {
      fetchData()
    })

    socket.on('vehicle_offer_accept', () => {
      fetchData()
    })

    socket.on('vehicle_back_to_parkingloat', () => {
      fetchData()
    })

    socket.on('vehicle_launch', () => {
      fetchData()
    })

    socket.on('upcoming_vehicle_live', () => {
      fetchData()
    })

    return () => {
      socket.off('vehicle_expired', fetchData)
      socket.off('vehicle_offer_accept', fetchData)
      socket.off('vehicle_back_to_parkingloat', fetchData)
      socket.off('vehicle_launch', fetchData)
      socket.off('upcoming_vehicle_live', fetchData)
    }
  }, [deleteModal])

  return (
    <Fragment>
      <CustomTitle addBtn handleAddClick={handleAddVehicle} />
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTable
          data={carList}
          pathname={pathname}
          handleEditVehicle={handleEditVehicle}
          openDeleteModal={openDeleteModal}
          gridClass='lg:w-auto w-full grid max-lg:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 lg:max-w-none gap-5 border-b border-gray border-opacity-10 place-items-center py-3 lg:py-5 last:mb-0 last:pb-0 last:border-b-0'
          showStatus
        />
      </CustomLoadingSection>
      {deleteModal && (
        <CustomModal
          handleCloseModal={closeDeleteModal}
          closeModal={deleteModal}
        >
          <div
            className='close-ico absolute top-[10px] right-[10px] cursor-pointer'
            onClick={closeDeleteModal}
          >
            <MdOutlineClose className='text-3xl' />
          </div>
          <div className='modal-content'>
            <div className='flex flex-wrap justify-center alert-ico'>
              <RiAlertFill />
            </div>
            <div className='flex items-center justify-center text-center'>
              <div className='title title-gray'>
                <h5 className='heading-5'>
                  Are you sure you want to delete this vehicle?
                </h5>
              </div>
            </div>

            <div className='flex flex-wrap justify-center w-full gap-5'>
              <button
                onClick={closeDeleteModal}
                className='btn btn-gray max-xl:flex-auto'
              >
                No, cancel
              </button>
              <button
                onClick={deleteVehicleHandler}
                className={`btn btn-primary max-xl:flex-auto ${
                  isDeleteLoading && 'hover:bg-[#fff]'
                }`}
              >
                {isDeleteLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full loader animate-spin border-t-transparent' />
                ) : (
                  "Yes, I'm sure"
                )}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </Fragment>
  )
}

export default Vehicle
