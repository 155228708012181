import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Field, FormikProvider, useFormik } from 'formik'
import { useSearchParams, useNavigate } from 'react-router-dom'

import CustomRadioInput from '../../../../../custom/CustomRadioInput'
import CustomToast from '../../../../../components/Custom/CustomToast'
import CustomLoadingSection from '../../../../../components/Custom/CustomLoadingSection'
import { useStepContext } from '../../../../../context/useStepContext'

import axiosInterceptorInstance from '../../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../../utils/axios/handleRequestError'

import { Icons } from '../../../../../components/Icons'

const { TiArrowDown, MdArrowRightAlt } = Icons

const structural_partsList = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Repaired', label: 'Repaired' },
  { value: 'Altered', label: 'Altered' },
  { value: 'Damaged', label: 'Damaged' },
]

const radioOption = [
  { value: 'Standard', label: 'Yes' },
  { value: 'Optional', label: 'No' },
]

const windshieldList = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Chipped (Passenger Side)', label: 'Chipped (Passenger Side)' },
  { value: 'Cracked', label: 'Cracked' },
  { value: 'Chipped (Driver Side)', label: 'Chipped (Driver Side)' },
]
const tiresList = [
  { value: 'Needs 1 Tire', label: 'Needs 1 Tires' },
  { value: 'Needs 2 Tires', label: 'Needs 2 Tires' },
  { value: 'Needs 3 Tires', label: 'Needs 3 Tires' },
  { value: 'Needs 4 Tires', label: 'Needs 4 Tires' },
  { value: 'Good', label: 'Good' },
]
const suspensionList = [
  { value: 'Normal', label: 'Normal' },
  { value: 'Modified', label: 'Modified' },
]
const braketypelist = [
  { value: 'Disc', label: 'Disc' },
  { value: 'Drum', label: 'Drum' },
  { value: 'Composite', label: 'Composite' },
  { value: 'Regenerative', label: 'Regenerative' },
  { value: 'Carbon Ceramic', label: 'Carbon Ceramic' },
]

const VehicleConditions = () => {
  const data = useSelector((store) => store.vehicle)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const vehicle = searchParams.get('vehicle')

  const formik = useFormik({
    onSubmit: (values, formikHelpers, additionalProps) =>
      handleSubmit(values, formikHelpers, additionalProps),
    initialValues: {
      vin_number: '',
      structural_parts: '',
      windshield: '',
      tires: '',
      suspension: '',
      air_conditioning: '',
      ABS_brakes: '',
      cruise_control: '',
      power_windows: '',
      front_side_airbag: '',
      side_head_curtain_airbag: '',
      parking_sensors: '',
      navigation_system: '',
      fog_lights: '',
      manual_sunroof: '',
      child_safety_door_locks: '',
      front_brake_type: '',
      tire_pressure_monitor: '',
      description: '',
    },
  })

  const getvehicleDetails = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/${vehicle}`,
      )
      const fieldsToSet = {
        ...formik.values,
        ...response.data.vehicleDetails,
      }

      Object.entries(fieldsToSet).forEach(([key, value]) => {
        if (value !== '' && value !== undefined) {
          formik.setFieldValue(key, value)
        }
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [vehicle])

  useEffect(() => {
    getvehicleDetails()
  }, [])

  const { step, handleStepClick, steps } = useStepContext()
  const goToNextStep = () => {
    if (step < steps.length) {
      handleStepClick(step + 1)
    }
  }
  const goToPreviousStep = () => {
    if (step > 1) {
      handleStepClick(step - 1)
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (values, { resetForm }, additionalProps) => {
    try {
      setIsLoading(true)
      const response = await axiosInterceptorInstance.put(
        `/vehicle/seller/vehicle-details/update/${vehicle}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data.success) {
        if (additionalProps) {
          navigate(`/vehicle`)
          CustomToast({
            message: 'The vehicle has been successfully moved to draft.',
            type: 'success',
          })
          setIsLoading(false)
        } else {
          resetForm()
          setIsLoading(false)
          goToNextStep()
          CustomToast({
            message:
              response.data.message ||
              'Vehicle conditions successfully updated.',
            type: 'success',
          })
        }
      }
    } catch (error) {
      resetForm()
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  function convertToSnakeCase(input) {
    const words = input.toLowerCase().split(' ')
    const snakeCase = words.join('_')
    return snakeCase.toUpperCase()
  }

  const updateFormValues = useCallback((formdata, formattedData) => {
    Object.keys(formdata)?.forEach((key) => {
      formattedData?.forEach((formattedElement) => {
        const formattedKey = Object.keys(formattedElement)[0]
        if (key.toUpperCase() === convertToSnakeCase(formattedKey)) {
          formik.setFieldValue(key, formattedElement[formattedKey])
        }
      })
    })
  }, [])

  useEffect(() => {
    const formattedData = data.equipments?.map(
      ({ name, availability, value }) => {
        const fieldValue = value !== '' ? value : availability
        return { [name]: fieldValue }
      },
    )

    updateFormValues(formik.initialValues, formattedData)
  }, [data])

  const vehicleSaveAsDrafthandler = () => {
    handleSubmit(formik.values, formik, 'drafthandler')
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex flex-wrap gap-10 large:gap-16'>
            <Field
              name='child_safety_door_locks'
              label='Safety Door Locks'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='front_side_airbag'
              label='Front Air Bags'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='side_head_curtain_airbag'
              label='Side Air Bags'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='ABS_brakes'
              label='Anti-Brake System'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='tire_pressure_monitor'
              label='Tire Pressure Monitor'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />

            <Field
              name='parking_sensors'
              label='Parking Sensors'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='air_conditioning'
              label='Air Conditioning'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />

            <Field
              name='cruise_control'
              label='Cruise Control'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='power_windows'
              label='Power Windows'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />

            <Field
              name='navigation_system'
              label='Navigation System'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='fog_lights'
              label='Fog Lights'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />
            <Field
              name='manual_sunroof'
              label='Sunroof'
              component={CustomRadioInput}
              options={radioOption}
              className='custom-radio'
            />

            <Field
              name='front_brake_type'
              label='Brake Type'
              component={CustomRadioInput}
              options={braketypelist}
              className='custom-radio'
            />
            <Field
              name='structural_parts'
              label='Structural Parts are Damaged / Altered / or Repaired'
              component={CustomRadioInput}
              options={structural_partsList}
              className='custom-radio'
            />
            <Field
              name='windshield'
              label='Windshield'
              component={CustomRadioInput}
              options={windshieldList}
              className='custom-radio'
            />
            <Field
              name='tires'
              label='Tires'
              component={CustomRadioInput}
              options={tiresList}
              className='custom-radio'
            />
            <Field
              name='suspension'
              label='Suspension'
              component={CustomRadioInput}
              options={suspensionList}
              className='custom-radio'
            />
            <div className='flex items-center justify-start w-full'>
              <div className='w-full'>
                <h5 className='flex items-center gap-1 pb-3 mb-3 text-base font-medium border-b text-gray md:mb-2 md:pb-0 border-gray border-opacity-10 md:border-0'>
                  Additional Information
                </h5>
                <Field
                  id='message'
                  as='textarea'
                  rows={4}
                  name='description'
                  label='Adetional Information'
                  className='form-textarea'
                />
              </div>
            </div>
          </div>
          <div className='flex justify-between  mt-8'>
            <button
              type='reset'
              className='prev next-prev-button '
              onClick={goToPreviousStep}
            >
              <MdArrowRightAlt /> Back
            </button>
            <div className='flex flex-col  sm:flex-row gap-2 justify-end'>
              <button
                className='next-prev-button  btn btn-primary-ico'
                type='button'
                disabled={formik.isSubmitting}
                onClick={vehicleSaveAsDrafthandler}
              >
                Save as Draft <TiArrowDown />
              </button>

              <button
                className='next-prev-button  btn btn-primary-ico'
                type='submit'
                disabled={formik.isSubmitting}
              >
                {isLoading ? (
                  <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
                ) : (
                  <Fragment>
                    Save & Next
                    <MdArrowRightAlt />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

export default VehicleConditions
