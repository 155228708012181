import React from 'react'
import PropTypes from 'prop-types'

const Show = ({ children, when }) => {
  return when ? <>{children}</> : null
}

Show.propTypes = {
  children: PropTypes.node,
  when: PropTypes.bool,
}

export default Show
