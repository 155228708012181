import React, { Fragment, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Search from './Search'

import { Icons } from '../../components/Icons'

const { IoCloseOutline } = Icons

const SearchSidebar = ({
  searchSidebarOpen,
  setSearchSidebarOpen,
  searchref,
}) => {
  const searchtrigger = useRef(null)
  const searchsidebar = useRef(null)

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!searchSidebarOpen || keyCode !== 27) return
      setSearchSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)

    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (searchref?.current?.contains(target)) return
      if (searchsidebar?.current?.contains(target)) return
      setSearchSidebarOpen(false)
    }

    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  return (
    <Fragment>
      <aside
        ref={searchsidebar}
        className={`fixed md:absolute border-r border-primary left-0 top-0 z-[99999] md:z-[9999] flex  w-full md:w-[450px] h-[calc(100dvh_-_48px)] lg:h-screen flex-col overflow-y-hidden bg-[rgba(250,_249,_245,_1)] duration-300 ease-linear ${
          searchSidebarOpen
            ? 'translate-x-[0px] md:translate-x-[270px]'
            : '-translate-x-full'
        }`}
      >
        <div className='absolute top-0 right-0 flex items-center justify-end gap-2 px-4 py-5'>
          <button
            ref={searchtrigger}
            onClick={() => setSearchSidebarOpen(false)}
          >
            <IoCloseOutline className='text-xl text-primary' />
          </button>
        </div>
        <Search />
      </aside>
    </Fragment>
  )
}

SearchSidebar.propTypes = {
  searchSidebarOpen: PropTypes.bool.isRequired,
  setSearchSidebarOpen: PropTypes.func.isRequired,
  searchref: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
}

export default SearchSidebar
