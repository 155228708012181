import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import io from 'socket.io-client'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomListView from '../../../../components/Custom/CustomListView'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

import configManager from '../../../../config/configManager'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const ActiveList = () => {
  const location = useLocation()
  const { pathname } = location

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleList, setVehicleList] = useState(null)

  const fetchActiveVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/active',
      )
      if (response.data.success) {
        setVehicleList(response.data.vehicleList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchActiveVehicleList()
    if (pathname === '/active') {
      socket.on('vehicle_expired', () => {
        fetchActiveVehicleList()
      })

      socket.on('vehicle_offer_accept', () => {
        fetchActiveVehicleList()
      })

      socket.on('vehicle_back_to_parkingloat', () => {
        fetchActiveVehicleList()
      })

      socket.on('vehicle_launch', () => {
        fetchActiveVehicleList()
      })

      socket.on('upcoming_vehicle_live', () => {
        fetchActiveVehicleList()
      })
    }

    return () => {
      socket.off('vehicle_expired', fetchActiveVehicleList)
      socket.off('vehicle_offer_accept', fetchActiveVehicleList)
      socket.off('vehicle_back_to_parkingloat', fetchActiveVehicleList)
      socket.off('vehicle_launch', fetchActiveVehicleList)
      socket.off('upcoming_vehicle_live', fetchActiveVehicleList)
    }
  }, [pathname])

  return (
    <Fragment>
      {pathname === '/active' ? (
        <CustomTitle />
      ) : (
        <CustomTitle customTitle='Active Vehicles' />
      )}
      <CustomListView isLoading={isLoading} data={vehicleList} />
    </Fragment>
  )
}

export default ActiveList
