import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Logo from '../../../assets/logo/sidebar-logo.png'

import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import {
  getRole,
  removeRole,
  removeToken,
  setRole,
  setToken,
} from '../../../utils/cookies/cookies'
import { signOut, updateRole } from '../../../redux/user/userSlice'
import { RemoveEditVehicleDetails } from '../../../redux/vehicle/vehicleSlice'

const SelectRole = () => {
  const dispatch = useDispatch()

  const isRoles = getRole()
  const location = useLocation()
  const { state } = location

  const roles = useMemo(() => ['seller', 'buyer'], [])
  const navigate = useNavigate()

  useEffect(() => {
    if (roles.includes(isRoles)) {
      navigate('/')
    }
    if (!state.token) {
      navigate('/login')
      removeRole()
      removeToken()
      dispatch(signOut())
      dispatch(RemoveEditVehicleDetails())
    }
  }, [isRoles, state.token, navigate, dispatch, roles])

  const { id } = useParams()
  const [isError, setError] = useState(null)
  const [userRole, setUserRole] = useState('null')
  const [isLoading, setIsloading] = useState(false)

  const submitRole = async () => {
    if (roles.includes(userRole)) {
      setIsloading(true)
      const role = userRole
      try {
        const response = await axiosInterceptorInstance.put(
          `/auth/update-role/${id}`,
          { role },
        )
        if (response?.data?.success) {
          setToken(state.token)
          setRole(response.data.role)
          dispatch(updateRole(response.data.role))
          navigate('/')
        }
      } catch (error) {
        setError(error.response.data.message)
      } finally {
        setIsloading(false)
      }
    } else {
      setError('The role provided is invalid.')
    }
  }

  const slectrolehandler = (e) => {
    setError(null)
    setUserRole(e.target.value)
  }

  return (
    <div className='login-root'>
      <div className='grid grid-cols-1 lg:grid-cols-2 m-0 p-0 w-full lg:h-full h-auto overflow-y-auto'>
        <div className='card-left'>
          <div className='revvit-logo-wrapper'>
            <a href='https://revvit.vercel.app/'>
              <img
                src={Logo}
                alt='Logo'
                className='login-form-logo drop-shadow-xl'
              />
            </a>
          </div>
          <h4 className='text-xl font-semibold text-white mt-10'>
            Welcome to Revvit
          </h4>
        </div>
        <div className={`login-card-root`}>
          <div className='title text-center'>
            <h1>
              Just <span className='text-primary'>One</span> More Step!
            </h1>
            <p>Please specify your identity?</p>
          </div>
          <div className='card-block relative flex flex-wrap items-center justify-center'>
            <div className='w-full justify-center flex gap-2 items-center mb-10'>
              <label className='buyer'>
                <input
                  type='radio'
                  name='role'
                  value='buyer'
                  onClick={slectrolehandler}
                />
                <span> Buyer</span>
              </label>
              <span className='text-gray text-opacity-40 text-lg px-2 sm:px-5'>
                OR
              </span>
              <label className='seller'>
                <input
                  type='radio'
                  name='role'
                  value='seller'
                  onClick={slectrolehandler}
                />
                <span> Seller</span>
              </label>
            </div>
            <div className='flex flex-col w-full items-center gap-2'>
              {isError && (
                <div className='text-center text-red'> {isError}</div>
              )}
              <button
                disabled={isLoading}
                onClick={submitRole}
                className='px-8 py-2 bg-primary text-white border border-primary rounded-md hover:bg-gray hover:border-gray w-full max-w-[60%]'
              >
                {isLoading ? (
                  <div className='m-auto h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
                ) : (
                  'Continue'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectRole
