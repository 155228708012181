import React, { Fragment, useEffect, useState } from 'react'
import io from 'socket.io-client'

import CustomListView from '../../../components/Custom/CustomListView'
import CustomTitle from '../../../components/Custom/CustomTitle'

import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import configManager from '../../../config/configManager'

const companyData = configManager()
const socket = io(companyData.API_BASE_URL)

const Vehicle = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [vehicleList, setVehicleList] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInterceptorInstance.get(
          '/vehicle/buyer/vehicle-details/list',
        )
        if (response.data.success) {
          setVehicleList(response.data.vehicleList)
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        handleRequestError(error)
      }
    }

    fetchData()

    // Define separate handler functions for each socket event
    const handleVehicleExpired = () => {
      fetchData()
    }

    const handleVehicleOfferAccept = () => {
      fetchData()
    }

    const handleVehicleBackToParkingloat = () => {
      fetchData()
    }

    const handleVehicleLaunch = () => {
      fetchData()
    }

    const handleUpcomingVehicleLive = () => {
      fetchData()
    }

    const handleVehicleSold = () => {
      fetchData()
    }

    // Listen to each socket event only once
    socket.once('vehicle_expired', handleVehicleExpired)
    socket.once('vehicle_offer_accept', handleVehicleOfferAccept)
    socket.once('vehicle_back_to_parkingloat', handleVehicleBackToParkingloat)
    socket.once('vehicle_launch', handleVehicleLaunch)
    socket.once('upcoming_vehicle_live', handleUpcomingVehicleLive)
    socket.once('vehicle_sold', handleVehicleSold)

    return () => {
      // Remove the event listeners when the component unmounts
      socket.off('vehicle_expired', handleVehicleExpired)
      socket.off('vehicle_offer_accept', handleVehicleOfferAccept)
      socket.off('vehicle_back_to_parkingloat', handleVehicleBackToParkingloat)
      socket.off('vehicle_launch', handleVehicleLaunch)
      socket.off('upcoming_vehicle_live', handleUpcomingVehicleLive)
      socket.off('vehicle_sold', handleVehicleSold)
    }
  }, [])

  return (
    <Fragment>
      <CustomTitle customTitle='Vehicles' />
      <CustomListView isLoading={isLoading} data={vehicleList} />
    </Fragment>
  )
}

export default Vehicle
