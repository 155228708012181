import React, { useEffect, useState } from 'react'

import CustomListView from '../../../../components/Custom/CustomListView'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'

const AwardedList = () => {
  const [awardedIsLoading, setAwardedIsLoading] = useState(true)
  const [awardedVehicleList, setAwardedVehicleList] = useState([])

  const fetchAwardedVehicleList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/list/awarded',
      )
      if (response.data.success) {
        setAwardedVehicleList(response.data.vehicleList)
        setAwardedIsLoading(false)
      }
    } catch (error) {
      setAwardedIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchAwardedVehicleList()
  }, [])

  return (
    <CustomLoadingSection isLoading={awardedIsLoading}>
      <CustomListView
        CustomNoData='You have not awarded sofar'
        isLoading={awardedIsLoading}
        data={awardedVehicleList}
      />
    </CustomLoadingSection>
  )
}

export default AwardedList
