import React from 'react'

import Home from '../pages/Buyer/Home'
import ViewProfile from '../views/Auth/ViewProfile'

import Vehicle from '../pages/Buyer/Vehicle'
import ViewVehicle from '../pages/Buyer/Vehicle/actions/View'

import Active from '../pages/Buyer/Active'
import ViewActive from '../pages/Buyer/Active/actions/View'

import Upcoming from '../pages/Buyer/Upcoming'
import ViewUpcoming from '../pages/Buyer/Upcoming/actions/View'

import Pending from '../pages/Buyer/Pending'
import ViewPending from '../pages/Buyer/Pending/actions/View'

import Awarded from '../pages/Buyer/Awarded'
import ViewAwarded from '../pages/Buyer/Awarded/actions/View'
import Document from '../views/Common/BillOfSale/Document'

import ViewPurchased from '../pages/Buyer/Purchased/actions/View'

import BuyHistory from '../pages/Buyer/History'

import EditProfile from '../views/Auth/EditProfile'

import Announcement from '../views/Common/Announcement'
import Notification from '../views/Common/Notification'
import Sellerprofile from '../pages/Buyer/SellerProfile'
import SellerBankDetails from '../pages/Buyer/SellerBankDetails'

const BuyerRoutes = [
  { path: '/', element: <Home /> },
  { path: '/vehicle', element: <Vehicle /> },
  { path: '/vehicle/:id', element: <ViewVehicle /> },
  { path: '/active', element: <Active /> },
  { path: '/active/:id', element: <ViewActive /> },
  { path: '/upcoming', element: <Upcoming /> },
  { path: '/upcoming/:id', element: <ViewUpcoming /> },
  { path: '/pending', element: <Pending /> },
  { path: '/pending/:id', element: <ViewPending /> },
  { path: '/awarded', element: <Awarded /> },
  { path: '/awarded/:id', element: <ViewAwarded /> },
  { path: '/purchased/:id', element: <ViewPurchased /> },
  { path: '/document/:id', element: <Document /> },
  { path: '/edit-profile', element: <EditProfile /> },
  { path: '/buy-history', element: <BuyHistory /> },
  { path: '/profile', element: <ViewProfile /> },
  { path: '/announcement/:id', element: <Announcement /> },
  { path: '/notifications', element: <Notification /> },
  { path: '/seller/profile/:id', element: <Sellerprofile /> },
  { path: '/seller/bank/details/:id', element: <SellerBankDetails /> },
]

export default BuyerRoutes
