import React, { useEffect, useState } from 'react'

import CustomTitle from '../../../components/Custom/CustomTitle'

import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../utils/axios/handleRequestError'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import Achart from './achart'

const BuyHistory = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [liveVehicleCount, setLiveVehicleCount] = useState(0)
  const [parkingVehicleCount, setParkingVehicleCount] = useState(0)
  const [scheduledVehicleCount, setScheduledVehicleCount] = useState(0)
  const [pendingVehicleCount, setPendingVehicleCount] = useState(0)
  const [closedVehicleCount, setClosedVehicleCount] = useState(0)

  const fetchData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/buyer/vehicle-details/vehicle-count',
      )
      if (response.data.success) {
        const result = response.data?.result
        setLiveVehicleCount(result?.live || 0)
        setParkingVehicleCount(result?.parking || 0)
        setScheduledVehicleCount(result?.scheduled || 0)
        setPendingVehicleCount(result?.pending || 0)
        setClosedVehicleCount(result?.closed || 0)

        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  let data = {
    labels: ['Parking Lot', 'Live', 'Scheduled', 'Pending', 'Closed'],
    datasets: [
      {
        label: 'Available',
        data: [
          parkingVehicleCount,
          liveVehicleCount,
          scheduledVehicleCount,
          pendingVehicleCount,
          closedVehicleCount,
        ],
        backgroundColor: [
          '#8C0F3D',
          '#BA063C',
          '#333333',
          '#F67905',
          '#CC2423',
        ],
      },
    ],
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <CustomLoadingSection isLoading={isLoading}>
        <CustomTitle />

        <div className='flex flex-wrap items-center justify-between m-0 mt-10 p-0 w-full relative gap-5'>
          <div className='flex-[calc(50%_-_10px)] sm:flex-[calc(16.667%_-_0px)]'>
            <div className='flex flex-col items-strech justify-start m-0 w-full overflow-hidden rounded-xl shadow border border-gray/10 bg-[#8C0F3D]'>
              <div className='title text-white text-center px-4 pt-8 pb-2'>
                <h5 className='subHeading-4 font-medium uppercase'>
                  Parking Lot
                </h5>
              </div>
              <div className='title title-white text-center px-4 py-8 flex items-center justify-center'>
                <h2 className='text-6xl font-medium'>{parkingVehicleCount}</h2>
              </div>
            </div>
          </div>

          <div className='flex-[calc(50%_-_10px)] sm:flex-[calc(16.667%_-_0px)]'>
            <div className='flex flex-col items-strech justify-start m-0 w-full overflow-hidden rounded-xl shadow border border-gray/10 bg-[#BA063C]'>
              <div className='title text-white text-center px-4 pt-8 pb-2'>
                <h5 className='subHeading-4 font-medium uppercase'>Live</h5>
              </div>
              <div className='title title-white text-center px-4 py-8 flex items-center justify-center'>
                <h2 className='text-6xl font-medium'>{liveVehicleCount}</h2>
              </div>
            </div>
          </div>

          <div className='flex-[calc(50%_-_10px)] sm:flex-[calc(16.667%_-_0px)]'>
            <div className='flex flex-col items-strech justify-start m-0 w-full overflow-hidden rounded-xl shadow border border-gray/10 bg-[#333333]'>
              <div className='title text-white text-center px-4 pt-8 pb-2'>
                <h5 className='subHeading-4 font-medium uppercase'>
                  Scheduled
                </h5>
              </div>
              <div className='title title-white text-center px-4 py-8 flex items-center justify-center'>
                <h2 className='text-6xl font-medium'>
                  {scheduledVehicleCount}
                </h2>
              </div>
            </div>
          </div>

          <div className='flex-[calc(50%_-_10px)] sm:flex-[calc(16.667%_-_0px)]'>
            <div className='flex flex-col items-strech justify-start m-0 w-full overflow-hidden rounded-xl shadow border border-gray/10 bg-[#F67905]'>
              <div className='title text-white text-center px-4 pt-8 pb-2'>
                <h5 className='subHeading-4 font-medium uppercase'>Pending</h5>
              </div>
              <div className='title title-white text-center px-4 py-8 flex items-center justify-center'>
                <h2 className='text-6xl font-medium'>{pendingVehicleCount}</h2>
              </div>
            </div>
          </div>

          <div className='flex-[calc(50%_-_10px)] sm:flex-[calc(16.667%_-_0px)]'>
            <div className='flex flex-col items-strech justify-start m-0 w-full overflow-hidden rounded-xl shadow border border-gray/10 bg-[#CC2423]'>
              <div className='title text-white text-center px-4 pt-8 pb-2'>
                <h5 className='subHeading-4 font-medium uppercase'>Closed</h5>
              </div>
              <div className='title title-white text-center px-4 py-8 flex items-center justify-center'>
                <h2 className='text-6xl font-medium'>{closedVehicleCount}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap items-start justify-start m-0 p-0 mt-10 w-full relative gap-5'>
          {/* <div className='w-full max-w-full md:max-w-[calc(50%_-_10px)] xl:max-w-[calc(33.333%_-_10px)]'>
            <div className='flex flex-col items-center justify-center m-0 lg:p-10 p-5 w-full border border-gray/10 rounded-xl'>
              <Achart data={data} />
            </div>
          </div> */}

          <div className='w-full max-w-full '>
            <div className='flex flex-col items-center justify-center m-0 lg:p-10 p-5 w-fit border border-gray/10 rounded-xl'>
              <Achart data={data} />
            </div>
          </div>

          {/* <div className='w-full  max-w-full md:max-w-[calc(50%_-_10px)] xl:max-w-[calc(66.667%_-_10px)]'>
            <Bchart />
          </div> */}
        </div>
      </CustomLoadingSection>
    </div>
  )
}

export default BuyHistory
